<template>
    <div class="client" v-loading="allLoad">
        <div class="client-left" v-if="isAssign">
            <div class="filter">
                <el-select v-model="nurStatus" style="width: 100px" @change="getNurses()">
                    <el-option label="全部状态" value=""></el-option>
                    <el-option label="合约中" value="合约中"></el-option>
                    <el-option label="中止中" value="中止中"></el-option>
                    <el-option label="已终止" value="已终止"></el-option>
                    <el-option label="待审核" value="待审核"></el-option>
                    <el-option label="已删除" value="已删除"></el-option>
                </el-select>
                &nbsp;<el-cascader
                    v-model="contractTypeAndSiteInquireValue"
                    :options="contractTypeAndSiteInquireValueOptions"
                    :props="{ expandTrigger: 'hover' }"
                    @change="siteChangeOperating()"
                    style="width:200px"></el-cascader>
                <el-input v-model="nurText" class="inp line" placeholder="姓名、联系号码、地址" clearable
                          @keyup.enter.native="getNurses"></el-input>
            </div>
            <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1"></pageTemp>
            <div class="client-ul">
                <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                              view-class="p20-scrollbar__view">
                    <div class="client-list" v-for="item in nurseArr" :key="item.id"
                         @click="nurseDes(item.id)" :class="{'active-class':item.id==staffId}">
                        <div v-if="item.status == '合约中'" class="stateGreen"><span>{{item.status}}</span></div>
                        <div v-else-if="item.status == '已终止'" class="stateRed"><span>{{item.status}}</span></div>
                        <div v-else-if="item.status == '中止中'" class="stateRed"><span>{{item.status}}</span></div>
                        <div v-else-if="item.status == '待审核'" class="stateYellow"><span>{{item.status}}</span></div>
                        <div v-else-if="item.status == '已删除'" class="stateDarkRed"><span>{{item.status}}</span></div>
                        <div class="flex number">
                            <p class="num">{{item.numbering}}</p>
                        </div>
                        <p>{{item.name}} ({{item.gender}}) | {{item.moblle}}</p>
                        <p>{{item.statusChangetime}}</p>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="client-right">
            <div class="right-center">
                <div class="center-one">
                    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                  view-class="p20-scrollbar__view">
                        <div class="client-list" style="position: relative;" v-for="item in dates" :key="item"
                             @click="getDates(item)" :class="{'active-class':item==dateList}">
                            <div class="left-border"></div>
                            <h2>{{item}}</h2>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="center-two" v-loading="loading" :style="clientRight" v-if="this.billingDetailsModule.customerWorkOrderSettlement">
                    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                  view-class="p20-scrollbar__view">
                        <div class="client-list" style="position: relative"
                             v-for="item in customerWorkOrderSettlementList" :key="item.id"
                             @click="getCustomer(item.id)" :class="{'active-class':item.id==customerId}">
                            <div class="left-border"></div>
                            <h2>{{item.patientName}} ({{item.gender}}) | {{item.wordOrderCount}} 单</h2>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="center-four" v-loading="loadingtwo" :style="clientRight" v-if="this.billingDetailsModule.customerWorkOrderSettlement">
                    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                  view-class="p20-scrollbar__view">
                        <div class="client-list" style="position: relative;" v-for="item in workDateList" :key="item">
                            <div class="left-border"></div>
                            <h2>{{item}}</h2>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="center-three" v-loading="loadingtwo" :style="clientRight">
                    <div class="client-up">
                        <!-- 里面写eleCalendar组件-->
                        <ele-calendar
                                :render-content="renderContent"
                                :data="datedef"
                                :prop="prop"
                                :defaultValue="defaultValue"
                        ></ele-calendar>
                    </div>
                    <div class="client-down">
                        <el-form v-if="this.billingDetailsModule.caregiverBillingExportButton">
                            <el-form-item label="导出已结算 月份筛选">
                                <el-date-picker
                                        v-model="month"
                                        type="month"
                                        placeholder="选择月">
                                </el-date-picker>&nbsp;&nbsp;
                                <el-button size="small" type="success" @click="caregiverBillingExportButton()">导出
                                </el-button>
                                <el-button size="small" type="primary" @click="caregiverBillingAllExportButton()">导出全部
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <br>
                        <el-form v-if="this.billingDetailsModule.noSettlementWorkOrderListExportButton">
                            <el-form-item label="导出未结算 日期筛选">
                                <el-date-picker
                                        v-model="date"
                                        type="date"
                                        placeholder="选择日期">
                                </el-date-picker>&nbsp;&nbsp;
                                <el-button size="small" type="success" @click="noSettlementWorkOrderExportButton()">导出
                                </el-button>
                                <el-button size="small" type="primary" @click="noSettlementWorkOrderAllExportButton()">
                                    导出全部
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="center-five">

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment';
    import eleCalendar from 'ele-calendar';
    import 'ele-calendar/dist/vue-calendar.css';
    import pageTemp from "@/components/pageTemp.vue";
    import {
        careList,
        settlementDateList,
        customerWorkOrderSettlement,
        theDateOfTheWorkOrderIsDisplayed,
        caregiverBillingExportButton,
        noSettlementWorkOrderButton,
        siteChange
    } from "@/common/js/index.js";

    export default {
        name: "caregiverBilling",
        components: {pageTemp, eleCalendar},
        data() {
            return {
                contractTypeId: '1',
                contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
                contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
                date: '',
                month: '',
                searchForm: {
                    status: [],
                    keyWords: '',
                    date: null,
                },
                workDateList: [],
                defaultValue: new Date(),//日历默认显示月份
                datedef: [],
                prop: 'date',//对应日期字段名
                customerId: '',
                customerWorkOrderSettlementList: [],
                loading: false,
                loadingtwo: false,
                dates: [],
                dateList: '',
                adressForm: {
                    area: "",
                    sreet: "",
                    committees: ""
                },
                addressPage: 1,
                addressTotal: 0,
                addressPSize: 0,
                addressData: [],
                addressDialog: false,
                clientRight: {
                    width: ""
                },
                isAssign: false,
                clientData: [],//员工名下客户
                operaDialog: false,//操作弹窗
                operaForm: {
                    targetStatus: "",
                    statusRemarks: ""
                },
                formInline: {
                    logSTime: ""//日志时间筛选
                },
                siteValue: sessionStorage.getItem("siteValue"),
                allLoad: true,
                nurStatus: "",
                nurText: "",
                pages: 0,
                nurseArr: [],
                currPage: 1,
                isadd: false,
                staffOpera: [],
                contractTime: "",
                staffId: null,
                imageUrl: '',
                activeName: 'first',

                inputVisible: false,
                inputValue: '',
                logSPage: 1,
                logSData: [],
                logSTotal: 0,
                billingDetailsModule:{
                  billingDetailsList:false,
                  batchImportBillingDetails:false,
                  caregiverList:false,
                  automaticBinding:false,
                  settlementDateList:false,
                  customerWorkOrderSettlement:false,
                  theDateOfTheWorkOrderIsDisplayed:false,
                  caregiverBillingExportButton:false,
                  noSettlementWorkOrderListExportPython:false,
                  noSettlementWorkOrderListExportButton:false
                }
            }
        },
        methods: {
            siteChangeOperating(){
              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.billingDetailsModule.billingDetailsList = this.isExist(permissionList,"billingDetails:billingDetailsList");
                  this.billingDetailsModule.batchImportBillingDetails = this.isExist(permissionList,"billingDetails:batchImportBillingDetails");
                  this.billingDetailsModule.caregiverList = this.isExist(permissionList,"billingDetails:caregiverList");
                  this.billingDetailsModule.automaticBinding = this.isExist(permissionList,"billingDetails:automaticBinding");
                  this.billingDetailsModule.settlementDateList = this.isExist(permissionList,"billingDetails:settlementDateList");
                  this.billingDetailsModule.customerWorkOrderSettlement = this.isExist(permissionList,"billingDetails:customerWorkOrderSettlement");
                  this.billingDetailsModule.theDateOfTheWorkOrderIsDisplayed = this.isExist(permissionList,"billingDetails:theDateOfTheWorkOrderIsDisplayed");
                  this.billingDetailsModule.caregiverBillingExportButton = this.isExist(permissionList,"billingDetails:caregiverBillingExportButton");
                  this.billingDetailsModule.noSettlementWorkOrderListExportPython = this.isExist(permissionList,"billingDetails:noSettlementWorkOrderListExportPython");
                  this.billingDetailsModule.noSettlementWorkOrderListExportButton = this.isExist(permissionList,"billingDetails:noSettlementWorkOrderListExportButton");
                  this.getNurses();

                }
              })

            },
            isExist(permissionList,str) {
              if (permissionList.indexOf(str) !== -1) {
                return true;
              } else {
                return false;
              }
            },
            p(s) {
                return s < 10 ? '0' + s : s
            },
            /*导出护理员未结算工单日期*/
            noSettlementWorkOrderExportButton() {
                this.$confirm('生成护理员未结算日期中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (this.date === '') {
                        alert("日期选择不能为空")
                    } else if (this.staffId === null) {
                        alert("导出单个护理员时请选择护理员")
                    } else {
                        var d = this.date;
                        // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 20190307
                        const resDate = d.getFullYear() + '' + this.p((d.getMonth() + 1)) + '' + this.p(d.getDate())
                        let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + "&caregiverId=" + this.staffId + "&date=" + resDate+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                        noSettlementWorkOrderButton(subData).then(res => {
                            if (res) {
                                this.tableData = res.data
                                for (let i = 0; i < this.tableData.length; i++) {
                                    if (this.tableData[i].date != null && this.tableData[i].date != '') {
                                        this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                    }
                                }
                                this.$message.success('护理员结算导出成功,请到报表中进行查看下载');
                            }
                        })
                    }
                }).catch(() => {
                });
            },

            /*导出全部护理员未结算工单日期*/
            noSettlementWorkOrderAllExportButton() {
                this.$confirm('生成护理员未结算日期中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (this.date === '') {
                        alert("日期选择不能为空")
                    } else {
                        var d = this.date;
                        // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 20190307
                        const resDate = d.getFullYear() + '' + this.p((d.getMonth() + 1)) + '' + this.p(d.getDate())
                        let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + "&caregiverId=" + '' + "&date=" + resDate+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                        noSettlementWorkOrderButton(subData).then(res => {
                            if (res) {
                                this.tableData = res.data
                                for (let i = 0; i < this.tableData.length; i++) {
                                    if (this.tableData[i].date != null && this.tableData[i].date != '') {
                                        this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                    }
                                }
                                this.$message.success('护理员结算导出成功,请到报表中进行查看下载');
                            }
                        })
                    }
                }).catch(() => {
                });
            },
            /*护理员结算导出全部*/
            caregiverBillingAllExportButton() {
                this.$confirm('生成护理员结算中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (this.month === '') {
                        alert("日期选择不能为空")
                    } else {
                        var d = this.month;
                        // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 201903
                        const resDate = d.getFullYear() + '' + this.p((d.getMonth() + 1))

                        let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + "&month=" + resDate + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                        caregiverBillingExportButton(subData).then(res => {
                            if (res) {
                                this.tableData = res.data
                                for (let i = 0; i < this.tableData.length; i++) {
                                    if (this.tableData[i].date != null && this.tableData[i].date != '') {
                                        this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                    }
                                }
                                this.$message.success('护理员结算导出成功,请到报表中进行查看下载');
                            }
                        })
                    }
                }).catch(() => {
                });
            },
            /*护理员结算导出*/
            caregiverBillingExportButton() {
                this.$confirm('生成护理员结算中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (this.month === '') {
                        alert("日期选择不能为空")
                    } else {
                        if (this.staffId === null) {
                            alert("导出单个护理员时请选择护理员")
                        } else {
                            var d = this.month;
                            // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 201903
                            const resDate = d.getFullYear() + '' + this.p((d.getMonth() + 1))
                            let subData = "clientId=" + this.customerId + "&caregiverId=" + this.staffId + "&month=" + resDate + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)+ '&contractTypeId='+ this.this.contractTypeAndSiteInquireValue.toString().charAt(2);
                            caregiverBillingExportButton(subData).then(res => {
                                if (res) {
                                    this.tableData = res.data
                                    for (let i = 0; i < this.tableData.length; i++) {
                                        if (this.tableData[i].date != null && this.tableData[i].date != '') {
                                            this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                        }
                                    }
                                    this.$message.success('护理员结算导出成功,请到报表中进行查看下载');
                                }
                            })
                        }
                    }

                }).catch(() => {
                });
            },

            /**点击老人*/
            getCustomer(id) {
                this.workDateList = [];
                this.datedef = [];
                this.loadingtwo = true;
                this.customerId = id;
                let subData = "customerId=" + id + "&caregiverId=" + this.staffId + "&date=" + this.dateList + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                    + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                theDateOfTheWorkOrderIsDisplayed(subData).then(res => {
                    if (res) {
                        this.workDateList = res.data;
                        this.workDateList.sort(function (a, b) {
                            return a > b ? -1 : 1;
                        });
                        for (let i = 0; i < res.data.length; i++) {

                            var date = res.data[i];
                            var s = {
                                date: date
                            };
                            this.datedef.push(s)
                        }
                        this.loadingtwo = false;
                    }
                });

            },
            /*点击日期*/
            getDates(item) {
                this.$confirm('查询速度较慢，请稍等...', '确定查询', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.workDateList = [];
                    this.datedef = [];
                    this.customerId = '';
                    this.dateList = item;
                    this.loading = true;
                    if (item === "未结算") {
                        this.defaultValue = new Date();
                    } else {
                        this.defaultValue = moment(item).format();
                    }

                    let subData = "caregiverId=" + this.staffId + "&date=" + item + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                        + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);

                    customerWorkOrderSettlement(subData).then(res => {
                        if (res) {
                            this.customerWorkOrderSettlementList = res.data;
                            this.loading = false;
                        }
                    })
                }).catch(() => {
                });


            },
            /**点击护理员*/
            nurseDes(id) {
                this.workDateList = [];
                this.datedef = [];
                this.dateList = '';
                this.customerId = '';
                this.customerWorkOrderSettlementList = [];
                this.staffId = id;
                this.isadd = false;
                let subData = "id=" + id;
                settlementDateList(subData).then(res => {
                    if (res) {
                        this.dates = res.data;
                    }
                })
            },
            /**
             * 地址栏输入用户id 提取
             * */
            submit() {
                var newurl = this.updateParam(window.location.href, 'staffId', this.input);
                //向当前url添加参数，没有历史记录
                window.history.replaceState({
                    path: newurl
                }, '', newurl);
            },
            updateParam(uri, key, value) {
                if (!value) {
                    return uri;
                }
                var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
                var separator = uri.indexOf('?') !== -1 ? "&" : "?";
                if (uri.match(re)) {
                    return uri.replace(re, '$1' + key + "=" + value + '$2');
                } else {
                    return uri + separator + key + "=" + value;
                }
            },

            /**
             * 分页
             * */
            goSpecifiedPage(val) {
                this.currPage = val;
                this.getNurses();
            },
            /**
             * 护理员列表
             * */
            getNurses() {

                var strs = this.contractTypeAndSiteInquireValue.toString();
                let subData = "currPage=" + this.currPage + "&searchCondition=" + this.nurText + "&status=" + this.nurStatus + "&siteList=" + strs.charAt(0) + '&contractTypeId=' + strs.charAt(2);
                careList(subData).then(res => {
                    if (res) {
                        this.nurseArr = res.data;
                        this.pages = res.data[0].totalCount;
                    }
                })
            },
            refresh() {
              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.billingDetailsModule.billingDetailsList = this.isExist(permissionList,"billingDetails:billingDetailsList");
                  this.billingDetailsModule.batchImportBillingDetails = this.isExist(permissionList,"billingDetails:batchImportBillingDetails");
                  this.billingDetailsModule.caregiverList = this.isExist(permissionList,"billingDetails:caregiverList");
                  this.billingDetailsModule.automaticBinding = this.isExist(permissionList,"billingDetails:automaticBinding");
                  this.billingDetailsModule.settlementDateList = this.isExist(permissionList,"billingDetails:settlementDateList");
                  this.billingDetailsModule.customerWorkOrderSettlement = this.isExist(permissionList,"billingDetails:customerWorkOrderSettlement");
                  this.billingDetailsModule.theDateOfTheWorkOrderIsDisplayed = this.isExist(permissionList,"billingDetails:theDateOfTheWorkOrderIsDisplayed");
                  this.billingDetailsModule.caregiverBillingExportButton = this.isExist(permissionList,"billingDetails:caregiverBillingExportButton");
                  this.billingDetailsModule.noSettlementWorkOrderListExportPython = this.isExist(permissionList,"billingDetails:noSettlementWorkOrderListExportPython");
                  this.billingDetailsModule.noSettlementWorkOrderListExportButton = this.isExist(permissionList,"billingDetails:noSettlementWorkOrderListExportButton");

                  var str = "staff"
                  var staffId = "";
                  if (this.staffId != '' && this.staffId != null && this.staffId.search(str) == -1) {
                    staffId = this.staffId
                  }
                  var strs = this.contractTypeAndSiteInquireValue.toString();
                  let subData = "currPage=" + this.currPage + "&searchCondition=" + this.nurText + "&status=" + this.nurStatus + "&siteList=" + strs.charAt(0) + "&staffId=" + staffId + '&contractTypeId=' + strs.charAt(2);
                  careList(subData).then(res => {
                    if (res) {
                      this.nurseArr = res.data;
                      if (res.data.length!==0){
                        this.pages = res.data[0].totalCount;
                      }
                      this.allLoad = false;
                    }
                  });

                }
              })

            },
            renderContent(h, parmas) {
                //设置lunarcalendar=true,parmas返回值包含农历
                const loop = data => {
                    return (
                        data.defvalue.value ? (< div style="color: #FF4500">{data.defvalue.text}< span> ^ < /span>< /div>) : <div>{data.defvalue.text}</div>
                    )
                }
                return (
                    < div style="min-height:60px;">
                    {loop(parmas)}
                    </div>
                );
            },
        },
        created() {

            window.addEventListener('setItem', () => {
                this.allLoad = true;
                this.siteValue = sessionStorage.getItem('siteValue');
                this.staffId = '';
                this.refresh();
            }, false)
            if (this.$route.query.id != null && this.$route.query.id != undefined) {
                this.isAssign = false;
                this.staffId = this.$route.query.id;
                this.clientRight.width = '100%'
            } else {
                this.isAssign = true;
                this.refresh();
            }
        },
        watch: {
            '$route'(to, from) {
                if (this.$route.query.id != null && this.$route.query.id != undefined) {
                    this.isAssign = false;
                    this.staffId = this.$route.query.id;
                    this.clientRight.width = '100%'
                } else {
                    this.clientRight.width = ''
                    this.isAssign = true;
                    this.refresh();
                }
            },
        },
        mounted() {
        }
    }
</script>
<style scoped lang="scss">
    .filter{
        width: 100%;
        padding: 5px 0 0 10px;
        height:100px;
        box-sizing: border-box;
        border-bottom:1px solid #DDD;
        .inp{
            width: 80%;
        }
        .sel{
            width: 45%;
        }
    }

    .is-selected {
        color: #1989FA;
    }

    .client-left {
        width: 350px;
    }

    .clientDetails {
        margin-left: 95%;
        margin-top: -70px;
    }

    .left-border {
        position: absolute;
        left: 0;
        top: 16px;
        background: green;
        width: 2px;
        height: 60px;
    }

    .right-center {
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex; /* Safari */
        /*   border: 1px solid red;*/
        .center-one {
            width: 15%;
            border-left: 1px solid #DDD;
        }

        .center-two {
            width: 20%;
            border-left: 1px solid #DDD;
        }

        .center-three {
            width: 55%;
            border-left: 1px solid #DDD;

            .client-up {
                width: 100%;
                height: 60%;
                //border: 1px solid red;
            }

            .client-down {
                width: 100%;
                height: 30%;
            }

        }

        .center-four {
            width: 15%;
            border-left: 1px solid #DDD;
        }

        .center-five {
            width: 15%;
            text-align: center;
        }
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 20px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .serve-address {
            width: 400px;
            /*  border: 1px solid red;*/
            padding: 20px 0 0 20px;
        }

        span {
            margin-left: 20px;
        }

        .adress {
            padding-left: 2%;
        }
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 2%;
        }

        .line {
            width: 280px;
        }

        .line2 {
            width: 50px;
            background: #409EFF;
            color: #FFFFFF;
        }
    }

    #map {
        width: 100%;
        height: 300px;
    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    /*图片上传*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }
</style>
