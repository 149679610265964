<template>
  <div class="windControl">
    <div class="gd-top" style="margin:10px 0 20px 5px">
      <div style="text-align: center">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">
          <el-form-item label=" 合约/护理站:">
            &nbsp;<el-cascader
              v-model="contractTypeAndSiteInquireValue"
              :options="contractTypeAndSiteInquireValueOptions"
              :props="{ expandTrigger: 'hover' }"
              @change="siteChangeOperating()"
              style="width:200px"></el-cascader>
          </el-form-item>
          <el-form-item label=" 风险类型:">
            <el-select v-model="riskControlTypeIdValue" placeholder="请选择" style="width: 200px"
                       @change="inquiryWindControlInformationList()">
              <el-option
                  v-for="item in windControlTypeList"
                  :key="item.riskControlTypeId"
                  :label="item.riskControlTypeName"
                  :value="item.riskControlTypeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" 风险状态:">
            <el-select v-model="riskStatusValue" placeholder="请选择" style="width: 100px"
                       @change="inquiryWindControlInformationList()">
              <el-option
                  v-for="item in riskStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.keyWords" placeholder="请输入护理员/老人相关信息" style="width:255px"
                      @keyup.enter.native="inquiryWindControlInformationList"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="searchForm.date"
                type="daterange"
                range-separator="至"
                start-placeholder="工单开始日期"
                end-placeholder="工单结束日期"
                style="width: 260px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="inquiryWindControlInformationList()">查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="this.windControlModule.windControlInformationList">
        <el-table
            :data="windControlData"
            style="width: 100% ;margin-bottom:30px;"
            :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
            border
            stripe
            max-height="730"
            id="out-table"
            v-if="boxFlag"
            v-loading="loading"

        >
          <el-table-column prop="site,controlTypeName" label="业务类型/所属站" width="200px">
            <template slot-scope="scope">{{ scope.row.controlTypeName }}/{{ scope.row.site}}</template>
          </el-table-column>
          <el-table-column
              prop="caregiverName"
              label="护理员姓名"
              width="100px">
          </el-table-column>
          <el-table-column
              prop="customerName"
              label="老人姓名"
              width="100px">
          </el-table-column>
          <el-table-column
              prop="workOrderTime"
              label="工单时间"
              width="100px">
          </el-table-column>
          <el-table-column
              prop="riskControlTypeName"
              label="风控类型"
              width="200px">
          </el-table-column>
          <el-table-column
              prop="riskDescription"
              label="风险描述">
          </el-table-column>
          <el-table-column
              prop="riskStatus"
              label="风险状态"
              width="80px">
          </el-table-column>
          <el-table-column
              prop="riskOpenTime"
              label="风险开启时间"
              width="160px">
          </el-table-column>
          <el-table-column
              prop="riskCloseTime"
              label="风险关闭时间"
              width="160px">
          </el-table-column>
          <el-table-column label="操作" height="20px" width="80px"><template slot-scope="scope"><el-button type="primary" size="small" @click="toDeail(
                                scope.row.customerId,
                                scope.row.evaluationNursingId,
                                scope.row.contractTypeId,
                                scope.row.caregiverId,
                                scope.row.contractInformationId,
                                scope.row.riskControlTypeId,
                                scope.row.siteId)">操作</el-button></template></el-table-column>
        </el-table>
        <div class="block" style="text-align:right">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="pagesizes"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {
  siteChange,
  windControlInformationList,
  windControlTypeList
} from '@/common/js/index.js'
import {setDate, setHours, setTime, isEmpty} from '@/common/js/util.js'

export default {
  data() {
    return {
      searchForm: {
        keyWords: '',
        date: null,
      },
      status: [],
      windControlData: [],
      boxFlag: true,
      currentPage: 1,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      pagesize: 20,
      totalCount: 0,
      windControlTypeList: [],
      value: null,
      riskControlTypeIdValue: "",
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      riskStatus: [{
        value: '',
        label: '全部'
      }, {
        value: '开启中',
        label: '开启中'
      }, {
        value: '已操作',
        label: '已操作'
      }], // 风险状态列表
      riskStatusValue: "", //风险状态
      windControlModule:{
        windControlInformationList:false
      },

    };
  },
  methods: {
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.windControlModule.windControlInformationList = this.isExist(permissionList,"windControl:windControlInformationList");
          this.inquiryWindControlInformationList();

        }
      })

    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    // 跳转操作页面
    toDeail(customerId, evaluationNursingId, contractTypeId, caregiverId, contractInformationId, riskControlTypeId, siteId) {
      // 跳转老人界面填写银行卡信息
      if (evaluationNursingId !== null && evaluationNursingId !== "") {
        if (riskControlTypeId === 1 || riskControlTypeId === 7 || riskControlTypeId === 8) {
          let routeUrl = this.$router.resolve({
            path: "client",
            query: {
              id: customerId,
              evaluationNursingId: evaluationNursingId,
              contractTypeId: contractTypeId
            }
          });
          window.open(routeUrl.href, '_blank');
        } else {
          let routeUrl = this.$router.resolve({
            path: "sendOrders",
          });
          window.open(routeUrl.href, '_blank');
        }

      }
      // 跳转护理员界面填写护理员证件有效期
      if (contractInformationId !== null && contractInformationId !== "") {
        let routeUrl = this.$router.resolve({
          path: "staff",
          query: {
            id: caregiverId,
            contractInformationId: contractInformationId,
            contractTypeId: contractTypeId
          }
        });
        window.open(routeUrl.href, '_blank');
      }

    },
    //查询风控信息列表
    inquiryWindControlInformationList() {
      this.loading = true  //开启加载动画
      const strs = this.contractTypeAndSiteInquireValue.toString();
      let subData = 'currPage=' + this.currentPage
          + '&pageSize=' + this.pagesize
          + '&riskControlTypeId=' + this.riskControlTypeIdValue
          + '&keyWords=' + this.searchForm.keyWords +
          '&siteId=' + strs.charAt(0) +
          '&contractTypeId=' + strs.charAt(2) +
          '&riskStatus=' + this.riskStatusValue;
      if (this.searchForm.date != null) {
        let searchStartTime = setDate(this.searchForm.date[0])
        let searchEndTime = setDate(this.searchForm.date[1])
        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
      }
      windControlInformationList(subData).then(res => {
        if (res) {
          this.windControlData = res.data.windControlInformationList
          this.currentPage = res.data.currentPage
          this.totalCount = res.data.totalCount
        }
        this.loading = false
      })
    },
    //查询风控类型list
    inquiryWindControlTypeList() {
      windControlTypeList().then(res => {
        if (res) {
          this.windControlTypeList = res.data
        }
      })
    },
    handleSizeChange(val) {
      this.pagesize = val
      this.inquiryWindControlInformationList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.inquiryWindControlInformationList()
    },
  },
  created() {
    let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
    siteChange(subData).then(res => {
      if (res) {
        const permissionList  = res.data.userPermission.permissionList;
        this.windControlModule.windControlInformationList = this.isExist(permissionList,"windControl:windControlInformationList");
        this.inquiryWindControlTypeList();
        this.inquiryWindControlInformationList();
        window.addEventListener('setItem', () => {
          this.inquiryWindControlInformationList();
        })
      }
    })



  },
}
</script>
