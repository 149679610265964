<template>
    <div class="billingDetails" v-loading="loading">
        <div class="gd-top" style="margin:10px 0 20px 0">
            <div class="gd-left">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">

                    <el-form-item label="状态">
                        <el-select v-model="status" placeholder="请选择" class="sel2" @change="searchBillingDetailsList()">
                            <el-option
                                    v-for="item in options"
                                    :key="item.status"
                                    :label="item.label"
                                    :value="item.status">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-cascader
                            v-model="contractTypeAndSiteInquireValue"
                            :options="contractTypeAndSiteInquireValueOptions"
                            :props="{ expandTrigger: 'hover' }"
                            @change="siteChangeOperating()"
                            ></el-cascader>
                    <el-form-item label="     ">
                        <el-input v-model="searchForm.keyWords" placeholder="请输入老人姓名" style="width:255px"
                                  @keyup.enter.native="searchBillingDetailsList"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                                v-model="searchForm.date"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="searchBillingDetailsList()">查询</el-button>
                    </el-form-item>
                </el-form>

            </div>
            <div class="right">
                <el-form>
                    <el-form-item>
                        <el-button size="small" type="success" @click="batchImportBillingDetails()" v-if="this.billingDetailsModule.batchImportBillingDetails">导入</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <el-table
                :data="tableData"
                @selection-change="handleSelectionChange"
                style="width: 100% ;margin-bottom:30px;"
                :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
                :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
                border
                stripe
                max-height="730"
                id="out-table"
                v-if="boxFlag && this.billingDetailsModule.billingDetailsList"
                v-loading="loading"

        >
            <el-table-column class-name="aa" label="结算序号" width="70" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.billingSerialNumber}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="卡号" width="100" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.cardNumber}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="患者姓名" width="65" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.patientName}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="身份证号" width="165" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.identityCard}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="联系地址" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.contactAddress}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="联系电话" width="108" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.contactNumber}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="患者类型" width="50" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.patientType}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="费用总额" width="69" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.totalCost}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="现金支付" width="69" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.cashPayment}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="基金支付" width="69" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.fundPayment}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="自费" width="69" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.selfPay}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="结算日期" width="160" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.settlementDate}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="护理次数" width="50" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.numberOfTreatments}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="护理开始" width="93" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.careStartCycle}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="护理结束" width="93" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.endOfCareCycle}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="确认单数量" width="70" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.confirmOrderQuantity}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="评估等级" width="50" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.evaluationLervl}}</p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="状态" width="65" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.status}}</p>
                </template>
            </el-table-column>

        </el-table>
        <div class="block" style="text-align:right" v-if="this.billingDetailsModule.billingDetailsList">
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pagesizes"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="all">
            </el-pagination>
        </div>
        <!--导入-->
        <el-dialog title="导入" :visible.sync="billingImportPage">
          <h2 style="color: orangered">导入时请先对表格 结算序列 进行从小到大（正序）排序！</h2>
            <form>
                <input type="file" @change="getFile($event)">
                <button @click="submitForm($event)">提交</button>
                <div style="margin-left: 80%;margin-top: -20px">
                    <a :href="herf" target="_blank" rel="noopener noreferrer">结算导入模板下载</a>
                </div>
            </form>

        </el-dialog>
        <!--自动绑定页面-->
        <el-dialog title="结算自动绑定" :visible.sync="automaticBindingPage && this.billingDetailsModule.caregiverList" width="56%">
            <div>
                <div style="display:flex;justify-content:space-between;border-bottom:1px solid #ccc;padding-bottom:5px;">
                    <div style="line-height:50px"><h2>{{subForm.patientName}}</h2></div>
                    <div>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="automaticBindingPage = false">取 消</el-button>
                            <el-button type="primary" @click="automaticBinding(subForm.id)" v-if="this.billingDetailsModule.automaticBinding">自动绑定</el-button>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 style="font-weight:bold">结算时间：{{subForm.settlementDate}} | 护理周期段:{{subForm.careStartCycle}} ~
                        {{subForm.endOfCareCycle}} | 护理次数：{{subForm.numberOfTreatments}} | 状态:{{subForm.status}} </h3>

                    <div class="el-dialog-div">
                        <template>
                            <el-table
                                    :data="automaticBindingCaregiverList"
                                    stripe
                                    style="width: 50%">
                                <el-table-column
                                        prop="caregiverName"
                                        label="护理员"
                                        width="70"
                                        align="center">
                                </el-table-column>
                                <el-table-column
                                        prop="date"
                                        label="日期"
                                        width="100"
                                        align="center">
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>

            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {
        getBillingDetailsList,
        worksheetPythonExport,
        batchImportBillingDetails,
        automaticBindingCaregiverList,
        automaticBinding,
        siteChange
    } from '@/common/js/index.js'
    import {setDate} from '@/common/js/util.js'

    export default {
        //el: 'form',
        name: "billingDetails",
        data() {
            return {
                contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
                contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
                status: '',
                options: [{
                    status: '',
                    label: '全部'
                }, {
                    status: '1',
                    label: '已匹配'
                }, {
                    status: '2',
                    label: '未匹配'
                },
                    {
                        status: '3',
                        label: '部分匹配'
                    }, {
                        status: '-1',
                        label: '已取消'
                    }],
                herf: 'http://47.110.149.54:8080/statics/报表/报表详情导入模板/导入需保持格式一致.xlsx',
                automaticBindingPage: false,
                billingImportPage: false,

                cRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                },
                clientDetails: false,
                staffDetails: false,
                all: 0,
                all1: 0,
                allC: 0,
                allD: 0,
                innerVisible1: false,     //客户表格
                innerVisible2: false,     //护理员表格
                clientData: [],
                caregiverData: [],
                gridData: [],
                tableData: [],
                loading: false,
                clientList: [],
                caregiverList: [],
                customerSearch: '',
                careSearch: '',
                // cliStatus:"",  //护理站
                siteValue: '1,巾帼站',        //护理站
                siteList: localStorage.getItem("siteList").split("$"),
                searchForm: {
                    status: [],
                    keyWords: '',
                    date: null,
                },
                rules: {
                    caregiverId: [{required: true, message: '请选择员工', trigger: 'change'}],
                    clientId: [{required: true, message: '请选择用户', trigger: 'change'}]
                },
                subForm: {
                    'id': null,
                    'billingSerialNumber': '',// 结算序号
                    'cardNumber': '',//卡号
                    'patientName': '',//患者姓名
                    'identityCard': '',//身份证号
                    'contactAddress': '',//联系地址
                    'contactNumber': null,//联系电话
                    'patientType': '',//患者类型
                    'totalCost': '',//费用总额
                    'cashPayment': '',//现金支付
                    'fundPayment': '',//基金支付
                    'selfPay': '',//自费
                    'settlementDate': '',//结算日期
                    'numberOfTreatments': null,//护理次数
                    'careStartCycle': new Date(2016, 9, 10, 18, 40),//护理开始周期
                    'endOfCareCycle': new Date(2016, 9, 10, 18, 40),//护理结束周期
                    'evaluationLervl': null,//评估等级
                    'status': '',//状态
                    'totalPage': null,//总页数
                    'currPage': null,//当前页数
                    'totalCount': null,
                    'searchStartTime': '',
                    'searchEndTime': '',
                    'siteId': '',
                    'confirmOrderQuantity': null,//确认单数量

                },
                automaticBindingCaregiverList: [],
                formLabelWidth: '120px',
                search: '',
                result: [],//保存条件查询时箭名用的
                subFlag: '',//用于区分提交操作
                exportFlag: true,//打印时去掉尾部编辑用的
                currentPage: 1,
                currentPage1: 1,
                currentPageC: 1,
                currentPageD: 1,
                boxFlag: true,
                pagesize: 20,
                pagesize1: 10,
                pagesizeC: 10,
                pagesizeD: 10,
                pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
                pagesizes1: [10],
                pagesizesC: [10, 20, 30, 50, 100, 200, 300, 400, 500],  //客户用的
                pagesizesD: [10, 20, 30, 50, 100, 200, 300, 400, 500],   //护理员用的
                multipleSelection: [],
                ids: '',   //批量修改的id
                searchFlag: true,
                billingDetailsModule:{
                  billingDetailsList:false,
                  batchImportBillingDetails:false,
                  caregiverList:false,
                  automaticBinding:false,
                  settlementDateList:false,
                  customerWorkOrderSettlement:false,
                  theDateOfTheWorkOrderIsDisplayed:false,
                  caregiverBillingExportButton:false,
                  noSettlementWorkOrderListExportPython:false,
                  noSettlementWorkOrderListExportButton:false
                }
            }
        },
        methods: {
          siteChangeOperating(){
            let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
            siteChange(subData).then(res => {
              if (res) {
                const permissionList  = res.data.userPermission.permissionList;
                this.billingDetailsModule.billingDetailsList = this.isExist(permissionList,"billingDetails:billingDetailsList");
                this.billingDetailsModule.batchImportBillingDetails = this.isExist(permissionList,"billingDetails:batchImportBillingDetails");
                this.billingDetailsModule.caregiverList = this.isExist(permissionList,"billingDetails:caregiverList");
                this.billingDetailsModule.automaticBinding = this.isExist(permissionList,"billingDetails:automaticBinding");
                this.billingDetailsModule.settlementDateList = this.isExist(permissionList,"billingDetails:settlementDateList");
                this.billingDetailsModule.customerWorkOrderSettlement = this.isExist(permissionList,"billingDetails:customerWorkOrderSettlement");
                this.billingDetailsModule.theDateOfTheWorkOrderIsDisplayed = this.isExist(permissionList,"billingDetails:theDateOfTheWorkOrderIsDisplayed");
                this.billingDetailsModule.caregiverBillingExportButton = this.isExist(permissionList,"billingDetails:caregiverBillingExportButton");
                this.billingDetailsModule.noSettlementWorkOrderListExportPython = this.isExist(permissionList,"billingDetails:noSettlementWorkOrderListExportPython");
                this.billingDetailsModule.noSettlementWorkOrderListExportButton = this.isExist(permissionList,"billingDetails:noSettlementWorkOrderListExportButton");
                this.searchBillingDetailsList();

              }
            })

          },
          isExist(permissionList,str) {
            if (permissionList.indexOf(str) !== -1) {
              return true;
            } else {
              return false;
            }
          },
            //点击自动绑定
            automaticBinding(id) {
                this.$confirm('绑定中...', '确定自动绑定', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let subData = "id=" + id + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
                    automaticBinding(subData).then(res => {
                        if (res) {
                            this.$message.success('自动绑定成功');
                            // console.log('export',this.tableData)
                        }
                        this.searchBillingDetailsList();
                    })

                }).catch(() => {
                });
            },
            // 导入结算明细
            batchImportBillingDetails() {
                this.billingImportPage = true
            },
            getFile(event) {
                this.file = event.target.files[0];
                console.log(this.file);
            },
            submitForm(event) {
                event.preventDefault();
                let formData = new FormData();
                formData.append('siteList', this.contractTypeAndSiteInquireValue.toString().charAt(0));
                formData.append('contractTypeId', this.contractTypeAndSiteInquireValue.toString().charAt(2))
                formData.append('file', this.file);
                this.$message.warning('导入数据中,请稍等...');
                batchImportBillingDetails(formData).then(res => {
                    if (res) {
                        this.tableData = res.retCode
                        if (this.tableData == 0) {
                            this.$message.success('导入成功');
                            this.billingImportPage = false;
                            this.searchBillingDetailsList();
                        }

                        // console.log('export',this.tableData)
                    }
                })

            },


            /*日工单导出*/
            worksheetPythonExport() {
                this.$confirm('生成日工单中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let subData = ''
                    let str = /\"(.*)\"/
                    let num = /\d+/g
                    if (this.searchForm.status.length > 0) {
                        for (let i = 0; i < this.result.length; i++) {
                            subData += '&' + this.searchForm.status[i].match(str)[1] + '=' + this.searchForm.status[i].match(num)
                        }
                        // this.searchForm.status=[]
                    } else {
                        this.result = []
                    }
                    if (this.searchForm.keyWords != '') {
                        subData += '&searchCondition=' + this.searchForm.keyWords
                    }
                    if (this.searchForm.date != null) {
                        let searchStartTime = setDate(this.searchForm.date[0])
                        let searchEndTime = setDate(this.searchForm.date[1])
                        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                    }
                    subData += '&siteList=' + this.siteValue
                    worksheetPythonExport(subData).then(res => {
                        if (res) {
                            this.tableData = res.data
                            for (let i = 0; i < this.tableData.length; i++) {
                                if (this.tableData[i].date != null && this.tableData[i].date != '') {
                                    this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                }
                            }
                            this.$message.success('日工单导出成功,请到报表中进行查看下载');
                            // console.log('export',this.tableData)
                        }
                    })

                }).catch(() => {
                });
            },
            handleSelectionChange(val) {//改变选择时触发
                this.multipleSelection = val;
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            handleSizeChange(val) {
                this.pagesize = val
            },
            handleEdit(index, row) {  //编辑
                this.subForm.id = row.id
                this.subForm.identityCard = row.identityCard
                this.subForm.patientName = row.patientName
                this.subForm.settlementDate = row.settlementDate
                this.subForm.status = row.status
                this.subForm.numberOfTreatments = row.numberOfTreatments
                this.subForm.careStartCycle = row.careStartCycle
                this.subForm.endOfCareCycle = row.endOfCareCycle

                let subData = "identityCard=" + row.identityCard + "&careStartCycle=" + row.careStartCycle + "&endOfCareCycle=" + row.endOfCareCycle
                    + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                automaticBindingCaregiverList(subData).then(res => {
                    if (res) {
                        this.automaticBindingCaregiverList = res.data;
                    }
                })

                this.automaticBindingPage = true
            },


            dataForCheckBox(data) {
                if (data == '已结算' || data == '已认证' || data == '已录入') {
                    return true
                } else if (data == null) {
                    return null
                } else {
                    return false
                }
            },
            checkBoxDataForSubData(data) {
                if (data == true) {
                    return 1
                } else if (data == false) {
                    return 0
                } else {
                    return null
                }
            },


            searchBillingDetailsList() {    //查询按钮对应的方法
                if (this.searchFlag == true) {
                    this.loading = true  //开启加载动画
                    let subData = 'currPage=' + this.currentPage + '&pageSizePara=' + this.pagesize + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) +
                            "&status=" + this.status + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                    if (this.searchForm.keyWords != '') {
                        subData += '&searchCondition=' + this.searchForm.keyWords
                    }
                    if (this.searchForm.date != null) {
                        let searchStartTime = setDate(this.searchForm.date[0])
                        let searchEndTime = setDate(this.searchForm.date[1])
                        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                    }
                    getBillingDetailsList(subData).then(res => {
                        if (res) {
                            this.tableData = res.data
                            if (res.data.length != 0) {
                                this.all = res.data[0].totalCount
                            } else {
                                this.all = 0
                            }
                        }
                        this.loading = false
                    })
                }

            },


            checkPhoneNum(formName) {
                let a = formName.customerMoblle
                let b = formName.caregiverMoblle
                let str1 = ''
                let str2 = ''
                let str3 = ''
                let str4 = ''
                if (a.length != 0) {
                    for (let i = 0; i < a.length; i++) {
                        if (isNaN(a[i])) {
                            if (i == a.length - 1) {
                                formName.customerMoblle = a.substring(0, a.length - 1)
                            } else {
                                str1 = a.substring(0, i)
                                str2 = a.substring(i + 1, a.length)
                                formName.customerMoblle = str1 + str2
                            }
                            this.$message.warning('号码应为数值')
                        }
                    }
                }
                if (b.length != 0) {
                    for (let i = 0; i < b.length; i++) {
                        if (isNaN(b[i])) {
                            if (i == b.length - 1) {
                                formName.caregiverMoblle = b.substring(0, b.length - 1)
                            } else {
                                str3 = b.substring(0, i)
                                str4 = b.substring(i + 1, b.length)
                                formName.caregiverMoblle = str3 + str4
                            }
                            this.$message.warning('号码应为数值')
                        }
                    }
                }
            }
        },
        created() {    //护理站
            this.siteChangeOperating();
            window.addEventListener('setItem', () => {
                this.siteValue = sessionStorage.getItem('siteValue');
                this.searchBillingDetailsList();
            })
        },
        watch: {
            currentPage: function () {
                this.searchBillingDetailsList()
            },
            currentPage1: function () {
                this.getLog()
            },
            pagesize: function () {
                this.searchBillingDetailsList()
            },
        }
    }
</script>

<style scoped>
    .gd-top {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        height: 50px;
        box-sizing: border-box;
        padding: 5px 20px;
    }

    .gd-left {
        display: -webkit-flex; /* Safari */
        display: flex;
    }

    .red .el-form-item__label {
        color: #f31616;
    }

</style>
<!--用户弹出页面css-->
<style scoped lang="scss">
    .el-dialog-div {
        height: 60vh;
        overflow: auto;
    }

    .over-due {
        /* position: absolute;*/
        /* top:4px;*/
        font-size: 12px;
        color: #FFF;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: red;
        text-align: center;
        line-height: 20px;
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 70px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .local {
            display: inline-block;
            margin-left: 20px;
        }
    }

    .serve {
        padding: 20px 0 0 20px;
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 5%;
        }

        .line {
            width: 300px;
        }
    }

    .assess {
        padding: 20px 0 0 30px;

        .line {
            width: 260px;
        }
    }

    #map {
        width: 100%;
        height: 300px;
    }

    .adress {
        padding-left: 5%;

        .line {
            width: 260px;
        }
    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }
    }

    /*上传图片*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .form .form-left {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }

    .form .form-right {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }

    .sel2 {
        width: 150px;
    }
</style>
