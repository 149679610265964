<template>
  <div class="site" v-loading="loading">
    <div class="gd-top" style="margin:10px 0 20px 0">
      <div class="gd-left" style="text-align: right;margin-right: 100px">
        <el-form :inline="true" class="demo-form-inline" style="z-index:2">
          <el-cascader
              v-model="contractTypeAndSiteInquireValue"
              :options="contractTypeAndSiteInquireValueOptions"
              :props="{ expandTrigger: 'hover' }"
              @change="getSiteCompetenceList()"
              style="width:200px"></el-cascader>
        </el-form>
      </div>

    </div>
    <el-table
        :data="siteCompetenceList"
        border
        v-loading="loading"
        stripe
        :header-cell-style="{background:'#dee2ec', color: '#666666'}"
        max-height="750"
        style="width: 100%">

      <el-table-column
          type="index"
          label="序号"
          width="50">
      </el-table-column>

      <el-table-column
          width="150"
          prop="menu_name"
          label="模块"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-checkbox-group v-model="row.ids">
            <span
                v-for="(item, index) in row.permissionsList"
                :key="index"
                style="margin-right: 10px"
            >
                <el-checkbox :label="item.id" :key="item.id" >{{ item.permissionName }}</el-checkbox>
                </span>
          </el-checkbox-group>

        </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button type="warning" size="small" @click="modify(scope.row)">修改</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-dialog :title="title" :visible.sync="modifyDialog" width="500px">
      <el-form :model="addModifyForm" ref="addModifyForm" label-width="100px">

        <el-form-item label="权限列表">
          <el-select v-model="addModifyForm.menuCodeIds" multiple placeholder="请选择" clearable filterable>
            <el-option
                v-for="item in permissionsList"
                :key="item.id"
                :label="item.permissionName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDialog = false">取 消</el-button>
        <el-button type="primary" @click="modifySitePermission('addModifyForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {listAllPermission, querySiteCompetenceList,modifySitePermission} from '@/common/js/index.js'

export default {
  name: "role",
  data() {
    return {
      loading: true,
      siteCompetenceList: [],
      siteId: null,
      title: "权限",
      contractTypeAndSiteInquireValue: ['1', '1'],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      modifyDialog: false,
      addModifyForm: {
        menuCodeIds: [],
        menu_code:''
      },
      // permissionsList: [{permissions: [{permissionName: '全选', id: 'all'}]}],//权限列表
      permissionsList: [],//权限列表
      checkList:["护理员信息"]
    }
  },
  methods: {
    modify(row) {
      this.title = "修改" + row.menu_name + "权限"
      this.permissionsList = row.permissionsList
      this.addModifyForm.menuCodeIds  = row.ids
      this.addModifyForm.menu_code = row.menu_code
      this.modifyDialog = true;
    },
    modifySitePermission(){
      var strs = this.contractTypeAndSiteInquireValue.toString();
      let subData = 'siteId=' + strs.charAt(0)
                    + '&menuCodeIds=' + this.addModifyForm.menuCodeIds
                    + '&menu_code=' + this.addModifyForm.menu_code

      modifySitePermission(subData).then(res => {
        if (res) {
          this.getSiteCompetenceList()
          this.modifyDialog = false
          this.$message.success('修改成功')
        }
      })
    },
    getSiteCompetenceList() {
      var strs = this.contractTypeAndSiteInquireValue.toString();
      let subData = 'siteId=' + strs.charAt(0 )
      querySiteCompetenceList(subData).then(res => {
        this.siteCompetenceList = res.data
        for (let i = 0; i < this.siteCompetenceList.length; i++) {
          const ids = []
          for (let v = 0; v < this.siteCompetenceList[i].permissions.length; v++) {
              ids.push(this.siteCompetenceList[i].permissions[v].id)
          }
          this.siteCompetenceList[i].ids = ids

          for (let j = 0; j < this.permissionList.length; j++) {
            if (this.siteCompetenceList[i].menu_code === this.permissionList[j].menuCode) {
              this.siteCompetenceList[i].permissionsList = this.permissionList[j].permissions
            }
          }

        }
        this.loading = false
      })

    },
    getAllPermission() {//获取所有权限
      listAllPermission().then(res => {
        this.permissionList = res.data.list
      })
    },

  },
  mounted() {
    this.getSiteCompetenceList()
    this.getAllPermission()
  },
  created() {
  },
  watch: {
    // 'addModifyForm.menuCodeIds': function () {
    //   this.selectAll()
    // }
  }
}
</script>

<style scoped>
</style>
