var options=[
    {
        value:'黄浦区',
        label:'黄浦区',
        children:[{
            value:'南京东路街道',
            label:'南京东路街道'
        },{
            value:'外滩街道',
            label:'外滩街道'
        },{
            value:'半淞园路街道',
            label:'半淞园路街道'
        },{
            value:'小东门街道',
            label:'小东门街道'
        },{
            value:'豫园街道',
            label:'豫园街道'
        },{
            value:'老西门街道',
            label:'老西门街道'
        },{
            value:'五里桥街道',
            label:'五里桥街道'
        },{
            value:'打浦桥街道',
            label:'打浦桥街道'
        },{
            value:'淮海中路街道',
            label:'淮海中路街道'
        },{
            vlaue:'瑞金二路街道',
            label:'瑞金二路街道'
        }]
    },{
        value:'徐汇区',
        label:'徐汇区',
        children:[{
            value:'天平路街道',
            label:'天平路街道'
        },{
            value:'湖南路街道',
            label:'湖南路街道'
        },{
            value:'斜土路街道',
            label:'斜土路街道'
        },{
            value:'枫林路街道',
            label:'枫林路街道'
        },{
            value:'长桥街道',
            label:'长桥街道'
        },{
            value:'田林街道',
            label:'田林街道'
        },{
            value:'虹梅路街道',
            label:'虹梅路街道'
        },{
            value:'康建新村街道',
            label:'康建新村街道'
        },{
            value:'徐家汇街道',
            label:'徐家汇街道'
        },{
            value:'凌云路街道',
            label:'凌云路街道'
        },{
            value:'龙华街道',
            label:'龙华街道'
        },{
            value:'漕河泾街道',
            label:'漕河泾街道'
        },{
            value:'华泾镇',
            label:'华泾镇'
        }]
    },{
       value:'长宁区',
       label:'长宁区' ,
       children:[{
           value:'华阳路街道',
           label:'华阳路街道'
       },{
           value:'江苏路街道',
           label:'江苏路街道'
       },{
           value:'周家桥街道',
           label:'周家桥街道'
       },{
           value:'新华路街道',
           label:'新华路街道'
       },{
           value:'天山路街道',
           label:'天山路街道'
       },{
           value:'仙霞新村街道',
           label:'仙霞新村街道'
       },{
           value:'虹桥街道',
           label:'虹桥街道'
       },{
           value:'程家桥街道',
           label:'程家桥街道'
       },{
           value:'北新泾街道',
           label:'北新泾街道'
       },{
           value:'新泾镇',
           label:'新泾镇'
       }]
    },{
       value:'静安区',
       label:'静安区',
       children:[{
           value:'江宁路街道',
           label:'江宁路街道'
       },{
           value:'石门二路街道',
           label:'石门二路街道'
       },{
           value:'南京西路街道',
           label:'南京西路街道'
       },{
           value:'静安寺街道',
           label:'静安寺街道'
       },{
           value:'曹家渡街道',
           label:'曹家渡街道'
       }]
    },{
        value:'普陀区',
        label:'普陀区',
        children:[{
            value:'曹杨新村街道',
            label:'曹杨新村街道'
        },{
            value:'长风新村街道',
            label:'长风新村街道'
        },{
            value:'长寿路街道',
            label:'长寿路街道'
        },{
            value:'甘泉路街道',
            label:'甘泉路街道'
        },{
            value:'石泉路街道',
            label:'石泉路街道'
        },{
            value:'宜川路街道',
            label:'宜川路街道'
        },{
            value:'真如镇街道',
            label:'真如镇街道'
        },{
            value:'万里街道',
            label:'万里街道'
        },{
            value:'长征镇',
            label:'长征镇'
        },{
            value:'桃浦镇',
            label:'桃浦镇'
        }]
    },{
        value:'闸北区',
        label:'闸北区',
        children:[{
            value:'天目西路街道',
            label:'天目西路街道'
        },{
            value:'北站街道',
            label:'北站街道'
        },{
            value:'宝山路街道',
            label:'宝山路街道'
        },{
            value:'共和新路街道',
            label:'共和新路街道'
        },{
            value:'大宁路街道',
            label:'大宁路街道'
        },{
            value:'彭浦新村街道',
            label:'彭浦新村街道'
        },{
            value:'临汾路街道',
            label:'临汾路街道'
        },{
            value:'芷江西路街道',
            label:'芷江西路街道镇'
        },{
            value:'彭浦镇',
            label:'彭浦镇'
        }]
    },{
        value:'虹口区',
        label:'虹口区',
        children:[{
            value:'欧阳路街道',
            label:'欧阳路街道'
        },{
            value:'曲阳路街道',
            label:'曲阳路街道'
        },{
            value:'广中路街道',
            label:'广中路街道'
        },{
            value:'嘉兴路街道',
            label:'嘉兴路街道'
        },{
            value:'凉城新路街道',
            label:'凉城新路街道'
        },{
            value:'四川北路街道',
            label:'四川北路街道'
        },{
            value:'提篮桥街道',
            label:'提篮桥街道'
        },{
            value:'江湾镇街道',
            label:'江湾镇街道'
        }]
    },{
        value:'杨浦区',
        label:'杨浦区',
        children:[{
            value:'定海路街道',
            label:'定海路街道'
        },{
            value:'平凉路街道',
            label:'平凉路街道'
        },{
            value:'江浦路街道',
            label:'江浦路街道'
        },{
            value:'四平路街道',
            label:'四平路街道'
        },{
            value:'控江路街道',
            label:'控江路街道'
        },{
            value:'长白新村街道',
            label:'长白新村街道'
        },{
            value:'延吉新村街道',
            label:'延吉新村街道'
        },{
            value:'殷行街道',
            label:'殷行街道'
        },{
            value:'大桥街道',
            label:'大桥街道'
        },{
            value:'五角场街道',
            label:'五角场街道'
        },{
            value:'新江湾城街道',
            label:'新江湾城街道'
        },{
            value:'五角场镇',
            label:'五角场镇'
        }]
    },{
        value:'闵行区',
        label:'闵行区',
        children:[{
            value:'浦锦街道',
            label:'浦锦街道'
        },{
            value:'江川路街道',
            label:'江川路街道'
        },{
            value:'古美街道',
            label:'古美街道'
        },{
            value:'新虹街道',
            label:'新虹街道'
        },{
            value:'莘庄镇',
            label:'莘庄镇'
        },{
            value:'七宝镇',
            label:'七宝镇'
        },{
            value:'颛桥镇',
            label:'颛桥镇'
        },{
            value:'华漕镇',
            label:'华漕镇'
        },{
            value:'虹桥镇',
            label:'虹桥镇'
        },{
            value:'梅陇镇',
            label:'梅陇镇'
        },{
            value:'吴泾镇',
            label:''
        },{
            value:'马桥镇',
            label:'马桥镇'
        },{
            value:'浦江镇',
            label:'浦江镇'
        },{
            value:'莘庄工业区',
            label:'莘庄工业区'
        }]
    },{
        value:'宝山区',
        label:'宝山区',
        children:[{
            value:'友谊路街道',
            label:'友谊路街道'
        },
        {
            value:'吴淞街道',
            label:'吴淞街道'
        },{
            value:'张庙街道',
            label:'张庙街道'
        },{
            value:'罗店镇',
            label:'罗店镇'
        },{
            value:'大场镇',
            label:'大场镇'
        },{
            value:'杨行镇',
            label:'杨行镇'
        },{
            value:'月浦镇',
            label:'月浦镇'
        },{
            value:'罗泾镇',
            label:'罗泾镇'
        },{
            value:'顾村镇',
            label:'顾村镇'
        },{
            value:'高境镇',
            label:'高境镇'
        },{
            value:'庙行镇',
            label:'庙行镇'
        },{
            value:'淞南镇',
            label:'淞南镇'
        },{
            value:'宝山城市工业园区',
            label:'宝山城市工业园区'
        }]
    },{
        value:'嘉定区',
        label:'嘉定区',
        children:[{
            value:'新成路街道',
            label:'新成路街道'
        },{
            value:'真新街道',
            label:'真新街道'
        },{
            value:'菊园新区',
            label:'菊园新区'
        },{
            value:'嘉定镇街道',
            label:'嘉定镇街道'
        },{
            value:'南翔镇',
            label:'南翔镇'
        },{
            value:'安亭镇',
            label:'安亭镇'
        },{
            value:'马陆镇',
            label:'马陆镇'
        },{
            value:'徐行镇',
            label:'徐行镇'
        },{
            value:'华亭镇',
            label:'华亭镇'
        },{
            value:'外冈镇',
            label:'外冈镇'
        },{
            value:'江桥镇',
            label:'江桥镇'
        },{
            value:'嘉定工业园区',
            label:'嘉定工业园区'
        }]
    },{
        value:'浦东新区',
        label:'浦东新区',
        children:[{
            value:'潍坊新村街道',
            label:'潍坊新村街道'
        },{
            value:'陆家嘴街道',
            label:'陆家嘴街道'
        },{
            value:'周家渡街道',
            label:'周家渡街道'
        },{
            value:'塘桥街道',
            label:'塘桥街道'
        },{
            value:'上钢新村街道',
            label:'上钢新村街道'
        },{
            value:'南码头路街道',
            label:'南码头路街道'
        },{
            value:'沪东新村街道',
            label:'沪东新村街道'
        },{
            value:'金杨新村街道',
            label:'金杨新村街道'
        },{
            value:'洋泾街道',
            label:'洋泾街道'
        },{
            value:'浦兴路街道',
            label:'浦兴路街道'
        },{
            value:'东明路街道',
            label:'东明路街道'
        },{
            value:'花木街道',
            label:'花木街道'
        },{
            value:'川沙新镇',
            label:'川沙新镇'
        },{
            value:'高桥镇',
            label:'高桥镇'
        },{
            value:'北蔡镇',
            label:'北蔡镇'
        },{
            value:'合庆镇',
            label:'合庆镇'
        },{
            value:'唐镇镇',
            label:'唐镇镇'
        },{
            value:'曹路镇',
            label:'曹路镇'
        },{
            value:'金桥镇',
            label:'金桥镇'
        },{
            value:'高行镇',
            label:'高行镇'
        },{
            value:'高东镇',
            label:'高东镇'
        },{
            value:'张江镇',
            label:'张江镇'
        },{
            value:'三林镇',
            label:'三林镇'
        },{
            value:'惠南镇',
            label:'惠南镇'
        },{
            value:'周浦镇',
            label:'周浦镇'
        },{
            value:'新阳镇',
            label:'新阳镇'
        },{
            value:'新场镇',
            label:'新场镇'
        },{
            value:'大团镇',
            label:'大团镇'
        },{
            value:'康桥镇',
            label:'康桥镇'
        },{
            value:'航头镇',
            label:'航头镇'
        },{
            value:'祝桥镇',
            label:'祝桥镇'
        },{
            value:'泥城镇',
            label:'泥城镇'
        },{
            value:'宣桥镇',
            label:'宣桥镇'
        },{
            value:'书院镇',
            label:'书院镇'
        },{
            value:'万祥镇',
            label:'万祥镇'
        },{
            value:'老港镇',
            label:'老港镇'
        },{
            value:'南汇新城镇',
            label:'南汇新城镇'
        }]
    },{
        value:'金山区',
        label:'金山区',
        children:[{
            value:'石化街道',
            label:'石化街道'
        },{
            value:'朱泾镇',
            label:'朱泾镇'
        },{
            value:'枫泾镇',
            label:'枫泾镇'
        },{
            value:'张堰镇',
            label:'张堰镇'
        },{
            value:'亭林镇',
            label:'亭林镇'
        },{
            value:'吕巷镇',
            label:'吕巷镇'
        },{
            value:'廊下镇',
            label:'廊下镇'
        },{
            value:'金山卫镇',
            label:'金山卫镇'
        },{
            value:'漕泾镇',
            label:'漕泾镇'
        },{
            value:'山阳镇',
            label:'山阳镇'
        },{
            value:'金山工业区',
            label:'金山工业区'
        }]
    },{
        value:'松江区',
        label:'松江区',
        children:[{
            value:'九里亭街道',
            label:'九里亭街道'
        },{
            value:'广富林街道',
            label:'广富林街道'
        },{
            value:'岳阳街道',
            label:'岳阳街道'
        },{
            value:'永丰街道',
            label:'永丰街道'
        },{
            value:'方松街道',
            label:'方松街道'
        },{
            value:'中山街道',
            label:'中山街道'
        },{
            value:'泗泾镇',
            label:'泗泾镇'
        },{
            value:'佘山镇',
            label:'佘山镇'
        },{
            value:'车墩镇',
            label:'车墩镇'
        },{
            value:'新桥镇',
            label:'新桥镇'
        },{
            value:'洞泾镇',
            label:'洞泾镇'
        },{
            value:'九亭镇',
            label:'九亭镇'
        },{
            value:'柳港镇',
            label:'柳港镇'
        },{
            value:'石湖荡镇',
            label:'石湖荡镇'
        },{
            value:'新浜镇',
            label:'新浜镇'
        },{
            value:'叶榭镇',
            label:'叶榭镇'
        },{
            value:'小昆山镇',
            label:'小昆山镇'
        }]
    },{
        value:'青浦区',
        label:'青浦区',
        children:[{
            value:'夏阳街道',
            label:'夏阳街道'
        },{
            value:'盈浦街道',
            label:'盈浦街道'
        },{
            value:'香花桥街道',
            label:'香花桥街道'
        },{
            value:'朱家角镇',
            label:'朱家角镇'
        },{
            value:'练塘镇',
            label:'练塘镇'
        },{
            value:'金泽镇',
            label:'金泽镇'
        },{
            value:'赵岗镇',
            label:'赵岗镇'
        },{
            value:'徐泾镇',
            label:'徐泾镇'
        },{
            value:'华新镇',
            label:'华新镇'
        },{
            value:'重固镇',
            label:'重固镇'
        },{
            value:'白鹤镇',
            label:'白鹤镇'
        }]
    },{
        value:'奉贤区',
        label:'奉贤区',
        children:[{
            value:'南桥镇',
            label:'南桥镇'
        },{
            value:'奉城镇',
            label:'奉城镇'
        },{
            value:'庄行镇',
            label:'庄行镇'
        },{
            value:'金汇镇',
            label:'金汇镇'
        },{
            value:'四团镇',
            label:'四团镇'
        },{
            value:'青村镇',
            label:'青村镇'
        },{
            value:'拓林镇',
            label:'拓林镇'
        },{
            value:'海湾镇',
            label:'海湾镇'
        },{
            value:'奉浦社区',
            label:'奉浦社区'
        },{
            value:'金海社区',
            label:'金海社区'
        },{
            value:'海湾旅游区',
            label:'海湾旅游区'
        },{
            value:'上海海港综合经济开发区',
            label:'上海海港综合经济开发区'
        }]
    },{
        value:'崇明区',
        label:'崇明区',
        children:[{
            value:'城桥镇',
            label:'城桥镇'
        },{
            value:'堡镇镇',
            label:'堡镇镇'
        },{
            value:'新河镇',
            label:'新河镇'
        },{
            value:'庙镇镇',
            label:'庙镇镇'
        },{
            value:'竖新镇',
            label:'竖新镇'
        },{
            value:'向化镇',
            label:'向化镇'
        },{
            value:'三星镇',
            label:'三星镇'
        },{
            value:'港沿镇',
            label:'港沿镇'
        },{
            value:'中心镇',
            label:'中心镇'
        },{
            value:'陈家镇',
            label:'陈家镇'
        },{
            value:'绿华镇',
            label:'绿华镇'
        },{
            value:'港西镇',
            label:'港西镇'
        },{
            value:'建设镇',
            label:'建设镇'
        },{
            value:'新海镇',
            label:'新海镇'
        },{
            value:'东平镇',
            label:'东平镇'
        },{
            value:'长兴镇',
            label:'长兴镇'
        },{
            value:'新村乡',
            label:'新村乡'
        },{
            value:'横沙乡',
            label:'横沙乡'
        }]
    }
]
export function getMap(){ 
    return options
}