<template>
    <div class="statement">
        <div class="statement-top">
            <div class="sta-left">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="区">
                        <el-input v-model="searchForm.area" class="in-put" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="街道">
                        <el-input v-model="searchForm.sreet" class="in-put" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="居委会名称">
                        <el-input v-model="searchForm.committees" class="in-put" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="详细地址">
                        <el-input v-model="searchForm.addressDetall" class="in-put" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="居委会电话">
                        <el-input v-model="searchForm.phone" class="in-put" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="管辖范围">
                        <el-input v-model="searchForm.jurisdiction" class="in-put" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="getNeighborInfo()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="sta-right">
                <el-button type="primary" @click="addNeighbor()">新建</el-button>
            </div>
        </div>
        <el-table
                :data="tableData"
                border
                stripe
                style="width: 100%;margin-bottom:30px"
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center'}"
                max-height="650"
        >
            <el-table-column prop="area" label="区"></el-table-column>
            <el-table-column prop="sreet" label="街道"></el-table-column>
            <el-table-column prop="committees" label="居委会名称"></el-table-column>
            <el-table-column prop="addressDetall" label="详细地址"></el-table-column>
            <el-table-column prop="phone" label="居委会电话"></el-table-column>
            <el-table-column prop="jurisdiction" label="管辖范围"></el-table-column>
        </el-table>
        <div style="text-align:right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
        <div>
            <el-dialog title="新建" :visible.sync="dialogFormVisible">
                <el-form :model="subForm">
                    <el-form-item label="区/街道" :label-width="formLabelWidth">
                        <el-cascader
                                v-model="sreetAndArea"
                                :options="options"
                                :props="{ expandTrigger: 'hover' }"
                                @change="handleChange"
                                clearable
                                filterable>
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="居委会名称" :label-width="formLabelWidth">
                        <el-input v-model="subForm.committees" autocomplete="off" style="width:32%"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="详细地址" :label-width="formLabelWidth">
                        <el-input v-model="subForm.addressDetall" autocomplete="off" style="width:32%"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="居委会电话" :label-width="formLabelWidth">
                        <el-input v-model="subForm.phone" autocomplete="off" style="width:32%" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="管辖范围" :label-width="formLabelWidth">
                        <el-input v-model="subForm.jurisdiction" style="width:32%" clearable></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitNewNabor()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {insertNeighborhoodCommittee, neighborhoodCommittee} from '@/common/js/index.js';
    import {getMap} from '@/common/js/mapOfShanghai.js'

    export default {
        name: "neighborhood",
        data() {
            return {
                tableData: [],
                form: {},
                pageSize: 10,
                pageSizes: [10, 20, 30, 50, 100, 200, 300, 400, 500],
                total: 100,
                currentPage: 1,
                sreetAndArea: '',
                subFlag: false,
                searchForm: {
                    area: '',
                    sreet: '',
                    committees: '',
                    addressDetall: '',
                    phone: '',
                    jurisdiction: ''  //管辖范围
                },
                subForm: {
                    area: '',
                    sreet: '',
                    committees: '',
                    addressDetall: '',
                    phone: '',
                    jurisdiction: ''  //管辖范围
                },
                dialogFormVisible: false,
                formLabelWidth: '200px',
                options: []
            }
        },
        methods: {
            check(str, formName) {
                for (let i = 0; i < str.length; i++) {
                    if (isNaN(str[i])) {
                        this.$message.error('号码只能为数字')
                        formName.phone = formName.phone.substring(0, formName.phone.length - 1)
                        return false
                    }
                }
            },
            getNeighborInfo() {   //获取居委会信息
                let subData = 'currPage=' + this.currentPage
                    + '&pageSizePara=' + this.pageSize
                if (this.searchForm.area !== '') {
                    subData += '&area=' + this.searchForm.area
                }
                if (this.searchForm.sreet !== '') {
                    subData += '&sreet=' + this.searchForm.sreet
                }
                if (this.searchForm.committees !== '') {
                    subData += '&committees=' + this.searchForm.committees
                }
                if (this.searchForm.addressDetall !== '') {
                    subData += '&addressDetall=' + this.searchForm.addressDetall
                }
                if (this.searchForm.phone !== '') {
                    subData += '&phone=' + this.searchForm.phone
                }
                if (this.searchForm.jurisdiction !=='') {
                    subData += '&jurisdiction=' + this.searchForm.jurisdiction
                }
                neighborhoodCommittee(subData).then(res => {
                    if (res) {
                        this.tableData = res.data
                        if (res.data.length !== 0) {
                            this.total = res.data[0].totalCount
                        } else {
                            this.total = 0
                        }

                        this.pageSize = res.data[0].pageSize
                    }
                })
            },
            addNeighbor() {  //新增
                this.dialogFormVisible = true
            },
            submitNewNabor() {
                let subData = {
                    area: this.subForm.area,
                    sreet: this.subForm.sreet,
                    committees: this.subForm.committees,
                    addressDetall: this.subForm.addressDetall,
                    phone: this.subForm.phone,
                    jurisdiction: this.subForm.jurisdiction
                }
                for (var key in subData) {
                    if (subData[key] !== '' && subData[key] !== undefined) {
                        this.subFlag = true
                        break
                    }
                }
                if (this.subFlag === false) {
                    this.$message({
                        message: '输入不能全为空',
                        type: 'warning'
                    })
                } else {
                    insertNeighborhoodCommittee(subData).then(res => {
                        if (res) {
                            this.$message({
                                message: '新增成功',
                                type: 'success'
                            })
                        }
                        this.dialogFormVisible = false
                        this.subFlag = false
                        this.sreetAndArea = ''
                        this.clearForm(this.subForm)
                        this.getNeighborInfo()
                    })
                }
            },
            handleSizeChange(val) {  //分页的
                this.pageSize = val
            },
            handleCurrentChange(val) {  //分页的
                this.currentPage = val
            },
            handleChange(value) {   //级联选择器的
                this.subForm.area = ''
                this.subForm.sreet = ''
                this.subForm.area = value[0]
                this.subForm.sreet = value[1]
            },
            clearForm(formName) {        //清空表单
                formName.area = ''
                formName.sreet = ''
                formName.committees = '',
                    formName.addressDetall = '',
                    formName.phone = '',
                    formName.jurisdiction = ''
            }
        },
        mounted() {
            this.getNeighborInfo()
        },
        created() {
            this.options = getMap()
        },
        watch: {
            currentPage: function () {
                this.getNeighborInfo()
            },
            pageSize: function () {
                this.getNeighborInfo()
            },
            'subForm.phone': function (res) {
                this.check(res, this.subForm)
            },
            'searchForm.phone': function (res) {
                this.check(res, this.searchForm)
            }
        }
    }
</script>

<style scoped>
    .in-put {
        width: 120px;
    }

    .statement {
        width: 100%;
        /* border: 1px solid red;*/
    }

    .statement-top {
        height: 80px;
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 5px 20px 0px 20px;
    }

    .sta-left {
        margin-top: 10px;
        display: -webkit-flex; /* Safari */
        display: flex;
    }

    .sta-right {
        margin-top: 10px
    }
</style>
