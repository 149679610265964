<template>
  <el-container v-loading="loading">
    <el-aside width="300px">
      <el-row>
        <div style="color: #13ce66;text-align: center">注:报表目前仅显示登陆用户导出的文件</div>
        <p></p>
        <el-col :span="24" style="height: 90%">
          <div v-for="(item,index) in menuList" :key="item.id" class="menu menuli"
               @click="handleClickMenu(item,index)">{{ item.value }}
          </div>
        </el-col>

      </el-row>
    </el-aside>
    <el-main>
      <div>
        <el-table :cell-style="{'text-align':'center'}"
                  :data="filesList"
                  :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                  border
                  max-height="750"
                  stripe>
          <el-table-column label="文件名" property="value.fileName"></el-table-column>
          <el-table-column label="生成时间" property="value.date" width="240px"></el-table-column>
          <el-table-column label="操作" prop="operation" width="240">
            <template slot-scope="scope">
              <el-button plain size="small" type="success"
                         @click="getDownloadFile(scope.row)">
                下载文件
              </el-button>
              <el-button plain size="small" type="danger"
                         @click="getDeleteFile(scope.row)">删除文件
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import {deleteFile, fileDownload, fileList, sortList} from '@/common/js/index.js'
import {setTimeout} from 'timers';

export default {
  inject: ['reload'],
  name: "statement",
  data() {
    return {
      siteList: localStorage.getItem("siteList").split("$"),
      menuList: [],
      filesList: [],
      item: '',
      index: '',
      menu: '',//点击后文件夹名称
      subString: '',//下载和删除对应的subData;
      loading: true,
      siteValue: sessionStorage.getItem("siteValue"),
    }
  },
  methods: {
    handleClickMenu(item, index) {   //点击最左边菜单栏方法
      this.item = item;
      this.index = index;
      this.loading = false
      let _this = this
      let a = document.getElementsByClassName('menuli')
      for (let i = 0; i < a.length; i++) {
        a[i].style.background = '#fff'
        a[i].style.color = '#333'
      }
      a[index].style.background = '#ECF5FF'
      a[index].style.color = '#409EFF'     //改变样式
      let b = document.getElementsByClassName('fileli')
      for (let i = 0; i < b.length; i++) {
        b[i].style.background = '#fff'
        b[i].style.color = '#333'
      }
      // 切换后清空文件列表样式
      let subData = 'siteList=' + this.siteValue + '&folderName=' + item.value
      this.menu = item.value
      this.subString = ''   //切换后清空
      this.getFileList(subData)
      this.loading = false
      // /*setTimeout(function(){    //切换后默认选取第一个
      //     _this.handleClickFile(_this.filesList[0],0)
      // },800)*/
    },
    handleClickFile(item, index) {  //点击文件对应方法
      let a = document.getElementsByClassName('fileli')
      for (let i = 0; i < a.length; i++) {
        a[i].style.background = '#fff'
        a[i].style.color = '#333'
      }
      a[index].style.background = '#ECF5FF'
      a[index].style.color = '#409EFF'     //改变样式
      this.subString = "siteList=" + this.siteValue + '&folderName=' + this.menu + '&fileName=' + item.value.fileName
      let _this = this
      setTimeout(function () {
        _this.openFile()
      }, 500)

    },
    getSortList() {  //获取最左边菜单栏
      let subData = 'siteList=' + this.siteValue;
      sortList(subData).then(res => {
        if (res.retCode === 0) {
          for (let i = 0; i < res.data.folderName.length; i++) {
            let unitList = {}
            unitList.id = i
            unitList.value = res.data.folderName[i]
            this.menuList.push(unitList)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getFileList(subData) {  //获取菜单栏对应文件
      fileList(subData).then(res => {
        if (res.retCode === 0) {
          this.filesList = []
          for (let i = 0; i < res.data.filename.length; i++) {
            let unitList = {}
            unitList.id = i
            unitList.value = res.data.filename[i]
            this.filesList.push(unitList)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    openFile() {
      let showFile = document.getElementById('showFile')
      fileDownload(this.subString).then(res => {
        if (res.retCode === 0) {
          // http://www.xdocin.com/xdoc?_func=to&amp;_format=html&amp;_cache=1&amp;_xdoc=
          // http://office.qingshanboke.com/Default.aspx?url=
          let labela = '<iframe width="100%" height="100%" src="http://www.xdocin.com/xdoc?_func=to&amp;_format=html&amp;_cache=1&amp;_xdoc='
          let labelb = res.data + '"'
          let labelc = '></iframe>'
          let label = labela + labelb + labelc
          console.log(label)
          let _this = this
          setTimeout(function () {
            showFile.innerHTML = label
            _this.loading = false
          }, 500)
          this.$message.success('请稍等')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getDownloadFile(scope) {     //下载方法
      this.subString = "siteList=" + this.siteValue + '&folderName=' + this.menu + '&fileName=' + scope.value.fileName
      fileDownload(this.subString).then(res => {
        if (res.retCode === 0) {
          window.location.href = res.data;
          this.$message.success('文件下载中，请稍等。。。')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getDeleteFile(scope) {   //删除文件方法
      this.subString = "siteList=" + this.siteValue + '&folderName=' + this.menu + '&fileName=' + scope.value.fileName
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteFile(this.subString).then(res => {
          if (res.retCode === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            var item = this.item;
            var index = this.index;
            this.handleClickMenu(item, index);
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    refresh() {
      let _this = this
      setTimeout(function () {
        _this.handleClickMenu(_this.menuList[0], 0)
      }, 800)
    },
    siteFresh() {
      this.loading = true;
      this.siteValue = sessionStorage.getItem('siteValue');
      //this.refresh();
      //this.getSortList();
    }
  },
  created() {
    window.addEventListener('setItem', this.siteFresh, false);

    this.refresh();
    window.addEventListener('setItem', () => {
      this.loading = true;
      this.siteValue = sessionStorage.getItem('siteValue');
      this.refresh();
    }, false)
  },
  mounted() {
    this.getSortList()
    // this.openFullScreen()
    let _this = this
    setTimeout(function () {
      _this.handleClickMenu(_this.menuList[0], 0)
    }, 800)

  }
}
</script>

<style scoped>
.el-container {
  height: 100%
}

.el-aside {
  height: 100%;
}

.el-row {
  height: 100%
}

.el-col {
  height: 100%;
  border-right: 1px solid #ccc;
}

.menu {
  padding: 20px 0 20px 20px;
  cursor: pointer;
}

.menu:hover {
  background: #ccc;
}
</style>
