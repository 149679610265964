<template xmlns="http://www.w3.org/1999/html">
    <div class="clients">
        <div class="gd-top" style="margin:10px 0 20px 0">
            <div class="gd-left">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">
                    <el-form-item label="状态">
                        <el-select v-model="searchForm.status" multiple placeholder="请选择">
                            <el-option-group
                                    v-for="group in options"
                                    :key="group.label"
                                    :label="group.label">
                                <el-option
                                        v-for="item in group.options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                    &nbsp;<el-cascader
                        v-model="contractTypeAndSiteInquireValue"
                        :options="contractTypeAndSiteInquireValueOptions"
                        :props="{ expandTrigger: 'hover' }"
                        @change="siteChangeOperating()"
                        style="width:200px"></el-cascader>
                    <el-form-item>
                        <el-input v-model="searchForm.keyWords" placeholder="请输入工单号/员工/顾客相关信息" style="width:255px"
                                  @keyup.enter.native="searchOrder"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                                v-model="searchForm.date"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                               style="width: 230px">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="searchOrder()" v-if="this.dailyWorkOrderMoblle.workOrderList">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-form>
                    <el-form-item>
                        <el-button size="small" type="success" @click="exportExcel()" v-if="this.dailyWorkOrderMoblle.generateReports">工单</el-button>
                        <el-button size="small" type="success" @click="account()" v-if="this.dailyWorkOrderMoblle.accountExport">台账</el-button>
                        <el-button size="small" type="success" @click="worksheetPythonExport()" v-if="this.dailyWorkOrderMoblle.worksheetPythonExport">导出</el-button>
                        <el-button size="small" type="success" @click="deskCalendar()" v-if="this.dailyWorkOrderMoblle.deskCalendarPythonExport">台历</el-button>
                    </el-form-item>
                </el-form>
                <!--          <el-button type="success" @click="print()">打印</el-button>
                          -->
            </div>
            <div class="right">
                <el-button type="primary" @click="addOrder()" v-if="this.dailyWorkOrderMoblle.insertOrder">新增</el-button>
                <el-button type="danger" @click="deleteOrder()" v-if="this.dailyWorkOrderMoblle.updateOrder">删除</el-button>
                <el-button type="primary" @click="getLog()" v-if="this.dailyWorkOrderMoblle.workOrderLogList">日志</el-button>
                <el-button type="success" @click="updateList()" v-if="this.dailyWorkOrderMoblle.updateOrderList">批量修改</el-button>
            </div>

        </div>
        <el-table
            :data="tableData"
            @selection-change="handleSelectionChange"
            style="width: 100% ;margin-bottom:30px;"
            :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
            border
            stripe
            max-height="730"
            id="out-table"
            v-if="boxFlag && dailyWorkOrderMoblle.workOrderList"
            v-loading="loading"

        >
            <el-table-column type='selection' v-if="exportFlag" style="height: 10px"
                             min-height="10px"></el-table-column>
            <el-table-column class-name="aa" label="工单号" width="200" header-align="10px">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.workOrderNumber}}</p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="date" label="日期" width="95">
                <template slot-scope="scope">
                    <p v-show="scope.row.date" @click="handleEdit(scope.$index,scope.row)">{{scope.row.date}}</p>
                    <p v-show="!scope.row.date" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" label="员工" width="70">
                <template slot-scope="scope">
                    <p @click="jumpToCaregiver(scope.row.caregiverId,scope.row.contractInformationId)">{{scope.row.caregiverName}}</p>
                </template>
            </el-table-column>

            <!--<el-table-column class-name="aa" label="员工手机号">
                <template slot-scope="scope" >
                    <p v-show="scope.row.caregiverMoblle"  @click="handleEdit(scope.$index,scope.row)">{{scope.row.caregiverMoblle}}</p>
                    <p v-show="!scope.row.caregiverMoblle" @click="handleEdit(scope.$index,scope.row)" style="width:100%;height:20px"></p>
                </template>
            </el-table-column>-->

            <el-table-column class-name="aa" label="客户" width="70">
                <template slot-scope="scope">
                    <p @click="jumpToCustomer(scope.row)">{{scope.row.customerName}}</p>
                </template>
            </el-table-column>

            <!--<el-table-column class-name="aa" prop="customerMoblle" label="客户手机号">
                <template slot-scope="scope" >
                    <p v-show="scope.row.customerMoblle" @click="handleEdit(scope.$index,scope.row)">{{scope.row.customerMoblle}}</p>
                    <p v-show="!scope.row.customerMoblle" @click="handleEdit(scope.$index,scope.row)" style="width:100%;height:20px"></p>
                </template>
            </el-table-column>-->

            <el-table-column class-name="aa" prop="workOrderCompleted" label="客户确认" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.workOrderCompleted" @click="handleEdit(scope.$index,scope.row)">{{scope.row.workOrderCompleted}}</p>
                    <p v-show="!scope.row.workOrderCompleted" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="beginTime" label="开始时间" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.beginTime" @click="handleEdit(scope.$index,scope.row)">{{scope.row.beginTime}}</p>
                    <p v-show="!scope.row.beginTime" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="endTime" label="结束时间" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.endTime" @click="handleEdit(scope.$index,scope.row)">{{scope.row.endTime}}</p>
                    <p v-show="!scope.row.endTime" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="actualBeginTime" label="实际开始" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.actualBeginTime" @click="handleEdit(scope.$index,scope.row)">{{scope.row.actualBeginTime}}</p>
                    <p v-show="!scope.row.actualBeginTime" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="actualEndtime" label="实际结束" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.actualEndtime" @click="handleEdit(scope.$index,scope.row)">{{scope.row.actualEndtime}}</p>
                    <p v-show="!scope.row.actualEndtime" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>


            <el-table-column class-name="aa" prop="workOrderConsummation" label="工单录入" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.workOrderConsummation" @click="handleEdit(scope.$index,scope.row)">{{scope.row.workOrderConsummation}}</p>
                    <p v-show="!scope.row.workOrderConsummation" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="customerSettlement" label="用户结算" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.customerSettlement" @click="handleEdit(scope.$index,scope.row)">{{scope.row.customerSettlement}}</p>
                    <p v-show="!scope.row.customerSettlement" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" prop="customerSettlementDate" label="用户结算时间" width="105">
                <template slot-scope="scope">
                    <p v-show="scope.row.customerSettlementDate" @click="handleEdit(scope.$index,scope.row)">{{scope.row.customerSettlementDate}}</p>
                    <p v-show="!scope.row.customerSettlementDate" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" prop="medicalInsuranceSettlement" label="医保结算" width="77">
                <template slot-scope="scope">
                    <p v-show="scope.row.medicalInsuranceSettlement" @click="handleEdit(scope.$index,scope.row)">{{scope.row.medicalInsuranceSettlement}}</p>
                    <p v-show="!scope.row.medicalInsuranceSettlement" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>

            <el-table-column class-name="aa" prop="caregiverSettlement" label="护理员结算" width="95">
                <template slot-scope="scope">
                    <p v-show="scope.row.caregiverSettlement" @click="handleEdit(scope.$index,scope.row)">{{scope.row.caregiverSettlement}}</p>
                    <p v-show="!scope.row.caregiverSettlement" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" prop="ownExpense" label="自费" width="50">
              <template slot-scope="scope">
                <p v-show="scope.row.ownExpense" @click="handleEdit(scope.$index,scope.row)">{{scope.row.ownExpense}}</p>
                <p v-show="!scope.row.ownExpense" @click="handleEdit(scope.$index,scope.row)"
                   style="width:100%;height:20px"></p>
              </template>
            </el-table-column>
            <el-table-column class-name="aa" prop="medicareStatusValue" label="类型" width="65">
              <template slot-scope="scope">
                <p v-show="scope.row.medicareStatusValue" @click="handleEdit(scope.$index,scope.row)">{{scope.row.medicareStatusValue}}</p>
                <p v-show="!scope.row.medicareStatusValue" @click="handleEdit(scope.$index,scope.row)"
                   style="width:100%;height:20px"></p>
              </template>
            </el-table-column>
            <el-table-column class-name="aa" prop="contractTypeId" label="合约类型" width="95">
                <template slot-scope="scope">
                    <p v-show="scope.row.contractTypeId" @click="handleEdit(scope.$index,scope.row)">{{scope.row.contractTypeId}}</p>
                    <p v-show="!scope.row.contractTypeId" @click="handleEdit(scope.$index,scope.row)"
                       style="width:100%;height:20px"></p>
                </template>
            </el-table-column>
            <el-table-column class-name="aa" label="备注">
                <template slot-scope="scope">
                    <p @click="handleEdit(scope.$index,scope.row)">{{scope.row.remarks}}</p>
                </template>
            </el-table-column>
            <!--            <el-table-column prop="operation" label="操作" v-if="exportFlag" width="80">
                            <template slot-scope="scope">
                                <el-button size="small" @click="handleEdit(scope.$index,scope.row)">编辑</el-button>
                            </template>
                        </el-table-column>-->
        </el-table>
        <div class="block" style="text-align:right" v-if="this.dailyWorkOrderMoblle.workOrderList">
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pagesizes"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="all">
            </el-pagination>
        </div>
        <!-- 日志 -->
        <el-dialog title="日志" :visible.sync="dialogTableVisible" width="80%">
            <div style="margin-bottom:10px">
                <el-form :inline="true" class="demo-form-inline" style="z-index:2">
                    <el-date-picker
                            v-model="logSearchTime"
                            type="daterange"
                            align="right"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"  style="width: 230px">
                    </el-date-picker>&nbsp;&nbsp;&nbsp;
                    &nbsp;<el-cascader
                        v-model="contractTypeAndSiteInquireValue"
                        :options="contractTypeAndSiteInquireValueOptions"
                        :props="{ expandTrigger: 'hover' }"
                        @change="getLog()"
                        style="width:200px"></el-cascader>&nbsp;&nbsp;&nbsp;
                    <el-form-item label="操作者:">
                        <el-input v-model="handlers" placeholder="" style="width:150px"
                                  @keyup.enter.native="searchOrder"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="用户:">
                        <el-input v-model="beOperatedCustomerName" placeholder="" style="width:150px"
                                  @keyup.enter.native="searchOrder"
                                  clearable></el-input>
                    </el-form-item>

                    <el-button type="primary" size="small" @click="getLog()" style="margin-left:10px">查询</el-button>
                    <el-button size="small" type="success" @click="worksheetLogExport()" v-if="this.dailyWorkOrderMoblle.worksheetLogExport">日志导出</el-button>
                </el-form>
            </div>
            <el-table :data="gridData"
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center'}"
                      border
                      stripe
                      max-height="750">
                <el-table-column property="handlers" label="操作者" width="95"></el-table-column>
                <el-table-column property="operatingTime" label="操作时间" width="160"></el-table-column>
                <el-table-column property="customerName" label="用户" width="95"></el-table-column>
                <el-table-column property="workOrderNumber" label="工单号" width="200"></el-table-column>
                <el-table-column property="contractTypeName" label="合约类型" width="95"></el-table-column>
                <el-table-column property="siteName" label="护理站" width="95"></el-table-column>
                <el-table-column property="content" label="内容" width="150"></el-table-column>
                <el-table-column property="result" label="操作结果" width="300"></el-table-column>
                <el-table-column property="oerationNote" label="备注"></el-table-column>
            </el-table>
            <div class="block" style="text-align:right">
                <el-pagination
                        background
                        @size-change="handleSizeChange1"
                        @current-change="handleCurrentChange1"
                        :current-page="currentPage1"
                        :page-sizes="pagesizes1"
                        :page-size="pagesize1"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="all1">
                </el-pagination>
            </div>
        </el-dialog>
        <!-- 弹出表单栏 -->
        <el-dialog title="编辑" :visible.sync="dialogFormVisible1" width="56%">
            <div style="display:flex;justify-content:space-between;border-bottom:1px solid #ccc;padding-bottom:5px;">
                <div style="line-height:50px">{{subForm.workOrderNumber}}</div>
                <div>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
                        <el-button type="primary" @click="subFormFun()">确 定</el-button>
                    </div>
                </div>
            </div>
            <div>
                <p style="font-weight:bold">客户信息：</p>
                <div style="display:flex">
                    <div style="margin-right:20px">
                        <p>客户</p>
                        <p style="border-bottom:1px solid #ccc;height:40px;line-height:40px;width:229px">
                            {{subForm.customerName}}</p>
                    </div>
                    <div style="margin-right:20px">
                        <p>开始时间：</p>
                        <el-time-picker
                                style="width:229px"

                                v-model="subForm.beginTime"
                                format="HH:mm"
                                :picker-options="{
                            selectableRange: '00:00:00 - 23:59:59'
                            }"
                                placeholder="请选择">
                        </el-time-picker>
                    </div>
                    <div style="margin-right:20px">
                        <p>结束时间：</p>
                        <el-time-picker
                                style="width:229px;"

                                v-model="subForm.endTime"
                                format="HH:mm"
                                :picker-options="{
                            selectableRange: '00:00:00 - 23:59:59'
                            }"
                                placeholder="请选择">
                        </el-time-picker>
                    </div>
                </div>
                <div style="display:flex">
                    <div style="margin-right:20px">
                        <p>手机号：</p>
                        <el-input v-model="subForm.customerMoblle" style="width:229px">手机号</el-input>
                    </div>
                    <div style="margin-right:20px">
                        <p>实际开始时间：</p>
                        <el-time-picker
                                style="width:229px"

                                v-model="subForm.actualBeginTime"
                                format="HH:mm"
                                :picker-options="{
                            selectableRange: '00:00:00 - 23:59:59'
                            }"
                                placeholder="请选择">
                        </el-time-picker>
                    </div>
                    <div style="margin-right:20px">
                        <p>实际结束时间：</p>
                        <el-time-picker
                                style="width:229px;"

                                v-model="subForm.actualEndtime"
                                format="HH:mm"
                                :picker-options="{
                            selectableRange: '00:00:00 - 23:59:59'
                            }"
                                placeholder="请选择">
                        </el-time-picker>
                    </div>
                </div>
                <div style="display:flex">
                    <div style="margin-right:20px">
                        <p>护理员结算时间：</p>

                        <el-date-picker
                                style="width:229px"
                                v-model="subForm.caregiverSettlementDate" :disabled="true"
                                type="date"
                        >
                        </el-date-picker>
                    </div>
                    <div style="margin-right:20px">
                        <p>用户结算时间：</p>
                        <el-date-picker
                                style="width:229px"

                                v-model="subForm.customerSettlementDate" :disabled="true"
                                type="date"
                        >
                        </el-date-picker>
                    </div>
                    <div style="margin-right:20px">
                        <p>医保结算时间：</p>
                        <el-date-picker
                                style="width:229px;"
                                v-model="subForm.medicalInsuranceSettlementDate" :disabled="true"
                                type="date">
                        </el-date-picker>
                    </div>
                </div>
                <!-- <p>手机号：</p>
                <el-input  v-model="subForm.customerMoblle" style="width:229px">手机号</el-input> -->
                <p style="font-weight:bold">护理员信息：</p>
                <div style="display:flex">
                    <div style="margin-right:20px" @click="innerVisible2=true">
                        <p>护理员：</p>
                        <el-input v-model="subForm.caregiverName"></el-input>
                    </div>
                    <div>
                        <p>护理员电话：</p>
                        <el-input v-model="subForm.caregiverMoblle"></el-input>
                    </div>
                    <div style="margin-left:20px">
                        <p>备注：</p>
                        <el-input v-model="subForm.remarks" style="width:280px"></el-input>
                    </div>
                </div>
                <p style="font-weight:bold">长护险信息</p>
                <div style="display:flex">
                    <div style="margin-right:20px">
                        <p>长护险单号</p>
                        <el-input v-model="subForm.serviceOrderNumber" style="margin-top:-20px"
                                  placeholder="请输入长护险单号"></el-input>
                    </div>
                    <div style="margin-right:20px">
                      <p style="margin-bottom:26px">是否自费</p>
                      <el-checkbox v-model="subForm.ownExpense">自费</el-checkbox>
                    </div>
                    <div style="margin-right:20px">
                        <p style="margin-bottom:26px">客户确认</p>
                        <el-checkbox v-model="subForm.workOrderCompleted">已验证</el-checkbox>
                    </div>
                    <div style="margin-right:20px">
                        <p style="margin-bottom:26px">工单录入</p>
                        <el-checkbox v-model="subForm.workOrderConsummation">已录入</el-checkbox>
                    </div>
                    <div style="margin-right:20px">
                        <p style="margin-bottom:26px">用户结算</p>
                        <el-checkbox v-model="subForm.customerSettlement">已结算</el-checkbox>
                    </div>
                    <div style="margin-right:20px">
                        <p style="margin-bottom:26px">医保结算</p>
                        <el-checkbox v-model="subForm.medicalInsuranceSettlement">已结算</el-checkbox>
                    </div>
                    <div style="margin-right:20px">
                        <p style="margin-bottom:26px">护理员结算(如需结算，请到结算详情中进行自动绑定)</p>
                        <el-checkbox v-model="subForm.caregiverSettlement" :disabled="true">已结算</el-checkbox>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="新增" :visible.sync="dialogFormVisible">
            <!-- 内部弹出框 -->
            <el-dialog
                    width="80%"
                    title="请选择客户"
                    :visible.sync="innerVisible1"
                    append-to-body>
                <el-input v-model="customerSearch" placeholder="请输入客户名字/手机号,按Enter搜索" style="margin-bottom:5px"
                          @keyup.enter.native="getClients"></el-input>
                <el-table
                        :data="clientData.slice((currentPageC-1)*pagesizeC,currentPageC*pagesizeC)"
                        max-height="550"
                        :header-cell-style="{background:'#dee2ec','color': '#666666','text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        border
                        stripe
                >
                    <el-table-column prop="numbering" label="编号"></el-table-column>
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="gender" label="性别"></el-table-column>
                    <el-table-column prop="moblle" label="电话"></el-table-column>
                    <el-table-column prop="statusChangetime" label="时间"></el-table-column>
                    <el-table-column prop="status" label="状态"></el-table-column>
                    <el-table-column label="点击选择">
                        <template slot-scope="scope">
                            <el-button size="small" @click="handleEditC(scope.$index,scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="block" style="text-align:right">
                    <el-pagination
                            background
                            @size-change="handleSizeChangeC"
                            @current-change="handleCurrentChangeC"
                            :current-page="currentPageC"
                            :page-sizes="pagesizesC"
                            :page-size="pagesizeC"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="allC">
                    </el-pagination>
                </div>
            </el-dialog>
            <el-dialog
                    width="80%"
                    title="请选择护理员"
                    :visible.sync="innerVisible2"
                    append-to-body>
                <el-input v-model="careSearch" style="margin-bottom:5px" placeholder="请输入护理员名字/手机号,按Enter搜索"
                          @keyup.enter.native="getnurses"></el-input>
                <el-table
                        :data="caregiverData.slice((currentPageD-1)*pagesizeD,currentPageD*pagesizeD)"
                        max-height="550"
                        :header-cell-style="{background:'#dee2ec', color: '#666666','text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        border
                        stripe
                >
                    <el-table-column prop="status" label="状态"></el-table-column>
                    <el-table-column prop="numbering" label="编号"></el-table-column>
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="gender" label="性别"></el-table-column>
                    <el-table-column prop="moblle" label="电话"></el-table-column>
                    <el-table-column prop="statusChangetime" label="时间"></el-table-column>
                    <el-table-column label="点击选择">
                        <template slot-scope="scope">
                            <el-button size="small" @click="handleEditD(scope.$index,scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="block" style="text-align:right">
                    <el-pagination
                            background
                            @size-change="handleSizeChangeD"
                            @current-change="handleCurrentChangeD"
                            :current-page="currentPageD"
                            :page-sizes="pagesizesD"
                            :page-size="pagesizeD"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="allD">
                    </el-pagination>
                </div>
            </el-dialog>
            <el-form :model="subForm" :rules="rules" ref="subForm">
                <el-form-item>
                    <div style="display:flex;justify-content:space-between;border-bottom:1px solid #ccc;padding-bottom:5px;">
                        <div style="line-height:46px">{{subForm.workOrderNumber}}</div>
                        <div>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="dialogFormVisible = false">取 消</el-button>
                                <el-button type="primary" @click="subFormFun()">确 定</el-button>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <div>
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="合约类型:">
                                &nbsp;<el-cascader
                                    v-model="contractTypeAndSiteInquireValue"
                                    :options="contractTypeAndSiteInquireValueOptions"
                                    :props="{ expandTrigger: 'hover' }"
                                    @change="addWorkOrderSelectionCustomerAndCaregiver()"
                                    style="width:200px"></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="日期：">
                                <el-date-picker
                                        style="width:150px"
                                        v-model="subForm.date"
                                        type="date"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-select v-model="subForm.medicareStatusValue" label="工单类型" style="width: 150px">
                              <el-option
                                  v-for="item in medicareStatus"
                                  :key="item.medicareStatusValue"
                                  :label="item.label"
                                  :value="item.medicareStatusValue">
                              </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <p style="font-weight:bold">客户信息：</p>
                    <el-row>
                        <el-col :span="6">
                            <div @click="innerVisible1=true">
                                <el-form-item prop="clientId" label="客户：">
                                    <el-input style="width:120px" v-model="subForm.customerName"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号：">
                                <el-input v-model="subForm.customerMoblle" style="width:120px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="开始时间：">
                                <el-time-picker
                                        style="width:120px"
                                        v-model="subForm.beginTime"
                                        format="HH:mm"
                                        :picker-options="{
                                selectableRange: '00:00:00 - 23:59:59'
                                }"
                                        placeholder="请选择">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="结束时间：">
                                <el-time-picker
                                        style="width:120px;"
                                        v-model="subForm.endTime"
                                        format="HH:mm"
                                        :picker-options="{
                                selectableRange: '00:00:00 - 23:59:59'
                                }"
                                        placeholder="请选择">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <p style="font-weight:bold">护理员信息：</p>
                    <el-row>
                        <el-col :span="6">
                            <div @click="innerVisible2=true">
                                <el-form-item prop="caregiverId" label="护理员：">
                                    <el-input style="width:120px" v-model="subForm.caregiverName"></el-input>
                                </el-form-item>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="护理员电话：">
                                <el-input style="width:120px" v-model="subForm.caregiverMoblle"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="备注：">
                                <el-input style="width:850px" v-model="subForm.remarks"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <p style="font-weight:bold">长护险信息 : </p>
                    <div style="display:flex">
                        <div style="margin-right:20px">
                            <p>长护险单号</p>
                            <el-input v-model="subForm.serviceOrderNumber" style="margin-top:-20px;"
                                      placeholder="请输入长护险单号"></el-input>
                        </div>
                        <div style="margin-right:20px">
                          <p style="margin-bottom:26px">是否自费</p>
                          <el-checkbox v-model="subForm.ownExpense">是</el-checkbox>
                        </div>
                        <div style="margin-right:20px">
                            <p style="margin-bottom:26px">客户确认</p>
                            <el-checkbox v-model="subForm.workOrderCompleted">已验证</el-checkbox>
                        </div>
                        <div style="margin-right:20px">
                            <p style="margin-bottom:26px">工单录入</p>
                            <el-checkbox v-model="subForm.workOrderConsummation">已录入</el-checkbox>
                        </div>
                        <div style="margin-right:20px">
                            <p style="margin-bottom:26px">用户结算</p>
                            <el-checkbox v-model="subForm.customerSettlement">已结算</el-checkbox>
                        </div>
                        <div style="margin-right:20px">
                            <p style="margin-bottom:26px">医保结算</p>
                            <el-checkbox v-model="subForm.medicalInsuranceSettlement">已结算</el-checkbox>
                        </div>
                        <div style="margin-right:20px">
                            <p style="margin-bottom:26px">护理员结算</p>
                            <el-checkbox v-model="subForm.caregiverSettlement">已结算</el-checkbox>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-dialog>
        <!-- --------------------------->
        <el-dialog title="批量修改" :visible.sync="dialogFormVisible2">
            <el-form :model="subForm" label-width="160px" :rules="editFormRules" ref="editForm" :inline="true"
                     class="demo-form-inline">
                <el-form-item label="用户结算" :label-width="formLabelWidth">
                    <el-select v-model="subForm.customerSettlement" placeholder="请选择">
                        <el-option
                                v-for="item in customerSettlementList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="医保结算" :label-width="formLabelWidth">
                    <el-select v-model="subForm.medicalInsuranceSettlement" placeholder="请选择">
                        <el-option
                                v-for="item in medicalInsuranceSettlementList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--                <el-form-item label="护理员结算" :label-width="formLabelWidth">-->
                <!--                    <el-select v-model="subForm.caregiverSettlement" placeholder="请选择">-->
                <!--                        <el-option-->
                <!--                                v-for="item in caregiverSettlementList"-->
                <!--                                :key="item.value"-->
                <!--                                :label="item.label"-->
                <!--                                :value="item.value">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="客户确认" :label-width="formLabelWidth">
                    <el-select v-model="subForm.workOrderCompleted" placeholder="请选择">
                        <el-option
                                v-for="item in workCompletedList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工单录入" :label-width="formLabelWidth">
                    <el-select v-model="subForm.workOrderConsummation" placeholder="请选择">
                        <el-option
                                v-for="item in workOrderConsummationList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--<el-form-item class="red" style="color: #DC143C" label="删除工单" :label-width="formLabelWidth">
                    <el-select v-model="subForm.deleteStatus" placeholder="请选择">
                        <el-option
                                v-for="item in deleteStatusList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>-->
                <br>
                <el-form-item class="red" style="color: #f31616" label="用户结算时间" :label-width="formLabelWidth">
                    <el-date-picker
                            style="width:229px"
                            v-model="subForm.customerSettlementDate"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <!--                <el-form-item class="red" style="color: #f31616" label="护理员结算时间" :label-width="formLabelWidth">
                                    <el-date-picker

                                            style="width:229px"
                                            v-model="subForm.caregiverSettlementDate"
                                            type="date"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>-->
                <el-form-item class="red" style="color: #f31616" label="医保结算时间" :label-width="formLabelWidth">
                    <el-date-picker

                            style="width:229px"
                            v-model="subForm.medicalInsuranceSettlementDate"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div style="width: 100%;
            height: 1px;
            border-top: dashed #ACC0D8 1px;
"></div>
            <p style="color: #8c939d;float:right">如需修改护理员结算 状态或时间，请到结算详情中自动绑定 自动修改</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="subFormFun()">确 定</el-button>
            </div>
        </el-dialog>
        <!--用户详情-->
        <el-dialog title="用户详情" :visible.sync="clientDetails" width="80%">
            <div class="des-box">
                <el-form ref="form" :model="form" :rules="cRules" label-width="100px">
                  <div class="des  assess">
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="姓名" prop="name">
                          <el-input v-model="form.name" placeholder="请输入客户姓名" class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="手机号" prop="moblle">
                          <el-input v-model="form.moblle" placeholder="请输入客户手机号码" class="line"
                                    style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="身份证号" prop="identityCard">
                          <el-input v-model="form.identityCard" placeholder="请输入客户身份证号"
                                    class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="年龄" prop="age">
                          <el-input v-model="form.age" placeholder="请输入客户年龄" class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="家属姓名">
                          <el-input v-model="form.familyName" placeholder="请输入客户家属姓名"
                                    class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="紧急联络电话">
                          <el-input v-model="form.familyMoblle" placeholder="请输入客户紧急联系方式"
                                    class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">&nbsp;&nbsp;&nbsp;&nbsp;
                        <el-button type="primary" @click="viewOtherFamilyMembers()" size="small"
                                   v-show="!isAdd">查看其它家属
                        </el-button>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="性别">
                          <el-radio-group v-model="form.gender">
                            <el-radio label="男">男</el-radio>
                            <el-radio label="女">女</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="银行卡所属行" prop="bankCardName">
                          <el-input v-model="form.bankCardName" placeholder="请输入银行卡所属行名"
                                    class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="银行卡号" prop="bankCardNumber">
                          <el-input v-model="form.bankCardNumber" placeholder="请输入银行卡号"
                                    class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="状态备注" prop="statusRemarks">
                          <el-input v-model="form.statusRemarks" class="line" style="width: 400px"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                    <br/>
                    <el-row :gutter="0">
                        <el-col :span="6">
                            <el-form-item label="已签约:">
                                <el-select v-model="selectEvaluationNursingId" @change="jumpToCustomer()" v-show="!isAdd"
                                           style="width: 150px">
                                    <el-option
                                            v-for="item in customeContracttypesList"
                                            :key="item.index"
                                            :label="item.customeContractType"
                                            :value="item.evaluationNursingId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <div class="client-list-two">
                                <div v-if="form.status === '有意向'" class="stateYellow"><span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '待派单'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '待申请'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '待评估'" class="lightSkyBlue">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '中止中'" class="stateRed">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '长期中止'" class="stateMaroon">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '待确认'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '合约中'" class="stateGreen">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '已终止'" class="stateRed">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '到期合约'" class="stateDarkgray">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status === '已删除'" class="stateDarkgray">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="des  assess">
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="长护险平台是否录入" label-width="160px">
                          <el-switch v-model="form.enterPlan" active-value="1"
                                     inactive-value="0"></el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="长护险平台是否接收" label-width="160px">
                          <el-switch v-model="form.enterPlanFlag" active-value="1"
                                     inactive-value="0"></el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="医护平台是否派单" label-width="160px">
                          <el-switch v-model="form.mdicalPatform" active-value="1"
                                     inactive-value="0"></el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="护理计划编码">
                          <el-input v-model="form.plannlngSheet" placeholder="请输入客户护理计划编码"
                                    class="line"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="街道">
                          <el-input v-model="form.sreet" class="line" @focus="toAddress()" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="居委会" prop="committees">
                          <el-input v-model="form.committees" class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="详细地址">
                          <el-input v-model="form.addressDetall" class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="设备编号">
                          <el-input v-model="form.lbeaconMajor" placeholder="请输入设备编号" class="line"
                                    learable></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6">
                        <el-form-item label="评估有效期">
                          <el-date-picker
                              v-model="form.evaluationPeriod"
                              type="date"
                              style="width: 200px"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="首次护理时间">
                          <el-date-picker
                              v-model="form.firstCareTime"
                              type="date"
                              style="width: 200px"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="评估等级">
                          <el-input v-model="form.evaluationLervl" type="number" placeholder="请输入客户评估等级"
                                    class="line" style="width: 200px"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item label="医保卡类型">
                          <el-select v-model="form.medicareCardType" placeholder="请选择医保卡类型" class="line">
                            <el-option
                                v-for="item in medicareTypes"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                          <el-form-item label="推荐类型">
                            <el-select v-model="form.recommendedTypeId" placeholder="请选择推荐类型">
                              <el-option
                                  v-for="item in recommendedTypes"
                                  :key="item.recommendedTypeId"
                                  :label="item.recommendedType"
                                  :value="item.recommendedTypeId"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="16">
                          <el-form-item label="推荐明细" v-if="!whetherTheCaregiverRecommends">
                            <el-input v-model="form.recommendedDetails" placeholder="请输入推荐明细"
                                      class="line" style="width: 500px;"></el-input>
                          </el-form-item>
                          <el-form-item label="推荐护理员" v-if="whetherTheCaregiverRecommends" style="width: 300px;">
                            <el-input v-model="form.recommendedDetails" placeholder="请输入推荐明细"
                                      @focus="careInput(5)" style="width: 200px;"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    <el-row :gutter="20">
<!--                      <el-col :span="6">-->
<!--                        <el-form-item label="推荐渠道">-->
<!--                          <el-input v-model="form.recommend" placeholder="请输入客户推荐渠道"-->
<!--                                    class="line" style="width: 200px;"></el-input>-->
<!--                        </el-form-item>-->
<!--                      </el-col>-->
                      <el-col :span="12">
                        <el-form-item label="护理备注">
                          <el-input v-model="form.nursingRemarks" class="line" style="width: 800px;"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                    <div class="des  assess">

                    <el-row :gutter="0">
                      <el-col :span="8" style="width: 35%">
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="指定护理员一">
                              <el-input v-model="careWorker_one" placeholder="请选择指定护理员一" class="line"
                                        @focus="careInput(1)"></el-input>
                              <el-button type="danger" @click="deleteCareWorker_one()" v-show="!isAdd" size="mini"
                                         icon="el-icon-delete"
                                         circle></el-button>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="指定原因">
                              <el-input v-model="form.designationReason_one" placeholder="指定护理员一原因" class="line"
                                        clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="指定护理员二">
                              <el-input v-model="careWorker_two" placeholder="请选择指定护理员二" class="line"
                                        @focus="careInput(2)"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="指定原因">
                              <el-input v-model="form.designationReason_two" placeholder="指定护理员二原因"
                                        class="line"
                                        clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="指定护理员三">
                              <el-input v-model="careWorker_three" placeholder="请选择指定护理员三" class="line"
                                        @focus="careInput(3)"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="指定原因">
                              <el-input v-model="form.designationReason_three" placeholder="指定护理员三原因"
                                        class="line"
                                        clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="备用时间备注">
                              <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                        clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="12" style="width: 55%">
                        <div v-for="(item, index) in timePeriodList" v-bind:key="index">
                          <el-row :gutter="20">
                            <el-col :span="4">
                              <el-select v-model="item.week" placeholder="周期">
                                <el-option
                                    v-for="item in serveWeek"
                                    :key="item.week"
                                    :label="item.name"
                                    :value="item.week">
                                </el-option>
                              </el-select>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="护理时间">
                                <el-time-select
                                    style="width: 120px"
                                    placeholder="起始时间"
                                    v-model="item.beginTime"
                                    format="HH:mm"
                                    :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                                </el-time-select>
                                <el-time-select
                                    style="width: 120px"
                                    placeholder="结束时间"
                                    v-model="item.endTime" :disabled="true" class="line"
                                    :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                                </el-time-select>
                              </el-form-item>
                            </el-col>
                            <el-col :span="5">
                              <el-select v-model="item.medicareStatusValue" placeholder="状态">
                                <el-option
                                    v-for="item in medicareStatus"
                                    :key="item.medicareStatusValue"
                                    :label="item.label"
                                    :value="item.medicareStatusValue">
                                </el-option>
                              </el-select>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="des  assess">
                    <el-row :gutter="0">
                      <el-col :span="8" style="width: 35%">
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="预约指定护理员" label-width="160px">
                              <el-input v-model="reservation_careWorker" placeholder="请选择预约指定护理员" class="line"
                                        @focus="careInput(4)" clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="预约指定原因" label-width="160px">
                              <el-input v-model="form.reservation_designationReason" placeholder="预约指定原因" style="width:200px"
                                        clearable></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="状态预约时间" prop="appointmentTime" v-if="!isAdd" label-width="160px">
                              <el-input v-model="form.appointmentTime" :disabled="true" style="width:200px"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="24">
                            <el-form-item label="预约变更周期时间" prop="appointmentCycleChangeTime" v-if="!isAdd"
                                          label-width="160px">
                              <el-input v-model="form.appointmentCycleChangeTime" :disabled="true"
                                        style="width:200px"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="12" style="width: 55%">
                        <div v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                          <el-row :gutter="20">
                            <el-col :span="4">
                              <el-select v-model="item.week" :disabled="true" placeholder="周期">
                                <el-option
                                    v-for="item in serveWeek"
                                    :key="item.week"
                                    :label="item.name"
                                    :value="item.week">
                                </el-option>
                              </el-select>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="护理时间">
                                <el-time-select
                                    style="width: 120px"
                                    placeholder="起始时间"
                                    v-model="item.beginTime" :disabled="true"
                                    format="HH:mm"
                                    :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                                </el-time-select>
                                <el-time-select
                                    style="width: 120px"
                                    placeholder="结束时间"
                                    v-model="item.endTime" :disabled="true" class="line"
                                    :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                                </el-time-select>
                              </el-form-item>
                            </el-col>
                            <el-col :span="5">
                              <el-select v-model="item.medicareStatusValue" :disabled="true" placeholder="状态">
                                <el-option
                                    v-for="item in medicareStatus"
                                    :key="item.medicareStatusValue"
                                    :label="item.label"
                                    :value="item.medicareStatusValue">
                                </el-option>
                              </el-select>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="des map">
                      <div class="flex">
                          <el-form-item label="纬度" label-width="50px">
                              <el-input v-model="form.latltude" placeholder="" class="line"
                                        clearable></el-input>
                          </el-form-item>
                          <el-form-item label="经度" label-width="50px">
                              <el-input v-model="form.longltude" placeholder="" class="line"
                                        clearable></el-input>
                          </el-form-item>
                          <!--<span class="local">纬度：{{form.latltude}}</span><span class="local">经度：{{form.longltude}}</span>-->
                      </div>
                      <div id="map"></div>
                  </div>
                  <div class="des card">
                      <el-tabs v-model="activeName">
                          <el-tab-pane label="身份证" name="first">
                              <div class="flex img-box">
                                  <div>
                                      <el-upload
                                              class="avatar-uploader"
                                              action="customize"
                                              :show-file-list="false"
                                              :http-request="uploadFile"
                                              accept='.jpg,.png'
                                              ref="idBefUpload"
                                              :on-change="idBefChange"
                                      >
                                          <img v-if="idBefUrl" :src="idBefUrl" class="avatar">
                                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                      </el-upload>
                                      <p>（身份证正面）</p>
                                  </div>
                                  <div style="margin-left: 30px">
                                      <el-upload
                                              class="avatar-uploader"
                                              action="customize"
                                              :show-file-list="false"
                                              :http-request="uploadFile"
                                              accept='.jpg,.png'
                                              ref="idBacUpload"
                                              :on-change="idBacChange"
                                      >
                                          <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                      </el-upload>
                                      <p>（身份证反面）</p>
                                  </div>
                              </div>
                          </el-tab-pane>

                          <el-tab-pane label="医保卡" name="second">
                              <div class="flex img-box">
                                  <div>
                                      <el-upload
                                              class="avatar-uploader"
                                              action="customize"
                                              :show-file-list="false"
                                              :http-request="uploadFile"
                                              accept='.jpg,.png'
                                              ref="soBefUpload"
                                              :on-change="soBefChange"
                                      >
                                          <img v-if="soBefUrl" :src="soBefUrl" class="avatar">
                                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                      </el-upload>
                                      <p>（社保卡正面）</p>
                                  </div>
                                  <div style="margin-left: 30px">
                                      <el-upload
                                              class="avatar-uploader"
                                              action="customize"
                                              :show-file-list="false"
                                              :http-request="uploadFile"
                                              accept='.jpg,.png'
                                              ref="soBacUpload"
                                              :on-change="soBacChange"
                                      >
                                          <img v-if="soBacUrl" :src="soBacUrl" class="avatar">
                                          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                      </el-upload>
                                      <p>（社保卡反面）</p>
                                  </div>
                              </div>
                          </el-tab-pane>
                      </el-tabs>
                  </div>
                </el-form>
            </div>

        </el-dialog>
        <!--护理员详情-->
        <el-dialog title="护理员详情" :visible.sync="staffDetails" width="80%">
            <div class="des-box">
                <el-form ref="staForm" :model="staForm" :rules="sRules" label-width="100px">
                    <div class="des assess">
                        <el-row :gutter="0">
                            <el-col :span="9">
                                <el-form-item label="姓名" prop="name">
                                    <el-input v-model="staForm.name" placeholder="请输入护理员姓名"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="9">
                                <el-form-item label="紧急联络电话">
                                    <el-input v-model="staForm.contactMoblle" placeholder="请输入护理员紧急联络电话"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="9">
                            <el-form-item label="身份证号" prop="identityCard">
                              <el-input v-model="staForm.identityCard" placeholder="请输入护理员身份证号"
                                        class="line"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="4">
                            <el-form-item label="年龄" prop="age">
                              <el-input v-model="staForm.age" placeholder="请输入护理员年龄"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="9">
                            <el-form-item label="性别">
                              <el-radio-group v-model="staForm.gender">
                                <el-radio label="男">男</el-radio>
                                <el-radio label="女">女</el-radio>
                              </el-radio-group>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                          <el-col :span="7">
                            <el-form-item label="推荐类型">
                              <el-select v-model="staForm.recommendedTypeId" placeholder="请选择推荐类型"">
                                <el-option
                                    v-for="item in recommendedTypes"
                                    :key="item.recommendedTypeId"
                                    :label="item.recommendedType"
                                    :value="item.recommendedTypeId"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="推荐明细" v-if="!staForm.whetherTheCaregiverRecommends">
                              <el-input v-model="staForm.recommendedDetails" placeholder="请输入推荐明细"
                                        class="line" style="width: 350px;"></el-input>
                            </el-form-item>
                            <el-form-item label="推荐护理员" v-if="staForm.whetherTheCaregiverRecommends" style="width: 300px;">
                              <el-input v-model="staForm.recommendedDetails" placeholder="请输入推荐护理员"
                                        @focus="careInput(6)" style="width: 200px;"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="4">
                            <el-form-item label="服务用户人数" v-if="!isadd">
                              <el-input v-model="staForm.size" :disabled="true"
                                        class="line2"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="0">
                            <el-col :span="6">
                                <el-form-item label="街道">
                                    <el-input v-model="staForm.sreet" class="line" style="width: 150px"
                                              @focus="toAddress()"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="居委会">
                                    <el-input v-model="staForm.committees" class="line" style="width: 180px"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="9">
                                <el-form-item label="详细地址">
                                    <el-input v-model="staForm.addressDetall" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="0">
                            <el-col :span="9">
                                <el-form-item label="状态备注" prop="statusRemarks">
                                    <el-input v-model="staForm.statusRemarks"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row :gutter="0"></el-row>
                    <el-row :gutter="0">
                        <el-col :span="7">
                            <el-form-item label="已签约:" >
                                <el-select v-model="contractInformationId" @change="jumpToCaregiver()" v-show="!isAdd"
                                           style="width: 150px">
                                    <el-option
                                            v-for="item in caregiverContractInformationLists"
                                            :key="item.index"
                                            :label="item.caregiverContractType"
                                            :value="item.contractInformationId">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                        </el-col>

                        <el-col :span="8">
                            <div class="client-list-two" v-show="!isAdd">
                                <div v-if="staForm.status === '待审核'" class="stateYellow">
                                    <span>该合约状态：{{staForm.status}}</span>
                                </div>
                                <div v-else-if="staForm.status === '中止中'" class="stateRed">
                                    <span>该合约状态：{{staForm.status}}</span>
                                </div>
                                <div v-else-if="staForm.status === '合约中'" class="stateGreen">
                                    <span>该合约状态：{{staForm.status}}</span>
                                </div>
                                <div v-else-if="staForm.status === '已终止'" class="stateRed">
                                    <span>该合约状态：{{staForm.status}}</span>
                                </div>
                                <div v-else-if="staForm.status === '已删除'" class="stateDarkgray">
                                    <span>该合约状态：{{staForm.status}}</span>
                                </div>
                            </div>
                        </el-col>

                    </el-row>
                    <div class="des assess">
                        <el-row :gutter="0">
                            <el-col :span="6">
                                <el-form-item label="是否全职">
                                    <el-switch v-model="staForm.fullTime" active-value="1"
                                               inactive-value="0"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="是否逾期">
                                    <el-switch v-model="staForm.overdue" active-value="1"
                                               inactive-value="0"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="编号" prop="numbering">
                                    <el-input v-model="staForm.numbering" placeholder="请输入护理员编号"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="0">
                            <el-col :span="24">
                                <el-form-item label="合同时间">
                                    <el-date-picker
                                            v-model="staForm.contractStartDate"
                                            type="date"
                                            placeholder="合同开始时间"
                                            style="width: 150px"
                                    >
                                    </el-date-picker>&nbsp;&nbsp;&nbsp;
                                    <el-date-picker
                                            v-model="staForm.contractEndDate"
                                            type="date"
                                            placeholder="合同结束时间"
                                            style="width: 150px"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="0">
                            <el-col :span="10">
                                <el-form-item label="银行卡所属行" prop="bankCardName">
                                    <el-input v-model="staForm.bankCardName" placeholder="请输入银行卡所属行名"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="银行卡号" prop="bankCardNumber">
                                    <el-input v-model="staForm.bankCardNumber" placeholder="请输入银行卡号"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="0">
                            <el-col :span="10">
                                <el-form-item label="保险公司名" prop="nameOfInsuranceCompany">
                                    <el-input v-model="staForm.nameOfInsuranceCompany"
                                              placeholder="请输入保险公司名"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="保单号" prop="policyNumber">
                                    <el-input v-model="staForm.policyNumber" placeholder="请输入保单号"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="0">
                            <el-col :span="10">
                                <el-form-item label="保单地址链接" prop="policyAddressLink">
                                    <el-input v-model="staForm.policyAddressLink" placeholder="请输入保单地址链接"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="合约链接" prop="policyNumber">
                                    <el-input v-model="staForm.contractLink" placeholder="请输入合约链接"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="des card">
                        <el-tabs v-model="activeName">
                            <el-tab-pane label="身份证" name="first">
                                <div class="flex img-box">
                                    <div>
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="befUpload"
                                                :on-change="befChange"
                                        >
                                            <img v-if="befUrl" :src="befUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（身份证正面）</p>
                                    </div>
                                    <div style="margin-left: 30px">
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="bacUpload"
                                                :on-change="bacChange"
                                        >
                                            <img v-if="bacUrl" :src="bacUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（身份证反面）</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="健康证" name="second">
                                <div class="flex img-box">
                                    <div>
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="healthUpload"
                                                :on-change="healthChange"
                                        >
                                            <img v-if="healthUrl" :src="healthUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（健康证）</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="医疗证" name="third">
                                <div class="flex img-box">
                                    <div>
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="medUpload"
                                                :on-change="medChange"
                                        >
                                            <img v-if="medicalUrl" :src="medicalUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（医疗证）</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="批量删除工单" :visible.sync="openDeleteOrder" width="400px">
            <el-form :model="subForm" label-width="100px">
                <el-form-item label="删除备注">
                    <el-input v-model="subForm.deleteOrderRemarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="ConfirmDeleteOrder">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看家属信息" :visible.sync="familyInformationDialog" width="1200px">
            <el-table :data="familyInformation"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center','man-width':'100px'}"
                      max-height="700"
            >
                <el-table-column prop="familyName" label="家属姓名"></el-table-column>
                <el-table-column prop="familyMoblle" label="紧急联络电话"></el-table-column>
                <el-table-column prop="familyNotes" label="家属备注"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import {
  getDailyWork,
  updateDailyWork,
  insertOrder,
  updateOrderList,
  careList,
  cusList,
  workOrderListExport,
  workOrderLogList,
  accountListExport,
  getCus,
  getCare,
  worksheetPythonExport,
  worksheetLogExport,
  deskCalendarPythonExport,
  viewOtherFamilyMembers,
  recommendedType,
  siteChange
} from '@/common/js/index.js'
    import {setDate, setHours, setTime, isEmpty} from '@/common/js/util.js'
    import {setTimeout} from 'timers';
    import BMap from 'BMap';

    export default {
        name: "worksheet",
        data() {
            return {
                recommendedTypes: [],
                whetherTheCaregiverRecommends:false,// 是否护理员推荐
                timePeriodList: [], //时间周期
                appointmentTimePeriodList: [], //预约时间周期
                medicareStatus: [{
                  medicareStatusValue: '0',
                  label: '正常'
                }, {
                  medicareStatusValue: '2',
                  label: '非医保服务'
                }],
                medicareStatusValue: '0',
                serveWeek: [
                  {name: "周一", week: '1'},
                  {name: "周二", week: '2'},
                  {name: "周三", week: '3'},
                  {name: "周四", week: '4'},
                  {name: "周五", week: '5'},
                  {name: "周六", week: '6'},
                  {name: "周日", week: '7'},
                ],
                caregiverContractInformationLists : [],//护理员已签约合约list
                contractInformationId: null,// 护理员合约信息id
                clientId: null,//客户Id
                contractTypeId:'',
                familyInformationDialog: false,//查看家属信息
                familyInformation: [],
                selectEvaluationNursingId: null,// 合约信息id
                customeContracttypesList: [],// 老人 已签约 合约类型
                worksheetSelectContracttypes: '',// 工单搜索工单类型
                contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
                contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
                isAdd: false,
                editFormRules: null,
                openDeleteOrder: false,
                /*护理员begin*/
                adressForm: {
                    area: "",
                    sreet: "",
                    committees: ""
                },
                addressPage: 1,
                addressTotal: 0,
                addressPSize: 0,
                addressData: [],
                addressDialog: false,
                clientRight: {
                    width: ""
                },
                isAssign: false,
                operaDialog: false,//操作弹窗
                operaForm: {
                    targetStatus: "",
                    statusRemarks: ""
                },
                formInline: {
                    logSTime: ""//日志时间筛选
                },
                allLoad: true,
                nurStatus: "",
                nurText: "",
                pages: 0,
                nurseArr: [],
                currPage: 1,
                isadd: false,
                staffOpera: [],
                contractTime: "",
                staForm: {
                    name: "",//姓名
                    id: "",
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    emergencyContact: "",//紧急联系人
                    contactMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    recommend: "",//推荐渠道
                    recommendedType: "",//推荐类型
                    recommendedDetails: "",//推荐明细
                    recommendedCaregiverId: "",//推荐护理员ID
                    whetherTheCaregiverRecommends:false,
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    // latltude:"",//纬度
                    // longltude:"",//经度
                    idCardFront: null,//身份证正面
                    idCardNegative: null,//身份证反面
                    healthCertificateFront: null,//健康证
                    sicknessCertificate: null,//医疗证

                    fullTime: "0",//是否兼职
                    overdue: "0",//是否逾期
                    /*暂时没加*/
                    intentServiceAddress: "",//意向服务地址
                    intentionLongltude: "",//意向地址经度
                    intentionLatltude: "",//意向地址纬度
                    size: "",//护理员总数
                    contractStartDate: "",//合同开始时间
                    contractEndDate: "",//合同结束时间
                    deleteOrderRemarks: "",//删除工单备注
                    evaluationNursingId:'',//合约信息id
                    contractInformationId:''// 护理员合约信息id
                },
                subForm: {
                    'id': null,
                    'workOrderNumber': '',
                    'serviceOrderNumber': '',
                    'caregiverMoblle': '', //员工手机号
                    'customerMoblle': '',  //客户手机号
                    'caregiverName': '',  //员工名字
                    'customerName': '',   //客户名字
                    'caregiverId': '',//用于新增时添加客户id
                    'clientId': '',//用于新增时添加护理员id
                    'remarks': '',//备注
                    'workOrderCompleted': null,
                    'workOrderConsummation': null,
                    'customerSettlement': null,
                    'medicalInsuranceSettlement': null,
                    'caregiverSettlement': null,
                    'deleteStatus': null,
                    'beginTime': new Date(2016, 9, 10, 18, 40),
                    'endTime': new Date(2016, 9, 10, 18, 40),
                    'actualBeginTime': new Date(2016, 9, 10, 18, 40),
                    'actualEndtime': new Date(2016, 9, 10, 18, 40),
                    'date': null,
                    'nursingStartTime': new Date(2016, 9, 10, 18, 40),
                    'nursingEndTime': new Date(2016, 9, 10, 18, 40),
                    'medicalInsuranceSettlementDate': null, // 医保结算时间
                    'customerSettlementDate': null, // 用户结算时间
                    'caregiverSettlementDate': null, // 护理员结算时间
                    'contractTypeId': '',
                    'contractInformationId':'',
                    'ownExpense':null, //是否为自费工单，0、null否/1自费
                    'medicareStatusValue':'0',//工单类型
                    // 'status':'',
                },
                sRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                },
                staffId: null,
                imageUrl: '',
                activeName: 'first',
                befUrl: "",//身份证正面
                bacUrl: "",//身份证反面
                healthUrl: "",//健康证
                medicalUrl: "",//医疗证
                /*护理员end*/
                /*用户begin*/
                idBefUrl: "",//身份证正面
                idBacUrl: "",//身份证反面
                soBefUrl: "",//社保卡正面
                soBacUrl: "",//社保卡反面
                form: {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)1
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    statusRemarks: "", //状态备注
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                },
                medicareTypes: localStorage.getItem("medicareCardType").split("$"),
                checkList: [],
                reservation_nursingCycle: [],//意向服务时间（周）
                nursingCycle: [],//护理周期
                careWorker_one: "",//指定护理员1
                careWorker_two: "",//指定护理员2
                careWorker_three: "",//指定护理员3
                reservation_careWorker: "",//预约指定护理员
                nursingTime: "",//护理时间
                nursingAppoTime: "",//预约护理时间
                /*用户end*/
                cRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                },
                clientDetails: false,
                staffDetails: false,
                all: 0,
                all1: 0,
                allC: 0,
                allD: 0,
                dialogTableVisible: false,
                innerVisible1: false,     //客户表格
                innerVisible2: false,     //护理员表格
                clientData: [],
                caregiverData: [],
                logSearchTime: null,
                handlers: '', // 日志查询操作者
                beOperatedCustomerName: '', //日志查询被操作用户
                gridData: [],
                tableData: [],
                loading: false,
                clientList: [],
                caregiverList: [],
                customerSearch: '',
                careSearch: '',
                // cliStatus:"",  //护理站
                siteValue: sessionStorage.getItem("siteValue"),           //护理站
                workCompletedList: [{
                    label: '已认证',
                    value: 1
                }, {
                    label: '未认证',
                    value: 0
                }],
                workOrderConsummationList: [{
                    label: '已录入',
                    value: 1
                }, {
                    label: '未录入',
                    value: 0
                }],
                medicalInsuranceSettlementList: [{
                    label: '已结算',
                    value: 1
                }, {
                    label: '未结算',
                    value: 0
                }],
                caregiverSettlementList: [{
                    label: '已结算',
                    value: 1
                }, {
                    label: '未结算',
                    value: 0
                }],
                customerSettlementList: [{
                    label: '已结算',
                    value: 1
                }, {
                    label: '未结算',
                    value: 0
                }],
                deleteStatusList: [{
                    label: '删除',
                    value: 2
                }],
                searchForm: {
                    status: [],
                    keyWords: '',
                    date: null,
                },
                rules: {
                    caregiverId: [{required: true, message: '请选择员工', trigger: 'change'}],
                    clientId: [{required: true, message: '请选择用户', trigger: 'change'}],
                },
                formLabelWidth: '120px',
                dialogFormVisible: false,
                dialogFormVisible1: false,
                dialogFormVisible2: false,
                search: '',
                result: [],//保存条件查询时箭名用的
                subFlag: '',//用于区分提交操作
                exportFlag: true,//打印时去掉尾部编辑用的
                currentPage: 1,
                currentPage1: 1,
                currentPageC: 1,
                currentPageD: 1,
                boxFlag: true,
                pagesize: 20,
                pagesize1: 10,
                pagesizeC: 10,
                pagesizeD: 10,
                pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
                pagesizes1: [10],
                pagesizesC: [10, 20, 30, 50, 100, 200, 300, 400, 500],  //客户用的
                pagesizesD: [10, 20, 30, 50, 100, 200, 300, 400, 500],   //护理员用的
                multipleSelection: [],
                ids: '',   //批量修改的id
                caregiverSettlement: '',
                searchFlag: true,
                options: [{
                    label: '工单录入',
                    options: [{
                        value: '{"workOrderConsummation":1}',
                        label: '已录入'
                    }, {
                        value: '{"workOrderConsummation":0}',
                        label: '未录入'
                    }]
                }, {
                    label: '客户确认',
                    options: [{
                        value: '{"workOrderCompleted":1}',
                        label: '已认证'
                    }, {
                        value: '{"workOrderCompleted":0}',
                        label: '未认证'
                    }]
                }, {
                    label: '用户结算',
                    options: [{
                        value: '{"customerSettlement":1}',
                        label: '已结算'
                    }, {
                        value: '{"customerSettlement":0}',
                        label: '未结算'
                    }]
                }, {
                    label: '医保结算',
                    options: [{
                        value: '{"medicalInsuranceSettlement":1}',
                        label: '已结算'
                    }, {
                        value: '{"medicalInsuranceSettlement":0}',
                        label: '未结算'
                    }]
                }, {
                    label: '护理员结算',
                    options: [{
                        value: '{"caregiverSettlement":1}',
                        label: '已结算'
                    }, {
                        value: '{"caregiverSettlement":0}',
                        label: '未结算'
                    }]
                }, {
                  label: '是否自费',
                  options: [{
                    value: '{"ownExpense":1}',
                    label: '是'
                  }, {
                    value: '{"ownExpense":0}',
                    label: '否'
                  }]
                }, {
                  label: '工单类型',
                  options: [{
                    value: '{"medicareStatusValue":0}',
                    label: '正常'
                  }, {
                    value: '{"medicareStatusValue":2}',
                    label: '非结算'
                  }]
                }],
                dailyWorkOrderMoblle:{
                  workOrderList:false,
                  insertOrder:false,
                  updateOrder:false,
                  updateOrderList:false,
                  workOrderListExport:false,
                  workOrderLogList:false,
                  generateReports:false,
                  accountExport:false,
                  worksheetLogExport:false,
                  worksheetPythonExport:false,
                  updateOrderWorkOrderCompletedList:false,
                  workOrderLogListExport:false,
                  deskCalendarPythonExport:false,
                }
            }
        },
        methods: {
            siteChangeOperating(){
              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.dailyWorkOrderMoblle.workOrderList = this.isExist(permissionList,"dailyWorkOrder:workOrderList");
                  this.dailyWorkOrderMoblle.insertOrder = this.isExist(permissionList,"dailyWorkOrder:insertOrder");
                  this.dailyWorkOrderMoblle.updateOrder = this.isExist(permissionList,"dailyWorkOrder:updateOrder");
                  this.dailyWorkOrderMoblle.updateOrderList = this.isExist(permissionList,"dailyWorkOrder:updateOrderList");
                  this.dailyWorkOrderMoblle.workOrderListExport = this.isExist(permissionList,"dailyWorkOrder:workOrderListExport");
                  this.dailyWorkOrderMoblle.workOrderLogList = this.isExist(permissionList,"dailyWorkOrder:workOrderLogList");
                  this.dailyWorkOrderMoblle.generateReports = this.isExist(permissionList,"dailyWorkOrder:generateReports");
                  this.dailyWorkOrderMoblle.accountExport = this.isExist(permissionList,"dailyWorkOrder:accountExport");
                  this.dailyWorkOrderMoblle.worksheetLogExport = this.isExist(permissionList,"dailyWorkOrder:worksheetLogExport");
                  this.dailyWorkOrderMoblle.worksheetPythonExport = this.isExist(permissionList,"dailyWorkOrder:worksheetPythonExport");
                  this.dailyWorkOrderMoblle.updateOrderWorkOrderCompletedList = this.isExist(permissionList,"dailyWorkOrder:updateOrderWorkOrderCompletedList");
                  this.dailyWorkOrderMoblle.workOrderLogListExport = this.isExist(permissionList,"dailyWorkOrder:workOrderLogListExport");
                  this.dailyWorkOrderMoblle.deskCalendarPythonExport = this.isExist(permissionList,"dailyWorkOrder:deskCalendarPythonExport");

                  this.searchOrder()
                }
              })
            },
            isExist(permissionList,str) {
              if (permissionList.indexOf(str) !== -1) {
                return true;
              } else {
                return false;
              }
            },
            // 新增工单选择老人及护理员
            addWorkOrderSelectionCustomerAndCaregiver(){
                this.getClients()
                this.getnurses()
            },
            viewOtherFamilyMembers() {
                let subData = 'clientId=' + this.clientId
                viewOtherFamilyMembers(subData).then(res => {
                    if (res) {
                        this.familyInformation = res.data
                    }
                })
                this.familyInformationDialog = true;
            },
            // 文件上传
            uploadFile() {
            },
            deleteOrder() {
                this.openDeleteOrder = true;
            },
            ConfirmDeleteOrder() {
                this.$confirm('', '确定删除日工单', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',

                    type: 'warning'
                }).then(() => {
                    this.ids = '';
                    this.caregiverSettlement = '';
                    this.clearSubForm()
                    this.subFlag = 3
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        this.ids += this.multipleSelection[i].id + ','
                        this.caregiverSettlement += this.multipleSelection[i].caregiverSettlement + ','
                    }

                    // alert(this.caregiverSettlement.hasOwnProperty("已结算"))
                    // alert("已结算".hasOwnProperty(this.caregiverSettlement))
                    if (this.ids === '') {
                        this.$message({
                            message: "请选择需要删除的选项",
                            type: 'warning'
                        })


                    } else {

                        if (this.caregiverSettlement !== '' && this.caregiverSettlement.indexOf("已结算") !== -1) {
                            this.$message({
                                message: "已选择工单中包含 已结算护理员工单（该工单不可删除），请取消后重试",
                                type: 'warning'
                            })
                            this.openDeleteOrder = false;
                        } else {
                            this.dialogFormVisible2 = true
                            this.subForm.deleteStatus = 2;
                            this.subFormFun();
                            this.openDeleteOrder = false;
                        }
                    }

                }).catch(() => {
                });
            },
            /*日工单日志导出*/
            worksheetLogExport() {
                this.$confirm('生成日工单日志中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let subData = 'currPage=' + this.currentPage1
                        + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                        + "&handlers=" + this.handlers + "&beOperatedCustomerName=" + this.beOperatedCustomerName
                        + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2)
                    if (this.logSearchTime !== '' && this.logSearchTime != null) {
                        this.logSearchTime[0] = setDate(this.logSearchTime[0])
                        this.logSearchTime[1] = setDate(this.logSearchTime[1])
                        subData += '&beginCreateTime=' + this.logSearchTime[0] +
                            '&endCreateTime=' + this.logSearchTime[1]
                        console.log(this.logSearchTime[0])
                    }

                    worksheetLogExport(subData).then(res => {
                        if (res) {
                            this.tableData = res.data
                            for (let i = 0; i < this.tableData.length; i++) {
                                if (this.tableData[i].date != null && this.tableData[i].date !== '') {
                                    this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                }
                            }
                            this.change()//改变0/1为已录入/未录入模式
                            this.$message.success('日工单日志导出成功,请到报表中进行查看下载');
                            // console.log('export',this.tableData)
                        }
                    })

                }).catch(() => {
                });
            },
            /*日工单导出*/
            worksheetPythonExport() {
                this.$confirm('生成日工单中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let subData = "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2)
                    let str = /\"(.*)\"/
                    let num = /\d+/g
                    if (this.searchForm.status.length > 0) {
                        for (let i = 0; i < this.result.length; i++) {
                            subData += '&' + this.searchForm.status[i].match(str)[1] + '=' + this.searchForm.status[i].match(num)
                        }
                        // this.searchForm.status=[]
                    } else {
                        this.result = []
                    }
                    if (this.searchForm.keyWords !=='') {
                        subData += '&searchCondition=' + this.searchForm.keyWords
                    }
                    if (this.searchForm.date != null) {
                        let searchStartTime = setDate(this.searchForm.date[0])
                        let searchEndTime = setDate(this.searchForm.date[1])
                        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                    }
                    worksheetPythonExport(subData).then(res => {
                        if (res) {
                            this.change()//改变0/1为已录入/未录入模式
                            this.$message.success('日工单导出成功,请到报表中进行查看下载');
                            // console.log('export',this.tableData)
                        }
                    })

                }).catch(() => {
                });
            },
            /*台历导出*/
            deskCalendar() {
                this.$confirm('生成台历中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let subData = "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2)

                    if (this.searchForm.date != null) {
                        let searchStartTime = setDate(this.searchForm.date[0])
                        let searchEndTime = setDate(this.searchForm.date[1])
                        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                    }
                    deskCalendarPythonExport(subData).then(res => {
                        if (res) {
                            this.change()//改变0/1为已录入/未录入模式
                            this.$message.success('台历生成成功,请到报表中进行查看下载');
                        }
                    })

                }).catch(() => {
                });
            },

            jumpToCaregiver(caregiverId,contractInformationId) {
                this.loading = true;
                this.fatStaForm();

                this.isadd = false;
                if (contractInformationId!=null){
                    this.contractInformationId = contractInformationId
                    this.staffId = caregiverId;
                }
                let subData = "id=" +this.staffId+ '&contractInformationId=' + this.contractInformationId;
                getCare(subData).then(res => {
                    this.allLoad = false;
                    this.loading = false;
                    if (res) {
                        this.caregiverContractInformationLists = res.data['caregiverContractInformationLists']
                        this.staForm = res.data['basicCaregiver'][0];
                        if (this.staForm.recommendedTypeId === 1){
                          this.staForm.whetherTheCaregiverRecommends = true;
                        }else {
                          this.staForm.whetherTheCaregiverRecommends = false;
                        }
                        this.staForm.overdue = this.staForm.overdue.toString();
                        this.befUrl = res.data['basicCaregiver'][0].idCardFront;//身份证正面
                        this.bacUrl = res.data['basicCaregiver'][0].idCardNegative;//身份证反面
                        this.healthUrl = res.data['basicCaregiver'][0].healthCertificateFront;//健康证
                        this.medicalUrl = res.data['basicCaregiver'][0].sicknessCertificate;//医疗证
                        this.staffOpera = res.data['list'];
                        this.dynamicTags = res.data['label'];
                        this.clientData = res.data['associationCustomer'];
                    }
                })
                this.staffDetails = true
            },
            fatStaForm() {
                this.contractTime = "";
                this.staForm = {
                    name: "",//姓名
                    id: "",
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    emergencyContact: "",//紧急联系人
                    contactMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    recommend: "",//推荐渠道
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    // latltude:"",//纬度
                    // longltude:"",//经度
                    idCardFront: null,//身份证正面
                    idCardNegative: null,//身份证反面
                    healthCertificateFront: null,//健康证
                    sicknessCertificate: null,//医疗证

                    fullTime: "0",//是否兼职
                    overdue: "0",//是否逾期
                    /*暂时没加*/
                    intentServiceAddress: "",//意向服务地址
                    intentionLongltude: "",//意向地址经度
                    intentionLatltude: "",//意向地址纬度
                    contractStartDate: "",//合同开始日期
                    contractEndDate: "",//合同结束日期
                }
            },
            /*         jumpToCustomer(index, row) {
                         this.$router.push({path: '/assignClient', query: {id: row.clientId}})
                     },*/
            /*点击begin*/
            jumpToCustomer(row) {
                this.customeContracttypesList = null
                this.load = true;
                this.fatForm();
                if (row !== undefined) {
                    this.clientId = row.clientId;
                }
                if (row!=null){
                    this.selectEvaluationNursingId = row.evaluationNursingId
                }

                let subData = "id=" + this.clientId + '&evaluationNursingId=' + this.selectEvaluationNursingId;

                this.isAdd = false;
                var s = this
                getCus(subData).then(res => {
                    this.allLoading = false;
                    this.load = false;

                    if (res) {
                        this.customeContracttypesList = res.data['customeContracttypesList']
                        for (let i = 0; i < this.customeContracttypesList.length; i++) {
                            if (this.customeContracttypesList[i].evaluationNursingId === this.selectEvaluationNursingId){
                                this.contractTypeId = this.customeContracttypesList[i].contractTypeId
                            }
                        }
                        let timePeriod = res.data['basicInformation'][0].timePeriod;
                        if (timePeriod != null) {
                          this.timePeriodList = JSON.parse(timePeriod);
                          this.timePeriodList.sort(function (a, b) {
                            return b.week > a.week ? -1 : 1;
                          });

                        } else {
                          this.timePeriodList = []
                        }
                        let appointmentTimePeriod = res.data['basicInformation'][0].appointmentTimePeriod;
                        if (appointmentTimePeriod != null) {
                          this.appointmentTimePeriodList = JSON.parse(appointmentTimePeriod);
                          this.appointmentTimePeriodList.sort(function (a, b) {
                            return b.week > a.week ? -1 : 1;
                          });

                        } else {
                          this.appointmentTimePeriodList = []
                        }
                        var map = new BMap.Map("map");
                        var point = new BMap.Point(res.data['basicInformation'][0].longltude, res.data['basicInformation'][0].latltude);
                        map.centerAndZoom(point, 15);
                        var marker = new BMap.Marker(point); // 创建点
                        map.addOverlay(marker);

                        function showInfo(e) {
                            map.clearOverlays();
                            //改经纬度
                            var marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)); // 创建点
                            map.addOverlay(marker);//增加点
                            s.form.longltude = e.point.lng;
                            s.form.latltude = e.point.lat;
                        }

                        map.addEventListener("click", showInfo);

                        this.form = res.data['basicInformation'][0];
                        if (this.form.recommendedTypeId === 1){
                          this.whetherTheCaregiverRecommends = true;
                        }else {
                          this.whetherTheCaregiverRecommends = false;
                        }
                        this.idBefUrl = res.data['basicInformation'][0].ldentlyCarPlc;
                        this.idBacUrl = res.data['basicInformation'][0].ldentlyCardBackPlc;
                        this.soBefUrl = res.data['basicInformation'][0].medlcareCardPlc;
                        this.soBacUrl = res.data['basicInformation'][0].medlcareCardPlcBack;
                        this.operaList = [];
                        for (let i = 0; i < res.data['list'].length; i++) {
                            this.operaList.push({
                                value: res.data['list'][i].split(',')[0],
                                state: res.data['list'][i].split(',')[1],
                            })
                        }
                        this.dynamicTags = res.data['label'];
                        if (res.data['basicInformation'][0].nursingCycle != null) {
                            this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
                        } else {
                            this.nursingCycle = [];
                        }
                        if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
                            this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
                        } else {
                            this.reservation_nursingCycle = [];
                        }
                        if (isEmpty(this.form.careWorker_id_one)) {
                            this.nurseDes(this.form.careWorker_id_one,this.form.contractInformationId, 1)
                        }
                        if (isEmpty(this.form.careWorker_id_two)) {
                            this.nurseDes(this.form.careWorker_id_two,this.form.contractInformationId,2)
                        }
                        if (isEmpty(this.form.careWorker_id_three)) {
                            this.nurseDes(this.form.careWorker_id_three,this.form.contractInformationId, 3)
                        }
                        if (isEmpty(this.form.reservation_careWorker_id)) {
                            this.nurseDes(this.form.reservation_careWorker_id,this.form.reservationContractInformationId, 4)
                        }
                        if (isEmpty(this.form.nursingStartTime) && isEmpty(this.form.nursingEndTime)) {
                            this.nursingTime = [];
                            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingStartTime.split(':')[0], this.form.nursingStartTime.split(':')[1], this.form.nursingStartTime.split(':')[2]));
                            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingEndTime.split(':')[0], this.form.nursingEndTime.split(':')[1], this.form.nursingEndTime.split(':')[2]));
                        }
                        if (isEmpty(this.form.reservation_nursingStartTime) && isEmpty(this.form.reservation_nursingEndtime)) {
                            this.nursingAppoTime = [];
                            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingStartTime.split(':')[0], this.form.reservation_nursingStartTime.split(':')[1], this.form.reservation_nursingStartTime.split(':')[2]));
                            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingEndtime.split(':')[0], this.form.reservation_nursingEndtime.split(':')[1], this.form.reservation_nursingEndtime.split(':')[2]));
                        }
                    }
                })
                this.clientDetails = true
            },
            idBefChange(file) {
                this.idBefUrl = URL.createObjectURL(file.raw);
            },
            idBacChange(file) {
                this.idBacUrl = URL.createObjectURL(file.raw);
            },
            soBefChange(file) {
                this.soBefUrl = URL.createObjectURL(file.raw);
            },
            soBacChange(file) {
                this.soBacUrl = URL.createObjectURL(file.raw);
            },
            befChange(file) {
                this.befUrl = URL.createObjectURL(file.raw);
            },
            bacChange(file) {
                this.bacUrl = URL.createObjectURL(file.raw);
            },
            healthChange(file) {
                this.healthUrl = URL.createObjectURL(file.raw);
            },
            medChange(file) {
                this.medicalUrl = URL.createObjectURL(file.raw);
            },
            //获取护理员名称
            nurseDes(id,contractInformationId, num) {
                let subData = "id=" + id + '&contractInformationId=' + contractInformationId;
                getCare(subData).then(res => {
                    if (res) {
                        let name = res.data['basicCaregiver'][0].name;
                        switch (num) {
                            case 1:
                                this.careWorker_one = name;
                                break;
                            case 2:
                                this.careWorker_two = name;
                                break;
                            case 3:
                                this.careWorker_three = name;
                                break;
                            case 4:
                                this.reservation_careWorker = name;
                                break;
                            default:
                                break;
                        }
                    }
                })
            },
            /**
             *选择护理员
             * */
            careInput(num) {
                this.careFlag = num;
                this.careDialog = true;
                this.carePage = 1;
                this.careForm.status = "";
                this.careForm.searchCondition = "";
                this.getCares();
            },
            fatForm() {
                this.nursingTime = "";
                this.nursingAppoTime = '';
                this.reservation_nursingCycle = [];//预约服务时间（周）
                this.nursingCycle = [];//护理周期
                this.careWorker_one = "";//指定护理员1
                this.careWorker_two = "";//指定护理员2
                this.careWorker_three = "";//指定护理员3
                this.reservation_careWorker = "";//预约指定护理员
                this.form = {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                }
            },
            /*点击护理员end*/
            getLog() {   //日志
                let subData = 'currPage=' + this.currentPage1
                    + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                    + "&handlers=" + this.handlers + "&beOperatedCustomerName=" + this.beOperatedCustomerName
                    + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2)
                if (this.logSearchTime !== '' && this.logSearchTime != null) {
                    this.logSearchTime[0] = setDate(this.logSearchTime[0])
                    this.logSearchTime[1] = setDate(this.logSearchTime[1])
                    subData += '&beginCreateTime=' + this.logSearchTime[0] +
                        '&endCreateTime=' + this.logSearchTime[1]
                    console.log(this.logSearchTime[0])
                }

                workOrderLogList(subData).then(res => {
                    this.gridData = res.data
                    if (res.data.length !== 0) {
                        this.all1 = this.gridData[0].totalCount
                        this.pagesizes1 = []
                        this.pagesizes1.push(this.gridData[0].pageSize)
                    } else {
                        this.all1 = 0
                        this.pagesizes1 = [10]
                    }

                    // this.currentPage1 = this.gridData[0].currentPage
                })
                this.dialogTableVisible = true
            },
            reloadTable() {   //重新渲染表格，用于去掉或显示方框，真麻烦，有好办法就把这个换掉吧，去方框真的烦
                let _this = this
                this.boxFlag = false
                setTimeout(function () {
                    _this.boxFlag = true
                }, 200)
            },
            handleSizeChange(val) {
                this.pagesize = val
            },
            handleSizeChange1(val) {  //日志表格的
                this.pagesize1 = val
            },
            handleSizeChangeC(val) {     //选择客户用的
                this.pagesizeC = val
            },
            handleSizeChangeD(val) {     //选择护理员用的
                this.pagesizeD = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            handleCurrentChange1(val) {  //日志表格的
                this.currentPage1 = val
            },
            handleCurrentChangeC(val) {  //选择客户用的
                this.currPage = val
                this.getClients()
            },
            handleCurrentChangeD(val) {  //选择护理员用的
                this.currentPageD = val
            },
            handleSelectionChange(val) {//改变选择时触发
                this.multipleSelection = val;
            },
            handleEdit(index, row) {  //编辑
                this.subForm.id = row.id
                this.subForm.workOrderCompleted = this.dataForCheckBox(row.workOrderCompleted)
                this.subForm.workOrderConsummation = this.dataForCheckBox(row.workOrderConsummation)
                this.subForm.medicalInsuranceSettlement = this.dataForCheckBox(row.medicalInsuranceSettlement)
                this.subForm.customerSettlement = this.dataForCheckBox(row.customerSettlement)
                this.subForm.caregiverSettlement = this.dataForCheckBox(row.caregiverSettlement)
                this.subForm.ownExpense = this.dataForCheckBox(row.ownExpense)
                this.subForm.deleteStatus = this.dataForCheckBox(row.deleteStatus)
                this.subForm.customerMoblle = row.customerMoblle
                this.subForm.customerName = row.customerName
                this.subForm.caregiverName = row.caregiverName
                this.subForm.serviceOrderNumber = row.serviceOrderNumber
                this.subForm.caregiverMoblle = row.caregiverMoblle
                this.subForm.workOrderNumber = row.workOrderNumber
                this.subForm.remarks = row.remarks
                this.subForm.caregiverSettlementDate = row.caregiverSettlementDate
                this.subForm.customerSettlementDate = row.customerSettlementDate
                this.subForm.medicalInsuranceSettlementDate = row.medicalInsuranceSettlementDate
                if (row.beginTime != null && row.beginTime !== '') {
                    this.subForm.beginTime = new Date(2016, 9, 1, this.changeTimeForPickTime(row.beginTime)[0], this.changeTimeForPickTime(row.beginTime)[1], this.changeTimeForPickTime(row.beginTime)[2])
                } else {
                    this.subForm.beginTime = null
                }

                if (row.endTime != null && row.endTime !== '') {
                    this.subForm.endTime = new Date(2016, 9, 1, this.changeTimeForPickTime(row.endTime)[0], this.changeTimeForPickTime(row.endTime)[1], this.changeTimeForPickTime(row.endTime)[2])
                } else {
                    this.subForm.endTime = null
                }

                if (row.actualBeginTime != null && row.actualBeginTime !== '') {
                    this.subForm.actualBeginTime = new Date(2016, 9, 1, this.changeTimeForPickTime(row.actualBeginTime)[0], this.changeTimeForPickTime(row.actualBeginTime)[1], this.changeTimeForPickTime(row.actualBeginTime)[2])
                } else {
                    this.subForm.actualBeginTime = null
                }

                this.subForm.status = row.status

                if (row.actualEndtime != null && row.actualEndtime !== '') {
                    this.subForm.actualEndtime = new Date(2016, 9, 1, this.changeTimeForPickTime(row.actualEndtime)[0], this.changeTimeForPickTime(row.actualEndtime)[1], this.changeTimeForPickTime(row.actualEndtime)[2])
                } else {
                    this.subForm.actualEndtime = null
                }
                this.dialogFormVisible1 = true
                this.subFlag = 1
            },
            handleEditC(index, row) {   //选择客户
                this.subForm.beginTime = null
                this.subForm.endTime = null
                this.subForm.evaluationNursingId = row.evaluationNursingId
                this.subForm.customerName = row.name
                this.subForm.customerMoblle = row.moblle
                this.subForm.clientId = row.id
                if (row.nursingStartTime!=null && row.nursingEndTime!=null){
                  this.subForm.beginTime = (new Date(2016, 9, 10, row.nursingStartTime.split(':')[0], row.nursingStartTime.split(':')[1], row.nursingStartTime.split(':')[2]));
                  this.subForm.endTime = (new Date(2016, 9, 10, row.nursingEndTime.split(':')[0], row.nursingEndTime.split(':')[1], row.nursingEndTime.split(':')[2]));
                }
                this.innerVisible1 = false
                this.currentPageC = 1
                this.pagesizeC = 10
            },
            handleEditD(index, row) {    //选择护理员
                this.subForm.caregiverName = row.name
                this.subForm.caregiverMoblle = row.moblle
                this.subForm.caregiverId = row.id
                this.subForm.contractInformationId = row.contractInformationId
                this.innerVisible2 = false
                this.currentPageD = 1
                this.pagesizeD = 10
            },
            dataForCheckBox(data) {
                if (data === '已结算' || data === '已认证' || data === '已录入'|| data === '是') {
                    return true
                } else if (data == null) {
                    return null
                } else {
                    return false
                }
            },
            checkBoxDataForSubData(data) {
                if (data === true) {
                    return 1
                } else if (data === false) {
                    return 0
                } else {
                    return null
                }
            },
            addOrder() {  //新增
                this.dialogFormVisible = true
                this.clearSubForm()
                this.subFlag = 2
                this.getClients()
                this.getnurses()
            },
            updateList() {    //批量修改
                this.ids = ''
                this.clearSubForm()
                this.subFlag = 3
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    this.ids += this.multipleSelection[i].id + ','
                }
                if (this.ids === '') {
                    this.$message({
                        message: "请选择需要修改的选项",
                        type: 'warning'
                    })

                } else {
                    this.subForm.deleteStatus = 1
                    this.dialogFormVisible2 = true
                }
            },
            searchOrder() {    //查询按钮对应的方法
                var strs = this.contractTypeAndSiteInquireValue.toString();
                if (this.searchFlag === true) {

                    this.loading = true  //开启加载动画
                    let subData = 'currPage=' + this.currentPage
                        + '&pageSizePara=' + this.pagesize +
                        '&siteList=' + strs.charAt(0) +
                        '&contractTypeId=' + strs.charAt(2);
                    let str = /\"(.*)\"/
                    let num = /\d+/g
                    if (this.searchForm.status.length > 0) {
                        for (let i = 0; i < this.result.length; i++) {
                            subData += '&' + this.searchForm.status[i].match(str)[1] + '=' + this.searchForm.status[i].match(num)
                        }
                        // this.searchForm.status=[]
                    } else {
                        this.result = []
                    }
                    if (this.searchForm.keyWords !== '') {
                        subData += '&searchCondition=' + this.searchForm.keyWords
                    }
                    if (this.searchForm.date != null) {
                        let searchStartTime = setDate(this.searchForm.date[0])
                        let searchEndTime = setDate(this.searchForm.date[1])
                        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                    }
                    getDailyWork(subData).then(res => {
                        if (res) {
                            this.tableData = res.data
                            this.change()
                            if (res.data.length !== 0) {
                                this.all = res.data[0].totalCount
                            } else {
                                this.all = 0
                            }
                        }
                        this.loading = false
                    })
                }

            },
            print() {      //打印
                this.exportFlag = false
                this.reloadTable()
                // let tab = document.getElementById('select')
                // tab.setAttribute('type','')
                this.dataForExport()
                this.$message.warning('提示：字段过多，请选择打印选项缩放至50%')
                let _this = this
                setTimeout(function () {
                    var tableToPrint = document.getElementById("out-table")
                    // var td = tableToPrint.getElementsByClassName('aa')
                    // td.style='border:1px solid #eee'
                    var newWin = window.open("")
                    newWin.document.write(tableToPrint.outerHTML)
                    newWin.document.close()
                    newWin.focus();
                    newWin.print(); //打印
                    newWin.close(); //关闭窗口
                    _this.searchOrder()
                    _this.exportFlag = true
                    _this.reloadTable()
                }, 2000)
            },
            exportExcel() {     //导出
                this.exportFlag = false
                this.dataForExport()
            },
            account() {     //导出
                this.exportFlag = false
                this.accountForExport()
            },

            subFormFun() {  //公共提交部分
                switch (this.subFlag) {  //选择提交方式  1为提交更新   2为新增   3为批量修改
                    case 1:
                        this.dialogFormVisible1 = false
                        this.rechange()    //改变提交状态码
                        this.changeTimeForSubmit()  //改变提交时间
                        // console.log(111,this.subForm)
                        this.subForm.workOrderCompleted = this.checkBoxDataForSubData(this.subForm.workOrderCompleted)
                        this.subForm.workOrderConsummation = this.checkBoxDataForSubData(this.subForm.workOrderConsummation)
                        this.subForm.medicalInsuranceSettlement = this.checkBoxDataForSubData(this.subForm.medicalInsuranceSettlement)
                        this.subForm.customerSettlement = this.checkBoxDataForSubData(this.subForm.customerSettlement)
                        this.subForm.caregiverSettlement = this.checkBoxDataForSubData(this.subForm.caregiverSettlement)
                        this.subForm.ownExpense = this.checkBoxDataForSubData(this.subForm.ownExpense)
                        this.subForm.deleteStatus = this.checkBoxDataForSubData(this.subForm.deleteStatus)
                        // console.log(222,this.subForm)
                        // eslint-disable-next-line no-case-declarations
                        let formData = this.subForm
                        // console.log(formData)
                        formData.siteList = this.contractTypeAndSiteInquireValue.toString().charAt(0)
                        // console.log(this.subForm)
                        updateDailyWork(formData).then(res => {
                          if (res.retCode === 0) {
                            this.$message({
                              message: '工单修改成功',
                              type: 'success'
                            });
                            this.searchOrder()
                          } else {
                            this.$message({
                              message: '工单修改成功',
                              type: 'error'
                            });
                          }
                        });
                        break;
                    case 2:
                        if (this.subForm.clientId === '' || this.subForm.clientId === undefined || this.subForm.clientId === null ||
                            this.subForm.caregiverId === '' || this.subForm.caregiverId === undefined || this.subForm.caregiverId == null) {
                            this.$message.warning('请选择客户和护理员')
                        } else if (this.contractTypeAndSiteInquireValue === '' || this.contractTypeAndSiteInquireValue === undefined || this.subForm.date === undefined || this.subForm.date === null) {
                            this.$message.warning('请选择合约类型和日期')
                        } else if (this.subForm.beginTime === undefined || this.subForm.beginTime === null || this.subForm.endTime === undefined || this.subForm.endTime === null) {
                          this.$message.warning('请选择开始结束时间')
                        } else {
                            this.dialogFormVisible = false
                            this.changeTimeForSubmit()  //改变提交时间
                            this.subForm.workOrderCompleted = this.checkBoxDataForSubData(this.subForm.workOrderCompleted)
                            this.subForm.workOrderConsummation = this.checkBoxDataForSubData(this.subForm.workOrderConsummation)
                            this.subForm.medicalInsuranceSettlement = this.checkBoxDataForSubData(this.subForm.medicalInsuranceSettlement)
                            this.subForm.customerSettlement = this.checkBoxDataForSubData(this.subForm.customerSettlement)
                            this.subForm.caregiverSettlement = this.checkBoxDataForSubData(this.subForm.caregiverSettlement)
                            this.subForm.ownExpense = this.checkBoxDataForSubData(this.subForm.ownExpense)
                            this.subForm.deleteStatus = this.checkBoxDataForSubData(this.subForm.deleteStatus)
                            // this.subForm.medicareStatusValue = this.checkBoxDataForSubData(this.subForm.medicareStatusValue)
                            let formData1 = this.subForm
                            formData1.siteList = this.contractTypeAndSiteInquireValue.toString().charAt(0)
                            formData1.contractTypeId = this.contractTypeAndSiteInquireValue.toString().charAt(2)

                            insertOrder(formData1).then(res => {
                              if (res.retCode === 0) {
                                this.$message({
                                  message: '工单新增成功',
                                  type: 'success'
                                });
                                this.searchOrder()
                              } else {
                                this.$message({
                                  message: '工单新增失败',
                                  type: 'error'
                                });
                              }
                            });
                        }

                        break;
                    case 3:
                        // eslint-disable-next-line no-case-declarations
                        let subData = 'ids=' + this.ids + '&siteList=' + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                        if (this.subForm.workOrderCompleted != null) {
                            subData += '&workOrderCompleted=' + this.subForm.workOrderCompleted
                        }
                        if (this.subForm.customerSettlement != null) {
                            subData += '&customerSettlement=' + this.subForm.customerSettlement
                        }
                        if (this.subForm.caregiverSettlement != null) {
                            subData += '&caregiverSettlement=' + this.subForm.caregiverSettlement
                        }
                        if (this.subForm.workOrderConsummation != null) {
                            subData += '&workOrderConsummation=' + this.subForm.workOrderConsummation
                        }
                        if (this.subForm.medicalInsuranceSettlement != null) {
                            subData += '&medicalInsuranceSettlement=' + this.subForm.medicalInsuranceSettlement
                        }
                        if (this.subForm.ownExpense != null) {
                          subData += '&ownExpense=' + this.subForm.ownExpense
                        }
                        if (this.subForm.deleteStatus != null) {
                            subData += '&deleteStatus=' + this.subForm.deleteStatus
                        }
                        if (this.subForm.medicalInsuranceSettlementDate != null) {
                            subData += '&medicalInsuranceSettlementDate=' + setDate(this.subForm.medicalInsuranceSettlementDate)
                        }
                        if (this.subForm.customerSettlementDate != null) {
                            subData += '&customerSettlementDate=' + setDate(this.subForm.customerSettlementDate)
                        }
                        if (this.subForm.caregiverSettlementDate != null) {
                            subData += '&caregiverSettlementDate=' + setDate(this.subForm.caregiverSettlementDate)
                        }
                        if (this.subForm.deleteOrderRemarks != null) {
                            subData += '&deleteOrderRemarks=' + this.subForm.deleteOrderRemarks
                        }
                        if (this.subForm.workOrderCompleted == null &&
                            this.subForm.customerSettlement == null &&
                            this.subForm.caregiverSettlement == null &&
                            this.subForm.workOrderConsummation == null &&
                            this.subForm.medicalInsuranceSettlement == null &&
                            this.subForm.deleteStatus == null &&
                            this.subForm.medicalInsuranceSettlementDate == null &&
                            this.subForm.customerSettlementDate == null &&
                            this.subForm.caregiverSettlementDate == null) {
                            this.$message.warning('请至少输入一项')
                        } else {
                            updateOrderList(subData).then(res => {
                                if (res) {
                                    this.$message({
                                        message: "操作成功",
                                        type: 'success'
                                    })
                                    this.searchOrder()
                                }
                            })
                            this.dialogFormVisible2 = false
                            this.ids = ''
                        }

                }

            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogFormVisible = false
            },
            checkSearchStatus() {    //检查是否选择了同组对立选项
                let val = this.searchForm.status
                let arr = []
                if (val.length > 0) {
                    for (let i = 0; i < val.length; i++) {
                        arr[i] = Object.keys(JSON.parse(val[i]))[0]
                    }
                    this.result = []
                    for (let j = 0; j < arr.length; j++) {
                        if (this.result.indexOf(arr[j]) < 0) {
                            this.result.push(arr[j])
                            this.searchFlag = true
                        } else {
                            this.$message({
                                message: '同一组不能选择两个选项',
                                type: 'error'
                            })
                            this.searchFlag = false
                            return
                        }
                    }

                }
            },
            getClients() {    //获取客户列表

                this.clientList = []

                let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                    + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2) + "&accessCategory="+"新增工单查询老人列表"
                    + "&currPage=" + this.currPage;
                if (this.customerSearch !== ''){
                  subData = subData + "&searchCondition=" + this.customerSearch
                }
                cusList(subData).then(res => {
                  if (res) {
                    this.clientData = res.data
                    this.allC = res.data[0].totalCount
                  }
                })
            },
            getnurses() {  //获取护理员列表
                this.caregiverList = []
                let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                    + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2);
                if (this.careSearch !== '') {
                  subData = subData + "&searchCondition=" + this.careSearch
                }
                careList(subData).then(res => {
                  if (res) {
                    this.caregiverData = res.data
                    this.allD = res.data.length
                  }
                })

            },
            clearSubForm() {  //初始化提交表单数据
                this.subForm.caregiverMoblle = ''
                this.subForm.customerMoblle = ''
                this.subForm.caregiverId = ''
                this.subForm.clientId = ''
                this.subForm.caregiverName = ''
                this.subForm.customerName = ''
                this.subForm.workOrderConsummation = null
                this.subForm.workOrderCompleted = null
                this.subForm.medicalInsuranceSettlement = null
                this.subForm.customerSettlement = null
                this.subForm.caregiverSettlement = null
                this.subForm.ownExpense = null
                this.subForm.beginTime = null
                this.subForm.endTime = null
                this.subForm.actualBeginTime = null
                this.subForm.date = null
                this.subForm.status = ''
                this.subForm.actualEndtime = null
                this.subForm.serviceOrderNumber = ''
                this.subForm.workOrderNumber = ''
                this.subForm.remarks = ''
            },
            rechange() {  //更改提交显示方式
                if (this.subForm.workOrderCompleted != null) {
                    switch (this.subForm.workOrderCompleted) {
                        case '已认证' :
                            this.subForm.workOrderCompleted = 1
                            break;
                        case '未认证' :
                            this.subForm.workOrderCompleted = 0
                            break;
                    }
                    switch (this.subForm.workOrderConsummation) {
                        case '已录入' :
                            this.subForm.workOrderConsummation = 1
                            break;
                        case '未录入' :
                            this.subForm.workOrderConsummation = 0
                            break;
                    }
                    switch (this.subForm.medicalInsuranceSettlement) {
                        case '已结算' :
                            this.subForm.medicalInsuranceSettlement = 1
                            break;
                        case '未结算' :
                            this.subForm.medicalInsuranceSettlement = 0
                            break;
                    }
                    switch (this.subForm.customerSettlement) {
                        case '已结算' :
                            this.subForm.customerSettlement = 1
                            break;
                        case '未结算' :
                            this.subForm.customerSettlement = 0
                            break;
                    }
                    switch (this.subForm.caregiverSettlement) {
                        case '已结算' :
                            this.subForm.caregiverSettlement = 1
                            break;
                        case '未结算' :
                            this.subForm.caregiverSettlement = 0
                            break;
                    }
                    switch (this.subForm.ownExpense) {
                      case '是' :
                        this.subForm.ownExpense = 1
                        break;
                      case '否' :
                        this.subForm.ownExpense = 0
                        break;
                    }
                }
            },
            change() {   //改变显示形式
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].workOrderCompleted != null) {
                        switch (this.tableData[i].workOrderCompleted) {
                            case '1':
                                this.tableData[i].workOrderCompleted = '已认证'
                                break;
                            case '0':
                                this.tableData[i].workOrderCompleted = '未认证'
                                break;
                        }
                    }
                    if (this.tableData[i].workOrderConsummation != null) {
                        switch (this.tableData[i].workOrderConsummation) {
                            case '1':
                                this.tableData[i].workOrderConsummation = '已录入'
                                break;
                            case '0':
                                this.tableData[i].workOrderConsummation = '未录入'
                                break;
                        }
                    }
                    if (this.tableData[i].medicalInsuranceSettlement != null) {
                        switch (this.tableData[i].medicalInsuranceSettlement) {
                            case '1':
                                this.tableData[i].medicalInsuranceSettlement = '已结算'
                                break;
                            case '0':
                                this.tableData[i].medicalInsuranceSettlement = '未结算'
                                break;
                        }
                    }
                    if (this.tableData[i].customerSettlement != null) {
                        switch (this.tableData[i].customerSettlement) {
                            case '1':
                                this.tableData[i].customerSettlement = '已结算'
                                break;
                            case '0':
                                this.tableData[i].customerSettlement = '未结算'
                                break;
                        }
                    }
                    if (this.tableData[i].caregiverSettlement != null) {
                        switch (this.tableData[i].caregiverSettlement) {
                            case '1':
                                this.tableData[i].caregiverSettlement = '已结算'
                                break;
                            case '0':
                                this.tableData[i].caregiverSettlement = '未结算'
                                break;
                        }
                    }
                    if (this.tableData[i].ownExpense != null) {
                      switch (this.tableData[i].ownExpense) {
                        case '1':
                          this.tableData[i].ownExpense = '是'
                          break;
                        case '0':
                          this.tableData[i].ownExpense = '否'
                          break;
                      }
                    }else {
                      this.tableData[i].ownExpense = '否'
                    }
                    if (this.tableData[i].medicareStatusValue != null) {
                      switch (this.tableData[i].medicareStatusValue) {
                        case '0':
                          this.tableData[i].medicareStatusValue = '正常'
                          break;
                        case '2':
                          this.tableData[i].medicareStatusValue = '非结算'
                          break;
                      }
                    }

                    if (this.tableData[i].contractTypeId != null) {
                        switch (this.tableData[i].contractTypeId) {
                            case 1:
                                this.tableData[i].contractTypeId = '上海长护险'
                                break;
                            case 2:
                                this.tableData[i].contractTypeId = '居家照护'
                                break;
                            case 3:
                                this.tableData[i].contractTypeId = '公益照护'
                                break;
                            case 4:
                                this.tableData[i].contractTypeId = '康复照护'
                                break;
                            case 5:
                                this.tableData[i].contractTypeId = '家政照护'
                                break;
                            case 6:
                              this.tableData[i].contractTypeId = '南京照护险'
                              break;
                        }
                    }
                }
            },
            dataForExport() {  //获取导出/打印数据
                this.$confirm('生成日工单中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.$message.warning('提示：字段过多，请选择打印选项缩放至50%')
                    if (this.searchFlag == true) {
                        let subData = "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2)
                        let str = /\"(.*)\"/
                        let num = /\d+/g
                        if (this.searchForm.status.length > 0) {
                            for (let i = 0; i < this.result.length; i++) {
                                subData += '&' + this.searchForm.status[i].match(str)[1] + '=' + this.searchForm.status[i].match(num)
                            }
                            // this.searchForm.status=[]
                        } else {
                            this.result = []
                        }
                        if (this.searchForm.keyWords !== '') {
                            subData += '&searchCondition=' + this.searchForm.keyWords
                        }
                        if (this.searchForm.date != null) {
                            let searchStartTime = setDate(this.searchForm.date[0])
                            let searchEndTime = setDate(this.searchForm.date[1])
                            subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                        }
                        workOrderListExport(subData).then(res => {
                            if (res) {
                                this.change()//改变0/1为已完成/未完成模式
                                this.$message.success('日工单导出成功,请到报表中进行查看下载');
                                // console.log('export',this.tableData)
                            }
                        })

                    }
                }).catch(() => {
                });

            },
            accountForExport() {  //获取导出/打印数据
                this.$confirm('生成台账中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.$message.warning('提示：字段过多，请选择打印选项缩放至50%')
                    if (this.searchFlag === true) {
                        let subData = "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)+ '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2)
                        let str = /\"(.*)\"/
                        let num = /\d+/g
                        if (this.searchForm.status.length > 0) {
                            for (let i = 0; i < this.result.length; i++) {
                                subData += '&' + this.searchForm.status[i].match(str)[1] + '=' + this.searchForm.status[i].match(num)
                            }
                            // this.searchForm.status=[]
                        } else {
                            this.result = []
                        }
                        if (this.searchForm.keyWords !== '') {
                            subData += '&searchCondition=' + this.searchForm.keyWords
                        }
                        if (this.searchForm.date != null) {
                            let searchStartTime = setDate(this.searchForm.date[0])
                            let searchEndTime = setDate(this.searchForm.date[1])
                            subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
                        }
                        accountListExport(subData).then(res => {
                            if (res) {
                                this.change()//改变0/1为已完成/未完成模式
                                this.$message.success('台账导出成功,请到报表中进行查看下载');
                                // console.log('export',this.tableData)
                            }
                        })

                    }
                }).catch(() => {
                });

            },
            changeTime(date) {  //导出时将时间改为yyyy年mm月dd日格式，不然长度不够会被转成#####
                var date = new Date(date)
                var Y = date.getFullYear() + '年';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
                var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + "日";
                return Y + M + D;
            },
            changeTimeForPickTime(time) {   //获取subform时间初始值
                let str = []
                str = time.split(":")
                return str
            },
            changeTimeForSubmit() {      //提交时改回 HH:mm:ss 格式
                if (this.subForm.beginTime !== '' && this.subForm.beginTime != null) {
                    this.subForm.beginTime = setHours(this.subForm.beginTime)
                } else {
                    this.subForm.beginTime = null
                }
                if (this.subForm.endTime !== '' && this.subForm.endTime != null) {
                    this.subForm.endTime = setHours(this.subForm.endTime)
                } else {
                    this.subForm.endTime = null
                }
                if (this.subForm.actualBeginTime !== '' && this.subForm.actualBeginTime != null) {
                    this.subForm.actualBeginTime = setHours(this.subForm.actualBeginTime)
                } else {
                    this.subForm.actualBeginTime = null
                }
                if (this.subForm.actualEndtime !== '' && this.subForm.actualEndtime != null) {
                    this.subForm.actualEndtime = setHours(this.subForm.actualEndtime)
                } else {
                    this.subForm.actualEndtime = null
                }
                if (this.subForm.date != null) {
                    this.subForm.date = setDate(this.subForm.date)
                } else {
                    this.subForm.date = null
                }
            },
            checkPhoneNum(formName) {
                let a = formName.customerMoblle
                let b = formName.caregiverMoblle
                let str1 = ''
                let str2 = ''
                let str3 = ''
                let str4 = ''
                if (a.length !== 0) {
                    for (let i = 0; i < a.length; i++) {
                        if (isNaN(a[i])) {
                            if (i === a.length - 1) {
                                formName.customerMoblle = a.substring(0, a.length - 1)
                            } else {
                                str1 = a.substring(0, i)
                                str2 = a.substring(i + 1, a.length)
                                formName.customerMoblle = str1 + str2
                            }
                            this.$message.warning('号码应为数值')
                        }
                    }
                }
                if (b.length !== 0) {
                    for (let i = 0; i < b.length; i++) {
                        if (isNaN(b[i])) {
                            if (i === b.length - 1) {
                                formName.caregiverMoblle = b.substring(0, b.length - 1)
                            } else {
                                str3 = b.substring(0, i)
                                str4 = b.substring(i + 1, b.length)
                                formName.caregiverMoblle = str3 + str4
                            }
                            this.$message.warning('号码应为数值')
                        }
                    }
                }
            }
        },
        created() {    //护理站
          let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
          siteChange(subData).then(res => {
            if (res) {
              const permissionList  = res.data.userPermission.permissionList;
              this.dailyWorkOrderMoblle.workOrderList = this.isExist(permissionList,"dailyWorkOrder:workOrderList");
              this.dailyWorkOrderMoblle.insertOrder = this.isExist(permissionList,"dailyWorkOrder:insertOrder");
              this.dailyWorkOrderMoblle.updateOrder = this.isExist(permissionList,"dailyWorkOrder:updateOrder");
              this.dailyWorkOrderMoblle.updateOrderList = this.isExist(permissionList,"dailyWorkOrder:updateOrderList");
              this.dailyWorkOrderMoblle.workOrderListExport = this.isExist(permissionList,"dailyWorkOrder:workOrderListExport");
              this.dailyWorkOrderMoblle.workOrderLogList = this.isExist(permissionList,"dailyWorkOrder:workOrderLogList");
              this.dailyWorkOrderMoblle.generateReports = this.isExist(permissionList,"dailyWorkOrder:generateReports");
              this.dailyWorkOrderMoblle.accountExport = this.isExist(permissionList,"dailyWorkOrder:accountExport");
              this.dailyWorkOrderMoblle.worksheetLogExport = this.isExist(permissionList,"dailyWorkOrder:worksheetLogExport");
              this.dailyWorkOrderMoblle.worksheetPythonExport = this.isExist(permissionList,"dailyWorkOrder:worksheetPythonExport");
              this.dailyWorkOrderMoblle.updateOrderWorkOrderCompletedList = this.isExist(permissionList,"dailyWorkOrder:updateOrderWorkOrderCompletedList");
              this.dailyWorkOrderMoblle.workOrderLogListExport = this.isExist(permissionList,"dailyWorkOrder:workOrderLogListExport");
              this.dailyWorkOrderMoblle.deskCalendarPythonExport = this.isExist(permissionList,"dailyWorkOrder:deskCalendarPythonExport");

              recommendedType().then(res => {
                if(res) {
                  this.recommendedTypes = res.data
                }
              })
              this.searchOrder()
              window.addEventListener('setItem', () => {
                this.siteValue = sessionStorage.getItem('siteValue');
                this.searchOrder();
              })
            }
          })
        },
        watch: {
            currentPage: function () {
                this.searchOrder()
            },
            currentPage1: function () {
                this.getLog()
            },
            'searchForm.status': function () {
                this.checkSearchStatus()
            },
            pagesize: function () {
                this.searchOrder()
            },
            // dialogTableVisible: function () {
            //     this.logSearchTime = null
            // },
            'subForm.customerMoblle': function () {
                this.checkPhoneNum(this.subForm)
            },
            'subForm.caregiverMoblle': function () {
                this.checkPhoneNum(this.subForm)
            },
        }
    }
</script>

<style scoped>
    .el-row {
        margin-bottom: 20px;

    &
    :last-child {
        margin-bottom: 0;
    }

    }
    .el-col {
        border-radius: 4px;
    }

    .gd-top {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        height: 50px;
        box-sizing: border-box;
        padding: 5px 20px;
    }

    .gd-left {
        display: -webkit-flex; /* Safari */
        display: flex;
    }

    .red .el-form-item__label {
        color: #f31616;
    }

</style>
<!--用户弹出页面css-->
<style scoped lang="scss">
    .client-list-two {
        font-size: 25px;
        height: 40px;
        width: 280px;
        text-align: center;

        .state {
            background: #409EFF;
            color: #FFFFFF;
        }

        .stateGreen {
            background: #7FFFAA;
            color: #696969;
        }

        .stateRed {
            background: #FF0000;
            color: #ffffff;
        }

        .stateMaroon {
            background: #800000;
            color: #ffffff;
        }

        .stateDarkRed {
            background: #DC143C;
            color: #696969;
        }

        .stateDarkgray {
            background: #C0C0C0;
            color: #696969;
        }

        .stateYellow {
            background: #F0E68C;
            color: #696969;
        }

        .lightSkyBlue {
            background: #87CEFA;
            color: #696969;
        }

    }

    .over-due {
        /* position: absolute;*/
        /* top:4px;*/
        font-size: 12px;
        color: #FFF;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: red;
        text-align: center;
        line-height: 20px;
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 70px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .local {
            display: inline-block;
            margin-left: 20px;
        }
    }

    .serve {
        padding: 20px 0 0 20px;
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 5%;
        }

        .line {
            width: 300px;
        }
    }

    .assess {
        padding: 20px 0 0 30px;

        .line {
            width: 260px;
        }
    }

    #map {
        width: 100%;
        height: 300px;
    }

    .adress {
        padding-left: 5%;

        .line {
            width: 260px;
        }
    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }
    }

    /*上传图片*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .form .form-left {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }

    .form .form-right {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }
</style>
