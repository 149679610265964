<template>
    <div class="company" v-loading="loading">
        <div class="com-info">
            <div style="font-size: 50px">新功能开发中,敬请期待!</div>
           <!-- <div class="info-top">
                <div class="img">
                    &lt;!&ndash;   <img src="@/assets/head.png" alt="">&ndash;&gt;
                    <el-upload
                            class="avatar-uploader"
                            action="customize"
                            :show-file-list="false"
                            :http-request="uploadFile"
                            accept='.jpg,.png'
                            ref="headUpload"
                            :on-change="imgChange"
                    >
                        <img v-if="companyForm.logo" :src="companyForm.logo" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="right-form">
                    <div class="flex"><span>企业名称</span>
                        <el-input v-model="companyForm.name" class="input line"></el-input>
                    </div>
                    <div class="flex"><span>法人姓名</span>
                        <el-input v-model="companyForm.legalPersonName" class="input line"></el-input>
                    </div>
                    <div class="flex"><span>企业电话</span>
                        <el-input v-model="companyForm.enterprisePhone" class="input line"></el-input>
                    </div>
                    <div class="flex"><span>企业地址</span>
                        <el-input v-model="companyForm.enterpriseAddress" class="input line"></el-input>
                    </div>
                    &lt;!&ndash; <div class="flex"><span>企业居委会</span><el-input v-model="companyForm.name" class="input"></el-input></div>&ndash;&gt;
                    <div class="flex"><span>详细地址</span>
                        <el-input v-model="companyForm.addressDetall" class="input line"></el-input>
                    </div>
                    <div class="flex"><span>企业类型</span>
                        <el-input v-model="companyForm.enterpriseType" class="input line"></el-input>
                    </div>
                    <div class="flex"><span>注册时间</span>
                        <el-date-picker
                                class="input line"
                                v-model="companyForm.registrationTime"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="info-bottom">
                <span>Company description</span>
                <el-input type="textarea" v-model="companyForm.companyDescription"></el-input>
                <el-button type="primary" class="sub-button" @click="changeCompany">提交修改</el-button>
            </div>-->
        </div>
    </div>
</template>

<script>
    import {getCompany, chanCompany} from "@/common/js/index.js";
    import {setDate} from "@/common/js/util.js"

    export default {
        name: "company",
        data() {
            return {
                loading: true,
                siteValue: sessionStorage.getItem("siteValue"),
                companyForm: {
                    name: "",
                    legalPersonName: "",
                    enterprisePhone: "",
                    enterpriseAddress: "",
                    addressDetall: "",
                    enterpriseType: "",
                    registrationTime: "",
                    companyDescription: "",
                    logo: "",
                    logoFile: "",
                },
            }
        },
        methods: {
            /**
             * 获取公司信息
             * */
            getCpyInfor() {
                this.loading = true;
                let subData = "siteList=" + this.siteValue;
                getCompany(subData).then(res => {
                    if (res) {
                        this.companyForm = res.data[0];
                        this.loading = false;
                    }
                })
            },
            /**
             * 修改公司信息
             * */
            changeCompany() {
                let headFiles = this.$refs.headUpload.uploadFiles;
                let formData = new FormData();
                formData.append("siteList", this.siteValue);
                formData.append("name", this.companyForm.name);
                formData.append("legalPersonName", this.companyForm.legalPersonName);
                formData.append("enterprisePhone", this.companyForm.enterprisePhone);
                formData.append("enterpriseAddress", this.companyForm.enterpriseAddress);
                formData.append("addressDetall", this.companyForm.addressDetall);
                formData.append("enterpriseType", this.companyForm.enterpriseType);
                if (this.companyForm.registrationTime) {
                    formData.append("registrationTime", setDate(this.companyForm.registrationTime));
                }
                formData.append("companyDescription", this.companyForm.companyDescription);
                if (headFiles.length != 0) {
                    this.companyForm.logoFile = headFiles[headFiles.length - 1].raw;
                    formData.append("logoFile", this.companyForm.logoFile);
                }
                chanCompany(formData).then(res => {
                    if (res) {
                        this.$message.success('修改公司信息成功');
                        this.getCpyInfor();
                    }
                })
            },
            imgChange(file) {
                this.companyForm.logo = URL.createObjectURL(file.raw);
            },
            // 文件上传
            uploadFile() {
            },
        },
        created() {
            window.addEventListener('setItem', () => {
                // this.allLoading=true;
                this.siteValue = sessionStorage.getItem('siteValue');
                this.getCpyInfor();
            }, false)
            this.getCpyInfor();
        }
    }
</script>

<style scoped lang="scss">
    .company {
        width: 100%;
        height: 100%;
        /* border: 1px solid red;*/
        /*  box-sizing: border-box;*/
        padding-top: 20px;
    }

    .com-info {
        /*  border: 1px solid red;*/
        width: 710px;
        margin: 100px auto;
        flex-direction: column;
    }

    .info-top {
        display: -webkit-flex; /* Safari */
        display: flex;
        /*  border: 1px solid blue;*/
        .img {
            width: 300px;
            height: 300px;
            margin: 10px 0 0 0;
        }

        .right-form {
            padding-left: 20px;
        }

        .right-form span {
            width: 80px;
            line-height: 40px;
        }
    }

    .flex {
        display: -webkit-flex; /* Safari */
        display: flex;
        margin-top: 5px;
    }

    .input {
        width: 300px;
    }

    .info-bottom span {
        display: block;
        margin: 10px 0;
    }

    .sub-button {
        margin-top: 12px;
    }

    /*图片上传*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        height: 300px;
        line-height: 300px;
        text-align: center;
    }

    .avatar {
        width: 300px;
        height: 300px;
        display: block;
    }
</style>
