<template>
    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                  view-class="p20-scrollbar__view">
        <div>

            <el-container>
                <el-main>
                    <el-radio v-model="siteInterfaceSiteId" label="1">巾帼站</el-radio>
                    <el-radio v-model="siteInterfaceSiteId" label="2">建浦站</el-radio>
                    <el-radio v-model="siteInterfaceSiteId" label="3">安康通</el-radio>
                    <el-radio v-model="siteInterfaceSiteId" label="5">震瀛站</el-radio>
                    <el-radio v-model="siteInterfaceSiteId" label="4">震瀛二站</el-radio>
                    <el-radio v-model="siteInterfaceSiteId" label="6">优蓓杰</el-radio>
                    <div class="chart" ref="siteInterface"></div>
                </el-main>
                <el-main>
                    <el-radio v-model="customerServiceInformationTodaySiteId" label="1">巾帼站</el-radio>
                    <el-radio v-model="customerServiceInformationTodaySiteId" label="2">建浦站</el-radio>
                    <el-radio v-model="customerServiceInformationTodaySiteId" label="3">安康通</el-radio>
                    <el-radio v-model="customerServiceInformationTodaySiteId" label="5">震瀛站</el-radio>
                    <el-radio v-model="customerServiceInformationTodaySiteId" label="4">震瀛二站</el-radio>
                    <el-radio v-model="customerServiceInformationTodaySiteId" label="6">优蓓杰</el-radio>
                    <div class="chart" ref="customerServiceInformation"></div>
                </el-main>
            </el-container>
            <el-container>
                <el-main>
                    <el-radio v-model="todayIsLiveDataSiteId" label="1">巾帼站</el-radio>
                    <el-radio v-model="todayIsLiveDataSiteId" label="2">建浦站</el-radio>
                    <el-radio v-model="todayIsLiveDataSiteId" label="3">安康通</el-radio>
                    <el-radio v-model="todayIsLiveDataSiteId" label="5">震瀛站</el-radio>
                    <el-radio v-model="todayIsLiveDataSiteId" label="4">震瀛二站</el-radio>
                    <el-radio v-model="todayIsLiveDataSiteId" label="6">优蓓杰</el-radio>
                    <div class="chart" ref="todayIsLiveData"></div>
                </el-main>
            </el-container>

            <el-dialog title="" :visible.sync="clientDialog" width="1200px">
                <div slot="title" class="header-title">
                    <span v-show="titleType" class="title-name">{{ titleType }}</span>
                </div>
                <el-table :data="clientArr"
                          border
                          stripe
                          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                          :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
                          max-height="700"
                >
                    <el-table-column label="姓名">
                        <template slot-scope="scope">
                            <p @click="jumpToCustomer(scope.row)">{{scope.row.name}}</p>
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="status" label="状态"></el-table-column>-->
                    <el-table-column prop="numbering" label="编号"></el-table-column>
                    <el-table-column prop="gender" label="性别"></el-table-column>
                    <el-table-column prop="moblle" label="电话"></el-table-column>
                    <el-table-column prop="statusChangetime" label="时间"></el-table-column>

                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="carePageChange"
                        :current-page="clientPage"
                        style="text-align:right;margin-top: 20px"
                        layout="total,prev, pager, next, jumper"
                        :page-size="10"
                        :total="clientTotal">
                </el-pagination>
            </el-dialog>
            <!--用户详情-->
            <el-dialog title="用户详情" :visible.sync="clientDetails" width="80%">
            <div class="des-box">
              <el-form ref="form" :model="form" :rules="cRules" label-width="100px">
                <div class="des  assess">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="姓名" prop="name">
                        <el-input v-model="form.name" placeholder="请输入客户姓名" class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="手机号" prop="moblle">
                        <el-input v-model="form.moblle" placeholder="请输入客户手机号码" class="line"
                                  style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="身份证号" prop="identityCard">
                        <el-input v-model="form.identityCard" placeholder="请输入客户身份证号"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="年龄" prop="age">
                        <el-input v-model="form.age" placeholder="请输入客户年龄" class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="家属姓名">
                        <el-input v-model="form.familyName" placeholder="请输入客户家属姓名"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="紧急联络电话">
                        <el-input v-model="form.familyMoblle" placeholder="请输入客户紧急联系方式"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">&nbsp;&nbsp;&nbsp;&nbsp;
                      <el-button type="primary" @click="viewOtherFamilyMembers()" size="small"
                                 v-show="!isAdd">查看其它家属
                      </el-button>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="性别">
                        <el-radio-group v-model="form.gender">
                          <el-radio label="男">男</el-radio>
                          <el-radio label="女">女</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="银行卡所属行" prop="bankCardName">
                        <el-input v-model="form.bankCardName" placeholder="请输入银行卡所属行名"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="银行卡号" prop="bankCardNumber">
                        <el-input v-model="form.bankCardNumber" placeholder="请输入银行卡号"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="状态备注" prop="statusRemarks">
                        <el-input v-model="form.statusRemarks" class="line" style="width: 400px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <br/>
                <el-row :gutter="0">
                  <el-col :span="6">
                    <el-form-item label="已签约:">
                      <el-select v-model="selectEvaluationNursingId" @change="jumpToCustomer()" v-show="!isAdd"
                                 style="width: 150px">
                        <el-option
                            v-for="item in customeContracttypesList"
                            :key="item.index"
                            :label="item.customeContractType"
                            :value="item.evaluationNursingId">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <div class="client-list-two">
                      <div v-if="form.status === '有意向'" class="stateYellow"><span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '待派单'" class="stateYellow">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '待申请'" class="stateYellow">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '待评估'" class="lightSkyBlue">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '中止中'" class="stateRed">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '长期中止'" class="stateMaroon">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '待确认'" class="stateYellow">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '合约中'" class="stateGreen">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '已终止'" class="stateRed">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '到期合约'" class="stateDarkgray">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                      <div v-else-if="form.status === '已删除'" class="stateDarkgray">
                        <span>该合约状态：{{form.status}}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <div class="des  assess">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="长护险平台是否录入" label-width="160px">
                        <el-switch v-model="form.enterPlan" active-value="1"
                                   inactive-value="0"></el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="长护险平台是否接收" label-width="160px">
                        <el-switch v-model="form.enterPlanFlag" active-value="1"
                                   inactive-value="0"></el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="医护平台是否派单" label-width="160px">
                        <el-switch v-model="form.mdicalPatform" active-value="1"
                                   inactive-value="0"></el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="护理计划编码">
                        <el-input v-model="form.plannlngSheet" placeholder="请输入客户护理计划编码"
                                  class="line"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="街道">
                        <el-input v-model="form.sreet" class="line" @focus="toAddress()" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="居委会" prop="committees">
                        <el-input v-model="form.committees" class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="详细地址">
                        <el-input v-model="form.addressDetall" class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="设备编号">
                        <el-input v-model="form.lbeaconMajor" placeholder="请输入设备编号" class="line"
                                  learable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="评估有效期">
                        <el-date-picker
                            v-model="form.evaluationPeriod"
                            type="date"
                            style="width: 200px"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="首次护理时间">
                        <el-date-picker
                            v-model="form.firstCareTime"
                            type="date"
                            style="width: 200px"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="评估等级">
                        <el-input v-model="form.evaluationLervl" type="number" placeholder="请输入客户评估等级"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="医保卡类型">
                        <el-select v-model="form.medicareCardType" placeholder="请选择医保卡类型" class="line">
                          <el-option
                              v-for="item in medicareTypes"
                              :key="item"
                              :label="item"
                              :value="item">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="推荐渠道">
                        <el-input v-model="form.recommend" placeholder="请输入客户推荐渠道"
                                  class="line" style="width: 200px;"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="护理备注">
                        <el-input v-model="form.nursingRemarks" class="line" style="width: 800px;"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="des  assess">

                  <el-row :gutter="0">
                    <el-col :span="8" style="width: 35%">
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="指定护理员一">
                            <el-input v-model="careWorker_one" placeholder="请选择指定护理员一" class="line"
                                      @focus="careInput(1)"></el-input>
                            <el-button type="danger" @click="deleteCareWorker_one()" v-show="!isAdd" size="mini"
                                       icon="el-icon-delete"
                                       circle></el-button>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="指定原因">
                            <el-input v-model="form.designationReason_one" placeholder="指定护理员一原因" class="line"
                                      clearable></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="指定护理员二">
                            <el-input v-model="careWorker_two" placeholder="请选择指定护理员二" class="line"
                                      @focus="careInput(2)"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="指定原因">
                            <el-input v-model="form.designationReason_two" placeholder="指定护理员二原因"
                                      class="line"
                                      clearable></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="指定护理员三">
                            <el-input v-model="careWorker_three" placeholder="请选择指定护理员三" class="line"
                                      @focus="careInput(3)"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="指定原因">
                            <el-input v-model="form.designationReason_three" placeholder="指定护理员三原因"
                                      class="line"
                                      clearable></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="备用时间备注">
                            <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                      clearable></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12" style="width: 55%">
                      <div v-for="(item, index) in timePeriodList" v-bind:key="index">
                        <el-row :gutter="20">
                          <el-col :span="4">
                            <el-select v-model="item.week" placeholder="周期">
                              <el-option
                                  v-for="item in serveWeek"
                                  :key="item.week"
                                  :label="item.name"
                                  :value="item.week">
                              </el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="护理时间">
                              <el-time-select
                                  style="width: 120px"
                                  placeholder="起始时间"
                                  v-model="item.beginTime"
                                  format="HH:mm"
                                  :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                              </el-time-select>
                              <el-time-select
                                  style="width: 120px"
                                  placeholder="结束时间"
                                  v-model="item.endTime" :disabled="true" class="line"
                                  :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                              </el-time-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="5">
                            <el-select v-model="item.medicareStatusValue" placeholder="状态">
                              <el-option
                                  v-for="item in medicareStatus"
                                  :key="item.medicareStatusValue"
                                  :label="item.label"
                                  :value="item.medicareStatusValue">
                              </el-option>
                            </el-select>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="des  assess">
                  <el-row :gutter="0">
                    <el-col :span="8" style="width: 35%">
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="预约指定护理员" label-width="160px">
                            <el-input v-model="reservation_careWorker" placeholder="请选择预约指定护理员" class="line"
                                      @focus="careInput(4)" clearable></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="预约指定原因" label-width="160px">
                            <el-input v-model="form.reservation_designationReason" placeholder="预约指定原因" style="width:200px"
                                      clearable></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="状态预约时间" prop="appointmentTime" v-if="!isAdd" label-width="160px">
                            <el-input v-model="form.appointmentTime" :disabled="true" style="width:200px"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="0">
                        <el-col :span="24">
                          <el-form-item label="预约变更周期时间" prop="appointmentCycleChangeTime" v-if="!isAdd"
                                        label-width="160px">
                            <el-input v-model="form.appointmentCycleChangeTime" :disabled="true"
                                      style="width:200px"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12" style="width: 55%">
                      <div v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                        <el-row :gutter="20">
                          <el-col :span="4">
                            <el-select v-model="item.week" :disabled="true" placeholder="周期">
                              <el-option
                                  v-for="item in serveWeek"
                                  :key="item.week"
                                  :label="item.name"
                                  :value="item.week">
                              </el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="护理时间">
                              <el-time-select
                                  style="width: 120px"
                                  placeholder="起始时间"
                                  v-model="item.beginTime" :disabled="true"
                                  format="HH:mm"
                                  :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                              </el-time-select>
                              <el-time-select
                                  style="width: 120px"
                                  placeholder="结束时间"
                                  v-model="item.endTime" :disabled="true" class="line"
                                  :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                              </el-time-select>
                            </el-form-item>
                          </el-col>
                          <el-col :span="5">
                            <el-select v-model="item.medicareStatusValue" :disabled="true" placeholder="状态">
                              <el-option
                                  v-for="item in medicareStatus"
                                  :key="item.medicareStatusValue"
                                  :label="item.label"
                                  :value="item.medicareStatusValue">
                              </el-option>
                            </el-select>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="des map">
                  <div class="flex">
                    <el-form-item label="纬度" label-width="50px">
                      <el-input v-model="form.latltude" placeholder="" class="line"
                                clearable></el-input>
                    </el-form-item>
                    <el-form-item label="经度" label-width="50px">
                      <el-input v-model="form.longltude" placeholder="" class="line"
                                clearable></el-input>
                    </el-form-item>
                    <!--<span class="local">纬度：{{form.latltude}}</span><span class="local">经度：{{form.longltude}}</span>-->
                  </div>
                  <div id="map"></div>
                </div>
                <div class="des card">
                  <el-tabs v-model="activeName">
                    <el-tab-pane label="身份证" name="first">
                      <div class="flex img-box">
                        <div>
                          <el-upload
                              class="avatar-uploader"
                              action="customize"
                              :show-file-list="false"
                              :http-request="uploadFile"
                              accept='.jpg,.png'
                              ref="idBefUpload"
                              :on-change="idBefChange"
                          >
                            <img v-if="idBefUrl" :src="idBefUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                          <p>（身份证正面）</p>
                        </div>
                        <div style="margin-left: 30px">
                          <el-upload
                              class="avatar-uploader"
                              action="customize"
                              :show-file-list="false"
                              :http-request="uploadFile"
                              accept='.jpg,.png'
                              ref="idBacUpload"
                              :on-change="idBacChange"
                          >
                            <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                          <p>（身份证反面）</p>
                        </div>
                      </div>
                    </el-tab-pane>

                    <el-tab-pane label="医保卡" name="second">
                      <div class="flex img-box">
                        <div>
                          <el-upload
                              class="avatar-uploader"
                              action="customize"
                              :show-file-list="false"
                              :http-request="uploadFile"
                              accept='.jpg,.png'
                              ref="soBefUpload"
                              :on-change="soBefChange"
                          >
                            <img v-if="soBefUrl" :src="soBefUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                          <p>（社保卡正面）</p>
                        </div>
                        <div style="margin-left: 30px">
                          <el-upload
                              class="avatar-uploader"
                              action="customize"
                              :show-file-list="false"
                              :http-request="uploadFile"
                              accept='.jpg,.png'
                              ref="soBacUpload"
                              :on-change="soBacChange"
                          >
                            <img v-if="soBacUrl" :src="soBacUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                          </el-upload>
                          <p>（社保卡反面）</p>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-form>
            </div>

          </el-dialog>
            <el-dialog title="查看家属信息" :visible.sync="familyInformationDialog" width="1200px">
                <el-table :data="familyInformation"
                          border
                          stripe
                          :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                          :cell-style="{'text-align':'center','man-width':'100px'}"
                          max-height="700"
                >
                    <el-table-column prop="familyName" label="家属姓名"></el-table-column>
                    <el-table-column prop="familyMoblle" label="紧急联络电话"></el-table-column>
                    <el-table-column prop="familyNotes" label="家属备注"></el-table-column>
                </el-table>
            </el-dialog>
            <!--护理员详情-->
            <el-dialog title="护理员详情" :visible.sync="staffDetails" width="80%">
                <div class="des-box">
                    <el-form ref="staForm" :model="staForm" :rules="sRules" label-width="100px">
                        <div class="des">
                            <div class="des-flex">
                                <div class="input-box">
                                    <el-form-item label="姓名" prop="name">
                                        <el-input v-model="staForm.name" placeholder="请输入护理员姓名"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机号" prop="moblle">
                                        <el-input v-model="staForm.moblle" placeholder="请输入护理员手机号"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="身份证号" prop="identityCard">
                                        <el-input v-model="staForm.identityCard" placeholder="请输入护理员身份证号"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="年龄" prop="age">
                                        <el-input v-model="staForm.age" placeholder="请输入护理员年龄"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="性别">
                                        <el-radio-group v-model="staForm.gender">
                                            <el-radio label="男">男</el-radio>
                                            <el-radio label="女">女</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="编号" prop="numbering">
                                        <el-input v-model="staForm.numbering" placeholder="请输入护理员编号"
                                                  class="line"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="input-box">
                                    <el-form-item label="紧急联系人">
                                        <el-input v-model="staForm.emergencyContact" placeholder="请输入护理员紧急联系人"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="紧急联络电话">
                                        <el-input v-model="staForm.contactMoblle" placeholder="请输入护理员紧急联络电话"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="推荐渠道">
                                        <el-input v-model="staForm.recommend" placeholder="请输入护理员推荐渠道"
                                                  class="line"></el-input>
                                    </el-form-item>
                                    <el-form-item label="是否全职">
                                        <el-switch v-model="staForm.fullTime" active-value="1"
                                                   inactive-value="0"></el-switch>
                                    </el-form-item>
                                    <el-form-item label="是否逾期">
                                        <el-switch v-model="staForm.overdue" active-value="1"
                                                   inactive-value="0"></el-switch>
                                    </el-form-item>
                                    <el-form-item label="状态备注" prop="statusRemarks">
                                        <el-input v-model="staForm.statusRemarks"
                                                  class="line"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="input-box">
                                    <el-form-item label="服务用户人数" v-if="!isadd">
                                        <el-input v-model="staForm.size" :disabled="true"
                                                  class="line2"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="adress">


                                <el-form-item label="合同时间">
                                    <!--                                          <el-date-picker
                                                                                      v-model="contractTime"

                                                                                      type="datetimerange"
                                                                                      align="right"
                                                                                      start-placeholder="开始日期"
                                                                                      end-placeholder="结束日期"
                                                                                      :default-time="['12:00:00', '12:00:00']">

                                                                              </el-date-picker>-->
                                    <el-date-picker
                                            v-model="staForm.contractStartDate"
                                            type="date"
                                            placeholder="合同开始时间"
                                    >
                                    </el-date-picker>
                                    <el-date-picker
                                            v-model="staForm.contractEndDate"
                                            type="date"
                                            placeholder="合同结束时间"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="flex adress">
                                <el-form-item label="街道">
                                    <el-input v-model="staForm.sreet" class="line"
                                              @focus="toAddress()"></el-input>
                                </el-form-item>
                                <el-form-item label="居委会">
                                    <el-input v-model="staForm.committees" class="line"></el-input>
                                </el-form-item>
                                <el-form-item label="详细地址">
                                    <el-input v-model="staForm.addressDetall" class="line"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <!--                     <div class="des map">
                                                 <div class="serve-address">
                                                     <el-form-item label="意向服务地址">
                                                         <el-input v-model="staForm.intentServiceAddress" class="line"></el-input>
                                                     </el-form-item>
                                                 </div>
                                                 <div><span>纬度：31.24984675260468{{staForm.latltude}}</span><span>经度：31.24984675260468{{staForm.longltude}}</span>
                                                 </div>
                                                 <div id="map">
                                                 </div>
                                             </div>-->
                        <div class="des card">
                            <el-tabs v-model="activeName">
                                <el-tab-pane label="身份证" name="first">
                                    <div class="flex img-box">
                                        <div>
                                            <el-upload
                                                    class="avatar-uploader"
                                                    action="customize"
                                                    :show-file-list="false"
                                                    :http-request="uploadFile"
                                                    accept='.jpg,.png'
                                                    ref="befUpload"
                                                    :on-change="befChange"
                                            >
                                                <img v-if="befUrl" :src="befUrl" class="avatar">
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                            <p>（身份证正面）</p>
                                        </div>
                                        <div style="margin-left: 30px">
                                            <el-upload
                                                    class="avatar-uploader"
                                                    action="customize"
                                                    :show-file-list="false"
                                                    :http-request="uploadFile"
                                                    accept='.jpg,.png'
                                                    ref="bacUpload"
                                                    :on-change="bacChange"
                                            >
                                                <img v-if="bacUrl" :src="bacUrl" class="avatar">
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                            <p>（身份证反面）</p>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="健康证" name="second">
                                    <div class="flex img-box">
                                        <div>
                                            <el-upload
                                                    class="avatar-uploader"
                                                    action="customize"
                                                    :show-file-list="false"
                                                    :http-request="uploadFile"
                                                    accept='.jpg,.png'
                                                    ref="healthUpload"
                                                    :on-change="healthChange"
                                            >
                                                <img v-if="healthUrl" :src="healthUrl" class="avatar">
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                            <p>（健康证）</p>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="医疗证" name="third">
                                    <div class="flex img-box">
                                        <div>
                                            <el-upload
                                                    class="avatar-uploader"
                                                    action="customize"
                                                    :show-file-list="false"
                                                    :http-request="uploadFile"
                                                    accept='.jpg,.png'
                                                    ref="medUpload"
                                                    :on-change="medChange"
                                            >
                                                <img v-if="medicalUrl" :src="medicalUrl" class="avatar">
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                            <p>（医疗证）</p>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </el-form>
                </div>
            </el-dialog>
        </div>
    </el-scrollbar>
</template>
<script>
    import {
        siteInterfaceDateSelect,
        customerServiceInformationTodayDataSelect,
        todayIsLiveData,
        siteDataDetailList,
        selectTodayIsLiveDataDetailList,
        getCus,
        getCare,
        viewOtherFamilyMembers
    } from '@/common/js/index.js'
    import {isEmpty} from "@/common/js/util.js"
    import BMap from 'BMap';

    export default {
        name: "webmasterInterface",
        data() {
              return {
                timePeriodList: [], //时间周期
                appointmentTimePeriodList: [], //预约时间周期
                medicareStatus: [{
                  medicareStatusValue: '0',
                  label: '正常'
                }, {
                  medicareStatusValue: '2',
                  label: '非医保服务'
                }, {
                  medicareStatusValue: '4',
                  label: '医保虚拟服务'
                }],
                serveWeek: [
                  {name: "周一", week: '1'},
                  {name: "周二", week: '2'},
                  {name: "周三", week: '3'},
                  {name: "周四", week: '4'},
                  {name: "周五", week: '5'},
                  {name: "周六", week: '6'},
                  {name: "周日", week: '7'},
                ],
                contractTypeId:'',
                selectEvaluationNursingId: null,// 合约信息id
                customeContracttypesList: [],// 老人 已签约 合约类型
                clientId: null,//客户Id
                familyInformationDialog: false,//查看家属信息
                familyInformation: [],
                customerServiceInformationTodaySiteId: '1',
                siteInterfaceSiteId: '1',
                todayIsLiveDataSiteId: '1',
                siteInterfaceDate: [],
                clientProFileData: [],// 今日老人服务信息统计数据
                clientProFileDataName: [],// 今日老人服务信息名字
                todayIsLiveData: [],
                dataDetailListName: '',
                clientDialog: false,
                clientArr: [],
                currPage: 1,
                clientPage: 1,
                clientTotal: 0,
                titleType: '',
                /*用户begin*/
                siteValue: sessionStorage.getItem("siteValue"),
                idBefUrl: "",//身份证正面
                idBacUrl: "",//身份证反面
                soBefUrl: "",//社保卡正面
                soBacUrl: "",//社保卡反面
                activeName: 'first',
                form: {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)1
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    statusRemarks: "", //状态备注
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                },
                medicareTypes: localStorage.getItem("medicareCardType").split("$"),
                checkList: [],
                reservation_nursingCycle: [],//意向服务时间（周）
                nursingCycle: [],//护理周期
                careWorker_one: "",//指定护理员1
                careWorker_two: "",//指定护理员2
                careWorker_three: "",//指定护理员3
                reservation_careWorker: "",//预约指定护理员
                nursingTime: "",//护理时间
                nursingAppoTime: "",//预约护理时间
                clientDetails: false,
                cRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                },
                isAdd: false,
                /*用户end*/
                /*护理员begin*/
                staffDetails: false,
                adressForm: {
                    area: "",
                    sreet: "",
                    committees: ""
                },
                addressPage: 1,
                addressTotal: 0,
                addressPSize: 0,
                addressData: [],
                addressDialog: false,
                clientRight: {
                    width: ""
                },
                isAssign: false,
                operaDialog: false,//操作弹窗
                operaForm: {
                    targetStatus: "",
                    statusRemarks: ""
                },
                formInline: {
                    logSTime: ""//日志时间筛选
                },
                allLoad: true,
                nurStatus: "",
                nurText: "",
                pages: 0,
                nurseArr: [],
                isadd: false,
                staffOpera: [],
                contractTime: "",
                staForm: {
                    name: "",//姓名
                    id: "",
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    emergencyContact: "",//紧急联系人
                    contactMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    recommend: "",//推荐渠道
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    // latltude:"",//纬度
                    // longltude:"",//经度
                    idCardFront: null,//身份证正面
                    idCardNegative: null,//身份证反面
                    healthCertificateFront: null,//健康证
                    sicknessCertificate: null,//医疗证

                    fullTime: "0",//是否兼职
                    overdue: "0",//是否逾期
                    /*暂时没加*/
                    intentServiceAddress: "",//意向服务地址
                    intentionLongltude: "",//意向地址经度
                    intentionLatltude: "",//意向地址纬度
                    size: "",//护理员总数
                    contractStartDate: "",//合同开始时间
                    contractEndDate: "",//合同结束时间
                    deleteOrderRemarks: "",//删除工单备注
                },
                sRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                },
                staffId: null,
                imageUrl: '',
                befUrl: "",//身份证正面
                bacUrl: "",//身份证反面
                healthUrl: "",//健康证
                medicalUrl: "",//医疗证
                /*护理员end*/
            }
        },

        methods: {
            viewOtherFamilyMembers() {
                let subData = 'clientId=' + this.clientId
                viewOtherFamilyMembers(subData).then(res => {
                    if (res) {
                        this.familyInformation = res.data
                    }
                })
                this.familyInformationDialog = true;
            },
            carePageChange(val) {
                this.clientPage = val;
                this.siteDataDetailList();
            },
            handleSizeChange(val) {
                this.pagesize = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            /**
             * 今日实时数据查询
             */
            todayIsLiveDataSelect() {
                let subData = "siteId=" + this.todayIsLiveDataSiteId;
                todayIsLiveData(subData).then(res => {
                    if (res) {
                        this.todayIsLiveData = res.data;
                        this.todayIsLiveDataList();
                    }
                })
            },
            /**
             * 今日实时数据
             */
            todayIsLiveDataList() {
                // 基于准备好的dom，初始化echarts实例
                let todayIsLiveData = this.$echarts.init(this.$refs.todayIsLiveData);
                todayIsLiveData.setOption({ // 绘制图表

                    color: ['#3398DB'],
                    title: {text: '今日实时数据(点击查看详情)'},
                    tooltip: {},
                    xAxis: {
                        data: ["新增用户", "中止用户", "恢复用户", "终止用户", "待派单用户", "新增护理员", "中止护理员", "恢复护理员", "终止护理员"],
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.todayIsLiveData,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    formatter: '{c}',
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        },
                    }]

                });
                const that = this;
                //  柱状图点击事件  传入数据组:value 数据名:name
                todayIsLiveData.on("click",
                    function (param) {
                        that.dataDetailListName = param.name;
                        that.selectTodayIsLiveDataDetail();
                    }
                )
            },
            /**
             * 今日实时数据列表信息详情
             *
             */
            selectTodayIsLiveDataDetail() {
                let subData = "currPage=" + this.clientPage + "&status=" + this.dataDetailListName + "&siteId=" + this.siteInterfaceSiteId;
                selectTodayIsLiveDataDetailList(subData).then(res => {
                    if (res) {
                        this.clientArr = res.data;
                        this.titleType = '今日实时数据-->' + this.dataDetailListName;
                        if (res.data.length !== 0) {
                            this.clientTotal = res.data[0].totalCount;
                        }
                        this.clientDialog = true;
                    }
                })
            },
            /**
             * 今日老人服务信息统计查询
             */
            customerServiceInformationTodayDataSelect() {
                let subData = "siteId=" + this.customerServiceInformationTodaySiteId;
                customerServiceInformationTodayDataSelect(subData).then(res => {
                    if (res) {
                        this.clientProFileData = res.data.clientProFileData;
                        this.clientProFileDataName = res.data.clientProFileDataName;
                        this.customerServiceInformationTodayData();
                    }
                })
            },
            /**
             * 今日老人服务信息统计
             */
            customerServiceInformationTodayData() {
                // 基于准备好的dom，初始化echarts实例
                let customerServiceInformation = this.$echarts.init(this.$refs.customerServiceInformation);
                customerServiceInformation.off('click')
                customerServiceInformation.setOption({ // 绘制图表

                    color: ['#3398DB'],
                    title: {text: '今日老人服务信息统计'},
                    tooltip: {},
                    xAxis: {
                        data: this.clientProFileDataName,
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.clientProFileData,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    formatter: '{c}',
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        },
                    }]
                });
            },

            /**
             * 站点概况数据查询
             */
            siteInterfaceDateSelect() {
                let subData = "siteId=" + this.siteInterfaceSiteId;
                siteInterfaceDateSelect(subData).then(res => {
                    if (res) {
                        this.siteInterfaceDate = res.data;
                        this.site();
                    }
                })
            },
            /**
             * 站点概况
             */
            site() {
                // 基于准备好的dom，初始化echarts实例
                var siteInterface = this.$echarts.init(this.$refs.siteInterface);
                siteInterface.off('click')
                siteInterface.setOption({ // 绘制图表

                    color: ['#3398DB'],
                    title: {text: '护理站概况（点击查看详情）'},
                    tooltip: {},
                    xAxis: {
                        data: ["所有用户", "合约中用户", "所有护理员", "合约中护理员"],
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {triggerEvent: true},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.siteInterfaceDate,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    formatter: '{c}',
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        },
                    }]

                });

                const that = this;
                //  柱状图点击事件  传入数据组:value 数据名:name
                siteInterface.on("click",
                    function (param) {
                        that.dataDetailListName = param.name;
                        that.siteDataDetailList();
                    }
                )

            },
            /**
             * 站点概况详情列表
             *
             */
            siteDataDetailList() {
                let subData = "currPage=" + this.clientPage + "&status=" + this.dataDetailListName + "&siteId=" + this.siteInterfaceSiteId;
                console.log(subData)
                siteDataDetailList(subData).then(res => {
                    if (res) {
                        this.clientArr = res.data;
                        this.titleType = '护理站概况-->' + this.dataDetailListName;
                        if (res.data.length !== 0) {
                            this.clientTotal = res.data[0].totalCount;
                        }
                        this.clientDialog = true;
                    }
                })
            },
            /*老人点击begin*/
            jumpToCustomer(row) {
                if (this.dataDetailListName === '所有护理员' || this.dataDetailListName === '合约中护理员' || this.dataDetailListName === '新增护理员'
                    || this.dataDetailListName === '中止护理员' || this.dataDetailListName === '恢复护理员' || this.dataDetailListName === '终止护理员') {
                    this.loading = true;
                    this.fatStaForm();
                    this.staffId = row.id;
                    this.isadd = false;
                    let subData = "id=" + row.id;
                    getCare(subData).then(res => {
                        this.allLoad = false;
                        this.loading = false;
                        if (res) {
                            this.staForm = res.data['basicCaregiver'][0];
                            this.staForm.overdue = this.staForm.overdue.toString();
                            this.befUrl = res.data['basicCaregiver'][0].idCardFront;//身份证正面
                            this.bacUrl = res.data['basicCaregiver'][0].idCardNegative;//身份证反面
                            this.healthUrl = res.data['basicCaregiver'][0].healthCertificateFront;//健康证
                            this.medicalUrl = res.data['basicCaregiver'][0].sicknessCertificate;//医疗证
                            this.staffOpera = res.data['list'];
                            this.dynamicTags = res.data['label'];
                            this.clientData = res.data['associationCustomer'];
                        }
                    })
                    this.staffDetails = true
                } else {
                    this.customeContracttypesList = null
                    this.load = true;
                    this.fatForm();
                    if (row !== undefined) {
                        this.clientId = row.id;
                    }
                    if (row!=null){
                        this.selectEvaluationNursingId = row.evaluationNursingId
                    }

                    let subData = "id=" + this.clientId + '&evaluationNursingId=' + this.selectEvaluationNursingId;
                    this.isAdd = false;
                    var s = this
                    getCus(subData).then(res => {
                        this.allLoading = false;
                        this.load = false;

                        if (res) {
                            this.customeContracttypesList = res.data['customeContracttypesList']
                            for (let i = 0; i < this.customeContracttypesList.length; i++) {
                                if (this.customeContracttypesList[i].evaluationNursingId === this.selectEvaluationNursingId){
                                    this.contractTypeId = this.customeContracttypesList[i].contractTypeId
                                }
                            }
                            let timePeriod = res.data['basicInformation'][0].timePeriod;
                            if (timePeriod != null) {
                              this.timePeriodList = JSON.parse(timePeriod);
                              this.timePeriodList.sort(function (a, b) {
                                return b.week > a.week ? -1 : 1;
                              });

                            } else {
                              this.timePeriodList = []
                            }
                            let appointmentTimePeriod = res.data['basicInformation'][0].appointmentTimePeriod;
                            if (appointmentTimePeriod != null) {
                              this.appointmentTimePeriodList = JSON.parse(appointmentTimePeriod);
                              this.appointmentTimePeriodList.sort(function (a, b) {
                                return b.week > a.week ? -1 : 1;
                              });

                            } else {
                              this.appointmentTimePeriodList = []
                            }
                            var map = new BMap.Map("map");
                            var point = new BMap.Point(res.data['basicInformation'][0].longltude, res.data['basicInformation'][0].latltude);
                            map.centerAndZoom(point, 15);
                            var marker = new BMap.Marker(point); // 创建点
                            map.addOverlay(marker);

                            function showInfo(e) {
                                map.clearOverlays();
                                //改经纬度
                                var marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)); // 创建点
                                map.addOverlay(marker);//增加点
                                s.form.longltude = e.point.lng;
                                s.form.latltude = e.point.lat;
                            }

                            map.addEventListener("click", showInfo);

                            this.form = res.data['basicInformation'][0];
                            this.idBefUrl = res.data['basicInformation'][0].ldentlyCarPlc;
                            this.idBacUrl = res.data['basicInformation'][0].ldentlyCardBackPlc;
                            this.soBefUrl = res.data['basicInformation'][0].medlcareCardPlc;
                            this.soBacUrl = res.data['basicInformation'][0].medlcareCardPlcBack;
                            this.operaList = [];
                            for (let i = 0; i < res.data['list'].length; i++) {
                                this.operaList.push({
                                    value: res.data['list'][i].split(',')[0],
                                    state: res.data['list'][i].split(',')[1],
                                })
                            }
                            this.dynamicTags = res.data['label'];
                            if (res.data['basicInformation'][0].nursingCycle != null) {
                                this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
                            } else {
                                this.nursingCycle = [];
                            }
                            if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
                                this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
                            } else {
                                this.reservation_nursingCycle = [];
                            }
                            if (isEmpty(this.form.careWorker_id_one)) {
                                this.nurseDes(this.form.careWorker_id_one,this.form.contractInformationId, 1)
                            }
                            if (isEmpty(this.form.careWorker_id_two)) {
                                this.nurseDes(this.form.careWorker_id_two,this.form.contractInformationId, 2)
                            }
                            if (isEmpty(this.form.careWorker_id_three)) {
                                this.nurseDes(this.form.careWorker_id_three,this.form.contractInformationId, 3)
                            }
                            if (isEmpty(this.form.reservation_careWorker_id)) {
                                this.nurseDes(this.form.reservation_careWorker_id, this.form.reservationContractInformationId,4)
                            }
                            if (isEmpty(this.form.nursingStartTime) && isEmpty(this.form.nursingEndTime)) {
                                this.nursingTime = [];
                                this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingStartTime.split(':')[0], this.form.nursingStartTime.split(':')[1], this.form.nursingStartTime.split(':')[2]));
                                this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingEndTime.split(':')[0], this.form.nursingEndTime.split(':')[1], this.form.nursingEndTime.split(':')[2]));
                            }
                            if (isEmpty(this.form.reservation_nursingStartTime) && isEmpty(this.form.reservation_nursingEndtime)) {
                                this.nursingAppoTime = [];
                                this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingStartTime.split(':')[0], this.form.reservation_nursingStartTime.split(':')[1], this.form.reservation_nursingStartTime.split(':')[2]));
                                this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingEndtime.split(':')[0], this.form.reservation_nursingEndtime.split(':')[1], this.form.reservation_nursingEndtime.split(':')[2]));
                            }
                        }
                    })
                    this.clientDetails = true
                }
            },
            // 文件上传
            uploadFile() {
            },
            idBefChange(file) {
                this.idBefUrl = URL.createObjectURL(file.raw);
            },
            idBacChange(file) {
                this.idBacUrl = URL.createObjectURL(file.raw);
            },
            soBefChange(file) {
                this.soBefUrl = URL.createObjectURL(file.raw);
            },
            soBacChange(file) {
                this.soBacUrl = URL.createObjectURL(file.raw);
            },  befChange(file) {
                this.befUrl = URL.createObjectURL(file.raw);
            },
            bacChange(file) {
                this.bacUrl = URL.createObjectURL(file.raw);
            },
            healthChange(file) {
                this.healthUrl = URL.createObjectURL(file.raw);
            },
            medChange(file) {
                this.medicalUrl = URL.createObjectURL(file.raw);
            },
            //获取护理员名称
            nurseDes(id,contractInformationId,num) {
                let subData = "id=" + id + '&contractInformationId=' + contractInformationId;
                getCare(subData).then(res => {
                    if (res) {
                        let name = res.data['basicCaregiver'][0].name;
                        switch (num) {
                            case 1:
                                this.careWorker_one = name;
                                break;
                            case 2:
                                this.careWorker_two = name;
                                break;
                            case 3:
                                this.careWorker_three = name;
                                break;
                            case 4:
                                this.reservation_careWorker = name;
                                break;
                            default:
                                break;
                        }
                    }
                })
            },
            /**
             *选择护理员
             * */
            careInput(num) {
                this.careFlag = num;
                this.careDialog = true;
                this.carePage = 1;
                this.careForm.status = "";
                this.careForm.searchCondition = "";
                this.getCares();
            },
            fatForm() {
                this.nursingTime = "";
                this.nursingAppoTime = '';
                this.reservation_nursingCycle = [];//预约服务时间（周）
                this.nursingCycle = [];//护理周期
                this.careWorker_one = "";//指定护理员1
                this.careWorker_two = "";//指定护理员2
                this.careWorker_three = "";//指定护理员3
                this.reservation_careWorker = "";//预约指定护理员
                this.form = {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                }
            },
            /*老人点击end*/
            fatStaForm() {
                this.contractTime = "";
                this.staForm = {
                    name: "",//姓名
                    id: "",
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    emergencyContact: "",//紧急联系人
                    contactMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    recommend: "",//推荐渠道
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    // latltude:"",//纬度
                    // longltude:"",//经度
                    idCardFront: null,//身份证正面
                    idCardNegative: null,//身份证反面
                    healthCertificateFront: null,//健康证
                    sicknessCertificate: null,//医疗证

                    fullTime: "0",//是否兼职
                    overdue: "0",//是否逾期
                    /*暂时没加*/
                    intentServiceAddress: "",//意向服务地址
                    intentionLongltude: "",//意向地址经度
                    intentionLatltude: "",//意向地址纬度
                    contractStartDate: "",//合同开始日期
                    contractEndDate: "",//合同结束日期
                }
            },
            /*护理员详情end*/
        },

        created() {
            this.siteInterfaceDateSelect();
            this.customerServiceInformationTodayDataSelect();
            this.todayIsLiveDataSelect();
        },
        watch: {
            siteInterfaceSiteId: function () {
                this.siteInterfaceDateSelect()
            },
            customerServiceInformationTodaySiteId: function () {
                this.customerServiceInformationTodayDataSelect();
            },
            todayIsLiveDataSiteId: function () {
                this.todayIsLiveDataSelect();
                console.log(this.clientDialog)
                console.log(this.clientArr)
            },

        },

    }
</script>

<style scoped lang="scss">
    .client-list-two {
        font-size: 25px;
        height: 40px;
        width: 280px;
        text-align: center;

        .state {
            background: #409EFF;
            color: #FFFFFF;
        }

        .stateGreen {
            background: #7FFFAA;
            color: #696969;
        }

        .stateRed {
            background: #FF0000;
            color: #ffffff;
        }

        .stateMaroon {
            background: #800000;
            color: #ffffff;
        }

        .stateDarkRed {
            background: #DC143C;
            color: #696969;
        }

        .stateDarkgray {
            background: #C0C0C0;
            color: #696969;
        }

        .stateYellow {
            background: #F0E68C;
            color: #696969;
        }

        .lightSkyBlue {
            background: #87CEFA;
            color: #696969;
        }

    }

    .chart {
        width: 100%;
        height: 90%;
    }

    .el-main {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        width: 100%;
        height: 500px;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 70px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;
    }

    .input-box {
        margin-left: 5%;
    }

    .line {
        width: 300px;
    }

    .map {
        overflow: hidden;

    }

    .local {
        display: inline-block;
        margin-left: 20px;
    }


    /*老人详情begin*/
    .over-due {
        /* position: absolute;*/
        /* top:4px;*/
        font-size: 12px;
        color: #FFF;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: red;
        text-align: center;
        line-height: 20px;
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 70px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .local {
            display: inline-block;
            margin-left: 20px;
        }

    }

    .serve {
        padding: 20px 0 0 20px;
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 5%;
        }

        .line {
            width: 300px;
        }

    }

    .assess {
        padding: 20px 0 0 30px;

        .line {
            width: 260px;
        }

    }

    #map {
        width: 100%;
        height: 300px;
    }

    .adress {
        padding-left: 5%;

        .line {
            width: 260px;
        }

    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }

        }
    }

    /*上传图片*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .form .form-left {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }

    .form .form-right {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }

    /*老人详情end*/
</style>
