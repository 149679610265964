<template>
    <div class="client" v-loading="allLoad">
        <div class="client-left" v-if="isAssign">
            <div class="filter">
                <el-select v-model="nurStatus" class="sel line" @change="getNurses()">
                    <el-option label="全部状态" value=""></el-option>
                    <el-option label="合约中" value="合约中"></el-option>
                    <el-option label="中止中" value="中止中"></el-option>
                    <el-option label="已终止" value="已终止"></el-option>
                    <el-option label="待审核" value="待审核"></el-option>
                    <el-option label="已删除" value="已删除"></el-option>
                </el-select>
                <el-cascader
                        v-model="contractTypeAndSiteInquireValue"
                        :options="contractTypeAndSiteInquireValueOptions"
                        :props="{ expandTrigger: 'hover' }"
                        @change="siteChangeOperating()"
                        style="float: right;width: 200px"></el-cascader>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <br>
                <el-input v-model="nurText" class="inp line" placeholder="姓名、联系号码、地址" clearable
                          @keyup.enter.native="getNurses"></el-input>
                <el-button size="small" type="primary" @click="getNurses()">搜索</el-button>

            </div>
            <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1 && caregiverMoblle.caregiverList"></pageTemp>
            <div class="client-ul" v-if="caregiverMoblle.caregiverList">
                <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                              view-class="p20-scrollbar__view">
                    <div class="client-list" v-for="item in nurseArr" :key="item.index"
                         @click="nurseDes(item.id,item.contractInformationId,'staForm')" :class="{'active-class':item.id==staffId}">
                        <div class="flex number">
                            <p class="num">{{item.numbering}}</p>
                        </div>
                        <p>{{item.name}} ({{item.gender}}) | {{item.moblle}}</p>
                        <p>{{item.statusChangetime}}</p>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="client-right" v-loading="loading" :style="clientRight" v-if="caregiverMoblle.selectCaregiver">
            <div class="right-opare">
                <p>{{staForm.numbering}}</p>
                <div>
                    <el-button type="primary" @click="addButton('staForm')" v-show="!isadd" icon="el-icon-plus" circle
                               size="small" v-if="caregiverMoblle.insertCaregiver"></el-button>
                    <el-button type="primary" @click="changeSave('staForm')" v-show="!isadd" size="small"
                               icon="el-icon-check" v-if="caregiverMoblle.updateCaregiver">保存修改
                    </el-button>
                    <el-button type="primary" @click="logButton()" v-show="!isadd" size="small" v-if="caregiverMoblle.caregiverLogList">日志</el-button>
                    <el-button size="small" type="success" v-show="!isadd" @click="staffPythonExport()" v-if="caregiverMoblle.staffPythonExport">导出</el-button>
                    <el-button type="success" v-show="isadd" @click="addNurse('staForm')" size="small" v-if="caregiverMoblle.insertCaregiver">保存</el-button>

                </div>
            </div>
            <div class="right-center">
                <div class="center-one">
                    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                  view-class="p20-scrollbar__view">
                        <div class="tag" v-show="!isadd">
                            <el-tag
                                    :key="tag"
                                    v-for="tag in dynamicTags"
                                    closable
                                    :disable-transitions="false"
                                    @close="handleClose(tag)">
                                {{tag}}
                            </el-tag>
                            <el-input
                                    class="input-new-tag"
                                    v-if="inputVisible"
                                    v-model="inputValue"
                                    ref="saveTagInput"
                                    size="small"
                                    @keyup.enter.native="handleInputConfirm"
                                    @blur="handleInputConfirm"
                            >
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
                        </div>

                        <div class="des-box">
                            <el-form ref="staForm" :model="staForm" :rules="sRules" label-width="100px">
                                <div class="des assess">
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <el-form-item label="姓名" prop="name">
                                                <el-input v-model="staForm.name" placeholder="请输入护理员姓名"
                                                          class="line" style="width: 150px"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="7">
                                            <el-form-item label="手机号" prop="moblle">
                                                <el-input v-model="staForm.moblle" placeholder="请输入护理员手机号"
                                                          class="line" style="width: 150px"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="9">
                                            <el-form-item label="紧急联络电话">
                                                <el-input v-model="staForm.contactMoblle" placeholder="请输入护理员紧急联络电话"
                                                          class="line" style="width: 150px"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="9">
                                            <el-form-item label="身份证号" prop="identityCard">
                                                <el-input v-model="staForm.identityCard" placeholder="请输入护理员身份证号"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="4">
                                          <el-form-item label="年龄" prop="age">
                                            <el-input v-model="staForm.age" placeholder="请输入护理员年龄"></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="9">
                                          <el-form-item label="性别">
                                            <el-radio-group v-model="staForm.gender">
                                              <el-radio label="男">男</el-radio>
                                              <el-radio label="女">女</el-radio>
                                            </el-radio-group>
                                          </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                      <el-col :span="7">
                                        <el-form-item label="推荐类型">
                                          <el-select v-model="staForm.recommendedTypeId" placeholder="请选择推荐类型" @change="recommendedTypeChange()">
                                            <el-option
                                                v-for="item in recommendedTypes"
                                                :key="item.recommendedTypeId"
                                                :label="item.recommendedType"
                                                :value="item.recommendedTypeId"
                                            >
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                      </el-col>
                                      <el-col :span="12">
                                        <el-form-item label="推荐明细" v-if="!staForm.whetherTheCaregiverRecommends">
                                          <el-input v-model="staForm.recommendedDetails" placeholder="请输入推荐明细"
                                                    class="line" style="width: 350px;"></el-input>
                                        </el-form-item>
                                        <el-form-item label="推荐护理员" v-if="staForm.whetherTheCaregiverRecommends" style="width: 300px;">
                                          <el-input v-model="staForm.recommendedDetails" placeholder="请输入推荐护理员"
                                                    @focus="careInput(6)" style="width: 200px;"></el-input>
                                        </el-form-item>
                                      </el-col>
                                        <el-col :span="4">
                                            <el-form-item label="服务用户人数" v-if="!isadd">
                                                <el-input v-model="staForm.size" :disabled="true"
                                                          class="line2"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <el-form-item label="街道">
                                                <el-input v-model="staForm.sreet" class="line" style="width: 150px"
                                                          @focus="toAddress()"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="7">
                                            <el-form-item label="居委会">
                                                <el-input v-model="staForm.committees" class="line" style="width: 180px"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="9">
                                            <el-form-item label="详细地址">
                                                <el-input v-model="staForm.addressDetall" class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="4">
                                            <el-form-item label="民族">
                                                <el-input v-model="staForm.national" placeholder="请输入护理员民族"
                                                          class="line" style="width: 50px"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-form-item label="文化程度">
                                                <el-input v-model="staForm.levelOfEducation" placeholder="护理员文化程度"
                                                          class="line" style="width: 80px"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="7">
                                            <el-form-item label="体检日期">
                                                <el-date-picker
                                                        v-model="staForm.dateOfPhysicalExamination"
                                                        type="date"
                                                        placeholder="请选择"
                                                        style="width: 150px"
                                                >
                                                </el-date-picker>&nbsp;&nbsp;&nbsp;
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="9">
                                            <el-form-item label="健康证有效期">
                                                <el-date-picker
                                                        v-model="staForm.healthCertificateValidityPeriod"
                                                        type="date"
                                                        placeholder="请选择"
                                                        style="width: 150px"
                                                >
                                                </el-date-picker>&nbsp;&nbsp;&nbsp;
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="9" v-if="isadd">
                                            <el-form-item label="选择合约类型" >
                                                <el-cascader
                                                        v-model="contractTypeAndSiteInquireValue"
                                                        :options="contractTypeAndSiteInquireValueOptions"
                                                        :props="{ expandTrigger: 'hover' }"
                                                ></el-cascader>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="9">
                                            <el-form-item label="状态备注" prop="statusRemarks">
                                                <el-input v-model="staForm.statusRemarks"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <el-row :gutter="0"></el-row>
                                <el-row :gutter="0">
                                    <el-col :span="7">
                                        <el-form-item label="已签约:" v-if="signedDisplay">
                                            <el-select v-model="contractInformationId" @change="nurseDes(staffId)" v-show="!isAdd"
                                                       style="width: 150px">
                                                <el-option
                                                        v-for="item in caregiverContractInformationLists"
                                                        :key="item.index"
                                                        :label="item.caregiverContractType"
                                                        :value="item.contractInformationId">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="新增合约:" v-if="newSigning">
                                            <el-cascader
                                                    v-model="addCaregiverContractType"
                                                    :options="contractTypeAndSiteInquireValueOptions"
                                                    :props="{ expandTrigger: 'hover' }"
                                                    clearable
                                                    style="float: left;"></el-cascader>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item label="状态备注" prop="statusRemarks" v-if="newSigning">
                                            <el-input v-model="staForm.statusRemarks" class="line"></el-input>
                                        </el-form-item>

                                        <div class="client-list-two" v-show="!isAdd" v-if="signedDisplay">
                                            <div v-if="staForm.status === '待审核'" class="stateYellow">
                                                <span>该合约状态：{{staForm.status}}</span>
                                            </div>
                                            <div v-else-if="staForm.status === '中止中'" class="stateRed">
                                                <span>该合约状态：{{staForm.status}}</span>
                                            </div>
                                            <div v-else-if="staForm.status === '合约中'" class="stateGreen">
                                                <span>该合约状态：{{staForm.status}}</span>
                                            </div>
                                            <div v-else-if="staForm.status === '已终止'" class="stateRed">
                                                <span>该合约状态：{{staForm.status}}</span>
                                            </div>
                                            <div v-else-if="staForm.status === '已删除'" class="stateDarkgray">
                                                <span>该合约状态：{{staForm.status}}</span>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-button type="success" @click="addCaregiverContractTypeButton()" size="small" icon="el-icon-plus" v-if="signedDisplay && caregiverMoblle.updateCaregiver"
                                                   v-show="!isAdd">新增签约
                                        </el-button>

                                    </el-col>
                                    <el-col :span="6" v-if="caregiverMoblle.alterStatus">
                                        <el-dropdown v-show="!isAdd" v-if="signedDisplay" class="opera" @command="operaStaff">
                                            <el-button type="success" size="small">
                                                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-if="staffOpera.length===0" command="empty">暂无操作</el-dropdown-item>
                                                <el-dropdown-item v-else v-for="item in staffOpera" :key="item.index" :command="item">{{item}}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </el-col>
                                </el-row>
                                <div class="des assess">
                                    <el-row :gutter="0">
                                        <el-col :span="6">
                                            <el-form-item label="是否全职">
                                                <el-switch v-model="staForm.fullTime" active-value="1"
                                                           inactive-value="0"></el-switch>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="是否逾期">
                                                <el-switch v-model="staForm.overdue" active-value="1"
                                                           inactive-value="0"></el-switch>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="编号" prop="numbering">
                                                <el-input v-model="staForm.numbering" placeholder="请输入护理员编号"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="12">
                                            <el-form-item label="合同时间">
                                                <el-date-picker
                                                        v-model="staForm.contractStartDate"
                                                        type="date"
                                                        placeholder="合同开始时间"
                                                        style="width: 150px"
                                                >
                                                </el-date-picker>&nbsp;&nbsp;&nbsp;
                                                <el-date-picker
                                                        v-model="staForm.contractEndDate"
                                                        type="date"
                                                        placeholder="合同结束时间"
                                                        style="width: 150px"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                      <el-col :span="10">
                                        <el-button type="primary" @click="viewAllInsuranceInformation()" size="small"
                                                   v-show="!isAdd" v-if="caregiverMoblle.queryCaregiverInsuranceInformation">查看全部保险信息
                                        </el-button>
                                      </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="10">
                                            <el-form-item label="银行卡所属行" prop="bankCardName">
                                                <el-input v-model="staForm.bankCardName" placeholder="请输入银行卡所属行名"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="10">
                                            <el-form-item label="银行卡号" prop="bankCardNumber">
                                                <el-input v-model="staForm.bankCardNumber" placeholder="请输入银行卡号"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="10">
                                            <el-form-item label="保险公司名" prop="nameOfInsuranceCompany">
                                                <el-input v-model="staForm.nameOfInsuranceCompany"
                                                          placeholder="请输入保险公司名"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="10">
                                            <el-form-item label="保单号" prop="policyNumber">
                                                <el-input v-model="staForm.policyNumber" placeholder="请输入保单号"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                        <el-col :span="10">
                                            <el-form-item label="保单地址链接" prop="policyAddressLink">
                                                <el-input v-model="staForm.policyAddressLink" placeholder="请输入保单地址链接"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="10">
                                            <el-form-item label="合约链接" prop="policyNumber">
                                                <el-input v-model="staForm.contractLink" placeholder="请输入合约链接"
                                                          class="line"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                      <el-col :span="10">
                                        <el-form-item label="基础单价" prop="pol2icyAddressLink">
                                          <el-input v-model="staForm.baseWage" placeholder="默认基础单价40（元）" clearable style="width: 150px"></el-input>
                                        </el-form-item>
                                      </el-col>
                                      <el-col :span="10">
                                        <el-button type="primary" @click="modifyBaseMage(staForm.baseWage)" v-if="caregiverMoblle.modifyBaseMage">修改单价</el-button>
                                      </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                      <el-col :span="10">
                                        <el-form-item label="累计工龄" prop="pol2icyAddressLink">
                                          <el-input v-model="staForm.seniorityAccumulative" :disabled="true"  style="width: 80px;"></el-input>
                                        </el-form-item>
                                      </el-col>
                                      <el-col :span="10">
                                        <el-form-item label="工龄更新时间" prop="pol2icyAddressLink">
                                          <el-input v-model="staForm.seniorityUpdateTime" :disabled="true"  style="width: 200px"></el-input>
                                        </el-form-item>
                                      </el-col>
                                    </el-row>
                                    <el-row :gutter="0">
                                      <el-form-item label="工龄详情" prop="pol2icyAddressLink">
                                        <el-input type="textarea" v-model="staForm.seniorityDetails" :disabled="true"></el-input>
                                      </el-form-item>
                                    </el-row>
                                </div>
                                <div class="des card">
                                    <el-tabs v-model="activeName">
                                        <el-tab-pane label="身份证" name="first">
                                            <div class="flex img-box">
                                                <div>
                                                    <el-upload
                                                            class="avatar-uploader"
                                                            action="customize"
                                                            :show-file-list="false"
                                                            :http-request="uploadFile"
                                                            accept='.jpg,.png'
                                                            ref="befUpload"
                                                            :on-change="befChange"
                                                    >
                                                        <img v-if="befUrl" :src="befUrl" class="avatar">
                                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                    </el-upload>
                                                    <p>（身份证正面）</p>
                                                </div>
                                                <div style="margin-left: 30px">
                                                    <el-upload
                                                            class="avatar-uploader"
                                                            action="customize"
                                                            :show-file-list="false"
                                                            :http-request="uploadFile"
                                                            accept='.jpg,.png'
                                                            ref="bacUpload"
                                                            :on-change="bacChange"
                                                    >
                                                        <img v-if="bacUrl" :src="bacUrl" class="avatar">
                                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                    </el-upload>
                                                    <p>（身份证反面）</p>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="健康证" name="second">
                                            <div class="flex img-box">
                                                <div>
                                                    <el-upload
                                                            class="avatar-uploader"
                                                            action="customize"
                                                            :show-file-list="false"
                                                            :http-request="uploadFile"
                                                            accept='.jpg,.png'
                                                            ref="healthUpload"
                                                            :on-change="healthChange"
                                                    >
                                                        <img v-if="healthUrl" :src="healthUrl" class="avatar">
                                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                    </el-upload>
                                                    <p>（健康证）</p>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="医疗证" name="third">
                                            <div class="flex img-box">
                                                <div>
                                                    <el-upload
                                                            class="avatar-uploader"
                                                            action="customize"
                                                            :show-file-list="false"
                                                            :http-request="uploadFile"
                                                            accept='.jpg,.png'
                                                            ref="medUpload"
                                                            :on-change="medChange"
                                                    >
                                                        <img v-if="medicalUrl" :src="medicalUrl" class="avatar">
                                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                    </el-upload>
                                                    <p>（医疗证）</p>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="照片" name="fourth">
                                            <div class="flex img-box">
                                                <div>
                                                    <el-upload
                                                            class="avatar-uploader"
                                                            action="customize"
                                                            :show-file-list="false"
                                                            :http-request="uploadFile"
                                                            accept='.jpg,.png'
                                                            ref="photosUpload"
                                                            :on-change="photosChange"
                                                    >
                                                        <img v-if="photosUrl" :src="photosUrl" class="avatar">
                                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                    </el-upload>
                                                    <p>（照片）</p>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </el-form>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="center-two" v-show="!isadd">
                    <div class="center-two-button" v-if="caregiverMoblle.batchModificationConfirmation">
                        <el-row :gutter="20">
                            <el-col :span="12">
                            </el-col>
                            <el-col :span="12">
                                <el-dropdown @command="customerEditStatusInBulk">
                                    <el-button type="primary">
                                        批量修改状态<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="合约中--->中止中">合约中--->中止中</el-dropdown-item>
                                        <el-dropdown-item command="中止中--->合约中">中止中--->合约中</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="center-two-clientList" v-if="caregiverMoblle.customerBatchOperationQueryList">
                        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                      view-class="p20-scrollbar__view">
                            <div class="client-list" style="position: relative" v-for="item in clientData"
                                 :key="item.index"
                                 @click="jumpToCustomer(item)">
                                <div v-if="item.status === '有意向'" class="stateYellow"><span>{{item.status}}</span></div>
                                <div v-else-if="item.status === '待派单'" class="stateYellow"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '待申请'" class="stateYellow"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '待评估'" class="lightSkyBlue"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '中止中'" class="stateRed"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '长期中止'" class="stateMaroon"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '到期合约'" class="stateDarkgray"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '待确认'" class="stateYellow"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '合约中'" class="stateGreen"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '已终止'" class="stateRed"><span>{{item.status}}</span>
                                </div>
                                <div v-else-if="item.status === '已删除'" class="stateDarkgray"><span>{{item.status}}</span>
                                </div>
                                <div class="left-border"></div>
                                <div class="flex number">
                                    <p class="num">{{item.numbering}}</p>
                                </div>
                                <p>{{item.name}} ({{item.gender}}) | {{item.moblle}}</p>
                                <p>{{item.statusChangetime}}</p>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="员工日志" :visible.sync="staffLogDialog" width="80%">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="操作时间段">
                    <el-date-picker
                            v-model="formInline.logSTime"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>`
                </el-form-item>
                <el-form-item label="已签约:" v-if="signedDisplay">
                    <el-select v-model="contractInformationIdLog" @change="logSTimeSubmit" v-show="!isAdd"
                               style="width: 190px">
                        <el-option
                                v-for="item in caregiverContractInformationListsLog"
                                :key="item.index"
                                :label="item.caregiverContractType"
                                :value="item.contractInformationId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="logSTimeSubmit">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="logSData"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center'}"
                      max-height="700"
            >
                <el-table-column property="module" label="模块" width="80"></el-table-column>
                <el-table-column property="handlers" label="操作者" width="100"></el-table-column>
                <el-table-column property="name" label="被操作者姓名" width="110"></el-table-column>
                <el-table-column property="operatingTime" label="被操作日期" width="160"></el-table-column>
                <!--<el-table-column property="caregiverId" label="被操作者id" width="90"></el-table-column>-->
                <el-table-column property="result" label="操作结果" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column property="content" label="操作内容" width="180"></el-table-column>
                <el-table-column property="oerationNote" label="备注" width="180"></el-table-column>
            </el-table>
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="logSPage"
                    style="text-align:right;margin-top: 20px"
                    layout="total,prev, pager, next, jumper"
                    :page-size="10"
                    :total="logSTotal">
            </el-pagination>
        </el-dialog>
        <el-dialog title="操作" :visible.sync="operaDialog" width="400px">
            <el-form :model="operaForm" label-width="100px">
                <el-form-item label="状态更改">
                    <el-input v-model="operaForm.targetStatus" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="状态备注">
                    <el-input v-model="operaForm.statusRemarks"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="operaSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="地址" :visible.sync="addressDialog">
            <el-form :inline="true" :model="adressForm" class="demo-form-inline">
                <el-form-item label="区域">
                    <el-input v-model="adressForm.area" placeholder="请输入区域" @keyup.enter.native="getAddress"></el-input>
                </el-form-item>
                <el-form-item label="街道">
                    <el-input v-model="adressForm.sreet" placeholder="请输入街道"
                              @keyup.enter.native="getAddress"></el-input>
                </el-form-item>
                <el-form-item label="居委会">
                    <el-input v-model="adressForm.committees" placeholder="请输入居委会"
                              @keyup.enter.native="getAddress"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getAddress">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="addressData"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center','man-width':'100px'}"
                      max-height="600"
            >
                <el-table-column property="area" label="区域"></el-table-column>
                <el-table-column property="sreet" label="街道"></el-table-column>
                <el-table-column property="committees" label="居委会"></el-table-column>
                <el-table-column prop="operation" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="seleAddress(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @current-change="addressCurrentChange"
                    :current-page="addressPage"
                    :page-size="addressPSize"
                    style="text-align:right;margin-top: 20px"
                    layout="total, prev, pager, next, jumper"
                    :total="addressTotal">
            </el-pagination>
        </el-dialog>
        <!--用户详情-->
        <el-dialog title="用户详情" :visible.sync="clientDetails" width="80%">
        <div class="des-box">
          <el-form ref="form" :model="form" :rules="cRules" label-width="100px">
            <div class="des  assess">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" placeholder="请输入客户姓名" class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="手机号" prop="moblle">
                    <el-input v-model="form.moblle" placeholder="请输入客户手机号码" class="line"
                              style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="身份证号" prop="identityCard">
                    <el-input v-model="form.identityCard" placeholder="请输入客户身份证号"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="年龄" prop="age">
                    <el-input v-model="form.age" placeholder="请输入客户年龄" class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="家属姓名">
                    <el-input v-model="form.familyName" placeholder="请输入客户家属姓名"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="紧急联络电话">
                    <el-input v-model="form.familyMoblle" placeholder="请输入客户紧急联系方式"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">&nbsp;&nbsp;&nbsp;&nbsp;
                  <el-button type="primary" @click="viewOtherFamilyMembers()" size="small"
                             v-show="!isAdd">查看其它家属
                  </el-button>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="性别">
                    <el-radio-group v-model="form.gender">
                      <el-radio label="男">男</el-radio>
                      <el-radio label="女">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="银行卡所属行" prop="bankCardName">
                    <el-input v-model="form.bankCardName" placeholder="请输入银行卡所属行名"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="银行卡号" prop="bankCardNumber">
                    <el-input v-model="form.bankCardNumber" placeholder="请输入银行卡号"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="状态备注" prop="statusRemarks">
                    <el-input v-model="form.statusRemarks" class="line" style="width: 400px"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <br/>
            <el-row :gutter="0">
              <el-col :span="6">
                <el-form-item label="已签约:">
                  <el-select v-model="selectEvaluationNursingId" @change="jumpToCustomer()" v-show="!isAdd"
                             style="width: 150px">
                    <el-option
                        v-for="item in customeContracttypesList"
                        :key="item.index"
                        :label="item.customeContractType"
                        :value="item.evaluationNursingId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <div class="client-list-two">
                  <div v-if="form.status === '有意向'" class="stateYellow"><span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '待派单'" class="stateYellow">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '待申请'" class="stateYellow">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '待评估'" class="lightSkyBlue">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '中止中'" class="stateRed">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '长期中止'" class="stateMaroon">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '待确认'" class="stateYellow">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '合约中'" class="stateGreen">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '已终止'" class="stateRed">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '到期合约'" class="stateDarkgray">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                  <div v-else-if="form.status === '已删除'" class="stateDarkgray">
                    <span>该合约状态：{{form.status}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="des  assess">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="长护险平台是否录入" label-width="160px">
                    <el-switch v-model="form.enterPlan" active-value="1"
                               inactive-value="0"></el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="长护险平台是否接收" label-width="160px">
                    <el-switch v-model="form.enterPlanFlag" active-value="1"
                               inactive-value="0"></el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="医护平台是否派单" label-width="160px">
                    <el-switch v-model="form.mdicalPatform" active-value="1"
                               inactive-value="0"></el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="护理计划编码">
                    <el-input v-model="form.plannlngSheet" placeholder="请输入客户护理计划编码"
                              class="line"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="街道">
                    <el-input v-model="form.sreet" class="line" @focus="toAddress()" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="居委会" prop="committees">
                    <el-input v-model="form.committees" class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="详细地址">
                    <el-input v-model="form.addressDetall" class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="设备编号">
                    <el-input v-model="form.lbeaconMajor" placeholder="请输入设备编号" class="line"
                              learable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="评估有效期">
                    <el-date-picker
                        v-model="form.evaluationPeriod"
                        type="date"
                        style="width: 200px"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="首次护理时间">
                    <el-date-picker
                        v-model="form.firstCareTime"
                        type="date"
                        style="width: 200px"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="评估等级">
                    <el-input v-model="form.evaluationLervl" type="number" placeholder="请输入客户评估等级"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="医保卡类型">
                    <el-select v-model="form.medicareCardType" placeholder="请选择医保卡类型" class="line">
                      <el-option
                          v-for="item in medicareTypes"
                          :key="item"
                          :label="item"
                          :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="推荐类型">
                    <el-select v-model="form.recommendedTypeId" placeholder="请选择推荐类型">
                      <el-option
                          v-for="item in recommendedTypes"
                          :key="item.recommendedTypeId"
                          :label="item.recommendedType"
                          :value="item.recommendedTypeId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="推荐明细" v-if="!whetherTheCaregiverRecommends">
                    <el-input v-model="form.recommendedDetails" placeholder="请输入推荐明细"
                              class="line" style="width: 500px;"></el-input>
                  </el-form-item>
                  <el-form-item label="推荐护理员" v-if="whetherTheCaregiverRecommends" style="width: 300px;">
                    <el-input v-model="form.recommendedDetails" placeholder="请输入推荐明细"
                              @focus="careInput(5)" style="width: 200px;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="护理备注">
                    <el-input v-model="form.nursingRemarks" class="line" style="width: 800px;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="des  assess">

              <el-row :gutter="0">
                <el-col :span="8" style="width: 35%">
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定护理员一">
                        <el-input v-model="careWorker_one" placeholder="请选择指定护理员一" class="line"
                                  @focus="careInput(1)"></el-input>
                        <el-button type="danger" @click="deleteCareWorker_one()" v-show="!isAdd" size="mini"
                                   icon="el-icon-delete"
                                   circle></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定原因">
                        <el-input v-model="form.designationReason_one" placeholder="指定护理员一原因" class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定护理员二">
                        <el-input v-model="careWorker_two" placeholder="请选择指定护理员二" class="line"
                                  @focus="careInput(2)"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定原因">
                        <el-input v-model="form.designationReason_two" placeholder="指定护理员二原因"
                                  class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定护理员三">
                        <el-input v-model="careWorker_three" placeholder="请选择指定护理员三" class="line"
                                  @focus="careInput(3)"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定原因">
                        <el-input v-model="form.designationReason_three" placeholder="指定护理员三原因"
                                  class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="备用时间备注">
                        <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" style="width: 55%">
                  <div v-for="(item, index) in timePeriodList" v-bind:key="index">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-select v-model="item.week" placeholder="周期">
                          <el-option
                              v-for="item in serveWeek"
                              :key="item.week"
                              :label="item.name"
                              :value="item.week">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="护理时间">
                          <el-time-select
                              style="width: 120px"
                              placeholder="起始时间"
                              v-model="item.beginTime"
                              format="HH:mm"
                              :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                          </el-time-select>
                          <el-time-select
                              style="width: 120px"
                              placeholder="结束时间"
                              v-model="item.endTime" :disabled="true" class="line"
                              :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="5">
                        <el-select v-model="item.medicareStatusValue" placeholder="状态">
                          <el-option
                              v-for="item in medicareStatus"
                              :key="item.medicareStatusValue"
                              :label="item.label"
                              :value="item.medicareStatusValue">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="des  assess">
              <el-row :gutter="0">
                <el-col :span="8" style="width: 35%">
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="预约指定护理员" label-width="160px">
                        <el-input v-model="reservation_careWorker" placeholder="请选择预约指定护理员" class="line"
                                  @focus="careInput(4)" clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="预约指定原因" label-width="160px">
                        <el-input v-model="form.reservation_designationReason" placeholder="预约指定原因" style="width:200px"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="状态预约时间" prop="appointmentTime" v-if="!isAdd" label-width="160px">
                        <el-input v-model="form.appointmentTime" :disabled="true" style="width:200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="预约变更周期时间" prop="appointmentCycleChangeTime" v-if="!isAdd"
                                    label-width="160px">
                        <el-input v-model="form.appointmentCycleChangeTime" :disabled="true"
                                  style="width:200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" style="width: 55%">
                  <div v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-select v-model="item.week" :disabled="true" placeholder="周期">
                          <el-option
                              v-for="item in serveWeek"
                              :key="item.week"
                              :label="item.name"
                              :value="item.week">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="护理时间">
                          <el-time-select
                              style="width: 120px"
                              placeholder="起始时间"
                              v-model="item.beginTime" :disabled="true"
                              format="HH:mm"
                              :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                          </el-time-select>
                          <el-time-select
                              style="width: 120px"
                              placeholder="结束时间"
                              v-model="item.endTime" :disabled="true" class="line"
                              :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="5">
                        <el-select v-model="item.medicareStatusValue" :disabled="true" placeholder="状态">
                          <el-option
                              v-for="item in medicareStatus"
                              :key="item.medicareStatusValue"
                              :label="item.label"
                              :value="item.medicareStatusValue">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="des map">
              <div class="flex">
                <el-form-item label="纬度" label-width="50px">
                  <el-input v-model="form.latltude" placeholder="" class="line"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="经度" label-width="50px">
                  <el-input v-model="form.longltude" placeholder="" class="line"
                            clearable></el-input>
                </el-form-item>
                <!--<span class="local">纬度：{{form.latltude}}</span><span class="local">经度：{{form.longltude}}</span>-->
              </div>
              <div id="map"></div>
            </div>
            <div class="des card">
              <el-tabs v-model="activeName">
                <el-tab-pane label="身份证" name="first">
                  <div class="flex img-box">
                    <div>
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="idBefUpload"
                          :on-change="idBefChange"
                      >
                        <img v-if="idBefUrl" :src="idBefUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（身份证正面）</p>
                    </div>
                    <div style="margin-left: 30px">
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="idBacUpload"
                          :on-change="idBacChange"
                      >
                        <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（身份证反面）</p>
                    </div>
                  </div>
                </el-tab-pane>

                <el-tab-pane label="医保卡" name="second">
                  <div class="flex img-box">
                    <div>
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="soBefUpload"
                          :on-change="soBefChange"
                      >
                        <img v-if="soBefUrl" :src="soBefUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（社保卡正面）</p>
                    </div>
                    <div style="margin-left: 30px">
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="soBacUpload"
                          :on-change="soBacChange"
                      >
                        <img v-if="soBacUrl" :src="soBacUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（社保卡反面）</p>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-form>
        </div>

      </el-dialog>
        <el-dialog title="批量修改老人状态" :visible.sync="openBatchEditWindow" width="1000px" height="1500px">
            <p style="text-align: center; margin: 0 0 20px;font-size: 18px;color: cornflowerblue">{{batchOperation}}</p>
            <div style="text-align: center">
                <el-transfer
                        style="text-align: left; display: inline-block;"
                        v-model="value"
                        filterable
                        :left-default-checked="[2, 3]"
                        :right-default-checked="[1]"
                        :render-content="renderFunc"
                        :titles="['服务老人列表', '修改状态列表']"
                        :button-texts="['到左边', '到右边']"
                        :format="{
                            noChecked: '${total}',
                            hasChecked: '${checked}/${total}'
                          }"
                        @change="handleChange"
                        :data="customerEditStatusInBulkData">
                </el-transfer>
            </div>

            <div slot="footer" class="dialog-footer">
                <div style="text-align: left">
                    <el-form label-width="110px">
                        <el-form-item label="预约时间">
                            <el-date-picker
                                    v-model="batchModificationConfirmationAppointmentTime"
                                    type="date"
                                    placeholder="选择预约时间"
                                    style="width: 150px;">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input v-model="batchModificationConfirmationRemarks"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <el-button type="primary" @click="batchModificationConfirmation">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看家属信息" :visible.sync="familyInformationDialog" width="1200px">
            <el-table :data="familyInformation"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center','man-width':'100px'}"
                      max-height="700"
            >
                <el-table-column prop="familyName" label="家属姓名"></el-table-column>
                <el-table-column prop="familyMoblle" label="紧急联络电话"></el-table-column>
                <el-table-column prop="familyNotes" label="家属备注"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="查看全部保险信息" :visible.sync="caregiverInsuranceInformationDialog" width="1200px">
        <el-table :data="caregiverInsuranceInformations"
                  border
                  stripe
                  :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                  :cell-style="{'text-align':'center','man-width':'100px'}"
                  max-height="700"
        >
          <el-table-column prop="insuranceName" label="保险名"></el-table-column>
          <el-table-column prop="policyNumber" label="保单号"></el-table-column>
          <el-table-column prop="insuranceStartDate" label="保险开始日期"></el-table-column>
          <el-table-column prop="insuranceEndDate" label="保险结束日期"></el-table-column>
        </el-table>
      </el-dialog>
        <el-dialog title="护理员选择" :visible.sync="careDialog" width="800px">
        <el-form :inline="true" :model="careForm" class="demo-form-inline" label-width="100px">
          <el-form-item>
            <el-select v-model="careForm.status" class="sel line" @change="getCares()">
              <el-option label="全部状态" value=""></el-option>
              <el-option label="合约中" value="合约中"></el-option>
              <el-option label="中止中" value="中止中"></el-option>
              <el-option label="已终止" value="已终止"></el-option>
              <el-option label="待审核" value="待审核"></el-option>
              <el-option label="已删除" value="已删除"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="careForm.searchCondition" class="inp line" placeholder="关键字"
                      @keyup.enter.native="getCares()"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="getCares()">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="careData"
                  border
                  stripe
                  :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                  :cell-style="{'text-align':'center','man-width':'100px'}"
                  max-height="600"
        >
          <el-table-column property="moblle" label="手机号码"></el-table-column>
          <el-table-column property="name" label="姓名"></el-table-column>
          <el-table-column property="status" label="状态"></el-table-column>
          <el-table-column property="servingCustomerCount" label="排班时间">
            <template scope="scope">
            <span v-if="scope.row.servingCustomerCount > 8" style="color:red">{{
                scope.row.servingCustomerCount
              }}</span>
              <span v-else style="color: #37B328">{{ scope.row.servingCustomerCount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="seleCare(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @current-change="carePageChange"
            :current-page="carePage"
            style="text-align:right;margin-top: 20px"
            layout="total,prev, pager, next, jumper"
            :page-size="10"
            :total="careTotal">
        </el-pagination>
      </el-dialog>
    </div>
</template>

<script>
    import pageTemp from "@/components/pageTemp.vue";
    import {
        careList,
        getCare,
        addCare,
        changeCare,
        careOpera,
        addCareTag,
        deleCareTag,
        getCareLog,
        neighborhoodCommittee,
        staffPythonExport,
        getCus,
        customerBatchOperationQueryList,
        batchModificationConfirmation,
        viewOtherFamilyMembers,
        queryCaregiverInsuranceInformation,
        recommendedType,
        modifyBaseMage,
        siteChange
    } from "@/common/js/index.js";
    import {isEmpty, setTime, setDate} from "@/common/js/util.js";
    import BMap from 'BMap';

    export default {
        name: "staff",
        components: {pageTemp},
        data() {
            // eslint-disable-next-line no-unreachable
            return {
                whetherTheCaregiverRecommends:false,// 是否护理员推荐
                recommendedTypes: [],
                careFlag: 0,//第几个护理员
                careDialog: false,
                careName: "",
                careData: [],
                careForm: {
                  searchCondition: "",
                  status: "",
                },
                carePage: 0,
                careTotal: 0,
                timePeriodList: [], //时间周期
                appointmentTimePeriodList: [], //预约时间周期
                medicareStatus: [{
                  medicareStatusValue: '0',
                  label: '正常'
                }, {
                  medicareStatusValue: '2',
                  label: '非医保服务'
                }, {
                  medicareStatusValue: '4',
                  label: '医保虚拟服务'
                }],
                serveWeek: [
                  {name: "周一", week: '1'},
                  {name: "周二", week: '2'},
                  {name: "周三", week: '3'},
                  {name: "周四", week: '4'},
                  {name: "周五", week: '5'},
                  {name: "周六", week: '6'},
                  {name: "周日", week: '7'},
                ],
                renderFunc(h, option) {
                    return  <span>{option.key} - {option.label} </span>;
                 },
                siteId:'',// 护理员返回护理站id
                newSigning: false,
                signedDisplay: true,
                addCaregiverContractType:[],// 新增签约 选择合约类型及护理站
                contractInformationId: null,// 护理员合约信息id
                contractInformationIdLog: null,// 护理员合约信息id日志查询使用)
                caregiverContractInformationLists : [],//护理员已签约合约list
                caregiverContractInformationListsLog : [],//护理员已签约合约list（日志查询使用)
                contractTypeAndSiteInquireValue:[localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
                contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
                contractTypeId:'1',
                siteList: localStorage.getItem("siteList").split("$"),
                clientId: null,//客户Id
                familyInformationDialog: false,//查看家属信息
                familyInformation: [],
                selectEvaluationNursingId: null,// 合约信息id
                customeContracttypesList: [],// 老人 已签约 合约类型
                /*批量修改老人状态begin*/
                batchOperation: '',
                customerEditStatusInBulkData: [],
                value: [],
                value4: [],

                modifyData: '',
                batchModificationConfirmationAppointmentTime: '',
                batchModificationConfirmationRemarks: '',
                /*批量修改老人状态end*/

                isAdd: false,
                cRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                },
                /*用户begin*/
                idBefUrl: "",//身份证正面
                idBacUrl: "",//身份证反面
                soBefUrl: "",//社保卡正面
                soBacUrl: "",//社保卡反面
                form: {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    recommendedType: "",//推荐类型
                    recommendedDetails: "",//推荐明细
                    recommendedCaregiverId: "",//推荐护理员ID
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)1
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    statusRemarks: "", //状态备注
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                },
                medicareTypes: localStorage.getItem("medicareCardType").split("$"),
                checkList: [],
                reservation_nursingCycle: [],//意向服务时间（周）
                nursingCycle: [],//护理周期
                careWorker_one: "",//指定护理员1
                careWorker_two: "",//指定护理员2
                careWorker_three: "",//指定护理员3
                reservation_careWorker: "",//预约指定护理员
                nursingTime: "",//护理时间
                nursingAppoTime: "",//预约护理时间
                clientDetails: false,
                /*用户end*/
                adressForm: {
                    area: "",
                    sreet: "",
                    committees: ""
                },
                addressPage: 1,
                addressTotal: 0,
                addressPSize: 0,
                addressData: [],
                addressDialog: false,
                clientRight: {
                    width: ""
                },
                isAssign: false,
                clientData: [],//员工名下客户
                operaDialog: false,//操作弹窗
                openBatchEditWindow: false, //批量修改老人状态窗口
                operaForm: {
                    targetStatus: "",
                    statusRemarks: ""
                },
                formInline: {
                    logSTime: ""//日志时间筛选
                },
                siteValue: sessionStorage.getItem("siteValue"),
                allLoad: true,
                loading: false,
                nurStatus: "合约中",
                nurText: "",
                pages: 0,
                nurseArr: [],
                currPage: 1,
                isadd: false,
                staffOpera: [],
                contractTime: "",
                staForm: {
                    name: "",//姓名
                    id: "",
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    emergencyContact: "",//紧急联系人
                    contactMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    recommend: "",//推荐渠道
                    recommendedType: "",//推荐类型
                    recommendedDetails: "",//推荐明细
                    recommendedCaregiverId: "",//推荐护理员ID
                    whetherTheCaregiverRecommends:false,
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    // latltude:"",//纬度
                    // longltude:"",//经度
                    idCardFront: null,//身份证正面
                    idCardNegative: null,//身份证反面
                    healthCertificateFront: null,//健康证
                    sicknessCertificate: null,//医疗证
                    national:null,//民族
                    dateOfPhysicalExamination:"",//体检日期
                    healthCertificateValidityPeriod:"",//健康证有效期
                    levelOfEducation:null,//文化程度

                    fullTime: "0",//是否兼职
                    overdue: "0",//是否逾期
                    /*暂时没加*/
                    intentServiceAddress: "",//意向服务地址
                    intentionLongltude: "",//意向地址经度
                    intentionLatltude: "",//意向地址纬度
                    size: "",//护理员总数
                    contractStartDate: "",//合同开始时间
                    contractEndDate: "",//合同结束时间

                    bankCardNumber: "",// 银行卡号
                    bankCardName: "",// 银行卡名
                    policyNumber: "",// 护理员保单号
                    nameOfInsuranceCompany: "", //护理员保险公司名
                    policyAddressLink: "",// 保单地址链接
                    contractLink:"",// 合约链接
                    baseWage:null, // 护理员基础单价

                },
                sRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                },
                staffId: null,
                imageUrl: '',
                activeName: 'first',
                befUrl: "",//身份证正面
                bacUrl: "",//身份证反面
                healthUrl: "",//健康证
                medicalUrl: "",//医疗证
                photosUrl:"",//照片
                dynamicTags: [],//标签列表
                inputVisible: false,
                inputValue: '',
                /*日志*/
                staffLogDialog: false,
                logSPage: 1,
                logSData: [],
                logSTotal: 0,
                caregiverInsuranceInformations:[],
                caregiverInsuranceInformationDialog:false,
                caregiverMoblle:{
                  caregiverList:false,
                  selectCaregiver:false,
                  insertCaregiver:false,
                  updateCaregiver:false,
                  caregiverLogList:false,
                  alterStatus:false,
                  insertCaregiverLabel:false,
                  deleteCaregiverLabel:false,
                  staffPythonExport:false,
                  customerBatchOperationQueryList:false,
                  batchModificationConfirmation:false,
                  queryCaregiverInsuranceInformation:false,
                  modifyBaseMage:false
                },
            }
        },
        methods: {
          /**
           * 修嘎基础单价
           * */
          modifyBaseMage(baseWage) {
            let formData = 'baseWage=' +  baseWage + '&contractInformationId=' + this.contractInformationId + '&caregiverId=' + this.staForm.id
            modifyBaseMage(formData).then(res => {
              if (res) {
                this.$message.success('修改单价成功，如需查看工资，请在工资页面重新生产工资');
                this.nurseDes(this.staffId);
              }
            })

          },
          recommendedTypeChange() {
            if (this.staForm.recommendedTypeId === 1){
              this.staForm.whetherTheCaregiverRecommends = true;
            }else {
              this.staForm.whetherTheCaregiverRecommends = false;
            }
          },
          /**
           * 查询护理员保险信息
           */
          viewAllInsuranceInformation() {
            let subData = 'caregiverId=' +  this.staffId

            queryCaregiverInsuranceInformation(subData).then(res => {
              if (res) {
                this.caregiverInsuranceInformations = res.data
              }
            })
            this.caregiverInsuranceInformationDialog = true;
          },
            /**
             *护理员新增签约
             */
            addCaregiverContractTypeButton(){
                this.signedDisplay = false;
                this.staForm.fullTime = null;
                this.staForm.bankCardName = null;
                this.staForm.bankCardNumber = null;
                this.staForm.nameOfInsuranceCompany = null;
                this.staForm.policyNumber = null;
                this.staForm.policyAddressLink = null;
                this.staForm.contractStartDate = null;
                this.staForm.contractEndDate = null;
                this.staForm.contractLink = null;
                this.staForm.status = null;
                this.staForm.originalStatus = null;
                this.staForm.statusChangetime = null;
                this.staForm.statusRemarks = null;
                this.staForm.overdue = null;
                this.staForm.site_id = null;
                this.staForm.contractTypeId = null;
                this.staForm.numbering = null;
                this.staForm.dateOfPhysicalExamination = null;
                this.staForm.healthCertificateValidityPeriod = null;
                this.newSigning = true;
            },
            viewOtherFamilyMembers() {
                let subData = 'clientId=' + this.clientId
                viewOtherFamilyMembers(subData).then(res => {
                    if (res) {
                        this.familyInformation = res.data
                    }
                })
                this.familyInformationDialog = true;
            },
            batchModificationConfirmation() {
                if (this.batchModificationConfirmationAppointmentTime !== null) {
                    this.batchModificationConfirmationAppointmentTime = setDate(this.batchModificationConfirmationAppointmentTime);
                }
                let subData = "customerIds=" + this.modifyData + '&batchOperation=' + this.batchOperation +
                    '&siteList=' + this.siteId + '&remarks=' + this.batchModificationConfirmationRemarks + '&appointmentTime=' + this.batchModificationConfirmationAppointmentTime + '&contractTypeId=' + this.contractTypeId;

                console.log(subData)
                batchModificationConfirmation(subData).then(res => {
                    if (res) {
                        this.$message.success('批量修改老人状态成功');

                        this.value = [];
                        this.batchModificationConfirmationRemarks = '',
                            this.batchModificationConfirmationAppointmentTime = '',
                            this.nurseDes(this.staffId);
                        this.openBatchEditWindow = false;``

                    }
                })
            },
            /**
             * 批量修改老人状态---选择老人
             */
            handleChange(value) {
                //console.log(value, direction, movedKeys);
                this.modifyData = '';
                for (let i = 0; i < value.length; i++) {
                    console.log(value[i])
                    this.modifyData = this.modifyData + "," + value[i];

                }
                            console.log("this.modifyData"+this.modifyData)
            },
            /**
             * 批量修改护理员下老人状态
             * */
            customerEditStatusInBulk(command) {

                let subData = "caregiverId=" + sessionStorage.getItem('caregiverId') + '&batchOperation=' + command +
                    '&siteList=' + this.siteId + '&contractTypeId=' + this.contractTypeId;
                customerBatchOperationQueryList(subData).then(res => {
                    if (res) {
                        this.customerEditStatusInBulkData = res.data;
                    }
                })
                this.batchOperation = command;
                console.log(this.customerEditStatusInBulkData)
                this.openBatchEditWindow = true;

            },


            showDown() {
                this.clientDetails = false;
                this.nurseDes(this.staffId);
            },
            /*点击用户begin*/
            jumpToCustomer(item) {
                this.customeContracttypesList = null
                this.load = true;
                this.fatForm();
                if (item !== undefined && item !== 'undefined') {
                    this.clientId = item.id;
                }
                if (item!=null){
                    this.selectEvaluationNursingId = item.evaluationNursingId
                }

                let subData = "id=" + this.clientId + '&evaluationNursingId=' + this.selectEvaluationNursingId;


                this.isAdd = false;
                var s = this
                getCus(subData).then(res => {
                    this.allLoading = false;
                    this.load = false;

                      if (res) {
                          this.customeContracttypesList = res.data['customeContracttypesList']
                          for (let i = 0; i < this.customeContracttypesList.length; i++) {
                              if (this.customeContracttypesList[i].evaluationNursingId === this.selectEvaluationNursingId){
                              this.contractTypeId = this.customeContracttypesList[i].contractTypeId
                              }
                          }
                        let timePeriod = res.data['basicInformation'][0].timePeriod;
                        if (timePeriod != null) {
                          this.timePeriodList = JSON.parse(timePeriod);
                          this.timePeriodList.sort(function (a, b) {
                            return b.week > a.week ? -1 : 1;
                          });

                        } else {
                          this.timePeriodList = []
                        }
                        let appointmentTimePeriod = res.data['basicInformation'][0].appointmentTimePeriod;
                        if (appointmentTimePeriod != null) {
                          this.appointmentTimePeriodList = JSON.parse(appointmentTimePeriod);
                          this.appointmentTimePeriodList.sort(function (a, b) {
                            return b.week > a.week ? -1 : 1;
                          });

                        } else {
                          this.appointmentTimePeriodList = []
                        }
                        var map = new BMap.Map("map");

                        var point = new BMap.Point(res.data['basicInformation'][0].longltude, res.data['basicInformation'][0].latltude);
                        map.centerAndZoom(point, 15);
                        var marker = new BMap.Marker(point); // 创建点
                        map.addOverlay(marker);

                        function showInfo(e) {
                            map.clearOverlays();
                            //改经纬度
                            var marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)); // 创建点
                            map.addOverlay(marker);//增加点
                            s.form.longltude = e.point.lng;
                            s.form.latltude = e.point.lat;
                        }

                        map.addEventListener("click", showInfo);

                        this.form = res.data['basicInformation'][0];
                        if (this.form.recommendedTypeId === 1){
                          this.whetherTheCaregiverRecommends = true;
                        }else {
                          this.whetherTheCaregiverRecommends = false;
                        }
                        this.idBefUrl = res.data['basicInformation'][0].ldentlyCarPlc;
                        this.idBacUrl = res.data['basicInformation'][0].ldentlyCardBackPlc;
                        this.soBefUrl = res.data['basicInformation'][0].medlcareCardPlc;
                        this.soBacUrl = res.data['basicInformation'][0].medlcareCardPlcBack;
                        this.operaList = [];
                        for (let i = 0; i < res.data['list'].length; i++) {
                            this.operaList.push({
                                value: res.data['list'][i].split(',')[0],
                                state: res.data['list'][i].split(',')[1],
                            })
                        }
                        this.dynamicTags = res.data['label'];
                        if (res.data['basicInformation'][0].nursingCycle != null) {
                            this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
                        } else {
                            this.nursingCycle = [];
                        }
                        if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
                            this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
                        } else {
                            this.reservation_nursingCycle = [];
                        }
                        if (isEmpty(this.form.careWorker_id_one)) {
                            this.nurseDestwo(this.form.careWorker_id_one,this.form.contractInformationId, 1)
                        }
                        if (isEmpty(this.form.careWorker_id_two)) {
                            this.nurseDestwo(this.form.careWorker_id_two,this.form.contractInformationId, 2)
                        }
                        if (isEmpty(this.form.careWorker_id_three)) {
                            this.nurseDestwo(this.form.careWorker_id_three, this.form.contractInformationId,3)
                        }
                        if (isEmpty(this.form.reservation_careWorker_id)) {
                            this.nurseDestwo(this.form.reservation_careWorker_id, this.form.reservationContractInformationId,4)
                        }
                        if (isEmpty(this.form.nursingStartTime) && isEmpty(this.form.nursingEndTime)) {
                            this.nursingTime = [];
                            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingStartTime.split(':')[0], this.form.nursingStartTime.split(':')[1], this.form.nursingStartTime.split(':')[2]));
                            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingEndTime.split(':')[0], this.form.nursingEndTime.split(':')[1], this.form.nursingEndTime.split(':')[2]));
                        }
                        if (isEmpty(this.form.reservation_nursingStartTime) && isEmpty(this.form.reservation_nursingEndtime)) {
                            this.nursingAppoTime = [];
                            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingStartTime.split(':')[0], this.form.reservation_nursingStartTime.split(':')[1], this.form.reservation_nursingStartTime.split(':')[2]));
                            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingEndtime.split(':')[0], this.form.reservation_nursingEndtime.split(':')[1], this.form.reservation_nursingEndtime.split(':')[2]));
                        }
                    }
                })
                this.clientDetails = true;
            },
            idBefChange(file) {
                this.idBefUrl = URL.createObjectURL(file.raw);
            },
            idBacChange(file) {
                this.idBacUrl = URL.createObjectURL(file.raw);
            },
            soBefChange(file) {
                this.soBefUrl = URL.createObjectURL(file.raw);
            },
            soBacChange(file) {
                this.soBacUrl = URL.createObjectURL(file.raw);
            },
            befChange(file) {
                this.befUrl = URL.createObjectURL(file.raw);
            },
            bacChange(file) {
                this.bacUrl = URL.createObjectURL(file.raw);
            },
            healthChange(file) {
                this.healthUrl = URL.createObjectURL(file.raw);
            },
            medChange(file) {
                this.medicalUrl = URL.createObjectURL(file.raw);
            },
            photosChange(file) {
                this.photosUrl = URL.createObjectURL(file.raw);
            },
            //获取护理员名称
            nurseDestwo(id,contractInformationId, num) {
                let subData = "id=" + id  + '&contractInformationId=' + contractInformationId;
                getCare(subData).then(res => {
                    if (res) {
                        let name = res.data['basicCaregiver'][0].name;
                        switch (num) {
                            case 1:
                                this.careWorker_one = name;
                                break;
                            case 2:
                                this.careWorker_two = name;
                                break;
                            case 3:
                                this.careWorker_three = name;
                                break;
                            case 4:
                                this.reservation_careWorker = name;
                                break;
                            default:
                                break;
                        }
                    }
                })
            },
            /**
             *选择护理员
             * */
            careInput(num) {
                this.careFlag = num;
                this.carePage = 1;
                this.careForm.status = "";
                this.careForm.searchCondition = "";
                this.getCares();
                this.careDialog = true;
            },
            getCares() {
              let subData = "currPage=" + this.carePage + "&searchCondition=" + this.careForm.searchCondition + "&status=" + this.careForm.status
                  + "&siteList=" + this.staForm.siteId
                  + '&contractTypeId=' + this.staForm.contractTypeId
              careList(subData).then(res => {
                if (res) {
                  this.careData = res.data;
                  this.careTotal = res.data[0].totalCount;
                }
              })
            },
            carePageChange(val) {
              this.carePage = val;
              this.getCares();
            },
            //选择护理员
            seleCare(row) {
              this.careDialog = false;
              switch (this.careFlag) {
                case 5:
                  this.form.recommendedDetails = row.name;
                  this.form.recommendedCaregiverId = row.id;
                  break;
                case 6:
                  this.staForm.recommendedDetails = row.name;
                  this.staForm.recommendedCaregiverId = row.id;
                  break;
                default:
                  break;
              }

            },
            fatForm() {
                this.nursingTime = "";
                this.nursingAppoTime = '';
                this.reservation_nursingCycle = [];//预约服务时间（周）
                this.nursingCycle = [];//护理周期
                this.careWorker_one = "";//指定护理员1
                this.careWorker_two = "";//指定护理员2
                this.careWorker_three = "";//指定护理员3
                this.reservation_careWorker = "";//预约指定护理员
                this.form = {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                }
            },
            /*点击用户end*/

            /*护理员基本信息批量导出*/
            staffPythonExport() {
                this.$confirm('生成护理员信息中,请等待,稍后去报表中查看下载', '确定导出', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let subData = "searchCondition=" + this.nurText + "&status=" + this.nurStatus
                        + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                        + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                    staffPythonExport(subData).then(res => {
                        if (res) {
                            this.tableData = res.data
                            for (let i = 0; i < this.tableData.length; i++) {
                                if (this.tableData[i].date != null && this.tableData[i].date != '') {
                                    this.tableData[i].date = this.changeTime(this.tableData[i].date)
                                }
                            }
                            this.change()//改变0/1为已完成/未完成模式
                            this.$message.success('护理员信息导出成功,请到报表中进行查看下载');
                            // console.log('export',this.tableData)
                        }
                    })

                }).catch(() => {
                });
            },
            toClient(id) {
                this.$router.push({path: '/assignClient', query: {id: id}})
            },
            /**
             * 地址栏输入用户id 提取
             * */
            submit() {
                var newurl = this.updateParam(window.location.href, 'staffId', this.input);
                //向当前url添加参数，没有历史记录
                window.history.replaceState({
                    path: newurl
                }, '', newurl);
            },
            updateParam(uri, key, value) {
                if (!value) {
                    return uri;
                }
                var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
                var separator = uri.indexOf('?') !== -1 ? "&" : "?";
                if (uri.match(re)) {
                    return uri.replace(re, '$1' + key + "=" + value + '$2');
                } else {
                    return uri + separator + key + "=" + value;
                }
            },
            /**
             * 选择地址
             * */
            toAddress() {
                this.adressForm = {
                    area: "",
                    sreet: "",
                    committees: ""
                };
                this.getAddress();
            },
            seleAddress(row) {
                this.addressDialog = false;
                this.staForm.sreet = row.sreet;
                this.staForm.committees = row.committees;
            },
            getAddress() {
                let data = 'currPage=' + this.addressPage + '&pageSizePara=' + "10" + "&area=" + this.adressForm.area + "&sreet=" + this.adressForm.sreet + "&committees=" + this.adressForm.committees;
                neighborhoodCommittee(data).then(res => {
                    if (res) {
                        this.addressDialog = true;
                        this.addressData = res.data;
                        this.addressPSize = res.data[0].totalPage;
                        this.addressTotal = res.data[0].totalCount;
                    }
                });
            },
            addressCurrentChange(val) {
                this.addressPage = val;
                this.getAddress();
            },
            /**
             *日志
             * */
            logSTimeSubmit() {
                this.logSPage = 1;
                this.logSRequest();
            },
            logSRequest() {
                let subData = "";
                if (isEmpty(this.formInline.logSTime)) {
                    subData = "caregiverId=" + this.staffId + "&currPage=" + this.logSPage
                        + "&beginCreateTime=" + setTime(this.formInline.logSTime[0]) + "&endCreateTime=" + setTime(this.formInline.logSTime[1])+"&contractInformationId="+this.contractInformationIdLog;
                } else {
                    subData = "caregiverId=" + this.staffId + "&currPage=" + this.logSPage +"&contractInformationId="+this.contractInformationIdLog;
                }
                getCareLog(subData).then(res => {
                    if (res) {
                        this.staffLogDialog = true;
                        this.logSData = res.data;
                        if (res.data.length == 0) {
                            this.logSTotal = 0
                        } else {
                            this.logSTotal = res.data[0].totalCount;
                        }
                    }
                })
            },
            logButton() {
                this.formInline.logSTime = "";
                this.logSRequest();
            },
            handleCurrentChange(val) {
                this.logSPage = val;
                this.logSRequest();
            },
            addButton(formName) {
                this.$refs[formName].resetFields();
                this.isadd = true;
                this.newSigning = false;
                this.signedDisplay = false;
                this.fatStaForm();
                this.staffId = null;
                this.befUrl = "";//身份证正面
                this.bacUrl = "";//身份证反面
                this.healthUrl = "";//健康证
                this.medicalUrl = "";//医疗证
                this.photosUrl = "";// 照片
                this.dynamicTags = [];//标签列表
            },
            /**
             * 标签
             * */
            handleClose(tag) {
                let subData = {
                    id: this.staffId,
                    label: tag,
                    siteList: this.siteId
                }
                deleCareTag(subData).then(res => {
                    if (res) {
                        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
                    }
                })
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    let subData = {
                        id: this.staffId,
                        label: inputValue,
                        siteList: this.siteId
                    }
                    addCareTag(subData).then(res => {
                        if (res) {
                            this.$message.success('添加标签成功');
                            this.dynamicTags.push(inputValue);
                        }
                    })
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            /**
             * 分页
             * */
            goSpecifiedPage(val) {
                this.currPage = val;
                this.getNurses();
            },
            /**
             * 护理员列表
             * */
            getNurses() {
                let subData = "currPage=" + this.currPage + "&searchCondition=" + this.nurText + "&status=" + this.nurStatus
                            + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                            + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);
                careList(subData).then(res => {
                    if (res) {
                        this.nurseArr = res.data;
                        if (res.data.length!==0){
                          this.pages = res.data[0].totalCount;
                        }
                    }
                })
            },
            /**
             * 新增护理员
             * */
            addNurse(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = this.getformData();
                        formData.append("status", "待审核");//姓名
                        addCare(formData).then(res => {
                            if (res) {
                                this.$message.success('新增护理员成功');
                                this.getNurses();
                                this.isadd = false;
                                this.staffId = res.data.id;
                                this.nurseDes(this.staffId)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            /**
             * 操作护理员
             * */
            operaStaff(command) {
                if (command == 'empty') {
                    this.operaDialog = false;
                } else {
                    this.operaForm.statusRemarks = "";
                    this.operaDialog = true;
                    this.operaForm.targetStatus = command;
                }
            },
            operaSubmit() {
                let formData = new FormData();
                // 查看老人详情中选择合约类型id
                formData.append("contractTypeId", this.contractTypeId);//合约类型
                var siteList = '';
                for (var i = 0; i < this.caregiverContractInformationLists.length; i++) {
                    if (this.contractTypeId.toString() === this.caregiverContractInformationLists[i].contractTypeId.toString()){
                        siteList = this.caregiverContractInformationLists[i].siteId
                    }
                }
                if (siteList === '1') {
                    formData.append("siteList", '1,巾帼站');
                } else if (siteList === '2') {
                    formData.append("siteList", '2,建浦站');
                } else if (siteList === '3') {
                    formData.append("siteList", '3,安康通');
                } else if (siteList === '4') {
                    formData.append("siteList", '4,震瀛二站');
                } else if (siteList === '5') {
                    formData.append("siteList", '5,震瀛站');
                } else if (siteList === '6') {
                  formData.append("siteList", '6,优蓓杰');
                } else if (siteList === '7') {
                  formData.append("siteList", '7,雨花站');
                }
                formData.append("contractInformationId", this.contractInformationId);
                formData.append("id", this.staffId);
                formData.append("statusRemarks", this.operaForm.statusRemarks);//现在的状态
                formData.append("targetStatus", this.operaForm.targetStatus);//目标状态
                careOpera(formData).then(res => {
                    this.operaDialog = false;
                    this.$message.success('操作成功');
                    this.getNurses();
                    this.nurseDes(this.staffId);

                })
            },

            /**
             * 修改护理员信息
             * */
            changeSave(formName) {
                this.loading = true;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = this.getformData();
                        changeCare(formData).then(res => {
                            if (res) {
                                this.$message.success('修改护理员成功');
                                this.$refs.befUpload.uploadFiles = [];
                                this.$refs.bacUpload.uploadFiles = [];
                                this.$refs.healthUpload.uploadFiles = [];
                                this.$refs.medUpload.uploadFiles = [];
                                this.$refs.photosUpload.uploadFiles = [];
                                this.nurseDes(this.staffId);
                            }
                        })
                    } else {
                        return false;
                    }
                });

            },
            /**
             * 护理员详情
             * */
            nurseDes(id,contractInformationId ,formName) {
                this.customeContracttypesList = null
                this.clientData  = null
                this.signedDisplay = true;
                this.newSigning = false;
                // 存储护理员id
                sessionStorage.setItem('caregiverId', id);
                this.loading = true;
                if (formName) {
                    this.$refs[formName].resetFields();
                }
                this.fatStaForm();
                this.staffId = id;
                this.isadd = false;
                if (contractInformationId!=null){
                    this.contractInformationId = contractInformationId;
                    this.contractInformationIdLog = contractInformationId;
                }
                let subData = 'id=' + id  + '&contractInformationId=' + this.contractInformationId;
                getCare(subData).then(res => {
                    this.allLoad = false;
                    this.loading = false;
                    if (res) {
                        this.caregiverContractInformationLists = res.data['caregiverContractInformationLists']
                        this.caregiverContractInformationListsLog = res.data['caregiverContractInformationLists']
                        this.staForm = res.data['basicCaregiver'][0];
                        if (this.staForm.recommendedTypeId === 1){
                          this.staForm.whetherTheCaregiverRecommends = true;
                        }else {
                          this.staForm.whetherTheCaregiverRecommends = false;
                        }
                        this.contractTypeId = res.data['basicCaregiver'][0].contractTypeId
                        this.siteId = res.data['basicCaregiver'][0].siteId
                        if (this.staForm.overdue!==null){
                            this.staForm.overdue = this.staForm.overdue.toString();
                        }
                        this.befUrl = res.data['basicCaregiver'][0].idCardFront;//身份证正面
                        this.bacUrl = res.data['basicCaregiver'][0].idCardNegative;//身份证反面
                        this.healthUrl = res.data['basicCaregiver'][0].healthCertificateFront;//健康证
                        this.medicalUrl = res.data['basicCaregiver'][0].sicknessCertificate;//医疗证
                        this.photosUrl = res.data['basicCaregiver'][0].photos;//医疗证
                        this.staffOpera = res.data['list'];
                        console.log(this.staffOpera)
                        this.dynamicTags = res.data['label'];
                        this.clientData = res.data['associationCustomer'];
                    }
                })
            },
            /**
             * 提交的数据
             * */
            getformData() {
                let formData = new FormData();
                if (!this.isadd) {
                    formData.append("id", this.staForm.id);//id
                    if (this.newSigning) {
                        // 护理员新增合约类型选择合约类型id
                        formData.append("contractTypeId", this.addCaregiverContractType.toString().charAt(2));//合约类型
                        formData.append("siteList", this.addCaregiverContractType.toString().charAt(0));//合约类型
                        console.log(" 护理员新增合约类型选择合约类型id")
                    }else {
                        formData.append("contractInformationId", this.contractInformationId);//合约信息
                        // 查看护理员详情中选择合约类型id
                        formData.append("contractTypeId", this.contractTypeId);//合约类型
                        var siteList = '';
                        for (var i = 0; i < this.caregiverContractInformationLists.length; i++) {
                            if (this.contractTypeId.toString() === this.caregiverContractInformationLists[i].contractTypeId.toString()){
                                siteList = this.caregiverContractInformationLists[i].siteId
                            }
                        }
                        formData.append("siteList", siteList);
                        console.log(" 修改护理员基本信息 siteList="+siteList)
                    }
                }else {
                    var str = this.contractTypeAndSiteInquireValue.toString();
                    // 新增护理员选择合约类型id
                    formData.append("contractTypeId", str.charAt(2));
                    if (str.charAt(0) === '1') {
                        formData.append("siteList", '1,巾帼站');
                    } else if (str.charAt(0) === '2') {
                        formData.append("siteList", '2,建浦站');
                    } else if (str.charAt(0) === '3') {
                        formData.append("siteList", '3,安康通');
                    } else if (str.charAt(0) === '4') {
                        formData.append("siteList", '4,震瀛二站');
                    } else if (str.charAt(0) === '5') {
                        formData.append("siteList", '5,震瀛站');
                    } else if (str.charAt(0) === '6') {
                      formData.append("siteList", '6,优蓓杰');
                    } else if (str.charAt(0) === '7') {
                      formData.append("siteList", '7,雨花站');
                    }

                    console.log(" 新增护理员时选择合约类型id")
                }
                isEmpty(this.staForm.name) && formData.append("name", this.staForm.name);//姓名
                isEmpty(this.staForm.overdue) && formData.append("overdue", this.staForm.overdue);//是否逾期
                isEmpty(this.staForm.moblle) && formData.append("moblle", this.staForm.moblle);//电话
                isEmpty(this.staForm.identityCard) && formData.append("identityCard", this.staForm.identityCard);//身份证号
                isEmpty(this.staForm.age) && formData.append("age", this.staForm.age);//年龄
                isEmpty(this.staForm.sreet) && formData.append("sreet", this.staForm.sreet);//街道
                isEmpty(this.staForm.committees) && formData.append("committees", this.staForm.committees);//居委会
                isEmpty(this.staForm.addressDetall) && formData.append("addressDetall", this.staForm.addressDetall);//详细地址
                isEmpty(this.staForm.gender) && formData.append("gender", this.staForm.gender);//性别
                isEmpty(this.staForm.numbering) && formData.append("numbering", this.staForm.numbering);//编号
                isEmpty(this.staForm.emergencyContact) && formData.append("emergencyContact", this.staForm.emergencyContact);//家人姓名
                isEmpty(this.staForm.contactMoblle) && formData.append("contactMoblle", this.staForm.contactMoblle);//紧急电话
                isEmpty(this.staForm.recommend) && formData.append("recommend", this.staForm.recommend);//推荐渠道
                isEmpty(this.staForm.recommendedTypeId) && formData.append("recommendedTypeId", this.staForm.recommendedTypeId);//推荐渠道
                isEmpty(this.staForm.recommendedDetails) && formData.append("recommendedDetails", this.staForm.recommendedDetails);//推荐渠道
                isEmpty(this.staForm.recommendedCaregiverId) && formData.append("recommendedCaregiverId", this.staForm.recommendedCaregiverId);//推荐渠道
                isEmpty(this.staForm.national) && formData.append("national", this.staForm.national);//民族
                isEmpty(this.staForm.levelOfEducation) && formData.append("levelOfEducation", this.staForm.levelOfEducation);//文化程度
                isEmpty(this.staForm.dateOfPhysicalExamination) && formData.append("dateOfPhysicalExamination", setDate(this.staForm.dateOfPhysicalExamination));//体检日期
                isEmpty(this.staForm.healthCertificateValidityPeriod) && formData.append("healthCertificateValidityPeriod", setDate(this.staForm.healthCertificateValidityPeriod));//体检日期
                isEmpty(this.staForm.fullTime) && formData.append("fullTime", this.staForm.fullTime);//是否全职
                isEmpty(this.staForm.intentServiceAddress) && formData.append("intentServiceAddress", this.staForm.intentServiceAddress)//意向服务地址
                isEmpty(this.staForm.statusRemarks) && formData.append("statusRemarks", this.staForm.statusRemarks);//状态备注
                isEmpty(this.staForm.contractStartDate) && formData.append("contractStartDate", setDate(this.staForm.contractStartDate));//合同开始日期
                isEmpty(this.staForm.contractEndDate) && formData.append("contractEndDate", setDate(this.staForm.contractEndDate));//合同结束日期

                isEmpty(this.staForm.bankCardNumber) && formData.append("bankCardNumber", this.staForm.bankCardNumber);//银行卡号
                isEmpty(this.staForm.bankCardName) && formData.append("bankCardName", this.staForm.bankCardName);//银行卡名
                isEmpty(this.staForm.policyNumber) && formData.append("policyNumber", this.staForm.policyNumber);//保单号
                isEmpty(this.staForm.nameOfInsuranceCompany) && formData.append("nameOfInsuranceCompany", this.staForm.nameOfInsuranceCompany);//保险公司名
                isEmpty(this.staForm.policyAddressLink) && formData.append("policyAddressLink", this.staForm.policyAddressLink);//保单地址链接
                isEmpty(this.staForm.contractLink) && formData.append("contractLink", this.staForm.contractLink);//合约链接
                let befFiles = this.$refs.befUpload.uploadFiles;
                let bacFiles = this.$refs.bacUpload.uploadFiles;
                let healthFiles = this.$refs.healthUpload.uploadFiles;
                let medFiles = this.$refs.medUpload.uploadFiles;
                let photosFiles = this.$refs.photosUpload.uploadFiles;
                if (befFiles !== null && befFiles.length !== 0) {//身份证正面
                    this.staForm.idCardFront = befFiles[befFiles.length - 1].raw;
                    formData.append("idCardFrontFile", this.staForm.idCardFront);
                }
                if (bacFiles !== null && bacFiles.length !== 0) {//身份证反面
                    this.staForm.idCardNegative = bacFiles[bacFiles.length - 1].raw;
                    formData.append("idCardNegativeFile", this.staForm.idCardNegative);
                }
                if (healthFiles !== null && healthFiles.length !== 0) {//健康证
                    this.staForm.healthCertificateFront = healthFiles[healthFiles.length - 1].raw;
                    formData.append("healthCertificateFrontFile", this.staForm.healthCertificateFront);
                }
                if (medFiles !== null && medFiles.length !== 0) {//医疗证
                    this.staForm.sicknessCertificate = medFiles[medFiles.length - 1].raw;
                    formData.append("sicknessCertificateFile", this.staForm.sicknessCertificate);
                }
                if (photosFiles !== null && photosFiles.length !== 0) {//医疗证
                    this.staForm.sicknessCertificate = photosFiles[photosFiles.length - 1].raw;
                    formData.append("photosFile", this.staForm.sicknessCertificate);
                }
                return formData;
            },
            // 文件上传
            uploadFile() {
            },
            fatStaForm() {
                this.contractTime = "";
                this.staForm = {
                    name: "",//姓名
                    id: "",
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    emergencyContact: "",//紧急联系人
                    contactMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    recommend: "",//推荐渠道
                    recommendedTypeId: "",//推荐类型
                    recommendedDetails: "",//推荐明细
                    recommendedCaregiverId: "",//推荐护理员ID
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    // latltude:"",//纬度
                    // longltude:"",//经度
                    idCardFront: null,//身份证正面
                    idCardNegative: null,//身份证反面
                    healthCertificateFront: null,//健康证
                    sicknessCertificate: null,//医疗证
                    national:"",//民族
                    levelOfEducation:"",//文化程度
                    dateOfPhysicalExamination:"",//体检日期
                    healthCertificateValidityPeriod:"",//健康证有效期
                    fullTime: "0",//是否兼职
                    overdue: "0",//是否逾期
                    /*暂时没加*/
                    intentServiceAddress: "",//意向服务地址
                    intentionLongltude: "",//意向地址经度
                    intentionLatltude: "",//意向地址纬度
                    contractStartDate: "",//合同开始日期
                    contractEndDate: "",//合同结束日期

                    bankCardNumber: "",// 银行卡号
                    bankCardName: "",// 银行卡名
                    policyNumber: "",// 护理员保单号
                    nameOfInsuranceCompany: "", //护理员保险公司名
                    policyAddressLink: "",// 保单地址链接

                }
            },
            refresh() {

              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.caregiverMoblle.caregiverList = this.isExist(permissionList,"caregiver:caregiverList");
                  this.caregiverMoblle.selectCaregiver = this.isExist(permissionList,"caregiver:selectCaregiver");
                  this.caregiverMoblle.insertCaregiver = this.isExist(permissionList,"caregiver:insertCaregiver");
                  this.caregiverMoblle.updateCaregiver = this.isExist(permissionList,"caregiver:updateCaregiver");
                  this.caregiverMoblle.caregiverLogList = this.isExist(permissionList,"caregiver:caregiverLogList");
                  this.caregiverMoblle.alterStatus = this.isExist(permissionList,"caregiver:alterStatus");
                  this.caregiverMoblle.insertCaregiverLabel = this.isExist(permissionList,"caregiver:userList");
                  this.caregiverMoblle.deleteCaregiverLabel = this.isExist(permissionList,"caregiver:deleteCaregiverLabel");
                  this.caregiverMoblle.staffPythonExport = this.isExist(permissionList,"caregiver:staffPythonExport");
                  this.caregiverMoblle.customerBatchOperationQueryList = this.isExist(permissionList,"caregiver:customerBatchOperationQueryList");
                  this.caregiverMoblle.batchModificationConfirmation = this.isExist(permissionList,"caregiver:batchModificationConfirmation");
                  this.caregiverMoblle.queryCaregiverInsuranceInformation = this.isExist(permissionList,"caregiver:queryCaregiverInsuranceInformation");
                  this.caregiverMoblle.modifyBaseMage = this.isExist(permissionList,"wage:modifyBaseMage");

                  recommendedType().then(res => {
                    if(res) {
                      this.recommendedTypes = res.data
                    }
                  })
                  var str = "staff"
                  var staffId = "";
                  if (this.staffId != '' && this.staffId != null && this.staffId.search(str) == -1) {
                    staffId = this.staffId
                  }
                  console.log(this.contractTypeAndSiteInquireValue)
                  let subData = "currPage=" + this.currPage + "&searchCondition=" + this.nurText + "&status=" + this.nurStatus
                      + "&staffId=" + staffId  + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
                      + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(2);


                  careList(subData).then(res => {
                    if (res) {
                      this.nurseArr = res.data;
                      if (res.data.length!==0){
                        this.staffId = res.data[0].id;
                        this.contractInformationId = res.data[0].contractInformationId
                        this.pages = res.data[0].totalCount;
                        this.nurseDes(this.staffId, this.contractInformationId);
                      }
                      this.allLoad = false;

                    }
                  });
                }
              })
            },
            siteChangeOperating(){
              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.caregiverMoblle.caregiverList = this.isExist(permissionList,"caregiver:caregiverList");
                  this.caregiverMoblle.selectCaregiver = this.isExist(permissionList,"caregiver:selectCaregiver");
                  this.caregiverMoblle.insertCaregiver = this.isExist(permissionList,"caregiver:insertCaregiver");
                  this.caregiverMoblle.updateCaregiver = this.isExist(permissionList,"caregiver:updateCaregiver");
                  this.caregiverMoblle.caregiverLogList = this.isExist(permissionList,"caregiver:caregiverLogList");
                  this.caregiverMoblle.alterStatus = this.isExist(permissionList,"caregiver:alterStatus");
                  this.caregiverMoblle.insertCaregiverLabel = this.isExist(permissionList,"caregiver:userList");
                  this.caregiverMoblle.deleteCaregiverLabel = this.isExist(permissionList,"caregiver:deleteCaregiverLabel");
                  this.caregiverMoblle.staffPythonExport = this.isExist(permissionList,"caregiver:staffPythonExport");
                  this.caregiverMoblle.customerBatchOperationQueryList = this.isExist(permissionList,"caregiver:customerBatchOperationQueryList");
                  this.caregiverMoblle.batchModificationConfirmation = this.isExist(permissionList,"caregiver:batchModificationConfirmation");
                  this.caregiverMoblle.queryCaregiverInsuranceInformation = this.isExist(permissionList,"caregiver:queryCaregiverInsuranceInformation");
                  this.caregiverMoblle.modifyBaseMage = this.isExist(permissionList,"wage:modifyBaseMage");
                  this.getNurses();
                }
              })
            },
            isExist(permissionList,str) {
              if (permissionList.indexOf(str) !== -1) {
                return true;
              } else {
                return false;
              }
            },
        },
        created() {
            var reg = new RegExp("(^|&)" + 'id' + "=([^&]*)(&|$)", "i");
            var reg2 = new RegExp("(^|&)" + 'contractInformationId' + "=([^&]*)(&|$)", "i");

            var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
            var r2 = window.location.search.substr(1).match(reg2); //获取url中"?"符后的字符串并正则匹配

            var id = "";
            var contractInformationId = "";
            if (r != null)
                id = r[2];
            if (r2 != null)
                contractInformationId = r2[2];
            reg = null;
            r = null;

            this.staffId = id;
            if (this.staffId !== '' && this.staffId !== null) {
                this.siteChangeOperating()
                this.nurseDes(this.staffId,contractInformationId);
            }

            window.addEventListener('setItem', () => {
                this.allLoad = true;
                this.siteValue = sessionStorage.getItem('siteValue');
                this.staffId = '';
                this.refresh();
            }, false)
            if (this.$route.query.id !== null && this.$route.query.id !== undefined) {
                this.isAssign = false;
                this.staffId = this.$route.query.id;
                this.nurseDes(this.staffId)
                this.clientRight.width = '100%'
            } else {
                this.isAssign = true;
                this.refresh();
            }
        },
        watch: {
            '$route'(to, from) {
                if (this.$route.query.id != null && this.$route.query.id != undefined) {
                    this.isAssign = false;
                    this.staffId = this.$route.query.id;
                    this.nurseDes(this.staffId)
                    this.clientRight.width = '100%'
                } else {
                    this.clientRight.width = ''
                    this.isAssign = true;
                    this.refresh();
                }
            },
        },
        mounted() {
        }
    }
</script>
<style scoped lang="scss">
    .el-tooltip__popper {
      max-width: 30%;
    }
    .assess {
        padding: 20px 0 0 30px;

        .line {
            width: 260px;
        }
    }
    .clientDetails {
        margin-left: 95%;
        margin-top: -70px;
    }

    .left-border {
        position: absolute;
        left: 0;
        top: 16px;
        background: green;
        width: 2px;
        height: 60px;
    }

    .right-center {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        display: -webkit-flex; /* Safari */
        /*   border: 1px solid red;*/
        .center-one {
            width: 75%;
        }

        .center-two {
            width: 25%;
            border-left: 1px solid #DDD;

            .center-two-button {
                height: 6%;

            }

            .center-two-clientList {
                height: 94%;
            }

            .transfer-footer {
                margin-left: 20px;
                padding: 6px 5px;
            }
        }
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 20px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .serve-address {
            width: 400px;
            /*  border: 1px solid red;*/
            padding: 20px 0 0 20px;
        }

        span {
            margin-left: 20px;
        }

        .adress {
            padding-left: 2%;
        }
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 2%;
        }

        .line {
            width: 280px;
        }

        .line2 {
            width: 50px;
            background: #409EFF;
            color: #FFFFFF;
        }
    }

    #map {
        width: 100%;
        height: 300px;
    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    /*图片上传*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
</style>
