<template>
  <div class="homeVisit" v-loading="loading">
    <div class="gd-top" style="margin:10px 0 20px 0">
      <div class="gd-left">
        <el-form :inline="true" class="demo-form-inline" style="z-index:2">
          <el-cascader
              v-model="contractTypeAndSiteInquireValue"
              :options="contractTypeAndSiteInquireValueOptions"
              :props="{ expandTrigger: 'hover' }"
              @change="siteChangeOperating()"
              style="width:200px"></el-cascader>
          &nbsp;
          <el-form-item label="老人姓名">
            <el-input v-model="name" placeholder="请输入老人姓名" style="width:150px"
                      @keyup.enter.native="inquireHomeVisitList"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 230px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="inquireHomeVisitList()">查询</el-button>
            <el-button style="float: right" type="" @click="bookAHomeVisitButton()">预约家访</el-button>
          </el-form-item>
        </el-form>

      </div>

    </div>
    <el-table
        :data="homeVisitList"
        @selection-change="handleSelectionChange"
        style="width: 100% ;margin-bottom:30px;"
        :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        border
        stripe
        max-height="730"
        id="out-table"
        v-if="this.homeVisitModule.homeVisitList"
        v-loading="loading"
    >
      <el-table-column class-name="aa" label="姓名" header-align="10px" width="100px">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="类型" header-align="10px" width="100px">
        <template slot-scope="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="时间" header-align="10px" width="100px">
        <template slot-scope="scope">
          {{ scope.row.time }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="状态" header-align="10px" width="100px">
        <template slot-scope="scope">
          {{ scope.row.status }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="家访总结" header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.summary }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="地址" header-align="10px" width="200px">
        <template slot-scope="scope">
          {{ scope.row.longitude }}-{{scope.row.latitude}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="老师" header-align="10px" width="100px">
        <template slot-scope="scope">
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="图片" header-align="10px" width="100px">
        <template slot-scope="scope">
          <el-button size="small" @click="checkImg(scope.row.img)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align:right" v-if="this.homeVisitModule.homeVisitList">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pagesizes"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="all">
      </el-pagination>
    </div>
    <el-drawer
        title="上传图片"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
        style="text-align: center">
      <div v-for="item in img" :key="item.index" style="text-align: center">
          <el-image
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="srcList"
          @click="clickImg(item)">
          </el-image>
      </div>

    </el-drawer>

    <el-dialog
        width="80%"
        title="请选择客户"
        :visible.sync="customerList"
        append-to-body>
      <el-input v-model="customerSearch" placeholder="请输入客户名字/手机号,按Enter搜索" style="margin-bottom:5px"
                @keyup.enter.native="getClients"></el-input>
      <el-table
          :data="clientData.slice((currentPageC-1)*pagesizeC,currentPageC*pagesizeC)"
          max-height="550"
          :header-cell-style="{background:'#dee2ec','color': '#666666','text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          border
          stripe
      >
        <el-table-column prop="numbering" label="编号"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="gender" label="性别"></el-table-column>
        <el-table-column prop="moblle" label="电话"></el-table-column>
        <el-table-column prop="statusChangetime" label="时间"></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column label="点击选择">
          <template slot-scope="scope">
            <el-button size="small" @click="bookAHomeVisit(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" style="text-align:right">
        <el-pagination
            background
            @size-change="handleSizeChangeC"
            @current-change="handleCurrentChangeC"
            :current-page="currentPageC"
            :page-sizes="pagesizesC"
            :page-size="pagesizeC"
            layout="total, sizes, prev, pager, next, jumper"
            :total="allC">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  siteChange, inquireHomeVisitList, cusList, getClients, bookAHomeVisit
} from '@/common/js/index.js'
import {setDate} from "@/common/js/util";

export default {
  //el: 'form',
  name: "homeVisit",
  data() {
    return {
      all: 0,
      loading: false,
      currentPage: 1,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      homeVisitModule:{
        homeVisitList:false
      },
      date:null,
      homeVisitList:[],
      name:'',
      drawer: false,
      direction: 'rtl',
      srcList: [],
      img:[],
      customerList:false,
      customerSearch:'',
      clientData:[],
      currentPageC: 1,
      pagesizesC: [10, 20, 30, 50, 100, 200, 300, 400, 500],  //客户用的
      pagesizeC: 10,
      allC: 0,
      currPage:1,
    }
  },
  methods: {
    bookAHomeVisit(row){
      let subData = "siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2)
          + "&name=" + row.name
          + "&customerId=" + row.id
          + "&evaluationNursingId=" + row.evaluationNursingId
      bookAHomeVisit(subData).then(res => {
        if (res) {
          this.inquireHomeVisitList()
          this.customerList = false
          this.$message.success('预约家访成功');
        }
      })
    },
    bookAHomeVisitButton(){
      this.getClients()
      this.customerList = true
    },
    getClients() {    //获取客户列表
      let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2) + "&accessCategory="+"新增工单查询老人列表"
          + "&currPage=" + this.currPage;
      if (this.customerSearch !== ''){
        subData = subData + "&searchCondition=" + this.customerSearch
      }
      cusList(subData).then(res => {
        if (res) {
          this.clientData = res.data
          this.allC = res.data[0].totalCount
        }
      })
    },
    handleCurrentChangeC(val) {  //选择客户用的
      this.currPage = val
      this.getClients()
    },
    handleSizeChangeC(val) {     //选择客户用的
      this.pagesizeC = val
    },
    clickImg(item){
      this.srcList.push(item)
    },
    checkImg(img){
      this.drawer = true
      if (img!=null){
        this.img = img.split(",")
      }
    },
    handleClose(done) {
      done();
      this.img=[]
    },
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.homeVisitModule.homeVisitList = this.isExist(permissionList,"homeVisit:homeVisitList");
          this.inquireHomeVisitList();
        }
      })

    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    // 查询家访列表
    inquireHomeVisitList() {
      var strs = this.contractTypeAndSiteInquireValue.toString();
      let subData = 'siteId=' + strs.charAt(0)
          + '&contractTypeId=' + strs.charAt(2)
          + '&name=' + this.name
          + '&currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
      if (this.date != null) {
        let searchStartTime = setDate(this.date[0])
        let searchEndTime = setDate(this.date[1])
        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime
      }
      this.loading = true
      inquireHomeVisitList(subData).then(res => {
        if (res) {
          this.homeVisitList = res.data.homeVisitList
          this.all = res.data.totalCount
        }
        this.loading = false
      })
    },
    handleSelectionChange(val) {//改变选择时触发
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },

  },
  created() {
    this.siteChangeOperating();
  },
  watch: {
    currentPage: function () {
      this.inquireHomeVisitList();
    },
    pagesize: function () {
      this.inquireHomeVisitList();
    },
  }
}
</script>

<style scoped>
.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.sel2 {
  width: 100px;
}
</style>
