<template>
  <div class="client" v-loading="allLoading" v-if="isAssign">
    <div class="client-left">
      <div class="filter">
        <el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="siteChangeOperating()"
            style="float: left;"></el-cascader>
        <el-button style="float: right;" size="medium" type="primary" @click="getClients()" v-if="this.sendOrdersModule.customerList">搜索</el-button>
        <br>
        <el-input v-model="cliText" class="inp line" placeholder="姓名、电话、地址" clearable
                  @keyup.enter.native="getClients"></el-input>

      </div>
      <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1 && this.sendOrdersModule.customerList"></pageTemp>
      <div class="client-ul" v-if="this.sendOrdersModule.customerList">
        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                      view-class="p20-scrollbar__view">
          <div class="client-list" v-for="item in clientArr" :key="item.id"
               @click="getNurses(item.id,item.name,item.nursingCycle,item.nursingStartTime,item.nursingEndTime,item.nursingRemarks,item.evaluationLervl,item.longltude,item.latltude,item.siteId,item.contractTypeId,item.evaluationNursingId)"
               :class="{'active-class':item.id===staffId}">
            <div class="flex number">
              <span class="over-due" v-if="item.overdue===1">逾</span>
              <p class="num">{{ item.numbering }} </p>
              <div v-if="item.status === '待派单'"><p class="num">
                &nbsp;&nbsp;&nbsp;进入待派单时间:{{ item.stateUnchangedTime }}</p>
              </div>
            </div>
            <p>{{ item.name }} ({{ item.gender }}、{{ item.evaluationLervl }}级) | {{ item.moblle }}</p>
            <p>{{ item.statusChangetime }} | {{ item.addressDetall }}</p>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="client-right" v-loading="loading" :style="clientRight" >

      <div class="right-center" v-if="this.sendOrdersModule.recommendCaregiverList">
        <div class="center-one">
          <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                        view-class="p20-scrollbar__view">
            <div class="client-list" style="position: relative" v-for="item in clientData" :key="item.id"
                 @click="getDes(item.id,item.contractInformationId,item.periodAndDistances)"
                 :class="{'active-class':item.id===orderId}">
              <div class="left-border"></div>
              <p>{{ item.name }} ({{ item.gender }}) | {{ item.numberService }} 单</p>
              <p>约： {{ item.averageDistance }} （米） </p>

              <div :class="item.recommend=='1'?'green':'red'" >
                <p>{{ item.cycleFree }}</p>
              </div>

            </div>
          </el-scrollbar>
        </div>
        <div class="center-two">
          <div style="height: 12%;overflow:auto;">
            <el-row :gutter="0">
              <el-col :span="20">
                <div v-for="(item, index) in timePeriodList" v-bind:key="index">

                  <el-form>
                    <el-row :gutter="0">
                      <el-col :span="1" style="margin-top: 10px">
                        <div>{{ '（' + (index + 1) + '）' }}</div>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-time-select
                              style="width: 120px"
                              placeholder="起始时间"
                              v-model="item.beginTime"
                              format="HH:mm"
                              :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '21:00'
                                            }">
                          </el-time-select>
                          <el-time-select
                              style="width: 120px"
                              placeholder="结束时间"
                              v-model="item.endTime"
                              :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '22:00',
                                              minTime:item.beginTime
                                            }">
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="13">
                        <el-form-item>
                          <el-checkbox-group v-model="item.serviceCycle">
                            <el-checkbox v-for="item in weekTime"
                                         :label="item.id"
                                         :key="item.index">
                              {{ item.name }}
                            </el-checkbox>
                          </el-checkbox-group>
                        </el-form-item>

                      </el-col>
                      <el-col :span="1">
                        <!-- 在i标签设置删除按钮，运用splice函数。 -->
                        <i class="el-icon-remove-outline"
                           style="margin-left:20px;margin-top: 10px"
                           @click="deleteMockDispatch(index)"
                        ></i>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="4" style="float: right">
                <el-button @click="mockDispatch()">模拟派单</el-button>
                <el-button type="primary" @click="drawer = true" v-if="this.sendOrdersModule.beginSendOrder">派单</el-button>
              </el-col>
            </el-row>
          </div>
          <div style="height: 6%;overflow:auto;">
            <el-row style="margin-top: 10px;text-align: center">
              <el-col :span="5" v-if="showDetailsWhetherToOpen"><el-input v-model="showDetails.name" style="width: 150px"></el-input></el-col>
              <el-col :span="5" v-if="showDetailsWhetherToOpen">
                <el-input v-model="showDetails.moblle" style="width: 130px"></el-input>
                &nbsp;
                <el-button type="primary" @click="copyUrl" class="copy-btn" :data-clipboard-text="showDetails.moblle">复制电话</el-button>
              </el-col>
              <el-col :span="10" v-if="showDetailsWhetherToOpen">
                <el-input v-model="showDetails.address" style="width: 400px"></el-input>
                &nbsp;
                <el-button type="primary" @click="copyUrl" class="copy-btn" :data-clipboard-text="showDetails.address">复制地址</el-button>
              </el-col>
            </el-row>
          </div>
          <div style="height: 83%;position:relative">
            <div style="height:5%;width: 100%;position:absolute;top:45px;left:50px;z-index: 2;">
              <el-tag class="cycle" style="margin-left: 0px">周一 ( {{mondayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周二 ( {{tuesdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周三 ( {{wednesdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周四 ( {{thursdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周五 ( {{fridayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周六 ( {{saturdayNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周日 ( {{sunday}}单 )</el-tag>
            </div>
            <el-tabs v-model="activeName" v-loading="load">
              <el-tab-pane>
                <div class="container" style="margin: 0 auto;">
                  <FullCalendar
                      :plugins="calendarPlugins"
                      :all-day-slot="false"
                      :firstDay="1"
                      :slot-event-overlap="false"
                      :events=rosterEvents
                      @eventClick="eventClick"
                      :unselect-auto="false"
                      :select-overlap="false"
                      :business-hours="{
                                startTime: '06:00',
                                endTime:'23:00',
                                daysOfWeek: [ 1, 2, 3, 4, 5, 6, 0 ]
                              }"
                      select-mirror="true"
                      min-time="06:00:00"
                      max-time="23:00:00"
                      selectable="true"
                      slot-duration="00:40"
                      default-view="timeGridWeek"
                      locale="zh-cn"
                      :columnFormat="{
                                week:'ddd'
                              }"
                      :eventTimeFormat="{
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                              }"
                      :slotLabelFormat="{
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                              }"
                      :header="false"
                  />
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
        title="派单-确认时间周期"
        :visible.sync="drawer"
        :direction="direction"
        size='58%' v-if="this.sendOrdersModule.recommendCaregiverList">
      <div v-for="(item, index) in timePeriodList" v-bind:key="index">

        <el-form>
          <el-row :gutter="0">
            <el-col :span="5">
              <el-form-item>
                <el-time-select
                    style="width: 100px"
                    v-model="item.beginTime"
                    format="HH:mm"
                    :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '21:00'
                                            }">
                </el-time-select>
                <el-time-select
                    style="width: 100px"
                    v-model="item.endTime"
                    :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '22:00',
                                              minTime:item.beginTime
                                            }">
                </el-time-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-checkbox-group v-model="item.serviceCycle">
                  <el-checkbox v-for="item in weekTime"
                               :label="item.id"
                               :key="item.index">
                    {{ item.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <!-- 在i标签设置删除按钮，运用splice函数。 -->
              <i class="el-icon-remove-outline"
                 style="margin-left:20px;margin-top: 10px"
                 @click="timePeriodList.splice(index, 1)"
              ></i>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-button type="primary" @click="sendOrder()" style="float: right;margin-right: 100px">派单</el-button>
    </el-drawer>
  </div>
</template>

<script>
import pageTemp from "@/components/pageTemp.vue";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import '@fullcalendar/core/locales/zh-cn'
import Clipboard from 'clipboard';
// eslint-disable-next-line no-unused-vars
import {
  cusList,
  orderList,
  beginSendOrder,
  customerMapInformation,
  weeklyCalendar,
  siteChange
} from "@/common/js/index.js";

export default {
  inject: ['reload'],
  name: "sendOrders",
  components: {pageTemp, FullCalendar},
  data() {
    return {
      mondayOddNumber:0,//周一单数
      tuesdayOddNumber:0,
      wednesdayOddNumber:0,
      thursdayOddNumber:0,
      fridayOddNumber:0,
      saturdayNumber:0,
      sunday:0,
      orderId: null,
      radio: 1,
      drawer: false,
      direction: 'rtl',
      weekTime: [
        {name: "周一", id: '1'},
        {name: "周二", id: '2'},
        {name: "周三", id: '3'},
        {name: "周四", id: '4'},
        {name: "周五", id: '5'},
        {name: "周六", id: '6'},
        {name: "周日", id: '7'},
      ],
      serviceCycle: [],
      timePeriodList: [{
        serviceCycle: []
      }], //时间周期
      beginTime: null,
      endTime: null,
      rosterEvents: [],
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      contractInformationId: '',//护理员合约信息id
      evaluationNursingId: '',//老人合约信息id
      siteId: '',// 老人护理站id
      contractTypeId: null,// 老人合约类型id
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      staffId: null,
      loading: false,
      medicareTypes: localStorage.getItem("medicareCardType").split("$"),
      clientData: [],//员工名下客户
      schArr: [],//排班
      caregiverName: "",
      numberService: "",
      gender: "",
      distance: "",
      canAppoin: false,
      clientRight: {
        width: ""
      },
      isAssign: false,
      careFlag: 0,//第几个护理员
      careDialog: false,
      careName: "",
      careData: [],
      careForm: {
        searchCondition: "",
        status: "",
      },
      carePage: 0,
      careTotal: 0,
      operaDialog: false,//操作弹窗
      operaForm: {
        targetStatus: "",
        appointmentTime: "",
        statusRemarks: ""
      },
      careList: [],//护理员列表
      formInline: {
        logCTime: ""//日志时间筛选
      },
      siteValue: sessionStorage.getItem("siteValue"),
      //siteValue:"",
      allLoading: true,
      load: false,
      cliStatus: "待派单",
      cliText: "",
      isAdd: false,
      operaList: [],
      pages: 0,
      currPage: 1,
      clientArr: [],
      checkList: [],
      orderTable: [],
      activeName: '',
      isRouterAlive: true,
      periodAndDistances: {
        period: null,
        lastDistance: null,
        nextDistance: null,
        nursingCycle: null
      },
      showDetailsWhetherToOpen:false, //显示详细信息是否开启
      showDetails:{//显示详细信息
        name:"",
        address: "",
        moblle:""
      },
      sendOrdersModule:{
        customerList:false,
        caregiverOrder:false,
        beginSendOrder:false,
        caregiverPriorityList:false,
        recommendCaregiverList:false
      },
    }
  },
  methods: {
    siteChangeOperating() {
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.sendOrdersModule.customerList = this.isExist(permissionList,"sendOrders:customerList");
          this.sendOrdersModule.caregiverOrder = this.isExist(permissionList,"sendOrders:caregiverOrder");
          this.sendOrdersModule.beginSendOrder = this.isExist(permissionList,"sendOrders:beginSendOrder");
          this.sendOrdersModule.caregiverPriorityList = this.isExist(permissionList,"sendOrders:caregiverPriorityList");
          this.sendOrdersModule.recommendCaregiverList = this.isExist(permissionList,"mapReport:recommendCaregiverList");
          this.getClients()
        }
      })
    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    // 复制链接
    copyUrl() {
      let _this = this;
      let clipboard = new Clipboard(".copy-btn"); // 这里括号里填写上面点击事件绑定的class名
      clipboard.on("success", e => {
        // 复制成功，提示根据自己项目实际使用的UI来写
        _this.$message.success("复制成功")
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制，提示根据自己项目实际使用的UI来写
        _this.$message.error("该浏览器不支持自动复制")
        // 释放内存
        clipboard.destroy();
      });
    },
    eventClick(event){
      console.log("eventClick",event)
      this.showDetails.name = event.event.title
      this.showDetails.moblle = event.event._def.extendedProps.moblle
      this.showDetails.address = event.event._def.extendedProps.sreet + event.event._def.extendedProps.committees + event.event._def.extendedProps.addressDetall
      if (this.showDetails.address!==''){
        this.showDetailsWhetherToOpen =true
      }else {
        this.showDetailsWhetherToOpen =false
      }
    },
    // 模拟派单删除
    deleteMockDispatch(index) {
      for (let i = 0; i < this.rosterEvents.length; i++) {
        if (this.rosterEvents[i].title === "模拟派单") {
          this.rosterEvents.splice(i, 1)
          i = i - 1;
        }
      }
      this.timePeriodList.splice(index, 1)
      for (let i = 0; i < this.timePeriodList.length; i++) {
        const beginTime = this.timePeriodList[i].beginTime;
        const endTime = this.timePeriodList[i].endTime
        for (const serviceCycleElement of this.timePeriodList[i].serviceCycle) {
          if (beginTime != null) {
            const date = getThisWeeksDate(serviceCycleElement);
            const s = {
              start: date + " " + beginTime,
              end: date + " " + endTime,
              title: '模拟派单',
              color: '#41584b',
            }
            this.rosterEvents.push(s);
          }
        }
      }
    },
    mockDispatch() {
      this.timePeriodList.unshift({
        beginTime: this.beginTime,
        endTime: this.endTime,
        serviceCycle: this.serviceCycle
      });

      for (let i = 0; i < this.rosterEvents.length; i++) {
        if (this.rosterEvents[i].title === "模拟派单") {
          this.rosterEvents.splice(i, 1)
          i = i - 1;
        }
      }
      for (let i = 0; i < this.timePeriodList.length; i++) {
        const beginTime = this.timePeriodList[i].beginTime;
        const endTime = this.timePeriodList[i].endTime
        for (const serviceCycleElement of this.timePeriodList[i].serviceCycle) {
          if (beginTime != null) {
            const date = getThisWeeksDate(serviceCycleElement);
            const s = {
              start: date + " " + beginTime,
              end: date + " " + endTime,
              title: '模拟派单',
              color: '#41584b',
            }
            this.rosterEvents.push(s);
          }
        }
      }
    },
    filterTag(value, row) {
      return row.status === value;
    },
    contractTypeNamefilterTag(value, row) {
      return row.contractTypeName === value;
    },
    jumpToCaregiver() {
      var caregiverId = sessionStorage.getItem("caregiverId");
      this.$router.push({path: '/order', query: {id: caregiverId, careText: '万立美'}})
    },
    /**
     * 地址栏输入用户id 提取
     * */
    submit() {
      var newurl = this.updateParam(window.location.href, 'sendOrdersId', this.input);
      //向当前url添加参数，没有历史记录
      window.history.replaceState({
        path: newurl
      }, '', newurl);
    },
    updateParam(uri, key, value) {
      if (!value) {
        return uri;
      }
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      } else {
        return uri + separator + key + "=" + value;
      }
    },

    /**
     * 派单
     * */
    sendOrder: function () {
      this.$confirm('', '确定派单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        type: 'warning'
      }).then(() => {
        let timePeriod = [];
        for (let i = 0; i < this.timePeriodList.length; i++) {
          let beginTime = this.timePeriodList[i].beginTime;
          let endTime = this.timePeriodList[i].endTime;
          if (this.timePeriodList[i].beginTime.length === 5) {
            beginTime = beginTime + ":00";
          }
          if (this.timePeriodList[i].endTime.length === 5) {
            endTime = endTime + ":00";
          }
          const serviceCycle = this.timePeriodList[i].serviceCycle;
          for (let j = 0; j < serviceCycle.length; j++) {
            const week = serviceCycle[j];
            let test = {
              "week": week,
              "beginTime": beginTime,
              "endTime": endTime,
              "medicareStatusValue": "0"
            };
            timePeriod.push(test)
          }
        }

        let subData = new FormData();
        subData.append("id", sessionStorage.getItem("clientId"));
        subData.append("careWorker_id_one", sessionStorage.getItem("caregiverId"));
        subData.append("siteId", this.siteId);
        subData.append("contractTypeId", this.contractTypeId);
        subData.append("evaluationNursingId", this.evaluationNursingId);
        subData.append("contractInformationId", this.contractInformationId);

        subData.append("timePeriod", JSON.stringify({tp: 1, dt: timePeriod}));
        console.log(subData)
        beginSendOrder(subData).then(res => {
          this.$message.success('派单成功');
          this.getClients();
          this.drawer = false
          // 跳转老人界面填写信息
          if (this.evaluationNursingId !== null && this.evaluationNursingId !== "") {
            let routeUrl = this.$router.resolve({
              path: "client",
              query: {
                id: sessionStorage.getItem("clientId"),
                evaluationNursingId: this.evaluationNursingId,
                contractTypeId: this.contractTypeId
              }
            });
            window.open(routeUrl.href, '_blank');


          }

        })
      }).catch(() => {
      });
    },

    /**
     * 分页
     * */
    goSpecifiedPage(val) {
      this.currPage = val;
      this.getClients();
    },
    /**
     * 获取客户列表
     * */
    getClients() {
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.sendOrdersModule.customerList = this.isExist(permissionList,"sendOrders:customerList");
          this.sendOrdersModule.caregiverOrder = this.isExist(permissionList,"sendOrders:caregiverOrder");
          this.sendOrdersModule.beginSendOrder = this.isExist(permissionList,"sendOrders:beginSendOrder");
          this.sendOrdersModule.caregiverPriorityList = this.isExist(permissionList,"sendOrders:caregiverPriorityList");

          var str = this.contractTypeAndSiteInquireValue.toString();
          if (this.cliText !== "") {
            this.cliStatus = "";
          } else {
            this.cliStatus = "待派单";
          }
          let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus + "&siteList=" + str.charAt(0) + '&contractTypeId=' + str.charAt(2);

          orderList(subData).then(res => {
            if (res) {
              this.clientArr = res.data;
              if (res.data.length !== 0) {
                this.pages = res.data[0].totalCount;
              }
            }
          })
        }
      })


    },
    /**
     * 待派单推荐护理员列表信息
     * */
    getNurses(id, customerName, nursingCycle, nursingStartTime, nursingEndTime, nursingRemarks, evaluationLervl, longltude, latltude, siteId, contractTypeId, evaluationNursingId) {
      this.timePeriodList = [{serviceCycle: []}];
      sessionStorage.setItem("clientId", id)
      sessionStorage.setItem("nursingCycle", nursingCycle)
      sessionStorage.setItem("nursingStartTime", nursingStartTime)
      sessionStorage.setItem("nursingEndTime", nursingEndTime)
      sessionStorage.setItem("nursingRemarks", nursingRemarks)
      this.evaluationNursingId = evaluationNursingId
      this.siteId = siteId
      this.contractTypeId = contractTypeId
      this.$confirm('查询速度较慢，请稍等...', '确定查询护理员优先级列表', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true  //开启加载动画
        this.staffId = id;
        this.resetSetItem('clientId', this.staffId);
        let subData1 = "evaluationLervl=" + evaluationLervl + "&longltude=" + longltude + "&latltude=" + latltude + "&siteList=" + this.siteId + '&contractTypeId=' + this.contractTypeId;
        customerMapInformation(subData1).then(res => {
          if (res) {
            this.clientData = res.data;
            if (this.clientData !== null) {
              this.clientData.sort(
                  (pre, cur) =>
                      //pre.numberService - cur.numberService||
                      pre.averageDistance - cur.averageDistance
              )
            }

          }
          this.loading = false
        });
      }).catch(() => {
      });
    },

    /**
     * 点击护理员列表
     * */
    getDes(id, contractInformationId, periodAndDistances) {
      this.showDetailsWhetherToOpen =false
      sessionStorage.setItem("caregiverId", id)
      this.contractInformationId = contractInformationId
      this.orderId = id;
      this.orderTable = [];
      this.timePeriodList = [{serviceCycle: []}];


      let subData = "id=" + id + "&contractInformationId=" + contractInformationId;
      weeklyCalendar(subData).then(res => {
        if (res) {
          this.rosterEvents = res.data;

          const length = this.rosterEvents.length;
          this.mondayOddNumber = this.rosterEvents[length-1].mondayOddNumber;
          this.tuesdayOddNumber = this.rosterEvents[length-1].tuesdayOddNumber;
          this.wednesdayOddNumber = this.rosterEvents[length-1].wednesdayOddNumber;
          this.thursdayOddNumber = this.rosterEvents[length-1].thursdayOddNumber;
          this.fridayOddNumber = this.rosterEvents[length-1].fridayOddNumber;
          this.saturdayNumber = this.rosterEvents[length-1].saturdayNumber;
          this.sunday = this.rosterEvents[length-1].sunday;

          if (periodAndDistances !== undefined && periodAndDistances !== null && periodAndDistances !== '') {
            for (let i = 0; i < periodAndDistances.length; i++) {
              let nextDistance = periodAndDistances[i].nextDistance;
              let lastDistance = periodAndDistances[i].lastDistance;
              let whichDay = periodAndDistances[i].whichDay;
              const date = getThisWeeksDate(whichDay);

              let period = periodAndDistances[i].period;
              let nursingStartTime = period.split("-")[0];
              let nursingEndTime = period.split("-")[1];
              //当时间格式为 7:00:00 时，改为 07:00:00
              if (nursingStartTime.length === 4) {
                nursingStartTime = "0" + nursingStartTime;
              }
              if (nursingEndTime.length === 4) {
                nursingEndTime = "0" + nursingEndTime;
              }
              const s = {
                start: date + " " + nursingStartTime,
                end: date + " " + nursingEndTime,
                title: lastDistance + " / " + nextDistance + "（米）",
                color: '#8db596',
              }
              this.rosterEvents.push(s);
            }
          }
        }
      })
    },

    refresh() {
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.sendOrdersModule.customerList = this.isExist(permissionList,"sendOrders:customerList");
          this.sendOrdersModule.caregiverOrder = this.isExist(permissionList,"sendOrders:caregiverOrder");
          this.sendOrdersModule.beginSendOrder = this.isExist(permissionList,"sendOrders:beginSendOrder");
          this.sendOrdersModule.caregiverPriorityList = this.isExist(permissionList,"sendOrders:caregiverPriorityList");
          this.sendOrdersModule.recommendCaregiverList = this.isExist(permissionList,"mapReport:recommendCaregiverList");

          var str = "sendOrders"
          var sendOrdersId = "";
          if (this.sendOrdersId !== '' && this.sendOrdersId != null && this.sendOrdersId.search(str) === -1) {
            sendOrdersId = this.sendOrdersId
          }
          let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus + "&clientId=" + sendOrdersId + "&status=" + this.cliStatus + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
          cusList(subData).then(res => {
            if (res) {
              this.clientArr = res.data;
              this.schArr = res.data;
              //this.getDes(this.careId);
              this.allLoading = false;
              if (res.data.length !== 0) {
                this.pages = res.data[0].totalCount;
              }
            }
          });
        }
      })

    },
    siteFresh() {
      this.allLoading = true;
      this.contractTypeAndSiteInquireValue = sessionStorage.getItem('contractTypeAndSiteInquireValue');
      this.refresh();
    }
  },
  created() {
    var loc = location.href;
    var n1 = loc.length;//地址的总长度
    var n2 = loc.indexOf("=");//取得=号的位置
    var id = decodeURI(loc.substr(n2 + 1, n1 - n2));//从=号后面的内容
    this.sendOrdersId = id;

    window.addEventListener('setItem', this.siteFresh, false);

    window.addEventListener('setItem', () => {
      this.loading = true;
      this.contractTypeAndSiteInquireValue = sessionStorage.getItem('contractTypeAndSiteInquireValue');
      this.refresh();
    }, false)

    // 由工单点击客户名字跳转触发函数
    if (this.$route.query.id != null && this.$route.query.id !== undefined) {
      this.clientId = this.$route.query.id;
      this.clientDes(this.clientId);
      this.clientRight.width = '100%'
    } else {
      this.isAssign = true;
      this.refresh();
    }
  },
  watch: {
    '$route'() {
      if (this.$route.query.id != null && this.$route.query.id !== undefined) {
        this.isAssign = false;
        this.clientId = this.$route.query.id;
        this.clientDes(this.clientId);
        this.clientRight.width = '100%'
      } else {
        this.clientRight.width = '';
        this.isAssign = true;
        this.refresh();
      }
    },
  },

}

function getThisWeeksDate(week) {
  const date = new Date();
  const nowTime = date.getTime();
  const day = date.getDay() || 7;//周一是每周的第一天
  //var day = date.getDay() //周日是每周的第一天
  const oneDayTime = 24 * 60 * 60 * 1000;
  let dayTime = new Date(nowTime - (day - week) * oneDayTime);
  const year = dayTime.getFullYear();
  const monMath = dayTime.getMonth() + 1;
  const month = (monMath < 10) ? "0" + "" + monMath + "" : monMath;
  const data = (dayTime.getDate() < 10) ? "0" + "" + dayTime.getDate() + "" : dayTime.getDate();
  return "" + year + "-" + month + "-" + data + "";
}


</script>

<style scoped lang="scss">
.red{
  color: orangered;
}
.green{
  color: darkgreen;
}
.cycle {
  width: 178px;height: 40px;text-align: center;font-size:18px;
}
.left-border {
  position: absolute;
  left: 0;
  top: 16px;
  background: green;
  width: 2px;
  height: 60px;
}

.right-center {
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex; /* Safari */
  /*   border: 1px solid red;*/
  .center-one {
    width: 10%;
    border-left: 1px solid #DDD;
  }

  .center-two {
    width: 90%;
    border-left: 1px solid #DDD;
  }
}

.over-due {
  /* position: absolute;*/
  /* top:4px;*/
  font-size: 12px;
  color: #FFF;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: red;
  text-align: center;
  line-height: 20px;
}

.right-opare {
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #DDD;
  padding: 10px 20px;
  box-sizing: border-box;
}

.des-box {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0 20px 70px 20px;
  /*  border: 1px solid red;*/
  box-sizing: border-box;
}

.des {
  margin-top: 20px;
  border: 1px dashed #999;
  border-radius: 10px;
  overflow: hidden;

  .map {
    overflow: hidden;

  }

  .local {
    display: inline-block;
    margin-left: 20px;
  }
}

.serve {
  padding: 20px 0 0 20px;
}

.des-flex {
  min-width: 600px;
  display: -webkit-flex; /* Safari */
  display: flex;
  height: auto;
  padding-top: 20px;

  .input-box {
    margin-left: 5%;
  }

  .line {
    width: 300px;
  }
}

.assess {
  padding: 20px 0 0 30px;

  .line {
    width: 200px;
  }
}

#map {
  width: 100%;
  height: 300px;
}

.adress {
  padding-left: 5%;

  .line {
    width: 260px;
  }
}

.card {
  padding: 0 40px 40px 40px;

  .img-box {
    width: 500px;
    justify-content: space-between;

    p {
      text-align: center;
    }
  }
}

/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}

.avatar {
  width: 364px;
  height: 229px;
  display: block;
}

/*标签*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.client-left {
  width: 320px;
}

.client-right {
  box-sizing: border-box;
  //padding: 10px;
  width: calc(100% - 320px);

  .tab-box {
    height: 100%;
    overflow: auto;
  }
}

.filter {
  width: 100%;
  padding: 5px 0 0 10px;
  height: 90px;
  box-sizing: border-box;
  border-bottom: 1px solid #DDD;

  .inp {
    width: 100%;
    margin-left: -10px;
    /*margin:0 20px;*/
  }

  .sel {
    width: 30%;
  }
}

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
.main {
  margin: 10px;
  text-align: center;
  background: #fff;
  padding: 10px;
}

.info {
  min-height: 50px;
  min-width: 300px;
  line-height: 30px;
  position: fixed;
  top: 0px;
  left: 43%;
  color: forestgreen;
  background: rgba(173, 255, 47, .2);
}
</style>
