<template>
  <div class="client" v-loading="allLoading">
    <div class="client-left" v-if="isAssign">
      <div class="filter">
        <el-select v-model="cliStatus" class="sel line" @change="getClients()">
          <el-option label="全部状态" value=""></el-option>
          <el-option label="有意向" value="有意向"></el-option>
          <el-option label="待派单" value="待派单"></el-option>
          <el-option label="待申请" value="待申请"></el-option>
          <el-option label="待评估" value="待评估"></el-option>
          <el-option label="中止中" value="中止中"></el-option>
          <el-option label="长期中止" value="长期中止"></el-option>
          <el-option label="到期合约" value="到期合约"></el-option>
          <el-option label="待确认" value="待确认"></el-option>
          <el-option label="合约中" value="合约中"></el-option>
          <el-option label="已终止" value="已终止"></el-option>
          <el-option label="已删除" value="已删除"></el-option>
        </el-select>
        <el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptionsTwo"
            :props="{ expandTrigger: 'hover' }"
            @change="siteChangeOperating()"
            style="float: right"></el-cascader>
        <br>
        <el-input v-model="cliText" class="inp line" placeholder="姓名、联系号码、地址" clearable
                  @keyup.enter.native="getClients"></el-input>
        <el-button size="small" type="primary" @click="getClients()">搜索</el-button>
      </div>
      <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1 && customerModule.userList"></pageTemp>
      <div class="client-ul" v-if="customerModule.userList">
        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                      view-class="p20-scrollbar__view">
          <div class="client-list" v-for="item in clientArr" :key="item.id"
               @click="clientDes(item.id,item.evaluationNursingId,item.contractTypeId)"
               :class="{'active-class':item.id===clientId}">
            <div class="flex number">
              <span class="over-due" v-if="item.overdue===1">逾</span>
              <p class="num">{{ item.numbering }}</p>
            </div>
            <p>{{ item.name }} ({{ item.gender }}) | {{ item.moblle }}</p>
            <p>{{ item.statusChangetime }}</p>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <!-- <el-scrollbar  view-style="font-weight: bold;" view-class="view-box" :native="false">-->
    <div class="client-right" v-loading="load" element-loading-text="保存修改中，请稍等..."
         element-loading-spinner="el-icon-loading" :style="clientRight">
      <div class="right-opare">
        <p>{{ form.numbering }}</p>
        <div>
          <el-button type="primary" @click="addBu('form')" v-show="!isAdd" size="small" icon="el-icon-plus"
                     circle v-if="customerModule.insertCustomer"></el-button>

          <el-button type="primary" @click="manualSettlementButton()" v-show="!isAdd" size="small"
                      v-if="customerModule.updateCustomer">自费手动结算
          </el-button>

          <el-button type="primary" icon="el-icon-check" @click="chanSave('form')" v-show="!isAdd" size="small"
                     v-if="customerModule.updateCustomer">保存修改
          </el-button>
          <el-button type="primary" @click="externalCreateWorkflow()" v-show="!isAdd" size="small" v-if="customerModule.externalCreateWorkflowButton">创建工作流
          </el-button>
          <el-button type="primary" @click="allLogsBu()" v-show="!isAdd" size="small" v-if="customerModule.customerAllLogsList">全部日志</el-button>
          <el-button type="primary" @click="logBu()" v-show="!isAdd" size="small" v-if="customerModule.customerLogList">日志</el-button>
          <el-button size="small" type="success" v-show="!isadd" @click="clientExport()" v-if="customerModule.customerImport">导出</el-button>
          <el-button type="success" v-show="isAdd" @click="addClient('form')" size="small" v-if="customerModule.insertCustomer">保存</el-button>
        </div>
      </div>
      <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                    view-class="p20-scrollbar__view">
        <div class="tag" v-show="!isAdd">
          <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
        </div>
        <div class="des-box" v-if="customerModule.selectCustomer">
          <el-form ref="form" :model="form" :rules="cRules" label-width="100px">
            <el-row :gutter="5">
              <el-col :span="6" v-show="!isAdd">
                <el-row>
                  <el-col>
                    <div class="des  assessTwo">
                      <div style="height: 370px">
                        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                      view-class="p20-scrollbar__view">
                          <div class="client-list-three" v-for="item in customeContracttypesList" :key="item.index"
                               @click="clientDes(clientId,item.evaluationNursingId)"
                               :class="{'active-class':item.evaluationNursingId==selectEvaluationNursingId}">
                            <div v-if="signedDisplay">
                              <p>{{ item.customeContractType }}</p>
                              <div v-if="item.customeContractType.indexOf('有意向') !== -1" class="stateYellow">
                                <span>有意向</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('待派单') !== -1" class="stateYellow">
                                <span>待派单</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('待申请') !== -1" class="stateYellow">
                                <span>待申请</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('待评估') !== -1" class="lightSkyBlue">
                                <span>待评估</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('中止中') !== -1" class="stateRed">
                                <span>中止中</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('长期中止') !== -1" class="stateMaroon">
                                <span>长期中止</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('到期合约') !== -1" class="stateDarkgray">
                                <span>到期合约</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('待确认') !== -1" class="stateYellow">
                                <span>待确认</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('合约中') !== -1" class="stateGreen">
                                <span>合约中</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('已终止') !== -1" class="stateRed">
                                <span>已终止</span>
                              </div>
                              <div v-if="item.customeContractType.indexOf('已删除') !== -1" class="stateDarkgray">
                                <span>已删除</span>
                              </div>
                            </div>
                          </div>
                        </el-scrollbar>
                      </div>
                    </div>
                  </el-col>

                </el-row>
                <el-row style="text-align: center">
                  <el-col :span="12" v-if="customerModule.updateCustomer">
                    <el-button type="success" @click="addContractType()"
                               v-if="signedDisplay"
                               v-show="!isAdd">新增签约<i class="el-icon-plus"></i>
                    </el-button>
                  </el-col>
                  <el-col :span="12" v-if="customerModule.alterStatus">
                    <el-dropdown v-show="!isAdd" class="opera" @command="operaClient" v-if="signedDisplay">
                      <el-button type="success">
                        更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="operaList.length===0" command="empty">暂无操作
                        </el-dropdown-item>
                        <el-dropdown-item v-else v-for="item in operaList" :key="item.value"
                                          :command="item">
                          {{ item.value }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="18">
                <div class="des  assess">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="姓名" prop="name">
                        <el-input v-model="form.name" placeholder="请输入客户姓名" class="line"
                                  style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="手机号" prop="moblle">
                        <el-input v-model="form.moblle" placeholder="请输入客户手机号码" class="line"
                                  style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="身份证号" prop="identityCard">
                        <el-input v-model="form.identityCard" placeholder="请输入客户身份证号"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="年龄" prop="age">
                        <el-input v-model="form.age" :disabled="true" placeholder="年龄由身份证号自动生成" class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="性别">
                        <el-radio-group v-model="form.gender">
                          <el-radio label="男">男</el-radio>
                          <el-radio label="女">女</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="家属姓名">
                        <el-input v-model="form.familyName" placeholder="请输入客户家属姓名"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="紧急联络电话">
                        <el-input v-model="form.familyMoblle" placeholder="请输入客户紧急联系方式"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">&nbsp;&nbsp;&nbsp;&nbsp;
                      <el-button type="primary" @click="viewOtherFamilyMembers()" size="small"
                                 v-show="!isAdd" v-if="customerModule.viewOtherFamilyMembers">查看其它家属
                      </el-button>
                      <el-button type="success" @click="addFamily()" size="small" icon="el-icon-plus"
                                 v-show="!isAdd" v-if="customerModule.saveAddFamilyInformation">增加家属信息
                      </el-button>
                    </el-col>

                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="银行卡所属行" prop="bankCardName">
                        <el-input v-model="form.bankCardName" placeholder="请输入银行卡所属行名"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="银行卡号" prop="bankCardNumber">
                        <el-input v-model="form.bankCardNumber" placeholder="请输入银行卡号"
                                  class="line" style="width: 200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="合约类型" v-if="isAdd">
                        <el-cascader
                            v-model="contractTypeAndSiteInquireValue"
                            :options="contractTypeAndSiteInquireValueOptions"
                            :props="{ expandTrigger: 'hover' }"
                            @change="selectContractTypeNursingStation()"
                            style="float: right"></el-cascader>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="状态" prop="status" v-if="isAdd">
                        <el-select v-model="form.status" placeholder="请选择客户状态" class="line" style="width: 200px">
                          <el-option label="有意向" value="有意向"></el-option>
                          <div v-if="contractTypeAndSiteInquireValue.toString().charAt(2) === '1'">
                            <el-option label="待申请" value="待申请"></el-option>
                            <el-option label="待评估" value="待评估"></el-option>
                          </div>
                          <el-option label="待派单" value="待派单"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <div style="color: red;font-size: 14px" v-if="isAdd">&nbsp;注：请先选择合约类型，再选择状态
                      </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="状态备注" prop="statusRemarks">
                        <el-input v-model="form.statusRemarks" class="line" style="width: 800px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <br/>
            <el-row :gutter="150">
              <el-col :span="6">
                <el-form-item label="新增合约:" v-if="newSigning">
                  <el-cascader
                      v-model="newContracttype"
                      :options="contractTypeAndSiteInquireValueOptions"
                      :props="{ expandTrigger: 'hover' }"
                      clearable
                      style="float: left;width: 200px"></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="状态" prop="status" v-if="newSigning">
                  <el-select v-model="form.status" placeholder="请选择客户状态" class="line" style="width: 140px">
                    <el-option label="有意向" value="有意向"></el-option>
                    <div v-if="newContracttype.toString().charAt(0) === '1'">
                      <el-option label="待申请" value="待申请"></el-option>
                      <el-option label="待评估" value="待评估"></el-option>
                    </div>
                    <el-option label="待派单" value="待派单"></el-option>
                  </el-select>
                </el-form-item>

              </el-col>
              <el-col :span="6">
                <el-form-item label="状态备注" prop="statusRemarks" v-if="newSigning">
                  <el-input v-model="form.statusRemarks" class="line" style="width: 300px"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!--老人合约架构修改 begin-->
            <div class="des  assess">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="街道">
                    <el-input v-model="form.sreet" class="line" @focus="toAddress()" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="居委会" prop="committees">
                    <el-input v-model="form.committees" class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="详细地址">
                    <el-input v-model="form.addressDetall" class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="设备编号">
                    <el-input v-model="form.lbeaconMajor" placeholder="请输入设备编号" class="line"
                              learable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="评估有效期">
                    <el-date-picker
                        v-model="form.evaluationPeriod"
                        type="date"
                        style="width: 200px"
                        @change="evaluationPeriodChange(form.evaluationPeriod)"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="首次护理时间">
                    <el-date-picker
                        v-model="form.firstCareTime"
                        type="date"
                        style="width: 200px"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="评估等级">
                    <el-input v-model="form.evaluationLervl" type="number" placeholder="请输入客户评估等级"
                              class="line" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="医保卡类型" prop="medicareCardType">
                    <el-select v-model="form.medicareCardType" placeholder="请选择医保卡类型" class="line">
                      <el-option
                          v-for="item in medicareTypes"
                          :key="item"
                          :label="item"
                          :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="推荐类型" prop="recommendedTypeId">
                    <el-select v-model="form.recommendedTypeId" placeholder="请选择推荐类型" @change="recommendedTypeChange()">
                      <el-option
                          v-for="item in recommendedTypes"
                          :key="item.recommendedTypeId"
                          :label="item.recommendedType"
                          :value="item.recommendedTypeId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="推荐明细" v-if="!whetherTheCaregiverRecommends">
                    <el-input v-model="form.recommendedDetails" placeholder="请输入推荐明细"
                              class="line" style="width: 200px;"></el-input>
                  </el-form-item>
                  <el-form-item label="推荐护理员" v-if="whetherTheCaregiverRecommends" style="width: 300px;">
                    <el-input v-model="form.recommendedDetails" placeholder="请输入推荐护理员"
                              @focus="careInput(5)" style="width: 200px;"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="护理计划编码">
                    <el-input v-model="form.plannlngSheet" placeholder="请输入客户护理计划编码"
                              class="line"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="设备ID">
                    <el-input v-model="form.deviceId" placeholder="请输入客户蓝牙签到设备ID"
                              class="line"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <!--                <el-col :span="6">-->
                <!--                  <el-form-item label="推荐渠道">-->
                <!--                    <el-input v-model="form.recommend" placeholder="请输入客户推荐渠道"-->
                <!--                              class="line" style="width: 200px;"></el-input>-->
                <!--                  </el-form-item>-->
                <!--                </el-col>-->
                <el-col :span="12">
                  <el-form-item label="护理备注">
                    <el-input v-model="form.nursingRemarks" class="line" style="width: 800px;"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="des  assess">

              <el-row :gutter="0">
                <el-col :span="8" style="width: 35%">
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定护理员一">
                        <el-input v-model="careWorker_one" placeholder="请选择指定护理员一" class="line"
                                  @focus="careInput(1)"></el-input>
                        <el-button type="danger" @click="deleteCareWorker_one()" v-show="!isAdd" size="mini"
                                   icon="el-icon-delete"
                                   circle></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定原因">
                        <el-input v-model="form.designationReason_one" placeholder="指定护理员一原因" class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定护理员二">
                        <el-input v-model="careWorker_two" placeholder="请选择指定护理员二" class="line"
                                  @focus="careInput(2)"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定原因">
                        <el-input v-model="form.designationReason_two" placeholder="指定护理员二原因"
                                  class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定护理员三">
                        <el-input v-model="careWorker_three" placeholder="请选择指定护理员三" class="line"
                                  @focus="careInput(3)"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="指定原因">
                        <el-input v-model="form.designationReason_three" placeholder="指定护理员三原因"
                                  class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="备用时间备注">
                        <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="4" style="width: 10%">
                  <el-row :gutter="0">
                    <el-switch
                        v-model="sameTime"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="时间相同">
                    </el-switch>
                  </el-row>
                  <el-button type="primary" @click="addSameTimePeriod()" style="width: 112px" v-if="sameTime">新增时间
                  </el-button>
                  <el-button type="primary" @click="addDifferentTimePeriod()" style="width: 112px" v-if="!sameTime">
                    新增时间
                  </el-button>
                </el-col>
                <!--       时间相同         -->
                <el-col :span="12" style="width: 55%" v-if="sameTime">
                  <div v-for="(item, index) in timePeriodList" v-bind:key="index">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-select v-model="item.week" placeholder="周期">
                          <el-option
                              v-for="item in serveWeek"
                              :key="item.week"
                              :label="item.name"
                              :value="item.week">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="护理时间">
                          <el-time-select
                              style="width: 120px"
                              placeholder="起始时间"
                              v-model="item.beginTime"
                              @change="beginTimeChange(item.beginTime,index,true)"
                              format="HH:mm"
                              :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '21:00'
                                            }">
                          </el-time-select>
                          <el-time-select
                              style="width: 120px"
                              placeholder="结束时间"
                              v-model="item.endTime"
                              @change="endTimeChange(item.endTime,index,true)"
                              :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '22:00',
                                              minTime:item.beginTime
                                            }">
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="5">
                        <el-select v-model="item.medicareStatusValue" placeholder="状态">
                          <el-option
                              v-for="item in medicareStatus"
                              :key="item.medicareStatusValue"
                              :label="item.label"
                              :value="item.medicareStatusValue">
                          </el-option>
                        </el-select>
                      </el-col>

                      <el-col :span="3">
                        <!-- 在i标签设置删除按钮，运用splice函数。 -->
                        <i class="el-icon-remove-outline"
                           style="margin-left:20px;margin-top: 10px"
                           @click="timePeriodList.splice(index, 1)"
                        ></i>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <!--       时间不同         -->
                <el-col :span="12" style="width: 55%" v-if="!sameTime">
                  <div v-for="(item, index) in timePeriodList" v-bind:key="index">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-select v-model="item.week" placeholder="周期">
                          <el-option
                              v-for="item in serveWeek"
                              :key="item.week"
                              :label="item.name"
                              :value="item.week">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="护理时间">
                          <el-time-select
                              style="width: 120px"
                              placeholder="起始时间"
                              v-model="item.beginTime"
                              @change="beginTimeChange(item.beginTime,index,false)"
                              format="HH:mm"
                              :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '21:00'
                                            }">
                          </el-time-select>
                          <el-time-select
                              style="width: 120px"
                              placeholder="结束时间"
                              v-model="item.endTime"
                              @change="endTimeChange(item.endTime,index,false)"
                              :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '22:00',
                                              minTime:item.beginTime
                                            }">
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="5">
                        <el-select v-model="item.medicareStatusValue" placeholder="状态">
                          <el-option
                              v-for="item in medicareStatus"
                              :key="item.medicareStatusValue"
                              :label="item.label"
                              :value="item.medicareStatusValue">
                          </el-option>
                        </el-select>
                      </el-col>

                      <el-col :span="3">
                        <!-- 在i标签设置删除按钮，运用splice函数。 -->
                        <i class="el-icon-remove-outline"
                           style="margin-left:20px;margin-top: 10px"
                           @click="timePeriodList.splice(index, 1)"
                        ></i>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!--老人合约架构修改 end-->
            <div class="des  assess">

              <el-row :gutter="0">
                <el-col :span="8" style="width: 35%">
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="预约指定护理员" label-width="160px">
                        <el-input v-model="reservation_careWorker" :disabled="true" style="width:200px"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="预约指定原因" label-width="160px">
                        <el-input v-model="form.reservation_designationReason" placeholder="预约指定原因" style="width:200px"
                                  clearable></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="状态预约时间" prop="appointmentTime" v-if="!isAdd" label-width="160px">
                        <el-input v-model="form.appointmentTime" :disabled="true" style="width:200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="0">
                    <el-col :span="24">
                      <el-form-item label="预约变更周期时间" prop="appointmentCycleChangeTime" v-if="!isAdd"
                                    label-width="160px">
                        <el-input v-model="form.appointmentCycleChangeTime" :disabled="true"
                                  style="width:200px"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="4" style="width: 10%">
                  <el-row :gutter="0">
                    <el-switch
                        v-model="sameAppointmentTime"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="时间相同"
                        :disabled="true">
                    </el-switch>
                  </el-row>
                </el-col>
                <el-col :span="12" style="width: 55%">
                  <div v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-select v-model="item.week" :disabled="true" placeholder="周期">
                          <el-option
                              v-for="item in serveWeek"
                              :key="item.week"
                              :label="item.name"
                              :value="item.week">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="护理时间">
                          <el-time-select
                              style="width: 120px"
                              placeholder="起始时间"
                              v-model="item.beginTime" :disabled="true"
                              format="HH:mm"
                              :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '21:00'
                                            }">
                          </el-time-select>
                          <el-time-select
                              style="width: 120px"
                              placeholder="结束时间"
                              v-model="item.endTime" :disabled="true" class="line"
                              :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '22:00',
                                              minTime:item.beginTime
                                            }">
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="5">
                        <el-select v-model="item.medicareStatusValue" :disabled="true" placeholder="状态">
                          <el-option
                              v-for="item in medicareStatus"
                              :key="item.medicareStatusValue"
                              :label="item.label"
                              :value="item.medicareStatusValue">
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="des map">
              <div class="flex">
                <el-form-item label="纬度" label-width="50px">
                  <el-input v-model="form.latltude" placeholder="" class="line"
                            clearable></el-input>
                </el-form-item>
                <el-form-item label="经度" label-width="50px">
                  <el-input v-model="form.longltude" placeholder="" class="line"
                            clearable></el-input>
                </el-form-item>
                <!--<span class="local">纬度：{{form.latltude}}</span><span class="local">经度：{{form.longltude}}</span>-->
              </div>
              <div id="map"></div>
            </div>
            <div class="des card">
              <el-tabs v-model="activeName">
                <el-tab-pane label="身份证" name="first">
                  <div class="flex img-box">
                    <div>
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="idBefUpload"
                          :on-change="idBefChange"
                      >
                        <img v-if="idBefUrl" :src="idBefUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（身份证正面）</p>
                    </div>
                    <div style="margin-left: 30px">
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="idBacUpload"
                          :on-change="idBacChange"
                      >
                        <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（身份证反面）</p>
                    </div>
                  </div>
                </el-tab-pane>

                <el-tab-pane label="医保卡" name="second">
                  <div class="flex img-box">
                    <div>
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="soBefUpload"
                          :on-change="soBefChange"
                      >
                        <img v-if="soBefUrl" :src="soBefUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（社保卡正面）</p>
                    </div>
                    <div style="margin-left: 30px">
                      <el-upload
                          class="avatar-uploader"
                          action="customize"
                          :show-file-list="false"
                          :http-request="uploadFile"
                          accept='.jpg,.png'
                          ref="soBacUpload"
                          :on-change="soBacChange"
                      >
                        <img v-if="soBacUrl" :src="soBacUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <p>（社保卡反面）</p>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-form>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog title="客户日志" :visible.sync="clientLogDialog" width="80%">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="操作时间段">
          <el-date-picker
              v-model="formInline.logCTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="已签约:">
          <el-select v-model="selectEvaluationNursingIdLog" @change="logTimeSubmit" v-show="!isAdd"
                     style="width:200px">
            <el-option
                v-for="item in customeContracttypesListLog"
                :key="item.index"
                :label="item.customeContractType"
                :value="item.evaluationNursingId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="logTimeSubmit">查询</el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-switch
              v-model="justSeeTheNote"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="只看备注">
          </el-switch>
        </el-form-item>

      </el-form>
      <el-table :data="logCData"
                border
                stripe
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center','man-width':'100px'}"
                max-height="600"
      >
        <el-table-column property="module" label="模块" width="80"></el-table-column>
        <el-table-column property="handlers" label="操作者" width="100"></el-table-column>
        <el-table-column property="name" label="被操作者姓名" width="110"></el-table-column>
        <el-table-column property="operatingTime" label="被操作时间" width="160"></el-table-column>
        <!--<el-table-column property="clientId" label="被操作者id" width="90"></el-table-column>-->
        <el-table-column property="result" label="操作结果" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column property="content" label="操作内容" width="180"></el-table-column>
        <el-table-column property="oerationNote" label="备注" width="180"></el-table-column>
      </el-table>
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page="logCPage"
          style="text-align:right;margin-top: 20px"
          layout="total,prev, pager, next, jumper"
          :page-size="10"
          :total="logCTotal">
      </el-pagination>
    </el-dialog>
    <el-dialog title="客户全部日志" :visible.sync="clientAllLogsDialog" width="80%">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="操作时间段">
        <el-date-picker
            v-model="formInline.allLogsTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptionsTwo"
            :props="{ expandTrigger: 'hover' }"
            @change="allLogsRequest()"
            style="float: right"></el-cascader>
      </el-form-item>
      <el-form-item>
      <el-select v-model="allLogStatus" @change="allLogsRequest()">
        <el-option label="全部状态" value=""></el-option>
        <el-option label="老人新增" value="老人新增"></el-option>
        <el-option label="新增合约" value="新增合约"></el-option>
        <el-option label="修改个人信息" value="修改个人信息"></el-option>
        <el-option label="用户状态变更" value="用户状态变更"></el-option>
        <el-option label="用户预约状态变更" value="用户预约状态变更"></el-option>
      </el-select>
      </el-form-item>
      <el-form-item label = " 姓名">
        <el-input v-model="allLogsCustomerName" placeholder="请输入老人姓名" style="width: 200px"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="allLogsRequest">查询</el-button>
        <el-button  type="success" @click="allLogsExportButton()" v-if="customerModule.customerAllLogsExport">导出日志
        </el-button>
      </el-form-item>

    </el-form>
    <el-table :data="allLogsData"
              border
              stripe
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center','man-width':'100px'}"
              max-height="600"
              v-loading="allLogsLoading"
    >
      <el-table-column property="module" label="模块" width="80"></el-table-column>
      <el-table-column property="handlers" label="操作者" width="100"></el-table-column>
      <el-table-column property="name" label="被操作者姓名" width="110"></el-table-column>
      <el-table-column property="operatingTime" label="被操作时间" width="160"></el-table-column>
      <!--<el-table-column property="clientId" label="被操作者id" width="90"></el-table-column>-->
      <el-table-column property="result" label="操作结果" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column property="content" label="操作内容" width="180"></el-table-column>
      <el-table-column property="oerationNote" label="备注" width="180"></el-table-column>
    </el-table>
    <el-pagination
        @current-change="handleCurrentChange2"
        :current-page="allLogsPage"
        style="text-align:right;margin-top: 20px"
        layout="total,prev, pager, next, jumper"
        :page-size="10"
        :total="allLogsTotal">
    </el-pagination>
  </el-dialog>
    <el-dialog title="操作" :visible.sync="operaDialog" width="1400px" @close='closeOperaDialog'>
      <p style="font-size: 16px;color:#FF1493"><strong>注:任何预约操作时间不能在本日。如同时操作预约中止和预约变更周期 只会存在一个预约,以最后操作为准(
        操作完成后工单会立即修改,请查看该老人此合约工单是否正确 )</strong></p>
      <div v-loading="operatingLoading"
           element-loading-text="状态变更中，请稍等"
           element-loading-spinner="el-icon-loading">
        <div>
          <el-row :gutter="20">
            <el-col :span="8" style="width: 25%">
              <el-form :model="operaForm">
                <el-form-item label="状态更改">
                  <el-input v-model="operaForm.targetStatus" :disabled="true" style="width: 150px;"></el-input>
                </el-form-item>
                <el-form-item label="预约时间" v-if="canAppoin">
                  <el-date-picker
                      v-model="operaForm.appointmentTime"
                      type="date"
                      placeholder="选择预约时间"
                      style="width: 150px;"
                      :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="预约时间" v-if="canAppoin2">
                  <el-date-picker
                      v-model="operaForm.appointmentTime"
                      type="date"
                      placeholder="选择预约时间"
                      style="width: 150px;"
                      :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="预约护理员" v-if="canAppoin2">
                  <el-input v-model="reservation_careWorker" placeholder="请选择预约指定护理员"
                            style="width: 150px;"
                            @focus="careInput(4)" clearable></el-input>
                </el-form-item>
                <el-form-item label="合约延期日期" v-if="canAppoin3">
                  <el-date-picker
                      v-model="operaForm.appointmentTime"
                      type="date"
                      placeholder="选择日期"
                      style="width: 150px;">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="operaForm.statusRemarks" placeholder="备注或状态备注"></el-input>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" style="width: 10%" v-if="canAppoin2">
              <el-row :gutter="0">
                <el-switch
                    v-model="sameAppointmentTime"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="时间相同">
                </el-switch>
              </el-row>
              <el-button type="primary" @click="addSameAppointmentTimePeriod()" style="width: 112px"
                         v-if="sameAppointmentTime">新增时间
              </el-button>
              <el-button type="primary" @click="addDifferentAppointmentTimePeriod()" style="width: 112px"
                         v-if="!sameAppointmentTime">新增时间
              </el-button>
            </el-col>
            <el-col :span="12" v-if="canAppoin2">
              <!--       时间相同         -->
              <div v-if="sameAppointmentTime" v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                <el-row :gutter="20">
                  <el-col :span="4">
                    <el-select v-model="item.week" placeholder="周期">
                      <el-option
                          v-for="item in serveWeek"
                          :key="item.week"
                          :label="item.name"
                          :value="item.week">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <el-form>
                      <el-form-item label="护理时间">
                        <el-time-select
                            style="width: 120px"
                            placeholder="起始时间"
                            v-model="item.beginTime"
                            @change="beginAppointmentTimeChange(item.beginTime,index,true)"
                            format="HH:mm"
                            :picker-options="{
                                                start: '06:00',
                                                step: '00:05',
                                                end: '21:00'
                                              }">
                        </el-time-select>
                        <el-time-select
                            style="width: 120px"
                            placeholder="结束时间"
                            v-model="item.endTime"
                            @change="endTimeAppointmentTimeChange(item.endTime,index,true)"
                            :picker-options="{
                                                start: '07:00',
                                                step: '00:05',
                                                end: '22:00',
                                                minTime:item.beginTime
                                              }">
                        </el-time-select>
                      </el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="5">
                    <el-select v-model="item.medicareStatusValue" placeholder="状态">
                      <el-option
                          v-for="item in medicareStatus"
                          :key="item.medicareStatusValue"
                          :label="item.label"
                          :value="item.medicareStatusValue">
                      </el-option>
                    </el-select>
                  </el-col>

                  <el-col :span="3">
                    <!-- 在i标签设置删除按钮，运用splice函数。 -->
                    <i class="el-icon-remove-outline"
                       style="margin-left:20px;margin-top: 10px"
                       @click="appointmentTimePeriodList.splice(index, 1)"
                    ></i>
                  </el-col>
                </el-row>
              </div>
              <!--       时间不同         -->
              <div v-if="!sameAppointmentTime" v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                <el-row :gutter="20">
                  <el-col :span="4">
                    <el-select v-model="item.week" placeholder="周期">
                      <el-option
                          v-for="item in serveWeek"
                          :key="item.week"
                          :label="item.name"
                          :value="item.week">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <el-form>
                      <el-form-item label="护理时间">
                        <el-time-select
                            style="width: 120px"
                            placeholder="起始时间"
                            v-model="item.beginTime"
                            @change="beginAppointmentTimeChange(item.beginTime,index,false)"
                            format="HH:mm"
                            :picker-options="{
                                                start: '06:00',
                                                step: '00:05',
                                                end: '21:00'
                                              }">
                        </el-time-select>
                        <el-time-select
                            style="width: 120px"
                            placeholder="结束时间"
                            v-model="item.endTime"
                            @change="endTimeAppointmentTimeChange(item.endTime,index,false)"
                            :picker-options="{
                                                start: '07:00',
                                                step: '00:05',
                                                end: '22:00',
                                                minTime:item.beginTime
                                              }">
                        </el-time-select>
                      </el-form-item>
                    </el-form>
                  </el-col>
                  <el-col :span="5">
                    <el-select v-model="item.medicareStatusValue" placeholder="状态">
                      <el-option
                          v-for="item in medicareStatus"
                          :key="item.medicareStatusValue"
                          :label="item.label"
                          :value="item.medicareStatusValue">
                      </el-option>
                    </el-select>
                  </el-col>

                  <el-col :span="3">
                    <!-- 在i标签设置删除按钮，运用splice函数。 -->
                    <i class="el-icon-remove-outline"
                       style="margin-left:20px;margin-top: 10px"
                       @click="appointmentTimePeriodList.splice(index, 1)"
                    ></i>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer" style="margin-left: 70%">
            <el-button v-if="canAppoin2" type="warning" @click="getLog()">查看工单日志</el-button>
            <el-button @click="operaDialog = false" >取消</el-button>
            <el-button type="primary" @click="operaSubmit">确 定</el-button>
          </div>
        </div>

      </div>

    </el-dialog>
    <el-dialog title="备注" :visible.sync="remarks" width="400px">
      <el-form :model="operaForm" label-width="100px">
        <el-form-item label="备注">
          <el-input v-model="operaForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="operaSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="护理员" :visible.sync="careDialog" width="1300px">
      <el-form :inline="true" :model="careForm" class="demo-form-inline" label-width="100px">
        <el-form-item>
          <el-select v-model="careForm.status" class="sel line" @change="getCares()">
            <el-option label="全部状态" value=""></el-option>
            <el-option label="合约中" value="合约中"></el-option>
            <el-option label="中止中" value="中止中"></el-option>
            <el-option label="已终止" value="已终止"></el-option>
            <el-option label="待审核" value="待审核"></el-option>
            <el-option label="已删除" value="已删除"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="careForm.searchCondition" class="inp line" placeholder="关键字"
                    @keyup.enter.native="getCares()"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getCares()">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="careData"
                border
                stripe
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center','man-width':'100px'}"
                max-height="600"
      >
        <el-table-column property="moblle" label="手机号码"></el-table-column>
        <el-table-column property="name" label="姓名"></el-table-column>
        <el-table-column property="status" label="状态"></el-table-column>
        <el-table-column property="serviceHoursMap.week_1" label="周一">
            <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_1 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_1
                }}</span>
              <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_1 }}</span>
            </template>
        </el-table-column>
        <el-table-column property="serviceHoursMap.week_2" label="周二">
          <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_2 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_2
                }}</span>
            <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_2 }}</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceHoursMap.week_3" label="周三">
          <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_3 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_3
                }}</span>
            <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_3 }}</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceHoursMap.week_4" label="周四">
          <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_4 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_4
                }}</span>
            <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_4 }}</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceHoursMap.week_5" label="周五">
          <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_5 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_5
                }}</span>
            <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_5 }}</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceHoursMap.week_6" label="周六">
          <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_6 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_6
                }}</span>
            <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_6 }}</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceHoursMap.week_7" label="周日">
          <template scope="scope">
              <span v-if="scope.row.serviceHoursMap.week_7 > 8" style="color:red">{{
                  scope.row.serviceHoursMap.week_7
                }}</span>
            <span v-else style="color: #37B328">{{ scope.row.serviceHoursMap.week_7 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="seleCare(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="carePageChange"
          :current-page="carePage"
          style="text-align:right;margin-top: 20px"
          layout="total,prev, pager, next, jumper"
          :page-size="10"
          :total="careTotal">
      </el-pagination>
    </el-dialog>
    <el-dialog title="地址" :visible.sync="addressDialog">
      <el-form :inline="true" :model="adressForm" class="demo-form-inline">
        <el-form-item label="区域">
          <el-input v-model="adressForm.area" placeholder="请输入区域" @keyup.enter.native="getAddress"></el-input>
        </el-form-item>
        <el-form-item label="街道">
          <el-input v-model="adressForm.sreet" placeholder="请输入街道"
                    @keyup.enter.native="getAddress"></el-input>
        </el-form-item>
        <el-form-item label="居委会">
          <el-input v-model="adressForm.committees" placeholder="请输入居委会"
                    @keyup.enter.native="getAddress"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAddress">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="addressData"
                border
                stripe
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center','man-width':'100px'}"
                max-height="600"
      >
        <el-table-column property="area" label="区域"></el-table-column>
        <el-table-column property="sreet" label="街道"></el-table-column>
        <el-table-column property="committees" label="居委会"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="seleAddress(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="addressCurrentChange"
          :current-page="addressPage"
          :page-size="addressPSize"
          style="text-align:right;margin-top: 20px"
          layout="total, prev, pager, next, jumper"
          :total="addressTotal">
      </el-pagination>
    </el-dialog>
    <el-dialog title="新增工作流信息填写" :visible.sync="externalCreateWorkflowDialog" width="500px">
      <el-form>
        <el-form-item label="选择认领角色 :" :label-width="formLabelWidth">
          <el-select v-model="claimRoleId" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择已签约类型:" :label-width="formLabelWidth">
          <el-select v-model="createWorkflowEvaluationNursingId">
            <el-option
                v-for="item in customeContracttypesList"
                :key="item.index"
                :label="item.customeContractType"
                :value="item.evaluationNursingId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作内容 : " :label-width="formLabelWidth">
          <el-input v-model="jobTitle"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="externalCreateWorkflowDialog = false">取 消</el-button>
        <el-button type="primary" @click="externalCreateWorkflowButton()">创 建</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增家属信息" :visible.sync="addFamilyInformation" width="500px">
      <el-form>
        <el-form-item label="家属姓名 : " :label-width="formLabelWidth">
          <el-input v-model="family.familyName"></el-input>
        </el-form-item>
        <el-form-item label="紧急联络电话 : " :label-width="formLabelWidth">
          <el-input v-model="family.familyMoblle"></el-input>
        </el-form-item>
        <el-form-item label="家属备注 : " :label-width="formLabelWidth">
          <el-input v-model="family.familyNotes"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFamilyInformation = false">取 消</el-button>
        <el-button type="primary" @click="saveAddFamilyInformation()">创 建</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看家属信息" :visible.sync="familyInformationDialog" width="1200px">
      <el-table :data="familyInformation"
                border
                stripe
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center','man-width':'100px'}"
                max-height="700"
      >
        <el-table-column prop="familyName" label="家属姓名"></el-table-column>
        <el-table-column prop="familyMoblle" label="紧急联络电话"></el-table-column>
        <el-table-column prop="familyNotes" label="家属备注"></el-table-column>
        <el-table-column prop="operation" width="160">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="deleteFamilyInformation(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="自费手动结算" :visible.sync="manualSettlementDialog" width="800px">
      <p style="font-size: 16px;color:#FF1493"><strong>注:该操作会将开始结束日期之间的所有自费工单改为已结算，并算入本月护理员工资</strong></p>
      <div>
        <el-form>
        <el-row :gutter="20">
          <el-form-item label="开始日期">
            <el-date-picker
                v-model="manualSettlement.beginTime"
                type="date"
                style="width: 150px;"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-row>
          <el-row :gutter="20">
            <el-form-item label="结束日期">
              <el-date-picker
                  v-model="manualSettlement.endTime"
                  type="date"
                  placeholder="选择时间"
                  style="width: 150px;">
              </el-date-picker>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-left: 65%">
          <el-button @click="manualSettlementDialog = false" >取消</el-button>
          <el-button type="primary" @click="manualSettlementAtOwnExpenseBu">确 定</el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="工单日志" :visible.sync="dialogTableVisible" width="80%">
      <div style="margin-bottom:10px">
        <el-form :inline="true" class="demo-form-inline" style="z-index:2">
          <el-date-picker
              v-model="logSearchTime"
              type="daterange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"  style="width: 230px">
          </el-date-picker>&nbsp;&nbsp;&nbsp;
          &nbsp;<el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="getLog()"
            style="width:200px"></el-cascader>&nbsp;&nbsp;&nbsp;
          <el-form-item label="操作者:">
            <el-input v-model="handlers" placeholder="" style="width:150px"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="用户:">
            <el-input v-model="form.name" placeholder="" style="width:150px"
                      clearable></el-input>
          </el-form-item>

          <el-button type="primary" size="small" @click="getLog()" style="margin-left:10px">查询</el-button>
<!--          <el-button size="small" type="success" @click="worksheetLogExport()" v-if="this.dailyWorkOrderMoblle.worksheetLogExport">日志导出</el-button>-->
        </el-form>
      </div>
      <el-table :data="gridData"
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center'}"
                border
                stripe
                max-height="750">
        <el-table-column property="handlers" label="操作者" width="95"></el-table-column>
        <el-table-column property="operatingTime" label="操作时间" width="160"></el-table-column>
        <el-table-column property="customerName" label="用户" width="95"></el-table-column>
        <el-table-column property="workOrderNumber" label="工单号" width="200"></el-table-column>
        <el-table-column property="contractTypeName" label="合约类型" width="95"></el-table-column>
        <el-table-column property="siteName" label="护理站" width="95"></el-table-column>
        <el-table-column property="content" label="内容" width="150"></el-table-column>
        <el-table-column property="result" label="操作结果" width="300"></el-table-column>
        <el-table-column property="oerationNote" label="备注"></el-table-column>
      </el-table>
      <div class="block" style="text-align:right">
        <el-pagination
            background
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="pagesizes1"
            :page-size="pagesize1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="all1">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>


<script>

import pageTemp from "@/components/pageTemp.vue";
import {
  cusList,
  getCus,
  addCus,
  cusOpera,
  changeCus,
  addCusTag,
  deleCusTag,
  getCusLog,
  careList,
  getCare,
  neighborhoodCommittee,
  clientPythonExport,
  externalCreateWorkflowButton,
  saveAddFamilyInformation,
  viewOtherFamilyMembers,
  recommendedType,
  deleteFamilyInformation,
  getCusAllLogs,
  allLogsExport,
  siteChange,
  manualSettlementAtOwnExpense, wageExport, workOrderLogList,
} from "@/common/js/index.js";
import {setTime, setDate, isEmpty} from "@/common/js/util.js";

import BMap from 'BMap';

export default {
  inject: ['reload'],
  name: "client",
  components: {pageTemp},
  data() {

    return {
      operatingLoading:false,
      whetherTheCaregiverRecommends: false,// 是否护理员推荐
      sameTime: true, //时间周期里时间是否相同
      sameAppointmentTime: true, //预约时间周期里时间是否相同
      timePeriodList: [], //时间周期
      beginTime: null,
      endTime: null,
      appointmentTimePeriodList: [], //预约时间周期
      medicareStatus: [{
        medicareStatusValue: '0',
        label: '正常'
      }, {
        medicareStatusValue: '2',
        label: '非结算'
      }],
      medicareStatusValue: '0',
      checked: true,
      // 设置只能选择当前日期及之后的日期
      pickerOptions: {
        disabledDate(time) {
          //如果没有后面的-8.64e7就是不可以选择今天的
          return time.getTime() < Date.now();
        }
      },
      createWorkflowEvaluationNursingId: null,// 创建工作流选择合约信息id
      selectEvaluationNursingId: null,// 合约信息id
      selectEvaluationNursingIdLog: null,//日志查询是合约信息ID
      contractTypeId: '',
      newSigningSiteId: '',
      chooseCaregiverSiteId: '',// 选择护理员 老人该合约类型护理站id
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      contractTypeAndSiteInquireValueOptionsTwo: JSON.parse(localStorage.getItem('contractTypeAndNursingStationTwo')),//用户可访问合约类型及护理站权限
      siteList: localStorage.getItem("siteList").split("$"),
      newSigning: false,
      signedDisplay: true,
      newContracttype: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],// 新增合约类型id
      surplusContracttypesList: [],// 老人 未签约 合约类型
      customeContracttypesList: [],// 老人 已签约 合约类型
      customeContracttypesListLog: [],// 日志查询是 老人 已签约 合约类型
      chooseContractTypesId: '',//新增老人时选择合约类型id
      value: '1',
      addFamilyInformation: false,// 新增家属信息
      familyInformationDialog: false,//查看家属信息
      familyInformation: [],
      family: {
        familyName: '',
        familyMoblle: '',
        familyNotes: '',
      },
      formLabelWidth: '120px',
      createWorkflowDialog: false,
      externalCreateWorkflowDialog: false,
      claimRoleId: '',//角色id
      jobTitle: '',//指派工作标题
      options: [{
        value: '2',
        label: '站长'
      }, {
        value: '3',
        label: '服务中心'
      }, {
        value: '4',
        label: '管理层'
      }],
      justSeeTheNote: false, // 只看备注
      isadd: false,
      input: "",
      medicareTypes: localStorage.getItem("medicareCardType").split("$"),
      recommendedTypes: [],
      canAppoin: false,
      canAppoin2: false,
      canAppoin3: false,
      adressForm: {
        area: "",
        sreet: "",
        committees: ""
      },
      addressPage: 1,
      addressTotal: 0,
      addressPSize: 0,
      addressData: [],
      addressDialog: false,
      clientRight: {
        width: ""
      },
      isAssign: false,
      careFlag: 0,//第几个护理员
      careDialog: false,
      careName: "",
      careData: [],
      careForm: {
        searchCondition: "",
        status: "",
      },
      carePage: 0,
      careTotal: 0,
      operaDialog: false,//操作弹窗
      operaForm: {
        targetStatus: "",
        appointmentTime: "",
        statusRemarks: "",
        remarks: "",
      },
      careList: [],//护理员列表
      formInline: {
        logCTime: "",//日志时间筛选
        allLogsTime: "",//全部日志时间筛选
      },
      siteValue: sessionStorage.getItem("siteValue"),
      //siteValue:"",
      allLoading: true,
      load: false,
      cliStatus: "",
      cliText: "",
      isAdd: false,
      operaList: [],
      pages: 0,
      currPage: 1,
      clientArr: [],
      serveWeek: [
        {name: "周一", week: '1'},
        {name: "周二", week: '2'},
        {name: "周三", week: '3'},
        {name: "周四", week: '4'},
        {name: "周五", week: '5'},
        {name: "周六", week: '6'},
        {name: "周日", week: '7'},
      ],
      checkList: [],
      reservation_nursingCycle: [],//意向服务时间（周）
      nursingCycle: [],//护理周期
      careWorker_one: "",//指定护理员1
      careWorker_two: "",//指定护理员2
      careWorker_three: "",//指定护理员3
      reservation_careWorker: "",//预约指定护理员
      nursingTime: "",//护理时间
      nursingAppoTime: "",//预约护理时间
      form: {
        status: "",
        id: "",//用户id
        name: "",//姓名
        moblle: "",//电话
        identityCard: "",//身份证号
        age: "",//年龄
        familyName: "",//家人姓名
        familyMoblle: "",//紧急电话
        gender: "",//性别
        numbering: "",//编号
        medicareCardType: "",//医保卡类型
        recommend: "",//推荐渠道
        recommendedType: "",//推荐类型
        recommendedDetails: "",//推荐明细
        recommendedCaregiverId: "",//推荐护理员ID
        firstCareTime: "",//首次护理时间
        addressDetall: "",//详细地址
        sreet: "",//街道
        committees: "",//居委会
        latltude: "",//纬度
        longltude: "",//经度
        ldentlyCarPlc: null,//身份证正面
        ldentlyCardBackPlc: null,//身份证反面
        medlcareCardPlc: null,//社保卡正面
        medlcareCardPlcBack: null,//社保卡反面
        evaluationLervl: null,//评估等级
        plannlngSheet: "",//护理计划表编码
        evaluationPeriod: "",//评估有效期
        enterPlan: "0",//长护险平台录入计划（是否录入)
        enterPlanFlag: "0",//长护险平台接受（是否接受）
        mdicalPatform: "0",//医护平台派单（是否派单）
        lbeaconMajor: "",//设备编号
        nursingRemarks: "",//护理备注
        careWorker_id_one: null,//指定护理员(id)1
        designationReason_one: "",//指定原因1
        careWorker_id_two: null,//指定护理员(id)2
        designationReason_two: "",//指定原因2
        careWorker_id_three: null,//指定护理员(id)3
        designationReason_three: "",//指定原因3
        reservation_careWorker_id: "",//预约指定护理员
        reservation_designationReason: "",//预约指定原因
        statusRemarks: "", //状态备注
        standbyTimeRemarks: "",//备用时间备注
        appointmentTime: "",//状态操作预约时间
        appointmentCycleChangeTime: "",//预约变更周期时间
        contractInformationId: "",//指定护理员合约信息id
        reservationContractInformationId: "",//预约指定护理员id
        bankCardName: "",//银行卡所属行
        bankCardNumber: "",//银行卡号
        deviceId:"", //蓝牙签到设备ID
      },
      nursingStartTime: null,//护理开始时间
      nursingEndTime: null,//护理结束时间
      reservation_nursingStartTime: null,//预约护理时间（开始）
      reservation_nursingEndtime: null,//预约护理时间（结束）
      cRules: {
        name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
        moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
        identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
        // age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
        status: [{required: true, message: '请选择状态', trigger: 'change'}],
        recommendedTypeId: [{required: true, message: '请选择推荐类型', trigger: 'change'}],
        medicareCardType: [{required: true, message: '请选择医保类型', trigger: 'change'}]
      },
      activeName: 'first',
      lng: "",
      idBefUrl: "",//身份证正面
      idBacUrl: "",//身份证反面
      soBefUrl: "",//社保卡正面
      soBacUrl: "",//社保卡反面
      clientId: null,//客户Id
      /*标签*/
      dynamicTags: [],//标签列表
      inputVisible: false,
      inputValue: '',
      /*日志*/
      clientLogDialog: false,
      clientAllLogsDialog:false,
      logCPage: 1,
      logCData: [],
      logCTotal: 0,
      allLogsPage: 1,
      allLogsData: [],
      allLogsTotal: 0,
      remarks: false,
      lastTime: 0, // 默认上一次点击时间为0
      allLogsCustomerName:'',
      allLogsLoading:true,
      allLogStatus:'',
      customerModule:{
        userList:false,
        selectCustomer:false,
        insertCustomer:false,
        updateCustomer:false,
        customerLogList:false,
        alterStatus:false,
        insertCustomerLabel:false,
        deleteCustomerLabel:false,
        customerImport:false,
        clientPythonExport:false,
        saveAddFamilyInformation:false,
        customerImportArrange:false,
        customerAllLogsList:false,
        customerAllLogsExport:false,
        externalCreateWorkflowButton:false
      },
      manualSettlementDialog:false,
      manualSettlement:{
        beginTime:'',
        endTime:''
      },
      currentPage1: 1,
      pagesize1: 10,
      pagesizes1: [10],
      all1: 0,
      handlers: '',
      dialogTableVisible:false,
      gridData:[],
      logSearchTime: null,
    }
  },

  methods: {
    manualSettlementButton(){
      this.manualSettlementDialog = true
    },
    manualSettlementAtOwnExpenseBu(){
      var siteList = '';

      for (var i = 0; i < this.customeContracttypesList.length; i++) {
        if (this.selectEvaluationNursingId.toString() === this.customeContracttypesList[i].evaluationNursingId.toString()) {
          siteList = this.customeContracttypesList[i].siteId
        }
      }
      if (this.manualSettlement.beginTime!=='' && this.manualSettlement.endTime!==''){
        let subData = "id="+this.clientId+"&evaluationNursingId="+this.selectEvaluationNursingId+"&siteId="+siteList+"&beginTime="+setDate(this.manualSettlement.beginTime)+"&endTime="+setDate(this.manualSettlement.endTime)
        manualSettlementAtOwnExpense(subData).then(res => {
          if (res) {
            this.manualSettlementDialog = false
            this.manualSettlement = {
              beginTime:'',
              endTime:''
            }
            this.$message({
              type: 'success',
              message: '自费手动结算成功，请重新生成本月工资!'
            });
          }
        })
      }else {
        this.$message({
          type: 'info',
          message: '请填写开始结束日期'
        });
      }

    },
    // 判断评估有效期是否填为本年，是则提醒
    evaluationPeriodChange(evaluationPeriod){
      var dd = new Date();
      var year = dd.getFullYear() + Number(0);
      var day = year + "-12-31";
      var ep = setDate(evaluationPeriod);
      if (ep <= day) {
        this.$message({
          message: '评估有效期时间填写为本年，请确认是否正确',
          type: 'warning'
        });
      }
    },
    deleteFamilyInformation(id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = 'id=' + id
        deleteFamilyInformation(subData).then(res => {
          if (res) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.viewOtherFamilyMembers();
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    recommendedTypeChange() {
      if (this.form.recommendedTypeId === 1) {
        this.whetherTheCaregiverRecommends = true;
      } else {
        this.whetherTheCaregiverRecommends = false;
      }
    },
    beginTimeChange(beginTime,index,status) {
      // status = true 时间相同，false时间不同
      if (status){
        for (let i = 0; i < this.timePeriodList.length; i++) {
          if (beginTime.length === 5) {
            beginTime = beginTime + ":00";
          }
          this.timePeriodList[i].beginTime = beginTime
          let arrTime = beginTime.split(':');
          let time2 = arrTime.slice(1, arrTime.length).join(':');
          let h = parseInt(arrTime[0]) + 1;
          let newH = ((h < 24) ? h : (h % 24)).toString();
          let endTime = newH + ':' + time2;
          this.timePeriodList[i].endTime = endTime
        }
      }else {
        if (beginTime.length === 5) {
          beginTime = beginTime + ":00";
        }
        this.timePeriodList[index].beginTime = beginTime
        let arrTime = beginTime.split(':');
        let time2 = arrTime.slice(1, arrTime.length).join(':');
        let h = parseInt(arrTime[0]) + 1;
        let newH = ((h < 24) ? h : (h % 24)).toString();
        let endTime = newH + ':' + time2;
        this.timePeriodList[index].endTime = endTime
      }

    },
    endTimeChange(endTime,index,status) {
      // status = true 时间相同，false时间不同
      if (status){
        for (let i = 0; i < this.timePeriodList.length; i++) {
          if (endTime.length === 5) {
            endTime = endTime + ":00";
          }
          this.timePeriodList[i].endTime = endTime
        }
      }else {
        if (endTime.length === 5) {
          endTime = endTime + ":00";
        }
        this.timePeriodList[index].endTime = endTime
      }

    },

    beginAppointmentTimeChange(beginTime,index,status) {
      // status = true 时间相同，false时间不同
      if (status){
        for (let i = 0; i < this.appointmentTimePeriodList.length; i++) {
          if (beginTime.length === 5) {
            beginTime = beginTime + ":00";
          }
          this.appointmentTimePeriodList[i].beginTime = beginTime
          let arrTime = beginTime.split(':');
          let time2 = arrTime.slice(1, arrTime.length).join(':');
          let h = parseInt(arrTime[0]) + 1;
          let newH = ((h < 24) ? h : (h % 24)).toString();
          let endTime = newH + ':' + time2;
          this.appointmentTimePeriodList[i].endTime = endTime
        }
      }else {
        if (beginTime.length === 5) {
          beginTime = beginTime + ":00";
        }
        this.appointmentTimePeriodList[index].beginTime = beginTime
        let arrTime = beginTime.split(':');
        let time2 = arrTime.slice(1, arrTime.length).join(':');
        let h = parseInt(arrTime[0]) + 1;
        let newH = ((h < 24) ? h : (h % 24)).toString();
        let endTime = newH + ':' + time2;
        this.appointmentTimePeriodList[index].endTime = endTime
      }

    },
    endTimeAppointmentTimeChange(endTime,index,status) {
      // status = true 时间相同，false时间不同
      if (status){
        for (let i = 0; i < this.appointmentTimePeriodList.length; i++) {
          if (endTime.length === 5) {
            endTime = endTime + ":00";
          }
          this.appointmentTimePeriodList[i].endTime = endTime
        }
      }else {
        if (endTime.length === 5) {
          endTime = endTime + ":00";
        }
        this.appointmentTimePeriodList[index].endTime = endTime
      }
    },
    // 新增相同时间周期
    addSameTimePeriod() {
      if (this.timePeriodList.length !== 0) {
        this.timePeriodList.unshift({
          beginTime: this.timePeriodList[0].beginTime,
          endTime: this.timePeriodList[0].endTime,
          medicareStatusValue: "0"
        });
      } else {
        this.timePeriodList.unshift({
          beginTime: this.beginTime,
          endTime: this.endTime,
          medicareStatusValue: "0"
        });
      }

    },
    // 新增不同时间周期
    addDifferentTimePeriod() {
      this.timePeriodList.unshift({
        beginTime: this.beginTime,
        endTime: this.endTime,
        medicareStatusValue: "0"
      });
    },
    // 新增预约相同时间周期
    addSameAppointmentTimePeriod() {
      if (this.appointmentTimePeriodList.length !== 0) {
        this.appointmentTimePeriodList.unshift({
          beginTime: this.appointmentTimePeriodList[0].beginTime,
          endTime: this.appointmentTimePeriodList[0].endTime,
          medicareStatusValue: "0"
        });
      } else {
        this.appointmentTimePeriodList.unshift({
          beginTime: this.beginTime,
          endTime: this.beginTime,
          medicareStatusValue: "0"
        });
      }
    },
    // 新增预约不同时间周期
    addDifferentAppointmentTimePeriod() {
      this.appointmentTimePeriodList.unshift({
        beginTime: this.beginTime,
        endTime: this.endTime,
        medicareStatusValue: "0"
      });
    },
    selectContractTypeNursingStation() {
      var str = this.contractTypeAndSiteInquireValue.toString();
      // 新增老人时选择合约类型id
      this.chooseCaregiverSiteId = str.charAt(0)
    },
    siteChange() {
      this.chooseCaregiverSiteId = this.contractTypeAndSiteInquireValue.toString().charAt(0)
    },
    // 新增合约
    addContractType() {
      this.newcontracttype = null;
      this.signedDisplay = false;
      this.nursingStartTime = '';//护理开始时间
      this.nursingEndTime = '';//护理结束时间
      this.reservation_nursingStartTime = '';//预约护理时间（开始）
      this.reservation_nursingEndtime = '';//预约护理时间（结束）
      this.nursingTime = "";
      this.nursingAppoTime = '';
      this.reservation_nursingCycle = [];//预约服务时间（周）
      this.nursingCycle = [];//护理周期
      this.careWorker_one = "";//指定护理员1
      this.careWorker_two = "";//指定护理员2
      this.careWorker_three = "";//指定护理员3
      this.reservation_careWorker = "";//预约指定护理员
      this.form.status = null;
      this.form.numbering = "";//编号
      this.form.medicareCardType = "";//医保卡类型
      this.form.recommend = "";//推荐渠道
      this.form.recommendedTypeId = "";//推荐类型
      this.form.recommendedDetails = "";//推荐明细
      this.form.recommendedCaregiverId = "";//推荐护理员ID
      this.form.firstCareTime = "";//首次护理时间
      // this.form.addressDetall =  "";//详细地址
      // this.form.sreet =  "";//街道
      // this.form.committees =  "";//居委会
      // this.form.latltude =  "";//纬度
      // this.form.longltude =  "";//经度
      this.form.ldentlyCarPlc = null;//身份证正面
      this.form.ldentlyCardBackPlc = null;//身份证反面
      this.form.medlcareCardPlc = null;//社保卡正面
      this.form.medlcareCardPlcBack = null;//社保卡反面
      this.form.evaluationLervl = null;//评估等级
      this.form.plannlngSheet = "";//护理计划表编码
      this.form.evaluationPeriod = "";//评估有效期
      this.form.enterPlan = "0";//长护险平台录入计划（是否录入)
      this.form.enterPlanFlag = "0";//长护险平台接受（是否接受）
      this.form.mdicalPatform = "0";//医护平台派单（是否派单）
      this.form.lbeaconMajor = "";//设备编号
      this.form.nursingRemarks = "";//护理备注
      this.form.careWorker_id_one = null;//指定护理员(id)
      this.form.designationReason_one = "";//指定原因1
      this.form.careWorker_id_two = null;//指定护理员(id)2
      this.form.designationReason_two = "";//指定原因2
      this.form.careWorker_id_three = null;//指定护理员(id)3
      this.form.designationReason_three = "";//指定原因3
      this.form.reservation_careWorker_id = "";//预约指定护理员
      this.form.reservation_designationReason = "";//预约指定原因
      this.form.standbyTimeRemarks = "";//备用时间备注
      this.form.appointmentTime = "";//状态操作预约时间
      this.form.recommendedCaregiverId = null;// 推荐护理员ID
      this.idBefUrl = "";
      this.idBacUrl = "";
      this.soBefUrl = "";
      this.soBacUrl = "";
      this.appointmentTimePeriodList = [];
      this.timePeriodList = [];
      this.newSigning = true;
    },
    // 关闭操作
    closeOperaDialog() {
      this.reservation_nursingStartTime = ''
      this.reservation_nursingEndtime = ''
      this.reservation_nursingCycle = ''
      this.reservation_careWorker = ''
      this.form.reservation_careWorker_id = ''
      this.getClients();
      this.clientDes(this.clientId);
    },
    /**
     * 操作客户cusOpera
     * */
    operaClient(command) {

      if (command === 'empty') {
        this.operaDialog = false;
      } else {
        this.operaForm.statusRemarks = "";
        if (command.state === '1') {
          this.canAppoin = true;
        } else {
          this.canAppoin = false;
        }
        if (command.state === '2') {
          // var date=new Date();
          // date.setMonth(date.getMonth()+3);
          // this.logSearchTime = [new Date(),date]

          this.reservation_nursingStartTime = this.nursingStartTime
          this.reservation_nursingEndtime = this.nursingEndtime
          this.reservation_nursingCycle = this.nursingCycle
          this.reservation_careWorker = this.careWorker_one
          this.form.reservation_careWorker_id = this.form.careWorker_id_one
          this.form.reservationContractInformationId = this.form.contractInformationId
          console.log("name:"+this.form.name)
          this.canAppoin2 = true;
        } else {
          this.canAppoin2 = false;
        }
        if (command.state === '3') {
          this.canAppoin3 = true;
        } else {
          this.canAppoin3 = false;
        }
        this.operaDialog = true;
        this.operaForm.targetStatus = command.value;
        this.operaForm.appointmentTime = "";
      }
    },
    getLog() {   //日志
      let subData = 'currPage=' + this.currentPage1
          + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + "&handlers=" + this.handlers + "&beOperatedCustomerName=" + this.form.name
          + "&contractTypeId=" + this.contractTypeAndSiteInquireValue.toString().charAt(2)
      if (this.logSearchTime !== '' && this.logSearchTime != null) {
        this.logSearchTime[0] = setDate(this.logSearchTime[0])
        this.logSearchTime[1] = setDate(this.logSearchTime[1])
        subData += '&beginCreateTime=' + this.logSearchTime[0] +
            '&endCreateTime=' + this.logSearchTime[1]
      }

      workOrderLogList(subData).then(res => {
        this.gridData = res.data
        if (res.data.length !== 0) {
          this.all1 = this.gridData[0].totalCount
          this.pagesizes1 = []
          this.pagesizes1.push(this.gridData[0].pageSize)
        } else {
          this.all1 = 0
          this.pagesizes1 = [10]
        }

        // this.currentPage1 = this.gridData[0].currentPage
      })
      this.dialogTableVisible = true
    },
    handleSizeChange1(val) {  //日志表格的
      this.pagesize1 = val
    },
    handleCurrentChange1(val) {  //日志表格的
      this.currentPage1 = val
      this.getLog()
    },
    viewOtherFamilyMembers() {
      let subData = 'clientId=' + this.clientId
      viewOtherFamilyMembers(subData).then(res => {
        if (res) {
          this.familyInformation = res.data
        }
      })
      this.familyInformationDialog = true;
    },
    addFamily() {
      this.addFamilyInformation = true;
    },
    saveAddFamilyInformation() {
      let subData = 'clientId=' + this.clientId
          + '&familyName=' + this.family.familyName
          + '&familyMoblle=' + this.family.familyMoblle
          + '&familyNotes=' + this.family.familyNotes;
      saveAddFamilyInformation(subData).then(res => {
        if (res) {
          this.$message.success('增加家属信息完成');
          this.addFamilyInformation = false;
        }
      })
    },
    /**
     *创建工作流
     */
    externalCreateWorkflow() {
      this.externalCreateWorkflowDialog = true
    },
    externalCreateWorkflowButton() {
      let siteList = '';
      for (let i = 0; i < this.customeContracttypesList.length; i++) {
        if (this.contracttypes === this.customeContracttypesList[i].contractTypeId) {
          siteList = this.customeContracttypesList[i].siteId
        }
      }
      let subData = 'clientId=' + this.clientId
          + '&claimRoleId=' + this.claimRoleId
          + '&jobTitle=' + this.jobTitle
          + '&siteList=' + siteList
          + '&evaluationNursingId=' + this.createWorkflowEvaluationNursingId;
      externalCreateWorkflowButton(subData).then(res => {
        if (res) {
          this.$message.success('创建完成');
          this.externalCreateWorkflowDialog = false;
          this.jobTitle = '';
          this.clientId = '';
          this.claimRoleId = '';
          this.clientName = '';
          this.getDataList();
        }
      })
    },
    deleteCareWorker_one() {
      this.$confirm('', '确定清空指定护理员', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        type: 'warning'
      }).then(() => {
        this.careWorker_one = null;
        this.form.careWorker_id_one = null;
      }).catch(() => {
      });

    },
    /*用户基本信息批量导出*/
    clientExport() {
      this.$confirm('生成用户信息中,请等待,稍后去报表中查看下载', '确定导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "searchCondition=" + this.cliText + "&status=" + this.cliStatus
            + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
            + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
        clientPythonExport(subData).then(res => {
          if (res) {
            this.$message.success('用户信息导出成功,请到报表中进行查看下载');
            this.tableData = res.data
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].date != null && this.tableData[i].date !== '') {
                this.tableData[i].date = this.changeTime(this.tableData[i].date)
              }
            }
            this.change()//改变0/1为已完成/未完成模式

          }
        })

      }).catch(() => {
      });
    },
    /**
     * 地址栏输入用户id 提取
     * */
    submit() {
      var newurl = this.updateParam(window.location.href, 'clientId', this.input);
      //向当前url添加参数，没有历史记录
      window.history.replaceState({
        path: newurl
      }, '', newurl);
    },
    updateParam(uri, key, value) {
      if (!value) {
        return uri;
      }
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      } else {
        return uri + separator + key + "=" + value;
      }
    },

    /**
     * 选择地址
     * */
    toAddress() {
      this.adressForm = {
        area: "",
        sreet: "",
        committees: ""
      };
      this.getAddress();
    },
    seleAddress(row) {
      this.addressDialog = false;
      this.form.sreet = row.sreet;
      this.form.committees = row.committees;
    },
    getAddress() {
      let data = 'currPage=' + this.addressPage + '&pageSizePara=' + "10" + "&area=" + this.adressForm.area + "&sreet=" + this.adressForm.sreet + "&committees=" + this.adressForm.committees;
      neighborhoodCommittee(data).then(res => {
        if (res) {
          this.addressDialog = true;
          this.addressData = res.data;
          this.addressPSize = res.data[0].totalPage;
          this.addressTotal = res.data[0].totalCount;
        }
      });
    },
    addressCurrentChange(val) {
      this.addressPage = val;
      this.getAddress();
    },
    /**
     *选择护理员
     * */
    careInput(num) {
      this.careFlag = num;
      this.careDialog = true;
      this.carePage = 1;
      this.careForm.status = "";
      this.careForm.searchCondition = "";
      this.getCares();
    },
    getCares() {
      let subData = "currPage=" + this.carePage + "&searchCondition=" + this.careForm.searchCondition + "&status=" + this.careForm.status
          + "&siteList=" + this.form.siteId
          + '&contractTypeId=' + this.form.contractTypeId
      careList(subData).then(res => {
        if (res) {
          this.careData = res.data;
          this.careTotal = res.data[0].totalCount;
        }
      })
    },
    carePageChange(val) {
      this.carePage = val;
      this.getCares();
    },
    //选择4个护理员
    seleCare(row) {
      this.careDialog = false;
      switch (this.careFlag) {
        case 1:
          this.careWorker_one = row.name;
          this.form.careWorker_id_one = row.id;
          this.form.contractInformationId = row.contractInformationId;
          break;
        case 2:
          this.careWorker_two = row.name;
          this.form.careWorker_id_two = row.id;
          this.form.contractInformationId = row.contractInformationId;
          break;
        case 3:
          this.careWorker_three = row.name;
          this.form.careWorker_id_three = row.id;
          this.form.contractInformationId = row.contractInformationId;
          break;
        case 4:
          this.reservation_careWorker = row.name;
          this.form.reservation_careWorker_id = row.id;
          this.form.reservationContractInformationId = row.contractInformationId;
          break;
        case 5:
          this.form.recommendedDetails = row.name;
          this.form.recommendedCaregiverId = row.id;
          break;
        default:
          break;
      }

    },
    //获取护理员名称
    nurseDes(id, contractInformationId, num) {
      let subData = 'id=' + id + '&contractInformationId=' + contractInformationId;
      getCare(subData).then(res => {
        if (res) {
          let name = res.data['basicCaregiver'][0].name;
          switch (num) {
            case 1:
              this.careWorker_one = name;
              break;
            case 2:
              this.careWorker_two = name;
              break;
            case 3:
              this.careWorker_three = name;
              break;
            case 4:
              this.reservation_careWorker = name;
              break;
            default:
              break;
          }
        }
      })
    },
    //全部日志
    allLogsBu(){
      this.formInline.allLogsTime = "";
      this.allLogsRequest();
    },
    allLogTimeSubmit() {
      this.allLogsPage = 1;
      this.allLogsRequest()
    },
    allLogsExportButton(){
      this.$confirm('导出老人日志中,请等待,稍后去报表中查看下载', '确定导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let subData = "siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
            + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2)
            + '&name=' + this.allLogsCustomerName
            + '&status=' + this.allLogStatus
        if (isEmpty(this.formInline.allLogsTime)) {
          subData = subData + "&beginCreateTime=" + setTime(this.formInline.allLogsTime[0])
              + "&endCreateTime=" + setTime(this.formInline.allLogsTime[1])
        }
        allLogsExport(subData).then(res => {
          if (res) {
            this.$message.success('日志导出成功,请到报表中进行查看下载');
          }
        })
      }).catch(() => {
      });

    },
    allLogsRequest() {
      this.allLogsLoading = true
      let subData = "currPage=" + this.allLogsPage
          + "&siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2)
          + '&name=' + this.allLogsCustomerName
          + '&status=' + this.allLogStatus
      if (isEmpty(this.formInline.allLogsTime)) {
        subData = subData + "&beginCreateTime=" + setTime(this.formInline.allLogsTime[0])
            + "&endCreateTime=" + setTime(this.formInline.allLogsTime[1])
      }
      this.clientAllLogsDialog = true;
      getCusAllLogs(subData).then(res => {
        if (res) {
          this.allLogsData = res.data.allLogsPage;
          this.allLogsTotal = res.data.list[0].totalCount;
          this.allLogsData.sort(function (a, b) {
            return b.operatingTime > a.operatingTime ? 1 : -1;
          });
          this.allLogsLoading = false
        }
      })
    },
    /**
     *日志
     * */
    logTimeSubmit() {
      this.logCPage = 1;
      this.logRequest();
    },
    logRequest() {
      let subData = "";
      if (isEmpty(this.formInline.logCTime)) {
        subData = "clientId=" + this.clientId + "&currPage=" + this.logCPage + "&justSeeTheNote=" + this.justSeeTheNote +
            "&beginCreateTime=" + setTime(this.formInline.logCTime[0]) + "&endCreateTime=" + setTime(this.formInline.logCTime[1]) + "&evaluationNursingId=" + this.selectEvaluationNursingIdLog;
      } else {
        subData = "clientId=" + this.clientId + "&currPage=" + this.logCPage + "&justSeeTheNote=" + this.justSeeTheNote + "&evaluationNursingId=" + this.selectEvaluationNursingIdLog;
      }
      getCusLog(subData).then(res => {
        if (res) {
          this.clientLogDialog = true;
          this.logCData = res.data;
          for (let logCDatum of this.logCData) {
            if (logCDatum.module === '工作流' && logCDatum.result !== null) {
              var reg = new RegExp("<br/>", "g");
              logCDatum.result = logCDatum.result.replace(reg, "\n");
            }
          }
          if (res.data.length === 0) {
            this.logCTotal = 0
          } else {
            this.logCTotal = res.data[0].totalCount;
          }
          this.logCData.sort(function (a, b) {
            return b.operatingTime > a.operatingTime ? 1 : -1;
          });
        }
      })
    },
    logBu() {
      this.formInline.logCTime = "";
      this.logRequest();
    },
    handleCurrentChange(val) {
      this.logCPage = val;
      this.logRequest();
    },
    handleCurrentChange2(val) {
      this.allLogsPage = val;
      this.allLogsRequest();
    },
    /**
     * 标签
     * */
    handleClose(tag) {
      let subData = {
        id: this.clientId,
        label: tag,
        siteList: this.contractTypeAndSiteInquireValue.toString().charAt(0)
      }
      deleCusTag(subData).then(res => {
        if (res) {
          this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        }
      })
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        let subData = {
          id: this.clientId,
          label: inputValue,
          siteList: this.contractTypeAndSiteInquireValue.toString().charAt(0)
        }
        addCusTag(subData).then(res => {
          if (res) {
            this.$message.success('添加标签成功');
            this.dynamicTags.push(inputValue);
          }
        })
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    /**
     * 分页
     * */
    goSpecifiedPage(val) {
      this.currPage = val;
      this.getClients();
    },
    /**
     * 获取客户列表
     * */
    getClients() {
      let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus
          + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
      cusList(subData).then(res => {
        if (res) {
          this.clientArr = res.data;
          if (res.data.length !== 0) {
            this.pages = res.data[0].totalCount;
          }
          this.allLoading = false;
        }
      })

    },
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.customerModule.userList = this.isExist(permissionList,"customer:userList");
          this.customerModule.selectCustomer = this.isExist(permissionList,"customer:selectCustomer");
          this.customerModule.insertCustomer = this.isExist(permissionList,"customer:insertCustomer");
          this.customerModule.updateCustomer = this.isExist(permissionList,"customer:updateCustomer");
          this.customerModule.customerLogList = this.isExist(permissionList,"customer:customerLogList");
          this.customerModule.alterStatus = this.isExist(permissionList,"customer:alterStatus");
          this.customerModule.insertCustomerLabel = this.isExist(permissionList,"customer:insertCustomerLabel");
          this.customerModule.deleteCustomerLabel = this.isExist(permissionList,"customer:deleteCustomerLabel");
          this.customerModule.customerImport = this.isExist(permissionList,"customer:customerImport");
          this.customerModule.clientPythonExport = this.isExist(permissionList,"customer:clientPythonExport");
          this.customerModule.saveAddFamilyInformation = this.isExist(permissionList,"customer:saveAddFamilyInformation");
          this.customerModule.customerImportArrange = this.isExist(permissionList,"customer:customerImportArrange");
          this.customerModule.customerAllLogsList = this.isExist(permissionList,"customer:customerAllLogsList");
          this.customerModule.customerAllLogsExport = this.isExist(permissionList,"customer:customerAllLogsExport");
          this.customerModule.externalCreateWorkflowButton = this.isExist(permissionList,"customerWorkflow:externalCreateWorkflowButton");
          this.getClients();
         }
      })
    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    operaSubmit() {
      this.operatingLoading = true;
      let formData = new FormData();
      formData.append("id", this.clientId);
      // 查看老人详情中选择合约类型id
      formData.append("evaluationNursingId", this.selectEvaluationNursingId);//合约类型
      formData.append("contractTypeId", this.contractTypeId);//合约类型
      formData.append("statusRemarks", this.operaForm.statusRemarks);//现在的状态
      isEmpty(this.operaForm.appointmentTime) && formData.append("appointmentTime", setDate(this.operaForm.appointmentTime));
      formData.append("targetStatus", this.operaForm.targetStatus);//目标状态
      var siteList = '';

      for (var i = 0; i < this.customeContracttypesList.length; i++) {
        if (this.selectEvaluationNursingId.toString() === this.customeContracttypesList[i].evaluationNursingId.toString()) {
          siteList = this.customeContracttypesList[i].siteId
        }
      }
      if (this.canAppoin3){
        if (this.operaForm.appointmentTime === '') {
          this.$message({
            message: "请选择合约延期时间时间",
            type: 'warning'
          })
          this.operatingLoading = false;
          return
        }

      }
      formData.append("siteList", siteList);

      if (this.operaForm.targetStatus === "预约变更周期") {
        let appointmentTimePeriod = [];
        for (let i = 0; i < this.appointmentTimePeriodList.length; i++) {
          let test = {
            "week": this.appointmentTimePeriodList[i].week,
            "beginTime": this.appointmentTimePeriodList[i].beginTime,
            "endTime": this.appointmentTimePeriodList[i].endTime,
            "medicareStatusValue": this.appointmentTimePeriodList[i].medicareStatusValue
          };
          appointmentTimePeriod.push(test)

        }
        let tp = null;
        if (this.sameAppointmentTime === true) {
          tp = 1
        } else {
          tp = 0
        }
        formData.append("appointmentTimePeriod", JSON.stringify({tp: tp, dt: appointmentTimePeriod}));
        formData.append("reservation_careWorker_id", this.form.reservation_careWorker_id);
        formData.append("reservationContractInformationId", this.form.reservationContractInformationId);
        isEmpty(this.form.careWorker_id_one) && formData.append("careWorker_id_one", this.form.careWorker_id_one);//指定护理员1
        if (this.operaForm.appointmentTime === '') {
          this.$message({
            message: "请选择预约变更时间",
            type: 'warning'
          })
          this.operatingLoading = false;
        } else {
          cusOpera(formData).then(res => {
            this.operatingLoading = false;
            this.operaDialog = false;
            this.$message.success('操作成功');
            this.reservation_nursingStartTime = ''
            this.reservation_nursingEndtime = ''
            this.reservation_nursingCycle = ''
            this.reservation_careWorker = ''
            this.form.reservation_careWorker_id = ''
            this.getClients();
            this.clientDes(this.clientId);
          })
        }
      } else {
        cusOpera(formData).then(res => {
          this.operatingLoading = false;
          this.operaDialog = false;
          this.$message.success('操作成功');
          this.reservation_nursingStartTime = ''
          this.reservation_nursingEndtime = ''
          this.reservation_nursingCycle = ''
          this.reservation_careWorker = ''
          this.form.reservation_careWorker_id = ''
          this.getClients();
          this.clientDes(this.clientId);

        })
      }
    },
    /**
     * 保存修改
     * */
    chanSave: function (formName) {
      //获取当前时间的时间戳
      let now = new Date().valueOf();
      if (this.lastTime === 0 || (now - this.lastTime) > 5000) {
        this.load = true;
        //重置上一次点击时间，2000是我自己设置的2秒间隔，根据自己的需要更改
        this.lastTime = now;
        console.log('间隔大于5秒，触发方法');
        //添加自己要调用的方法
        let timePeriod = [];
        for (let i = 0; i < this.timePeriodList.length; i++) {
          let test = {
            "week": this.timePeriodList[i].week,
            "beginTime": this.timePeriodList[i].beginTime,
            "endTime": this.timePeriodList[i].endTime,
            "medicareStatusValue": this.timePeriodList[i].medicareStatusValue
          };
          timePeriod.push(test)

        }

        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formData = this.getformData();
            let tp = null;
            if (this.sameTime === true) {
              tp = 1
            } else {
              tp = 0
            }
            formData.append("timePeriod", JSON.stringify({tp: tp, dt: timePeriod}));
            changeCus(formData).then(res => {
              if (res) {
                this.load = false;
                this.$message.success('修改用户成功');
                this.$refs.idBefUpload.uploadFiles = [];
                this.$refs.idBacUpload.uploadFiles = [];
                this.$refs.soBefUpload.uploadFiles = [];
                this.$refs.soBacUpload.uploadFiles = [];
                this.clientDes(this.clientId)
              }
            })
          } else {
            this.load = false;
            return false;
          }
        });
      } else {
        console.log('不触发');
        this.$message.warning('保存操作太频繁，请等待重试');
      }
    },
    /**
     * 获取客户详情
     * */
    clientDes(id, evaluationNursingId, contractTypeId) {
      this.customeContracttypesList = null
      this.signedDisplay = true;
      this.newSigning = false;
      this.load = true;
      this.fatForm();
      this.clientId = id;
      this.isAdd = false;

      if (evaluationNursingId != null) {
        this.selectEvaluationNursingId = evaluationNursingId
        this.selectEvaluationNursingIdLog = evaluationNursingId
      }

      if (contractTypeId != null) {
        this.contractTypeId = contractTypeId
      }
      let subData = "id=" + id + '&evaluationNursingId=' + this.selectEvaluationNursingId;
      var s = this
      // alert(subData);
      getCus(subData).then(res => {
        this.allLoading = false;
        this.load = false;

        if (res) {
          this.customeContracttypesList = res.data['customeContracttypesList']
          this.customeContracttypesListLog = res.data['customeContracttypesList']
          for (let i = 0; i < this.customeContracttypesList.length; i++) {
            if (this.customeContracttypesList[i].evaluationNursingId === this.selectEvaluationNursingId) {
              this.contractTypeId = this.customeContracttypesList[i].contractTypeId
            }
          }
          this.surplusContracttypesList = res.data['surplusContracttypesList']
          var map = new BMap.Map("map");
          var point = new BMap.Point(res.data['basicInformation'][0].longltude, res.data['basicInformation'][0].latltude);
          const sameTime = res.data['basicInformation'][0].sameTime
          const sameAppointmentTime = res.data['basicInformation'][0].sameAppointmentTime
          if (sameTime === "1" || sameTime === null) {
            this.sameTime = true
          } else {
            this.sameTime = false
          }
          if (sameAppointmentTime === "1" || sameAppointmentTime === null) {
            this.sameAppointmentTime = true
          } else {
            this.sameAppointmentTime = false
          }
          let timePeriod = res.data['basicInformation'][0].timePeriod;
          if (timePeriod != null) {
            this.timePeriodList = JSON.parse(timePeriod);
            this.timePeriodList.sort(function (a, b) {
              return b.week > a.week ? -1 : 1;
            });

          } else {
            this.timePeriodList = []
          }
          let appointmentTimePeriod = res.data['basicInformation'][0].appointmentTimePeriod;
          if (appointmentTimePeriod != null) {
            this.appointmentTimePeriodList = JSON.parse(appointmentTimePeriod);
            this.appointmentTimePeriodList.sort(function (a, b) {
              return b.week > a.week ? -1 : 1;
            });

          } else {
            this.appointmentTimePeriodList = []
          }


          map.centerAndZoom(point, 15);
          var marker = new BMap.Marker(point); // 创建点
          map.addOverlay(marker);

          function showInfo(e) {
            map.clearOverlays();
            //改经纬度
            var marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)); // 创建点
            map.addOverlay(marker);//增加点
            s.form.longltude = e.point.lng;
            s.form.latltude = e.point.lat;
          }

          map.addEventListener("click", showInfo);

          this.form = res.data['basicInformation'][0];
          if (this.form.recommendedTypeId === 1) {
            this.whetherTheCaregiverRecommends = true;
          } else {
            this.whetherTheCaregiverRecommends = false;
          }
          this.chooseCaregiverSiteId = this.form.siteId
          this.idBefUrl = res.data['basicInformation'][0].ldentlyCarPlc;
          this.idBacUrl = res.data['basicInformation'][0].ldentlyCardBackPlc;
          this.soBefUrl = res.data['basicInformation'][0].medlcareCardPlc;
          this.soBacUrl = res.data['basicInformation'][0].medlcareCardPlcBack;
          this.operaList = [];
          for (let i = 0; i < res.data['list'].length; i++) {
            this.operaList.push({
              value: res.data['list'][i].split(',')[0],
              state: res.data['list'][i].split(',')[1],
            })
          }
          this.dynamicTags = res.data['label'];
          if (res.data['basicInformation'][0].nursingCycle != null) {
            this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
          } else {
            this.nursingCycle = [];
          }
          if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
            this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
          } else {
            this.reservation_nursingCycle = [];
          }
          if (isEmpty(this.form.careWorker_id_one)) {
            this.nurseDes(this.form.careWorker_id_one, this.form.contractInformationId, 1)
          }
          if (isEmpty(this.form.careWorker_id_two)) {
            this.nurseDes(this.form.careWorker_id_two, this.form.contractInformationId, 2)
          }
          if (isEmpty(this.form.careWorker_id_three)) {
            this.nurseDes(this.form.careWorker_id_three, this.form.contractInformationId, 3)
          }
          if (isEmpty(this.form.reservation_careWorker_id)) {
            this.nurseDes(this.form.reservation_careWorker_id, this.form.reservationContractInformationId, 4)
          }
          this.nursingStartTime = this.form.nursingStartTime;
          this.nursingEndTime = this.form.nursingEndTime;
          this.reservation_nursingStartTime = this.form.reservation_nursingStartTime;
          this.reservation_nursingEndtime = this.form.reservation_nursingEndtime;
        }

      })
    },
    // 文件上传
    uploadFile(params) {
    },
    //新增客户
    addClient(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = this.getformData();
          let timePeriod = [];
          for (let i = 0; i < this.timePeriodList.length; i++) {
            let test = {
              "week": this.timePeriodList[i].week,
              "beginTime": this.timePeriodList[i].beginTime,
              "endTime": this.timePeriodList[i].endTime,
              "medicareStatusValue": this.timePeriodList[i].medicareStatusValue
            };
            timePeriod.push(test)

          }
          formData.append("timePeriod", JSON.stringify({tp: 1, dt: timePeriod}));
          formData.append("status", this.form.status);
          addCus(formData).then(res => {
            if (res) {
              this.$message.success('新增用户成功');
              this.isAdd = false;
              this.clientId = res.data.id;
              this.getClients();
              this.clientDes(this.clientId)
            }
          })
        } else {
          return false;
        }
      });
    },
    /**
     * 提交的数据
     * */
    getformData() {
      let formData = new FormData();

      formData.append("name", this.form.name);//姓名
      if (!this.isAdd) {
        formData.append("id", this.form.id);//id

        if (this.newSigning) {
          // 老人新增合约类型选择合约类型id
          formData.append("contractTypeId", this.newContracttype.toString().charAt(2));//合约类型
          formData.append("siteList", this.newContracttype.toString().charAt(0));
          formData.append("status", this.form.status);//合约类型
          console.log(" 老人新增合约类型选择合约类型id")
        } else {
          formData.append("evaluationNursingId", this.selectEvaluationNursingId);//合约信息
          // 查看老人详情中选择合约类型id
          formData.append("contractTypeId", this.contractTypeId);//合约类型
          var siteList = '';
          for (var i = 0; i < this.customeContracttypesList.length; i++) {
            if (this.contractTypeId.toString() === this.customeContracttypesList[i].contractTypeId.toString()) {
              siteList = this.customeContracttypesList[i].siteId
            }
          }
          formData.append("siteList", siteList);
          console.log(" 查看老人详情中选择合约类型id")
        }
      }
      if (this.isAdd) {
        var str = this.contractTypeAndSiteInquireValue.toString();
        // 新增老人时选择合约类型id
        formData.append("contractTypeId", str.charAt(2));
        if (str.charAt(0) === '1') {
          formData.append("siteList", '1,巾帼站');
        } else if (str.charAt(0) === '2') {
          formData.append("siteList", '2,建浦站');
        } else if (str.charAt(0) === '3') {
          formData.append("siteList", '3,安康通');
        } else if (str.charAt(0) === '4') {
          formData.append("siteList", '4,震瀛二站');
        } else if (str.charAt(0) === '5') {
          formData.append("siteList", '5,震瀛站');
        } else if (str.charAt(0) === '6'){
          formData.append("siteList", '6,优蓓杰');
        } else if (str.charAt(0) === '7'){
          formData.append("siteList", '7,雨花站');
        }

        console.log(" 新增老人时选择合约类型id")
      }
      formData.append("bankCardName", this.form.bankCardName);//银行卡所属行
      formData.append("bankCardNumber", this.form.bankCardNumber);//银行卡号
      formData.append("moblle", this.form.moblle);//电话
      formData.append("identityCard", this.form.identityCard);//身份证号
      formData.append("age", this.form.age);//年龄
      formData.append("medicareCardType", this.form.medicareCardType);//医保卡类型
      isEmpty(this.form.sreet) && formData.append("sreet", this.form.sreet);//街道
      isEmpty(this.form.committees) && formData.append("committees", this.form.committees);//居委会
      isEmpty(this.form.addressDetall) && formData.append("addressDetall", this.form.addressDetall);//详细地址
      isEmpty(this.form.gender) && formData.append("gender", this.form.gender);//性别
      isEmpty(this.form.familyName) && formData.append("familyName", this.form.familyName);//家人姓名
      isEmpty(this.form.familyMoblle) && formData.append("familyMoblle", this.form.familyMoblle);//紧急电话
      /*isEmpty(this.form.numbering)&&formData.append("numbering",this.form.numbering);//编号*/
      isEmpty(this.form.recommend) && formData.append("recommend", this.form.recommend);//推荐渠道
      isEmpty(this.form.recommendedTypeId) && formData.append("recommendedTypeId", this.form.recommendedTypeId);//推荐渠道
      isEmpty(this.form.recommendedDetails) && formData.append("recommendedDetails", this.form.recommendedDetails);//推荐渠道
      isEmpty(this.form.recommendedCaregiverId) && formData.append("recommendedCaregiverId", this.form.recommendedCaregiverId);//推荐渠道
      isEmpty(this.form.firstCareTime) && formData.append("firstCareTime", setTime(this.form.firstCareTime));//首次护理时间
      isEmpty(this.form.evaluationLervl) && formData.append("evaluationLervl", this.form.evaluationLervl);//评估等级
      isEmpty(this.form.evaluationPeriod) && formData.append("evaluationPeriod", setDate(this.form.evaluationPeriod));//评估有效期
      isEmpty(this.form.plannlngSheet) && formData.append("plannlngSheet", this.form.plannlngSheet);//护理计划表编码
      isEmpty(this.form.deviceId) && formData.append("deviceId", this.form.deviceId);//设备ID
      isEmpty(this.form.enterPlan) && formData.append("enterPlan", this.form.enterPlan);//长护险平台录入计划（是否录入)
      isEmpty(this.form.enterPlanFlag) && formData.append("enterPlanFlag", this.form.enterPlanFlag);//长护险平台接受（是否接受）
      isEmpty(this.form.mdicalPatform) && formData.append("mdicalPatform", this.form.mdicalPatform);//医护平台派单（是否派单）
      (this.reservation_nursingCycle.length !== 0) && formData.append("reservation_nursingCycle", this.reservation_nursingCycle.join(','));//预约服务周期
      (this.nursingCycle.length !== 0) && formData.append("nursingCycle", this.nursingCycle.join(','));//服务周期
      isEmpty(this.form.contractInformationId) && formData.append("contractInformationId", this.form.contractInformationId);//指定护理员1
      isEmpty(this.form.careWorker_id_one) && formData.append("careWorker_id_one", this.form.careWorker_id_one);//指定护理员1
      isEmpty(this.form.careWorker_id_two) && formData.append("careWorker_id_two", this.form.careWorker_id_two);//指定护理员2
      isEmpty(this.form.careWorker_id_three) && formData.append("careWorker_id_three", this.form.careWorker_id_three);//指定护理员3
      isEmpty(this.form.designationReason_one) && formData.append("designationReason_one", this.form.designationReason_one);//指定护理员1原因
      isEmpty(this.form.designationReason_two) && formData.append("designationReason_two", this.form.designationReason_two);//指定护理员2原因
      isEmpty(this.form.designationReason_three) && formData.append("designationReason_three", this.form.designationReason_three);//指定护理员3原因
      isEmpty(this.form.reservation_careWorker_id) && formData.append("reservation_careWorker_id", this.form.reservation_careWorker_id);//预约指定护理员
      isEmpty(this.form.reservation_designationReason) && formData.append("reservation_designationReason", this.form.reservation_designationReason);//预约指定原因
      isEmpty(this.form.lbeaconMajor) && formData.append("lbeaconMajor", this.form.lbeaconMajor);//设备编号
      isEmpty(this.form.nursingRemarks) && formData.append("nursingRemarks", this.form.nursingRemarks);//护理备注
      // isEmpty(this.nursingTime) && formData.append("nursingStartTime", setHours(this.nursingTime[0]));//护理开始时间
      // isEmpty(this.nursingTime) && formData.append("nursingEndTime", setHours(this.nursingTime[1]));//护理结束时间
      // isEmpty(this.nursingAppoTime) && formData.append("reservation_nursingStartTime", setHours(this.nursingAppoTime[0]));//预约护理开始时间
      // isEmpty(this.nursingAppoTime) && formData.append("reservation_nursingEndtime", setHours(this.nursingAppoTime[1]));//预约护理结束时间
      if (this.nursingStartTime != null && this.nursingStartTime.length === 5) {
        isEmpty(this.nursingStartTime) && formData.append("nursingStartTime", this.nursingStartTime + ":00");//护理开始时间
      } else {
        isEmpty(this.nursingStartTime) && formData.append("nursingStartTime", this.nursingStartTime);//护理开始时间
      }
      if (this.nursingEndTime != null && this.nursingEndTime.length === 5) {
        isEmpty(this.nursingEndTime) && formData.append("nursingEndTime", this.nursingEndTime + ":00");//护理开始时间
      } else {
        isEmpty(this.nursingEndTime) && formData.append("nursingEndTime", this.nursingEndTime);//护理开始时间
      }
      if (this.reservation_nursingStartTime != null && this.reservation_nursingStartTime.length === 5) {
        isEmpty(this.reservation_nursingStartTime) && formData.append("reservation_nursingStartTime", this.reservation_nursingStartTime + ":00");//预约护理开始时间
      } else {
        isEmpty(this.reservation_nursingStartTime) && formData.append("reservation_nursingStartTime", this.reservation_nursingStartTime);//预约护理开始时间
      }
      if (this.reservation_nursingEndtime != null && this.reservation_nursingEndtime.length === 5) {
        isEmpty(this.reservation_nursingEndtime) && formData.append("reservation_nursingEndtime", this.reservation_nursingEndtime + ":00");//预约护理结束时间
      } else {
        isEmpty(this.reservation_nursingEndtime) && formData.append("reservation_nursingEndtime", this.reservation_nursingEndtime);//预约护理结束时间
      }


      isEmpty(this.form.statusRemarks) && formData.append("statusRemarks", this.form.statusRemarks);//状态备注
      isEmpty(this.form.standbyTimeRemarks) && formData.append("standbyTimeRemarks", this.form.standbyTimeRemarks);//备用时间备注
      isEmpty(this.form.appointmentTime) && formData.append("appointmentTime", setDate(this.form.appointmentTime));//状态操作预约时间
      isEmpty(this.form.latltude) && formData.append("latltude", this.form.latltude);//备用时间备注
      isEmpty(this.form.longltude) && formData.append("longltude", this.form.longltude);//备用时间备注
      /*      formData.append("latltude",this.form.latltude);//纬度
            formData.append("longltude",this.form.longltude);//经度*/
      let idBefFiles = this.$refs.idBefUpload.uploadFiles;
      let idBacFiles = this.$refs.idBacUpload.uploadFiles;
      let soBefFiles = this.$refs.soBefUpload.uploadFiles;
      let soBacFiles = this.$refs.soBacUpload.uploadFiles;
      if (idBefFiles !== null && idBefFiles.length !== 0) {
        this.form.ldentlyCarPlc = idBefFiles[idBefFiles.length - 1].raw;
        formData.append("ldentlyCarPlcFile", this.form.ldentlyCarPlc);
      }
      if (idBacFiles !== null && idBacFiles.length !== 0) {
        this.form.ldentlyCardBackPlc = idBacFiles[idBacFiles.length - 1].raw;
        formData.append("ldentlyCardBackPlcFile ", this.form.ldentlyCardBackPlc);
      }
      if (soBefFiles !== null && soBefFiles.length !== 0) {
        this.form.medlcareCardPlc = soBefFiles[soBefFiles.length - 1].raw;
        formData.append("medlcareCardPlcFile", this.form.medlcareCardPlc);
      }
      if (soBacFiles !== null && soBacFiles.length !== 0) {
        this.form.medlcareCardPlcBack = soBacFiles[soBacFiles.length - 1].raw;
        formData.append("medlcareCardPlcBackFile", this.form.medlcareCardPlcBack);
      }
      return formData;
    },
    //外部导入文件change
    /* handleChange(file, fileList) {
         // this.isSheet=true;
         // this.xlsxArr=[];
        // this.readExcel(file.raw);
         this.imageUrl = URL.createObjectURL(file.raw);
     },*/
    idBefChange(file) {
      this.idBefUrl = URL.createObjectURL(file.raw);
    },
    idBacChange(file) {
      this.idBacUrl = URL.createObjectURL(file.raw);
    },
    soBefChange(file) {
      this.soBefUrl = URL.createObjectURL(file.raw);
    },
    soBacChange(file) {
      this.soBacUrl = URL.createObjectURL(file.raw);
    },
    addBu() {
      console.log(this.newContracttype.toString().charAt(0)+"--"+ this.newContracttype.toString().charAt(2))
      this.isAdd = true;
      this.clientId = null;
      this.fatForm();
      this.idBefUrl = "";
      this.idBacUrl = "";
      this.soBefUrl = "";
      this.soBacUrl = "";
      this.dynamicTags = [];//标签列表
    },
    fatForm() {
      this.nursingStartTime = '',//护理开始时间
          this.nursingEndTime = '',//护理结束时间
          this.reservation_nursingStartTime = '',//预约护理时间（开始）
          this.reservation_nursingEndtime = '',//预约护理时间（结束）
          this.nursingTime = "";
      this.nursingAppoTime = '';
      this.reservation_nursingCycle = [];//预约服务时间（周）
      this.nursingCycle = [];//护理周期
      this.careWorker_one = "";//指定护理员1
      this.careWorker_two = "";//指定护理员2
      this.careWorker_three = "";//指定护理员3
      this.reservation_careWorker = "";//预约指定护理员
      this.timePeriodList = [];
      this.appointmentTimePeriodList = [];
      this.form = {
        status: "",
        id: "",//用户id
        name: "",//姓名
        moblle: "",//电话
        identityCard: "",//身份证号
        age: "",//年龄
        familyName: "",//家人姓名
        familyMoblle: "",//紧急电话
        gender: "",//性别
        numbering: "",//编号
        medicareCardType: "",//医保卡类型
        recommend: "",//推荐渠道
        recommendedTypeId: "",//推荐类型
        recommendedDetails: "",//推荐明细
        recommendedCaregiverId: "",//推荐护理员ID
        firstCareTime: "",//首次护理时间
        addressDetall: "",//详细地址
        sreet: "",//街道
        committees: "",//居委会
        latltude: "",//纬度
        longltude: "",//经度
        ldentlyCarPlc: null,//身份证正面
        ldentlyCardBackPlc: null,//身份证反面
        medlcareCardPlc: null,//社保卡正面
        medlcareCardPlcBack: null,//社保卡反面
        evaluationLervl: null,//评估等级
        plannlngSheet: "",//护理计划表编码
        evaluationPeriod: "",//评估有效期
        enterPlan: "0",//长护险平台录入计划（是否录入)
        enterPlanFlag: "0",//长护险平台接受（是否接受）
        mdicalPatform: "0",//医护平台派单（是否派单）
        lbeaconMajor: "",//设备编号
        nursingRemarks: "",//护理备注
        careWorker_id_one: null,//指定护理员(id)
        designationReason_one: "",//指定原因1
        careWorker_id_two: null,//指定护理员(id)2
        designationReason_two: "",//指定原因2
        careWorker_id_three: null,//指定护理员(id)3
        designationReason_three: "",//指定原因3
        reservation_careWorker_id: "",//预约指定护理员
        reservation_designationReason: "",//预约指定原因
        standbyTimeRemarks: "",//备用时间备注
        appointmentTime: "",//状态操作预约时间
      }

    },
    refresh() {
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.customerModule.userList = this.isExist(permissionList,"customer:userList");
          this.customerModule.selectCustomer = this.isExist(permissionList,"customer:selectCustomer");
          this.customerModule.insertCustomer = this.isExist(permissionList,"customer:insertCustomer");
          this.customerModule.updateCustomer = this.isExist(permissionList,"customer:updateCustomer");
          this.customerModule.customerLogList = this.isExist(permissionList,"customer:customerLogList");
          this.customerModule.alterStatus = this.isExist(permissionList,"customer:alterStatus");
          this.customerModule.insertCustomerLabel = this.isExist(permissionList,"customer:insertCustomerLabel");
          this.customerModule.deleteCustomerLabel = this.isExist(permissionList,"customer:deleteCustomerLabel");
          this.customerModule.customerImport = this.isExist(permissionList,"customer:customerImport");
          this.customerModule.clientPythonExport = this.isExist(permissionList,"customer:clientPythonExport");
          this.customerModule.saveAddFamilyInformation = this.isExist(permissionList,"customer:saveAddFamilyInformation");
          this.customerModule.customerImportArrange = this.isExist(permissionList,"customer:customerImportArrange");
          this.customerModule.customerAllLogsList = this.isExist(permissionList,"customer:customerAllLogsList");
          this.customerModule.customerAllLogsExport = this.isExist(permissionList,"customer:customerAllLogsExport");
          this.customerModule.externalCreateWorkflowButton = this.isExist(permissionList,"customerWorkflow:externalCreateWorkflowButton");

          recommendedType().then(res => {
            if (res) {
              this.recommendedTypes = res.data
            }
          })
          var str = "client"
          var clientId = "";
          if (this.clientId !== '' && this.clientId != null && this.clientId.search(str) === -1) {
            clientId = this.clientId
          }
          let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + "&clientId=" + clientId + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
          cusList(subData).then(res => {
            if (res) {
              this.clientArr = res.data;
              if (res.data.length !== 0) {
                this.clientId = res.data[0].id;
                var evaluationNursingId = res.data[0].evaluationNursingId;
                var contractTypeId = res.data[0].contractTypeId;
                this.clientDes(this.clientId, evaluationNursingId, contractTypeId);
                this.pages = res.data[0].totalCount;
              }
              this.allLoading = false;
            }
          });
        }
      })

    },
  },
  created() {
    var reg = new RegExp("(^|&)" + 'id' + "=([^&]*)(&|$)", "i");
    var reg2 = new RegExp("(^|&)" + 'evaluationNursingId' + "=([^&]*)(&|$)", "i");
    var reg3 = new RegExp("(^|&)" + 'contractTypeId' + "=([^&]*)(&|$)", "i");

    var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
    var r2 = window.location.search.substr(1).match(reg2); //获取url中"?"符后的字符串并正则匹配
    var r3 = window.location.search.substr(1).match(reg3); //获取url中"?"符后的字符串并正则匹配

    var id = "";
    var evaluationNursingId = "";
    var contractTypeId = "";
    if (r != null)
      id = r[2];
    if (r2 != null)
      evaluationNursingId = r2[2];
    if (r3 != null)
      contractTypeId = r3[2];
    reg = null;
    r = null;

    this.clientId = id;
    if (this.clientId !== '' && this.clientId != null) {
      this.siteChangeOperating()
      this.clientDes(this.clientId, evaluationNursingId, contractTypeId);
    }
    window.addEventListener('setItem', () => {
      this.contractTypeAndSiteInquireValue = sessionStorage.getItem('contractTypeAndSiteInquireValue');
      this.clientId = '';
      this.refresh();
    }, false)

    // 由工单点击客户名字跳转触发函数
    if (this.$route.query.id !== null && this.$route.query.id !== undefined) {
      this.clientId = this.$route.query.id;
      this.clientDes(this.clientId);
      this.clientRight.width = '100%'
    } else {
      this.isAssign = true;
      this.refresh();
    }
  },
  watch: {
    justSeeTheNote: function () {
      this.logTimeSubmit()
    },


    '$route'() {
      if (this.$route.query.id !== null && this.$route.query.id !== undefined) {
        this.isAssign = false;
        this.clientId = this.$route.query.id;
        this.clientDes(this.clientId);
        this.clientRight.width = '100%'
      } else {
        this.clientRight.width = '';
        this.isAssign = true;
        this.refresh();
      }
    },
  },
  mounted() {
  },

}
</script>
<style lang="scss">
.el-tooltip__popper {
  max-width: 30%;
}

.el-table .cell {
  white-space: pre-line;
}
</style>
<style scoped lang="scss">

.tableTitle {
  position: relative;
  margin: 0 auto;
  width: 560px;
  height: 1px;
  background-color: #d4d4d4;
  text-align: center;
  font-size: 16px;
  color: rgba(101, 101, 101, 1);
}

.over-due {
  /* position: absolute;*/
  /* top:4px;*/
  font-size: 12px;
  color: #FFF;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: red;
  text-align: center;
  line-height: 20px;
}

.right-opare {
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #DDD;
  padding: 10px 20px;
  box-sizing: border-box;
}

.des-box {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0 20px 70px 20px;
  /*  border: 1px solid red;*/
  box-sizing: border-box;
}

.des {
  margin-top: 20px;
  border: 1px dashed #999;
  border-radius: 10px;
  overflow: hidden;

  .map {
    overflow: hidden;

  }

  .local {
    display: inline-block;
    margin-left: 20px;
  }
}

.serve {
  padding: 20px 0 0 20px;
}

.des-flex {
  min-width: 600px;
  display: -webkit-flex; /* Safari */
  display: flex;
  height: auto;
  padding-top: 20px;

  .input-box {
    margin-left: 5%;
  }

  .line {
    width: 300px;
  }
}

.assess {
  padding: 20px 0 0 30px;

  .line {
    width: 260px;
  }
}

.assessTwo {

}

#map {
  width: 100%;
  height: 300px;
}

.adress {
  padding-left: 5%;

  .line {
    width: 260px;
  }
}

.card {
  padding: 0 40px 40px 40px;

  .img-box {
    width: 500px;
    justify-content: space-between;

    p {
      text-align: center;
    }
  }
}

/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}

.avatar {
  width: 364px;
  height: 229px;
  display: block;
}

/*标签*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}


.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
