<template>
  <div class="single" v-loading="loading">
    <div class="gd-top">
      <el-form :inline="true">

        <el-form-item label="护理员名">
          <el-input v-model="caregiverName" placeholder="请输入护理员姓名" style="width:255px"
                    @keyup.enter.native="inquireCustomerRecommendList()"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="推荐老人">
          <el-input v-model="customerName" placeholder="请输入老人姓名" style="width:255px"
                    @keyup.enter.native="inquireCustomerRecommendList()"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" @click="inquireCustomerRecommendList()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
        :data="customerRecommendData"
        @selection-change="handleSelectionChange"
        style="width: 100% ;margin-bottom:30px;"
        :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
        border
        stripe
        max-height="730"
        id="out-table"
        v-if="boxFlag"
        v-loading="loading"

    >
      <el-table-column class-name="aa" label="护理员"  header-align="10px">
        <template slot-scope="scope">
          <p>{{scope.row.caregiverName}}</p>
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="推荐老人名"  header-align="10px">
        <template slot-scope="scope">
          <p>{{scope.row.customerName}}</p>
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="联系方式"  header-align="10px">
        <template slot-scope="scope">
          <p>{{scope.row.contactNumber}}</p>
        </template>　　　　　　　　
      </el-table-column>
      <el-table-column class-name="aa" label="地址"  header-align="10px">
        <template slot-scope="scope">
          <p>{{scope.row.address}}</p>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align:right">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pagesizes"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="all">
      </el-pagination>
    </div>
  </div>

</template>
<script>
import {inquireCustomerRecommendList} from "@/common/js";

export default {
  name: "customerRecommend",
  data() {
    return {
      contractTypeAndSiteInquireValue: ['1', '1'],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      caregiverName:'',
      customerName:'',
      multipleSelection:null,
      boxFlag: true,
      currentPage: 1,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      all: 0,
      siteList: localStorage.getItem("siteList").split("$"),
      siteId:'1',
      loading:false,
      customerRecommendData:[],
      singleDetailsPage: false,
    }
  },
  methods: {
    // 查询护理员对单列表
    inquireCustomerRecommendList(){
      this.loading = true  //开启加载动画
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + "&caregiverName=" + this.caregiverName
          + "&customerName=" + this.customerName
      inquireCustomerRecommendList(subData).then(res => {
        if (res) {
          this.customerRecommendData = res.data
          if (res.data.length != 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
        }
        this.loading = false
      })
    },
    //改变选择时触发
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    this.inquireCustomerRecommendList()
  },
  watch: {}
}
</script>

<style scoped>
.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin:10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
</style>
