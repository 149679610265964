<template>
  <div class="billingDetails" v-loading="loading">
    <div class="gd-top" style="margin:10px 0 20px 0">
      <div class="gd-left">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">

          <el-form-item label="状态">
            <el-select v-model="status" placeholder="请选择" class="sel2" @change="serviceSituationList()">
              <el-option
                  v-for="item in options"
                  :key="item.status"
                  :label="item.label"
                  :value="item.status">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务状态">
            <el-select v-model="serviceStatus" placeholder="请选择" class="sel2" @change="serviceSituationList()">
              <el-option
                  v-for="item in options2"
                  :key="item.status"
                  :label="item.label"
                  :value="item.status">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="沟通状态">
            <el-select v-model="searchForm.communication" placeholder="请选择" class="sel2" @change="serviceSituationList()">
              <el-option
                  v-for="item in options3"
                  :key="item.status"
                  :label="item.label"
                  :value="item.status">
              </el-option>
            </el-select>
          </el-form-item>
          &nbsp;<el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="siteChangeOperating()"
            style="width:160px"></el-cascader>
          <el-form-item label="老人">
            <el-input v-model="searchForm.customerName" placeholder="老人姓名" style="width:120px"
                      @keyup.enter.native="serviceSituationList"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="护理员">
            <el-input v-model="searchForm.caregiverName" placeholder="护理员姓名" style="width:120px"
                      @keyup.enter.native="serviceSituationList"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="日期默认今天">
            <el-date-picker
                v-model="searchForm.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 230px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="serviceSituationList()">查询</el-button>
            <el-button type="success" @click="turnOn()" v-if="this.serviceSituationModule.communicationConfirmation">沟通确认</el-button>
          </el-form-item>
        </el-form>

      </div>
      <div class="right">
        <el-form>
          <el-form-item>
            <el-button  type="success" @click="serviceSituationImport()" v-if="this.serviceSituationModule.serviceSituationImport">导入</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100% ;margin-bottom:30px;"
        :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        border
        stripe
        max-height="730"
        id="out-table"
        v-if="boxFlag && this.serviceSituationModule.serviceSituationList"
        v-loading="loading"
    >
      <el-table-column type='selection' style="height: 10px"
                       min-height="10px"></el-table-column>
      <el-table-column class-name="aa" label="护理员"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.caregiverName}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="老人"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.customerName}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="状态"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.status}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="沟通状态" header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.communication }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="服务状态"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.serviceStatus}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="服务时长（分）"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.serviceHours}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="服务内容"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.serviceContentId}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="开始时间"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.beginTime}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="结束时间"  header-align="10px">
        <template slot-scope="scope">
          {{scope.row.endTime}}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="沟通备注" header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="无工单重新匹配" width="160" v-if="this.serviceSituationModule.rematch">
        <template slot-scope="scope">
          <el-button size="small" @click="rematch(scope.row.id)">重新匹配</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align:right" v-if="this.serviceSituationModule.rematch">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pagesizes"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="all">
      </el-pagination>
    </div>
    <!--导入-->
    <el-dialog title="导入" :visible.sync="serviceSituationImportPage">
      <form>
        <input type="file" @change="getFile($event)">
        <button @click="submitForm($event)">提交</button>
        <div style="margin-left: 80%;margin-top: -20px">
          <a :href="herf" target="_blank" rel="noopener noreferrer">导入模板下载</a>
        </div>
      </form>

    </el-dialog>
    <el-dialog title="沟通确认" :visible.sync="openCommunicationConfirmation" width="400px">
      <el-form :model="searchForm" label-width="100px">
        <el-form-item label="沟通备注">
          <el-input v-model="searchForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="communicationConfirmation">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  serviceSituationList,
  serviceSituationImport,
  rematch,
  communicationConfirmation,
  siteChange
} from '@/common/js/index.js'
import {setDate} from '@/common/js/util.js'

export default {
  //el: 'form',
  name: "billingDetails",
  data() {
    return {
      status: '',
      options: [{
        status: '',
        label: '全部'
      }, {
        status: '待同步',
        label: '待同步'
      }, {
        status: '已匹配',
        label: '已匹配'
      }, {
        status: '未打卡',
        label: '未打卡'
      }, {
        status: '无工单',
        label: '无工单'
      }, {
        status: '已删除',
        label: '已删除'
      }],
      serviceStatus:'',
      options2: [{
        status: '',
        label: '全部'
      }, {
        status: '正常',
        label: '正常'
      }, {
        status: '缺时',
        label: '缺时'
      }],
      options3: [{
        status: '',
        label: '全部'
      }, {
        status: '已沟通',
        label: '已沟通'
      }, {
        status: '未沟通',
        label: '未沟通'
      }],
      serviceSituationImportPage: false,
      all: 0,
      tableData: [],
      loading: false,
      searchForm: {
        caregiverName: '',
        customerName: '',
        date: null,
        communication:'',
        remark:''
      },
      currentPage: 1,
      boxFlag: true,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      multipleSelection: [],
      searchFlag: true,
      contractTypeAndSiteInquireValue:[localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      openCommunicationConfirmation:false,
      serviceSituationModule:{
        serviceSituationList:false,
        communicationConfirmation:false,
        rematch:false,
        serviceSituationImport:false
      },
      herf: 'http://47.110.149.54:8080/statics/报表/服务情况导入备份/模版.xlsx',
    }
  },
  methods: {
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.serviceSituationModule.serviceSituationList = this.isExist(permissionList,"serviceSituation:serviceSituationList");
          this.serviceSituationModule.communicationConfirmation = this.isExist(permissionList,"serviceSituation:communicationConfirmation");
          this.serviceSituationModule.rematch = this.isExist(permissionList,"serviceSituation:rematch");
          this.serviceSituationModule.serviceSituationImport = this.isExist(permissionList,"serviceSituation:serviceSituationImport");
          this.serviceSituationList();

        }
      })

    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    turnOn(){
      this.openCommunicationConfirmation = true
    },
    // 沟通确认按钮
    communicationConfirmation(){
      this.ids = '';
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.ids += this.multipleSelection[i].id + ','
      }
      if (this.ids === '') {
        this.$message({
          message: "请选择需要沟通的选项",
          type: 'warning'
        })
      } else {
        let subData = 'ids=' + this.ids + '&remark=' + this.searchForm.remark
        communicationConfirmation(subData).then(res => {
          if (res) {
            this.$message.success('沟通确认成功');
            this.serviceSituationList()
            this.openCommunicationConfirmation = false
            this.searchForm.remark = null
          }
        })
      }
    },
    // 无工单重新匹配
    rematch(id){
      let subData = 'id=' + id
      rematch(subData).then(res => {
        if (res) {
          if (res.retCode === 0) {
            this.$message.success('无工单重新匹配成功');
            this.serviceSituationList()
          }else {
            this.$message.error('无工单重新匹配失败，请检查工单是否正确');
          }
        }
      })
    },
    // 导入结算明细
    serviceSituationImport() {
      this.serviceSituationImportPage = true
    },
    getFile(event) {
      this.file = event.target.files[0];
      console.log(this.file);
    },
    submitForm(event) {
      event.preventDefault();
      let formData = new FormData();
      formData.append('file', this.file);
      this.$message.warning('导入数据中,请稍等...');
      serviceSituationImport(formData).then(res => {
        if (res) {
          this.tableData = res.retCode
          if (this.tableData == 0) {
            this.$message.success('导入成功');
            this.serviceSituationImportPage = false;
            this.serviceSituationList();
          }

          // console.log('export',this.tableData)
        }
      })

    },

    handleSelectionChange(val) {//改变选择时触发
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },

    serviceSituationList() {    //查询按钮对应的方法
      if (this.searchFlag === true) {
        var strs = this.contractTypeAndSiteInquireValue.toString();

        this.loading = true  //开启加载动画
        let subData = 'currPage=' + this.currentPage
            + '&pageSizePara=' + this.pagesize
            + '&status=' + this.status
            + '&serviceStatus=' + this.serviceStatus
            + '&customerName=' + this.searchForm.customerName
            + '&caregiverName=' + this.searchForm.caregiverName
            + '&siteId=' + strs.charAt(0)
            + '&contractTypeId=' + strs.charAt(2)
            + '&communication=' + this.searchForm.communication
        if (this.searchForm.date != null) {
          let searchStartTime = setDate(this.searchForm.date[0])
          let searchEndTime = setDate(this.searchForm.date[1])
          subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime + ' 23:59:59'
        }else {
          var nowDate = new Date();
          var year = nowDate.getFullYear();
          var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
              : nowDate.getMonth() + 1;
          var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
              .getDate();
          var date = year + "-" + month + "-" + day;
          subData += '&beginTime=' + date + '&endTime=' + date + ' 23:59:59'
        }
        serviceSituationList(subData).then(res => {
          if (res) {
            this.tableData = res.data
            for (let i = 0; i < this.tableData.length; i++) {
              var beginTime = this.tableData[i].beginTime;
              var endTime = this.tableData[i].endTime;
              var ms = Math.abs(new Date(endTime) - new Date(beginTime));
              var minute = Math.floor(ms / (60 * 1000));  //分
              this.tableData[i].serviceHours = minute
            }
            if (res.data.length !== 0) {
              this.all = res.data[0].totalCount
            } else {
              this.all = 0
            }
          }
          this.loading = false
        })
      }

    },
  },
  created() {    //护理站
    this.siteChangeOperating();
  },
  watch: {
    currentPage: function () {
      this.serviceSituationList()
    },
    pagesize: function () {
      this.serviceSituationList()
    },
  }
}
</script>

<style scoped>
 .gd-top {
   display: -webkit-flex; /* Safari */
   display: flex;
   justify-content: space-between;
   height: 50px;
   box-sizing: border-box;
   padding: 5px 20px;
   margin:10px 0 20px 0;
   display: -webkit-flex; /* Safari */
   display: flex;
   text-align: center;

 }
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}
 .sel2 {
   width: 85px;
 }
</style>
