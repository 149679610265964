import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//element-ui/lib/theme-chalk/index.css
import Axios from 'axios';
import BaiduMap from 'vue-baidu-map';
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;


Axios.defaults.withCredentials=true;//axios.defaults.withCredentials=true
Vue.use(BaiduMap, {
    ak: 'BIcWjERVmtV4T1clij473kx1aUk7MyX4'    //这个地方是官方提供的ak密钥
})
Vue.config.productionTip = false;
Vue.use(ElementUI);
//监听sessionStorage
Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'siteValue') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);

                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}
let vue=new Vue({
  router,
    store,
  render: h => h(App)
}).$mount('#app')
export default vue
