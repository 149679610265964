<template>
  <div v-loading="load">
    <div class="bu-box">
      <el-button type="primary" @click="siteAuthority=true">站权限设置</el-button>
      <el-button type="primary" @click="buAdd">新增人员</el-button>
    </div>
    <el-table :data="crewData"
              border
              stripe
              :header-cell-style="{background:'#dee2ec', color: '#666666'}"
              :cell-style="{'text-align':'center'}"
              max-height="700"
    >
      <el-table-column prop="username" label="账号" width="150"></el-table-column>
      <el-table-column prop="password" label="密码" width="150"></el-table-column>
      <el-table-column prop="roleName" label="角色" width="85"></el-table-column>
      <el-table-column prop="nickname" label="昵称" width="120"></el-table-column>
      <el-table-column prop="gender" label="性别" width="50"></el-table-column>
      <el-table-column prop="moblle" label="手机号码" width="110"></el-table-column>
      <el-table-column prop="sites" label="站点权限" width="400"></el-table-column>
      <el-table-column prop="contractTypeIds" label="合约类型权限" width="420"></el-table-column>
      <!--            <el-table-column prop="permissionList" label="权限列表"></el-table-column>-->
      <el-table-column prop="status" label="状态" width="70"></el-table-column>
      <el-table-column prop="operation" label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="warning" size="small" @click="buChan(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="userDelete(scope.row)">删除</el-button>
          <el-button type="success" size="small" @click="userActive(scope.row)"
                     v-if="scope.row.deleteStatus==2">激活
          </el-button>
          <el-button type="info" size="small" @click="userStop(scope.row)" v-if="scope.row.deleteStatus==1">
            停止
          </el-button>
          <!--   <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="addChanDialog" width="500px">
      <el-form :model="addChanForm" :rules="rules" ref="addChanForm" label-width="100px">
        <el-form-item label="角色" prop="roleId">
          <!-- <el-input v-model="addChanForm.roleId" class="input-class"></el-input>-->
          <el-select v-model="addChanForm.roleId" placeholder="请选择角色" class="input-class">
            <el-option
                v-for="item in roleData"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点" prop="siteId">
          <el-select v-model="addChanForm.siteId" multiple placeholder="请选择站点">
            <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合约类型" prop="contractTypeId">
          <el-select v-model="addChanForm.contractTypeId" multiple placeholder="请选择合约类型">
            <el-option
                v-for="item in contractTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" v-if="isAdd" prop="username">
          <el-input v-model="addChanForm.username" class="input-class" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addChanForm.password" class="input-class" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="addChanForm.gender">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="手机号码">
          <el-input v-model="addChanForm.moblle" class="input-class" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="addChanForm.nickname" class="input-class" placeholder="请输入昵称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addChanSubmit('addChanForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="siteAuthority" width="500px">
      <el-form :model="siteAuthorityForm" :rules="rules" ref="addChanForm" label-width="100px">
        <el-form-item label="站点" prop="siteId">
          <el-select v-model="siteAuthorityForm.siteId" placeholder="请选择站点" @change="siteChoose()">
            <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
                >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合约类型" prop="contractTypeId">
          <el-select v-model="siteAuthorityForm.contractTypeId" multiple placeholder="请选择合约类型">
            <el-option
                v-for="item in contractTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="modifySiteAuthority()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {userList, addUser, chanUser, listRole, userStop, userDele, userActive, listSite,modifySiteAuthority} from "@/common/js/index.js"
import {isEmpty} from "../../common/js/util";

export default {
  name: "crew",
  data() {
    return {
      siteList: [],
      contractTypeList: [],
      load: true,
      title: "新增人员",
      crewData: [],
      roleData: [],
      addChanDialog: false,
      addChanForm: {
        //deleteStatus: null,
        gender: "",
        moblle: "",
        nickname: "",
        password: "",
        //permissionList: [],
        roleId: null,
        userId: null,
        username: "",
        siteId: [],
        contractTypeId: []
      },
      isAdd: false,
      rules: {
        roleId: [{required: true, message: '请选择角色', trigger: 'blur'}],
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        siteId: [{required: true, message: '请选择站点', trigger: 'blur'}],
      },
      siteAuthority: false,
      siteAuthorityForm:{
        siteId: '',
        contractTypeId: []
      },
    }
  },
  methods: {
    siteChoose(){
      // 查询该护理站权限信息
      let subData = 'siteId='+this.siteAuthorityForm.siteId;
      listSite(subData).then(res => {
        if (res) {
          if (isEmpty(res.data[0].contractTypeId)) {
            this.siteAuthorityForm.contractTypeId = res.data[0].contractTypeId.split(",");
          }
        }
      })
    },
    modifySiteAuthority(){
      let subData = 'siteId='+this.siteAuthorityForm.siteId + "&contractTypeId="+this.siteAuthorityForm.contractTypeId;
      modifySiteAuthority(subData).then(res => {
        if (res) {
          this.siteAuthority = false
          this.$message.success('站权限修改成功');
        }
      })
    },
    getUserList() {
      userList().then(res => {
        if (res) {
          this.load = false;
          this.crewData = res.data;
          for (let i = 0; i < this.crewData.length; i++) {
            this.crewData[i].permissionList = this.crewData[i].permissionList.join('，');
            if (this.crewData[i].deleteStatus === 1) {
              this.crewData[i].status = "已激活";
            } else if (this.crewData[i].deleteStatus === 2) {
              this.crewData[i].status = "已停止";
            }
            if (isEmpty(this.crewData[i].siteId)) {
              this.crewData[i].siteId = this.crewData[i].siteId.split(",");
              this.crewData[i].sites = [];
              for (let m = 0; m < this.crewData[i].siteId.length; m++) {
                for (let j = 0; j < this.siteList.length; j++) {
                  if (this.siteList[j].id === this.crewData[i].siteId[m]) {
                    this.crewData[i].sites.push(this.siteList[j].value)
                  }
                }
              }
              this.crewData[i].sites = this.crewData[i].sites.join("，")
            } else {
              this.crewData[i].siteId = []
            }

            if (isEmpty(this.crewData[i].contractTypeId)) {
              this.crewData[i].contractTypeId = this.crewData[i].contractTypeId.split(",");
              this.crewData[i].contractTypeIds = [];
              for (let m = 0; m < this.crewData[i].contractTypeId.length; m++) {
                for (let j = 0; j < this.contractTypeList.length; j++) {
                  if (this.contractTypeList[j].id === this.crewData[i].contractTypeId[m]) {
                    this.crewData[i].contractTypeIds.push(this.contractTypeList[j].value)
                  }
                }
              }
              this.crewData[i].contractTypeIds = this.crewData[i].contractTypeIds.join("，")
            } else {
              this.crewData[i].contractTypeId = []
            }
          }
        }
      })
    },
    buAdd() {
      this.addChanDialog = true;
      this.title = "新增人员";
      this.isAdd = true;
      this.addChanForm = {
        //deleteStatus: null,
        gender: "",
        moblle: "",
        nickname: "",
        password: "",
        //permissionList: [],
        roleId: null,
        userId: null,
        username: "",
      };
    },
    buChan(row) {
      this.isAdd = false;
      this.addChanDialog = true;
      this.title = "修改人员信息";
      this.addChanForm.nickname = row.nickname,
          this.addChanForm.password = row.password,
          this.addChanForm.gender = row.gender,
          this.addChanForm.moblle = row.moblle,
          this.addChanForm.roleId = row.roleId,
          this.addChanForm.userId = row.userId
      this.addChanForm.siteId = row.siteId
      this.addChanForm.contractTypeId = row.contractTypeId
      console.log("this.addChanForm.contractTypeId="+this.addChanForm.contractTypeId)
    },
    addChanSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let addData = {
            username: this.addChanForm.username,
            password: this.addChanForm.password,
            nickname: this.addChanForm.nickname,
            roleId: this.addChanForm.roleId,
            gender: this.addChanForm.gender,
            moblle: this.addChanForm.moblle,
            siteId: this.addChanForm.siteId.join(","),
            contractTypeId: this.addChanForm.contractTypeId.join(",")
          };
          let chanData = {
            userId: this.addChanForm.userId,
            password: this.addChanForm.password,
            nickname: this.addChanForm.nickname,
            roleId: this.addChanForm.roleId,
            gender: this.addChanForm.gender,
            moblle: this.addChanForm.moblle,
            siteId: this.addChanForm.siteId.join(","),
            contractTypeId: this.addChanForm.contractTypeId.join(",")

          };
          if (this.title == "新增人员") {
            addUser(addData).then(res => {
              if (res) {
                this.addChanDialog = false;
                this.getUserList();
                this.$message.success('添加人员成功');
              }
            })
          } else if (this.title == "修改人员信息") {
            chanUser(chanData).then(res => {
              if (res) {
                this.addChanDialog = false;
                this.getUserList();
                this.$message.success('修改人员信息成功');
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    /**
     * 激活人员
     * */
    userActive(row) {
      this.$confirm('此操作将激活该人员,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        center: true
      }).then(() => {
        let subData = {
          userId: row.userId
        };
        userActive(subData).then(res => {
          if (res) {
            this.$message.success('激活成功');
            this.getUserList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消激活'
        });
      });
    },
    /**
     * 删除管理员
     * */
    userDelete(row) {
      this.$confirm('此操作将永久删除该人员,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        let subData = {
          userId: row.userId
        };
        userDele(subData).then(res => {
          if (res) {
            this.$message.success('删除成功');
            this.getUserList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /**
     *停止人员
     * */
    userStop(row) {
      this.$confirm('此操作停止该人员,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        let subData = {
          userId: row.userId
        };
        userStop(subData).then(res => {
          if (res) {
            this.$message.success('停止人员成功');
            this.getUserList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消停止人员'
        });
      });
    },
    getRoleList() {
      listRole().then(res => {
        if (res) {
          this.roleData = res.data.list;
        }
      })
    }
  },
  created() {
    let list = localStorage.getItem("siteList").split("$");
    for (let i = 0; i < list.length; i++) {
      this.siteList.push({
        id: list[i].split(",")[0],
        value: list[i].split(",")[1],
      });
    }
    let contractTypeLists = localStorage.getItem("contractTypeList").split("$");
    for (let i = 0; i < contractTypeLists.length; i++) {
      this.contractTypeList.push({
        id: contractTypeLists[i].split(",")[0],
        value: contractTypeLists[i].split(",")[1],
      });
    }
    this.getUserList();
    this.getRoleList();
  }
}
</script>
<style scoped>
.bu-box {
  text-align: right;
  padding: 20px;

}

.input-class {
  width: 200px;
}
</style>
