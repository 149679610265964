import Vue from 'vue';
import Router from 'vue-router';
//import Home from './views/Home.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/login.vue';
import Client from '@/views/client.vue';
import assignClient from '@/views/client.vue';
import Personal from '@/views/personal.vue';
import Worksheet from '@/views/worksheet.vue';
import Staff from '@/views/staff.vue';
import assignStaff from '@/views/staff.vue';
import Order from '@/views/order.vue';
import assignOrder from '@/views/order.vue';
import Statement from '@/views/statement.vue';
import Company from '@/views/manage/company.vue';
import Crew from '@/views/manage/crew.vue';
import Neighborhood from '@/views/manage/neighborhood.vue';
import Role from '@/views/manage/role.vue';
import Notice from '@/views/notice.vue';
import SendOrders from '@/views/manage/sendOrders.vue';
import MapReport from '@/views/manage/mapReport.vue';
import BillingDetails from '@/views/settlementModule/billingDetails.vue';
import CaregiverBilling from '@/views/settlementModule/caregiverBilling.vue';
import ServiceCenterInterface from '@/views/serviceCenterInterface.vue';
import WebmasterInterface from '@/views/webmasterInterface.vue';
import WindControl from '@/views/windControl.vue';
import Single from '@/views/single.vue';
import CaregiverRecommend from '@/views/recommend/caregiverRecommend.vue';
import CustomerRecommend from '@/views/recommend/customerRecommend.vue';
import ServiceSituation from '@/views/serviceSituation.vue';
import Wage from '@/views/settlementModule/wage.vue';
import Site from '@/views/manage/site.vue';
import Document from '@/views/document.vue';
import HomeVisit from '@/views/other/homeVisit.vue';
import Overview from '@/views/overview.vue';
import Epidemic from '@/views/epidemic.vue';
Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/login'
        },

        {
            path: '/home',
            name: 'home',
            component: Home,
            redirect: '/client',
            children:[
                {
                    path: '/assignOrder',
                    name: 'assignOrder',
                    component:assignOrder,
                },
                {
                    path: '/sendOrders',
                    name: 'sendOrders',
                    component:SendOrders,
                },
                {
                    path: '/client',
                    name: 'client',
                    component:Client,
                },
                {
                    path: '/assignClient',
                    name: 'assignClient',
                    component:assignClient,
                },
                {
                    path: '/personal',
                    name: 'personal',
                    component:Personal,
                },
                {
                    path: '/staff',
                    name: 'staff',
                    component:Staff,
                },
                {
                    path: '/assignStaff',
                    name: 'assignStaff',
                    component:assignStaff,
                },
                {
                    path: '/worksheet',
                    name: 'worksheet',
                    component:Worksheet,
                },
                {
                    path: '/order',
                    name: 'order',
                    component:Order,
                },
                {
                    path: '/statement',
                    name: 'statement',
                    component:Statement,
                },
                {
                    path: '/company',
                    name: 'company',
                    component:Company,
                },
                {
                    path: '/crew',
                    name: 'crew',
                    component:Crew,
                },
                {
                    path: '/neighborhood',
                    name: 'neighborhood',
                    component:Neighborhood,
                },
                {
                    path: '/role',
                    name: 'role',
                    component:Role,
                },
                {
                    path: '/notice',
                    name: 'notice',
                    component:Notice,
                },
                {
                    path: '/mapReport',
                    name: 'mapReport',
                    component:MapReport,
                },
                {
                    path: '/billingDetails',
                    name: 'billingDetails',
                    component:BillingDetails,
                },
                {
                    path: '/caregiverBilling',
                    name: 'caregiverBilling',
                    component:CaregiverBilling,
                },
                {
                    path: '/serviceCenterInterface',
                    name: 'serviceCenterInterface',
                    component:ServiceCenterInterface,
                },
                {
                    path: '/webmasterInterface',
                    name: 'webmasterInterface',
                    component:WebmasterInterface,
                },
                {
                    path: '/windControl',
                    name: 'windControl',
                    component:WindControl,
                },
                {
                    path: '/single',
                    name: 'single',
                    component:Single,
                },
                {
                    path: '/customerRecommend',
                    name: 'customerRecommend',
                    component:CustomerRecommend,
                },
                {
                    path: '/caregiverRecommend',
                    name: 'caregiverRecommend',
                    component:CaregiverRecommend,
                },
                {
                    path: '/serviceSituation',
                    name: 'serviceSituation',
                    component:ServiceSituation,
                },
                {
                    path: '/wage',
                    name: 'wage',
                    component:Wage,
                },
                {
                    path: '/site',
                    name: 'site',
                    component:Site,
                },
                {
                    path: '/document',
                    name: 'document',
                    component:Document,
                },
                {
                    path: '/homeVisit',
                    name: 'homeVisit',
                    component:HomeVisit,
                },
                {
                    path: '/overview',
                    name: 'overview',
                    component:Overview,
                },
                {
                    path: '/epidemic',
                    name: 'epidemic',
                    component:Epidemic,
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        /*{
          path: '/about',
          name: 'about',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/!* webpackChunkName: "about" *!/ './views/About.vue')
        }*/
    ]
})
