import fetch from '@/common/js/http.js'
import { EPROTO } from 'constants';
import axios from "axios";
let prefix;
if(process.env.NODE_ENV=='development'){
    prefix = '/api';//由于前后端完全分离，开发环境需要加上/api来实现跨域，打包放到生产环境不需要加/api跨域
}else{
    prefix="/api"
    //prefix=""
}
/**
 * 登录
 * */
//登录
export function login(subData) {
    return fetch({
        url: prefix+'/user/login',
        method: 'post',
        data:subData
    });
}
//登出
export function logout() {
    return fetch({
        url: prefix+'/user/logout',
        method: 'post',
    });
}
//修改密码
export function setPwd(subData) {
    return fetch({
        url: prefix+'/user/setPassword',
        method: 'post',
        data:subData
    });
}
/**
 * 派单
 */
// 待派单客户列表信息
export function orderList(subData) {
    return fetch({
        url: prefix+'/sendOrders/customerList?'+subData,
        method: 'get',
    });
}

// 待派单推荐护理员列表信息
export function caregiverPriorityList(subData) {
    return fetch({
        url: prefix+'/sendOrders/caregiverPriorityList?'+subData,
        method: 'get',
    });
}
// 点击派单按钮
export function beginSendOrder(subData) {
    return fetch({
        url: prefix+'/sendOrders/beginSendOrder',
        method: 'post',
        data:subData
    });
}
/**
 * 客户
 * */
//客户界面列表信息
export function cusList(subData) {
    return fetch({
        url: prefix+'/customer/userList?'+subData,
        method: 'get',
    });
}
//客户具体信息
export function getCus(subData) {
    return fetch({
        url: prefix+'/customer/selectCustomer?'+subData,
        method: 'get',
    });
}
//添加客户基本信息
export function addCus(subData) {
    return fetch({
        url: prefix+'/customer/insertCustomer',
        method: 'post',
        data:subData
    });
}
//更新客户基本信息
export function changeCus(subData) {
    return fetch({
        url: prefix+'/customer/updateCustomer',
        method: 'post',
        data:subData
    });
}
//操作客户信息
export function cusOpera(subData) {
    return fetch({
        url: prefix+'/customer/alterStatus',
        method: 'post',
        data:subData
    });
}
//新增客户标签
export function addCusTag(subData) {
    return fetch({
        url: prefix+'/customer/insertCustomerLabel',
        method: 'post',
        data:subData
    });
}
//删除客户标签
export function deleCusTag(subData) {
    return fetch({
        url: prefix+'/customer/deleteCustomerLabel',
        method: 'delete',
        data:subData
    });
}
//客户日志信息
export function getCusLog(subData) {
    return fetch({
        url: prefix+'/customer/customerLogList?'+subData,
        method: 'get',
    });
}
/**
 *护理员
 * */
//护理员界面列表信息
export function careList(subData) {
    return fetch({
        url: prefix+'/caregiver/caregiverList?'+subData,
        method: 'get',
    });
}
//护理员具体信息
export function getCare(subData) {
    return fetch({
        url: prefix+'/caregiver/selectCaregiver?'+subData,
        method: 'get',
    });
}
//添加护理员基本信息
export function addCare(subData) {
    return fetch({
        url: prefix+'/caregiver/insertCaregiver',
        method: 'post',
        data:subData
    });
}
//操作护理员信息
export function careOpera(subData) {
    return fetch({
        url: prefix+'/caregiver/alterStatus',
        method: 'post',
        data:subData
    });
}
//更新护理员基本信息
export function changeCare(subData) {
    return fetch({
        url: prefix+'/caregiver/updateCaregiver',
        method: 'post',
        data:subData
    });
}
//删除护理员信息
export function deleCare(subData) {
    return fetch({
        url: prefix+'/caregiver/deleteCaregiver',
        method: 'delete',
        data:subData
    });
}
//新增护理员标签
export function addCareTag(subData) {
    return fetch({
        url: prefix+'/caregiver/insertCaregiverLabel',
        method: 'post',
        data:subData
    });
}
//删除护理员标签
export function deleCareTag(subData) {
    return fetch({
        url: prefix+'/caregiver/deleteCaregiverLabel',
        method: 'delete',
        data:subData
    });
}
//护理员日志信息
export function getCareLog(subData) {
    return fetch({
        url: prefix+'/caregiver/caregiverLogList?'+subData,
        method: 'get',
    });
}
/**
 *排班
 */
//护理员界面列表信息
export function careLiSch(subData) {
    return fetch({
        url: prefix+'/scheduling/caregiverList?'+subData,
        method: 'get',
    });
}
//护理员登记时间
export function schTime(subData) {
    return fetch({
        url: prefix+'/scheduling/selectRegistrationTime?'+subData,
        method: 'get',
    });
}
//新增护理员登记时间
export function addSchTime(subData) {
    return fetch({
        url: prefix+'/scheduling/insertRegistrationTime',
        method: 'post',
        data:subData
    });
}
//修改护理员登记时间
export function chanSchTime(subData) {
    return fetch({
        url: prefix+'/scheduling/updateRegistrationTime',
        method: 'put',
        data:subData
    });
}
//删除护理员登记时间
export function deleteSchTime(subData) {
    return fetch({
        url: prefix+'/scheduling/deleteRegistrationTime?'+subData,
        method: 'get',
    });
}
/**
 * 企业信息
 * */
//获取企业信息
export function getCompany(subData) {
    return fetch({
        url: prefix+'/manage/enterpriseInformation?'+subData,
        method: 'get',
    });
}
//修改企业信息
export function chanCompany(subData) {
    return fetch({
        url: prefix + '/manage/updateEnterpriseInformation',
        method: 'post',
        data: subData
    });
}
/**
 * 人员管理
 * */
//获取人员列表
export function userList() {
    return fetch({
        url: prefix+'/manage/userList',
        method: 'get',
    });
}
//新增人员
export function addUser(subData) {
    return fetch({
        url: prefix + '/manage/addUser',
        method: 'post',
        data: subData
    });
}
//修改人员
export function chanUser(subData) {
    return fetch({
        url: prefix + '/manage/updateUser',
        method: 'post',
        data: subData
    });
}
//激活人员
export function userActive(subData) {
    return fetch({
        url: prefix+'/manage/activationUser',
        method: 'post',
        data: subData
    });
}
//停止人员
export function userStop(subData) {
    return fetch({
        url: prefix+'/manage/stopUser',
        method: 'post',
        data: subData
    });
}
//删除人员
export function userDele(subData) {
    return fetch({
        url: prefix+'/manage/deleteUser',
        method: 'post',
        data: subData
    });
}
/**
 * 日工单
 * */
// 获取日工单信息
export function getDailyWork(subData) {
    return fetch({
        url: prefix+'/dailyWorkOrder/workOrderList?'+subData,
        method: 'get'
    });
}
// 修改日工单
export function updateDailyWork(subData) {
    return fetch({
        url: prefix+'/dailyWorkOrder/updateOrder',
        method: 'put',
        data:subData
    });
}
// 新增日工单
export function insertOrder(subData){
    return fetch({
        url: prefix+'/dailyWorkOrder/insertOrder',
        method:'post',
        data:subData
    })
}
//工单批量修改
export function updateOrderList(subData){
    return fetch({
        url: prefix+'/dailyWorkOrder/updateOrderList',
        method:'post',
        data:subData
    })
}
// 日工单导出
export function workOrderListExport(subData){
    return fetch({
        url:prefix+'/dailyWorkOrder/generateReports?'+subData,
        method:'get'
    })
}
// 台账导出
export function accountListExport(subData){
    return fetch({
        url:prefix+'/dailyWorkOrder/accountExport?'+subData,
        method:'get'
    })
}
//日工单日志
export function workOrderLogList(subData){
    return fetch({
        url:prefix +'/dailyWorkOrder/workOrderLogList?'+subData,
        method:'get'
    })
}
// 居委会信息获取
export function neighborhoodCommittee(subData){
    return fetch({
        url:prefix+'/manage/neighborhoodCommittee?'+subData,
        method:'get'
    })
}
//居委会信息新增
export function insertNeighborhoodCommittee(subData){
    return fetch({
        url:prefix+'/manage/insertNeighborhoodCommittee',
        method:'post',
        data:subData
    })
}
//查询角色信息
export function getAllRoles(subData){
    return fetch({
        url:prefix +'/manage/getAllRoles?'+subData,
        method:'get'
    })
}
//删除角色
export function deleteRole(subData){
    return fetch({
        url:prefix+'/manage/deleteRole',
        method:'post',
        data:subData
    })
}
//新建角色
export function addRole(subData){
    return fetch({
        url:prefix+'/manage/addRole',
        method:'post',
        data:subData
    })
}
//角色列表
export function listRole(){
    return fetch({
        url:prefix+'/manage/listRole',
        method:'get'
    })
}
//权限列表
export function listAllPermission(){
    return fetch({
        url:prefix+'/manage/listAllPermission',
        method:'get'
    })
}
//报表/文件夹里面 文件列表
export function fileList(subData){
    return fetch({
        url:prefix+'/report/fileList?'+subData,
        method:'get'
    })
}
// 报表/文件夹列表
export function sortList(subData){
    return fetch({
        url:prefix+'/report/sortList?'+subData,
        method:'get'
    })
}
// 报表/文件下载
export function fileDownload(subData){
    return fetch({
        url:prefix+'/report/fileDownload?'+subData,
        method:'get'
    })
}
//  报表/文件删除
export function deleteFile(subData){
    return fetch({
        url:prefix+'/report/deleteFile?'+subData,
        method:'get'
    })
}
//通知层
export function notificationLayerList(subData){
    return fetch({
        url:prefix+'/manage/notificationLayerList?'+subData,
        method:'get'
    })
}
// 通知层确认
export function notificationLayerConfirm(subData){
    return fetch({
        url:prefix+'/manage/notificationLayerConfirm?'+subData,
        method:'get',
        // data:subData
    })
}
//顶部通知
export function notify() {
    return fetch({
        url: prefix + '/manage/notificationLayer',
        method: 'get',
    });
}

// 个人中心
export function personalCenter(){
    return fetch({
        url:prefix+'/user/personalCenter',
        method:'get'
    })
}
// 修改个人中心信息
export function updatepersonalCenter(subData){
    return fetch ({
        url:prefix+'/user/updatepersonalCenter',
        method:'post',
        data:subData
    })
}
// 角色下人员列表
export function queryRolePersonnel(subData){
    return fetch ({
        url:prefix+'/manage/queryRolePersonnel?'+subData,
        method:'get',
    })
}
// 通知层-分配人员
export function assignTargetPersonnel(subData){
    return fetch ({
        url:prefix+'/manage/assignTargetPersonnel?'+subData,
        method:'get',
    })
}
// 用户基本信息导出
export function clientPythonExport(subData){
    return fetch ({
        url:prefix+'/customer/clientPythonExport?'+subData,
        method:'get',
    })
}
// 护理员基本信息导出
export function staffPythonExport(subData){
    return fetch ({
        url:prefix+'/caregiver/staffPythonExport?'+subData,
        method:'get',
    })
}
// 日工单导出
export function worksheetPythonExport(subData){
    return fetch ({
        url:prefix+'/dailyWorkOrder/worksheetPythonExport?'+subData,
        method:'get',
    })
}

// 日工单日志导出
export function worksheetLogExport(subData){
    return fetch ({
        url:prefix+'/dailyWorkOrder/worksheetLogExport?'+subData,
        method:'get',
    })
}
// 地图推荐护理员列表
export function customerMapInformation(subData){
    return fetch ({
        url:prefix+'/mapReport/recommendCaregiverList?'+subData,
        method:'get',
    })
}
// 地图查询护理员护理合约中老人列表信息
export function serviceCustomerList(subData){
    return fetch ({
        url:prefix+'/mapReport/serviceCustomerList?'+subData,
        method:'get',
    })
}
// 护理员基本信息导出
export function orderPythonExport(subData){
    return fetch ({
        url:prefix+'/scheduling/schedulingExport?'+subData,
        method:'get',
    })
}
/**
 * 结算详情
 * */
// 获取结算详情信息
export function getBillingDetailsList(subData) {
    return fetch({
        url: prefix+'/billingDetails/billingDetailsList?'+subData,
        method: 'get'
    });
}
// 导入结算详情信息
export function batchImportBillingDetails(subData){
    return fetch({
        url:prefix+'/billingDetails/batchImportBillingDetails',
        method:'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data:subData
    })
}
// 获取结算详情信息
export function automaticBindingCaregiverList(subData) {
    return fetch({
        url: prefix+'/billingDetails/caregiverList?'+subData,
        method: 'get'
    });
}
// 结算表老人点击 自动绑定
export function automaticBinding(subData) {
    return fetch({
        url: prefix+'/billingDetails/automaticBinding?'+subData,
        method: 'get'
    });
}
// 护理员结算-日期列表
export function settlementDateList(subData) {
    return fetch({
        url: prefix+'/billingDetails/settlementDateList?'+subData,
        method: 'get'
    });
}
// 护理员结算-老人工单
export function customerWorkOrderSettlement(subData) {
    return fetch({
        url: prefix+'/billingDetails/customerWorkOrderSettlement?'+subData,
        method: 'get'
    });
}
// 护理员结算-结算工单日期显示
export function theDateOfTheWorkOrderIsDisplayed(subData) {
    return fetch({
        url: prefix+'/billingDetails/theDateOfTheWorkOrderIsDisplayed?'+subData,
        method: 'get'
    });
}
// 护理员结算-已结算导出按钮
export function caregiverBillingExportButton(subData) {
    return fetch({
        url: prefix+'/billingDetails/caregiverBillingExportButton?'+subData,
        method: 'get'
    });
}
// 护理员结算-未结算导出按钮
export function noSettlementWorkOrderButton(subData) {
    return fetch({
        url: prefix+'/billingDetails/noSettlementWorkOrderListExportButton?'+subData,
        method: 'get'
    });
}deskCalendarPythonExport
// 工单-台历导出
export function deskCalendarPythonExport(subData) {
    return fetch({
        url: prefix+'/dailyWorkOrder/deskCalendarPythonExport?'+subData,
        method: 'get'
    });
}
// 工作流list
export function customerWorkflowList(subData){
    return fetch({
        url:prefix+'/customerWorkflow/customerWorkflowList?'+subData,
        method:'get'
    })
}
// 工作流详情
export function customerWorkflowDetails(subData){
    return fetch({
        url:prefix+'/customerWorkflow/customerWorkflowDetails?'+subData,
        method:'get'
    })
}
// 工作流激活按钮触发
export function customerWorkflowButtonToTrigger(subData){
    return fetch({
        url:prefix+'/customerWorkflow/customerWorkflowButtonToTrigger?'+subData,
        method:'get'
    })
}
// 工作流指派按钮触发
export function querySupervisorPermissions(){
    return fetch({
        url:prefix+'/customerWorkflow/querySupervisorPermissions',
        method:'get'
    })
}
// 工作流创建按钮
export function createWorkflowButton(subData){
    return fetch({
        url:prefix+'/customerWorkflow/createWorkflowButton?'+subData,
        method:'get'
    })
}
// 查看历史工作流
export function customerHistoryWorkflowDetails(subData){
    return fetch({
        url:prefix+'/customerWorkflow/customerHistoryWorkflowDetails?'+subData,
        method:'get'
    })
}
// 工作流外部创建按钮
export function externalCreateWorkflowButton(subData){
    return fetch({
        url:prefix+'/customerWorkflow/externalCreateWorkflowButton?'+subData,
        method:'get'
    })
}
// 老人概况数据查询
export function clientProFileDateSelect(subData){
    return fetch({
        url:prefix+'/serviceCenterInterface/clientProFile?'+subData,
        method:'get'
    })
}
// 护理员概况数据查询
export function caregiverProFileSelect(subData){
    return fetch({
        url:prefix+'/serviceCenterInterface/caregiverProFile?'+subData,
        method:'get'
    })
}
// 评估等级分布情况
export function evaluationLervlDistributionSelect(subData){
    return fetch({
        url:prefix+'/serviceCenterInterface/evaluationLervlDistribution?'+subData,
        method:'get'
    })
}
// 工单报表
export function workOrderReportSelect(subData){
    return fetch({
        url:prefix+'/serviceCenterInterface/workOrderReport?'+subData,
        method:'get'
    })
}
// 昨天实时数据
export function yesterdayIsLiveData(){
    return fetch({
        url:prefix+'/serviceCenterInterface/yesterdayIsLiveData',
        method:'get'
    })
}
// 操作报表
export function operationReportSelect(){
    return fetch({
        url:prefix+'/serviceCenterInterface/operationReport',
        method:'get'
    })
}
// 站点概况数据查询
export function siteInterfaceDateSelect(subData){
    return fetch({
        url:prefix+'/webmasterInterface/siteInterface?'+subData,
        method:'get'
    })
}
// 今日老人服务信息统计
export function customerServiceInformationTodayDataSelect(subData){
    return fetch({
        url:prefix+'/webmasterInterface/customerServiceInformationToday?'+subData,
        method:'get'
    })
}
// 今日实时数据
export function todayIsLiveData(subData){
    return fetch({
        url:prefix+'/webmasterInterface/todayIsLiveData?'+subData,
        method:'get'
    })
}
// 站点概况列表信息详情
export function siteDataDetailList(subData){
    return fetch({
        url:prefix+'/webmasterInterface/selectSiteDataDetailList?'+subData,
        method:'get'
    })
}
// 今日实时数据列表信息详情
export function selectTodayIsLiveDataDetailList(subData){
    return fetch({
        url:prefix+'/webmasterInterface/selectTodayIsLiveDataDetailList?'+subData,
        method:'get'
    })
}

// 护理员批量操作查询老人列表
export function customerBatchOperationQueryList(subData){
    return fetch({
        url:prefix+'/caregiver/customerBatchOperationQueryList?'+subData,
        method:'get'
    })
}
// 批量修改老人状态确认
export function batchModificationConfirmation(subData){
    return fetch({
        url:prefix+'/caregiver/batchModificationConfirmation?'+subData,
        method:'get'
    })
}
// 新增老人额外家属信息
export function saveAddFamilyInformation(subData){
    return fetch({
        url:prefix+'/customer/saveAddFamilyInformation?'+subData,
        method:'post'
    })
}
// 查看老人额外家属信息
export function viewOtherFamilyMembers(subData){
    return fetch({
        url:prefix+'/customer/viewOtherFamilyMembers?'+subData,
        method:'get'
    })
}
// 查看护理员保险信息
export function queryCaregiverInsuranceInformation(subData){
    return fetch({
        url:prefix+'/caregiver/queryCaregiverInsuranceInformation?'+subData,
        method:'get'
    })
}
// 查询风控信息list
export function windControlInformationList(subData){
    return fetch({
        url:prefix+'/windControl/windControlInformationList?'+subData,
        method:'get'
    })
}
//查询风控类型list
export function windControlTypeList(subData){
    return fetch({
        url:prefix+'/windControl/windControlTypeList?'+subData,
        method:'get'
    })
}
//排班
export function roster(subData) {
    return fetch({
        url: prefix+'/scheduling/roster?'+subData,
        method: 'get',
    });
}
//合并周历
export function weeklyCalendar(subData) {
    return fetch({
        url: prefix+'/scheduling/weeklyCalendar?'+subData,
        method: 'get',
    });
}//推荐类型
export function recommendedType() {
    return fetch({
        url: prefix+'/user/recommendedType',
        method: 'get',
    });
}
// 删除老人其他家属
export function deleteFamilyInformation(subData) {
    return fetch({
        url: prefix+'/customer/deleteFamilyInformation?'+subData,
        method: 'get',
    });
}
// 护理员对单列表
export function inquireSingleList(subData) {
    return fetch({
        url: prefix+'/single/inquireSingleList?'+subData,
        method: 'get',
    });
}
// 护理员对单详情（当日、昨日对单工单）
export function singleDetails(subData) {
    return fetch({
        url: prefix+'/single/singleDetails?'+subData,
        method: 'get',
    });
}
// 护理员推荐老人
export function inquireCustomerRecommendList(subData) {
    return fetch({
        url: prefix+'/recommend/recommendCustomerList?'+subData,
        method: 'get',
    });
}
// 护理员推荐护理员
export function inquireCaregiverRecommendList(subData) {
    return fetch({
        url: prefix+'/recommend/recommendCaregiverList?'+subData,
        method: 'get',
    });
}
// 服务情况导入
export function serviceSituationImport(subData){
    const fetchTwo = axios.create({
        baseURL:process.env.NODE_ENV ==='development'? "http://localhost:8087":"http://47.110.149.54:8087",
        timeout: 50000,// request timeout
        xhrFields: {
            withCredentials: true
        },
    })
    return fetchTwo({
        url:'/serviceSituation/serviceSituationImport',
        method:'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data:subData
    })
}
// 服务情况表
export function serviceSituationList(subData) {
    return fetch({
        url: prefix+'/serviceSituation/serviceSituationList?'+subData,
        method: 'get',
    });
}
// 服务情况表
export function communicationConfirmation(subData) {
    return fetch({
        url: prefix+'/serviceSituation/communicationConfirmation?'+subData,
        method: 'get',
    });
}
// 老人全部日志查询
export function getCusAllLogs(subData) {
    return fetch({
        url: prefix+'/customer/customerAllLogsList?'+subData,
        method: 'get',
    });
}
// 工资生成
export function wageGenerationButton(subData) {
    return fetch({
        url: prefix+'/wage/wageGeneration?'+subData,
        method: 'get',
    });
}
// 工资列表
export function inquiryWageList(subData) {
    return fetch({
        url: prefix+'/wage/wageList?'+subData,
        method: 'get',
    });
}
// 修改基础单价
export function modifyBaseMage(subData) {
    return fetch({
        url: prefix+'/wage/modifyBaseMage?'+subData,
        method: 'get',
    });
}
// 工资导出
export function wageExport(subData) {
    return fetch({
        url: prefix+'/wage/wageExport?'+subData,
        method: 'get',
    });
}
// 老人全部日志导出
export function allLogsExport(subData) {
    return fetch({
        url: prefix+'/customer/customerAllLogsExport?'+subData,
        method: 'get',
    });
}
// 无工单重新匹配
export function rematch(subData) {
    return fetch({
        url: prefix+'/serviceSituation/rematch?'+subData,
        method: 'get',
    });
}
// 护理站权限
export function querySiteCompetenceList(subData) {
    return fetch({
        url: prefix+'/manage/querySiteCompetenceList?'+subData,
        method: 'get',
    });
}
// 修改护理站权限
export function modifySitePermission(subData) {
    return fetch({
        url: prefix+'/manage/modifySitePermission?'+subData,
        method: 'get',
    });
}
// 护理站变更刷新权限
export function siteChange(subData) {
    return fetch({
        url: prefix+'/user/siteChange?'+subData,
        method: 'get',
    });
}
// 角色权限
export function queryRoleCompetenceList(subData) {
    return fetch({
        url: prefix+'/manage/queryRoleCompetenceList?'+subData,
        method: 'get',
    });
}
// 修改角色权限
export function modifyRolePermission(subData) {
    return fetch({
        url: prefix+'/manage/modifyRolePermission?'+subData,
        method: 'get',
    });
}
// 新增角色
export function addRoleInfo(subData) {
    return fetch({
        url: prefix+'/manage/addRoleInfo?'+subData,
        method: 'get',
    });
}
// 文档
export function inquireUserDocumentTableList(subData) {
    return fetch({
        url: prefix+'/userDocument/inquireUserDocumentTableList?'+subData,
        method: 'get',
    });
}
// 生成文档
export function generateDocumentation(subData) {
    return fetch({
        url: prefix+'/userDocument/generateDocumentation?'+subData,
        method: 'get',
    });
}
// 家访列表
export function inquireHomeVisitList(subData) {
    return fetch({
        url: prefix+'/homeVisit/homeVisitList?'+subData,
        method: 'get',
    });
}
// 预约家访
export function bookAHomeVisit(subData) {
    return fetch({
        url: prefix+'/homeVisit/bookAHomeVisit?'+subData,
        method: 'get',
    });
}
// 护理站列表
export function listSite(subData) {
    return fetch({
        url: prefix+'/manage/listSite?'+subData,
        method: 'get',
    });
}
// 护理站列表
export function modifySiteAuthority(subData) {
    return fetch({
        url: prefix+'/manage/modifySiteAuthority?'+subData,
        method: 'get',
    });
}
// 自费手动结算
export function manualSettlementAtOwnExpense(subData) {
    return fetch({
        url: prefix+'/billingDetails/manualSettlementAtOwnExpense?'+subData,
        method: 'get',
    });
}
export function epidemicList(subData) {
    return fetch({
        url: prefix+'/epidemic/epidemicList?'+subData,
        method: 'get',
    });
}
export function exportEpidemicList(subData) {
    return fetch({
        url: prefix+'/epidemic/exportEpidemicList?'+subData,
        method: 'get',
    });
}