<template>
  <div class="client" v-loading="loading">
    <div class="client-left">
      <div class="filter">
        <el-select v-model="careStatus" style="width: 90px" class="sel line" @change="getCareList()">
          <el-option label="全部状态" value=""></el-option>
          <el-option label="合约中" value="合约中"></el-option>
          <el-option label="中止中" value="中止中"></el-option>
          <el-option label="已终止" value="已终止"></el-option>
          <el-option label="待审核" value="待审核"></el-option>
          <el-option label="已删除" value="已删除"></el-option>
        </el-select>&nbsp;
        <el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="siteChangeOperating()"
            style="float: right;width: 180px"></el-cascader>
        <br>
        <el-input v-model="careText" style="width: 210px" class="inp line" placeholder="姓名,手机号,紧急电话,地址" clearable
                  @keyup.enter.native="getCareList"></el-input>
        <el-button size="small" type="primary" @click="getCareList()">搜索</el-button>
      </div>
      <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1 && this.orderModule.caregiverList"></pageTemp>
      <div class="client-ul" v-if="this.orderModule.caregiverList">
        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                      view-class="p20-scrollbar__view">
          <div class="client-list" v-for="item in schArr" :key="item.id"
               @click="getDes(item.id,item.contractInformationId)"
               :class="{'active-class':item.id===careId}">
            <div v-if="item.status === '合约中'" class="stateGreen"><span>{{ item.status }}</span></div>
            <div v-else-if="item.status === '已终止'" class="stateRed"><span>{{ item.status }}</span></div>
            <div v-else-if="item.status === '中止中'" class="stateRed"><span>{{ item.status }}</span></div>
            <div v-else-if="item.status === '待审核'" class="stateYellow"><span>{{ item.status }}</span></div>
            <div v-else-if="item.status === '已删除'" class="stateDarkRed"><span>{{ item.status }}</span></div>
            <div class="flex number">
              <p class="num">{{ item.numbering }}</p>
            </div>
            <p>{{ item.name }} ({{ item.gender }}) | {{ item.moblle }}</p>
            <p>{{ item.statusChangetime }} | {{ item.count }}人</p>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="client-right">
      <div class="tab-box">
        <el-row style="margin-top: 10px;text-align: center">
          <el-col :span="5" v-if="showDetailsWhetherToOpen"><el-input v-model="showDetails.name" style="width: 150px"></el-input></el-col>
          <el-col :span="5" v-if="showDetailsWhetherToOpen">
            <el-input v-model="showDetails.moblle" style="width: 130px"></el-input>
            &nbsp;
            <el-button type="primary" @click="copyUrl" class="copy-btn" :data-clipboard-text="showDetails.moblle">复制电话</el-button>
          </el-col>
          <el-col :span="10" v-if="showDetailsWhetherToOpen">
            <el-input v-model="showDetails.address" style="width: 400px"></el-input>
              &nbsp;
            <el-button type="primary" @click="copyUrl" class="copy-btn" :data-clipboard-text="showDetails.address">复制地址</el-button>
          </el-col>
          <el-col :span="2" style="float: right">
            <el-button type="success" @click="orderAllExport()" v-if="this.orderModule.schedulingExport">
              导出全部
            </el-button>
          </el-col>
        </el-row>
        <el-tabs v-model="activeName" v-loading="load">
          <el-tab-pane label="排班" name="first" v-if="this.orderModule.roster">
            <div style="height:2%;width: 98%;position:absolute;top:0px;left:50px;z-index: 2">
              <el-tag class="cycle" style="margin-left: 0px">周一 ( {{mondayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周二 ( {{tuesdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周三 ( {{wednesdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周四 ( {{thursdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周五 ( {{fridayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周六 ( {{saturdayNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周日 ( {{sunday}}单 )</el-tag>
            </div>
            <div class="container" style="margin: 0 auto;">
              <FullCalendar
                  :plugins="calendarPlugins"
                  :all-day-slot="false"
                  :firstDay="1"
                  :slot-event-overlap="false"
                  :events=rosterEvents
                  @eventClick="eventClick"
                  :unselect-auto="false"
                  :select-overlap="false"
                  :business-hours="{
                    startTime: '05:25',
                    endTime:'22:30',
                    daysOfWeek: [ 1, 2, 3, 4, 5, 6, 0 ]
                  }"
                  select-mirror="true"
                  min-time="05:25:00"
                  max-time="22:30:00"
                  selectable="true"
                  slot-duration="00:35"
                  default-view="timeGridWeek"
                  locale="zh-cn"
                  :columnFormat="{
                    week:'ddd'
                  }"
                  :eventTimeFormat="{
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                  }"
                  :slotLabelFormat="{
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                  }"
                  :header="false"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="合并周历" name="weeklyCalendar" v-if="this.orderModule.weeklyCalendar">
            <div style="height:2%;width: 98%;position:absolute;top:0px;left:49px;z-index: 2">
              <el-tag class="cycle" style="margin-left: 0px">周一 ( {{mondayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周二 ( {{tuesdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周三 ( {{wednesdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周四 ( {{thursdayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周五 ( {{fridayOddNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周六 ( {{saturdayNumber}}单 )</el-tag>
              <el-tag class="cycle" style="margin-left: 0px">周日 ( {{sunday}}单 )</el-tag>
            </div>
            <div class="container" style="margin: 0 auto;">
              <FullCalendar
                  :plugins="calendarPlugins"
                  :all-day-slot="false"
                  :firstDay="1"
                  :slot-event-overlap="false"
                  :events=weeklyCalendarEvents
                  @eventClick="eventClick"
                  :unselect-auto="false"
                  :select-overlap="false"
                  :business-hours="{
                    startTime: '05:25',
                    endTime:'22:30',
                    daysOfWeek: [ 1, 2, 3, 4, 5, 6, 0 ]
                  }"
                  select-mirror="true"
                  min-time="05:25:00"
                  max-time="22:30:00"
                  selectable="true"
                  slot-duration="00:35"
                  default-view="timeGridWeek"
                  locale="zh-cn"
                  :columnFormat="{
                    week:'ddd'
                  }"
                  :eventTimeFormat="{
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                  }"
                  :slotLabelFormat="{
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                  }"
                  :header="false"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="登记时间" name="second" v-if="this.orderModule.selectRegistrationTime">
            <div class="add-regitime">
              <el-button type="primary" size="small" @click="addTimeBu()" v-if="this.orderModule.insertRegistrationTime">新增登记时间</el-button>
            </div>
            <el-table
                :data="regiTable"
                border
                style="width: 100%"
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center'}"
                max-height="780"
            >
              <el-table-column prop="theFirstFew" label="服务时间优先级"></el-table-column>
              <el-table-column prop="beginTime" label="开始时间"></el-table-column>
              <el-table-column prop="endTime" label="结束时间"></el-table-column>
              <el-table-column prop="timeType" label="时间类型"></el-table-column>
              <el-table-column prop="serviceCycle" label="服务周期（星期）"></el-table-column>
              <el-table-column prop="remarks" label="备注"></el-table-column>
              <el-table-column prop="operation" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="regiTimeBu(scope.row)" v-if="this.orderModule.updateRegistrationTime">修改</el-button>
                  <el-button type="text" size="small" @click="deleteTimeBu(scope.row)" v-if="this.orderModule.deleteRegistrationTime">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="addTimeDialog" width="600px">
      <el-form :model="addTimeForm" :rules="addTimeRules" ref="addTimeForm" label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="服务时间" prop="regionTime">
          <el-time-picker
              is-range
              v-model="addTimeForm.regionTime"
              format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="时间类型" prop="timeType">
          <el-select v-model="addTimeForm.timeType" placeholder="请选择时间类型">
            <el-option label="意向" value="意向"></el-option>
            <el-option label="占用" value="占用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务周期" prop="serviceCycle">
          <el-checkbox-group v-model="addTimeForm.serviceCycle">
            <el-checkbox v-for="item in weekTime" :label="item.id" :key="item.index">{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input class="input" placeholder="登记备注" v-model="addTimeForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="operaTimeCancel('addTimeForm')">取 消</el-button>
        <el-button type="primary" @click="operaTimeForm('addTimeForm')">确定</el-button>
      </div>
    </el-dialog>
    <!--用户详情-->
    <el-dialog title="用户详情" :visible.sync="clientDetails" width="80%">
      <div class="des-box">
        <el-form ref="form" :model="form" :rules="cRules" label-width="100px">
          <div class="des  assess">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="form.name" placeholder="请输入客户姓名" class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="手机号" prop="moblle">
                  <el-input v-model="form.moblle" placeholder="请输入客户手机号码" class="line"
                            style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="身份证号" prop="identityCard">
                  <el-input v-model="form.identityCard" placeholder="请输入客户身份证号"
                            class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="年龄" prop="age">
                  <el-input v-model="form.age" placeholder="请输入客户年龄" class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="家属姓名">
                  <el-input v-model="form.familyName" placeholder="请输入客户家属姓名"
                            class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="紧急联络电话">
                  <el-input v-model="form.familyMoblle" placeholder="请输入客户紧急联系方式"
                            class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">&nbsp;&nbsp;&nbsp;&nbsp;
                <el-button type="primary" @click="viewOtherFamilyMembers()" size="small"
                           v-show="!isAdd">查看其它家属
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-form-item label="性别">
                  <el-radio-group v-model="form.gender">
                    <el-radio label="男">男</el-radio>
                    <el-radio label="女">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="银行卡所属行" prop="bankCardName">
                  <el-input v-model="form.bankCardName" placeholder="请输入银行卡所属行名"
                            class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="银行卡号" prop="bankCardNumber">
                  <el-input v-model="form.bankCardNumber" placeholder="请输入银行卡号"
                            class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="状态备注" prop="statusRemarks">
                  <el-input v-model="form.statusRemarks" class="line" style="width: 400px"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <br/>
          <el-row :gutter="0">
            <el-col :span="6">
              <el-form-item label="已签约:">
                <el-select v-model="selectEvaluationNursingId" @change="jumpToCustomer()" v-show="!isAdd"
                           style="width: 150px">
                  <el-option
                      v-for="item in customeContracttypesList"
                      :key="item.index"
                      :label="item.customeContractType"
                      :value="item.evaluationNursingId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="client-list-two">
                <div v-if="form.status === '有意向'" class="stateYellow"><span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '待派单'" class="stateYellow">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '待申请'" class="stateYellow">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '待评估'" class="lightSkyBlue">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '中止中'" class="stateRed">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '长期中止'" class="stateMaroon">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '待确认'" class="stateYellow">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '合约中'" class="stateGreen">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '已终止'" class="stateRed">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '到期合约'" class="stateDarkgray">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
                <div v-else-if="form.status === '已删除'" class="stateDarkgray">
                  <span>该合约状态：{{ form.status }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="des  assess">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="长护险平台是否录入" label-width="160px">
                  <el-switch v-model="form.enterPlan" active-value="1"
                             inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="长护险平台是否接收" label-width="160px">
                  <el-switch v-model="form.enterPlanFlag" active-value="1"
                             inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="医护平台是否派单" label-width="160px">
                  <el-switch v-model="form.mdicalPatform" active-value="1"
                             inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="护理计划编码">
                  <el-input v-model="form.plannlngSheet" placeholder="请输入客户护理计划编码"
                            class="line"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="街道">
                  <el-input v-model="form.sreet" class="line" @focus="toAddress()" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="居委会" prop="committees">
                  <el-input v-model="form.committees" class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="详细地址">
                  <el-input v-model="form.addressDetall" class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="设备编号">
                  <el-input v-model="form.lbeaconMajor" placeholder="请输入设备编号" class="line"
                            learable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="评估有效期">
                  <el-date-picker
                      v-model="form.evaluationPeriod"
                      type="date"
                      style="width: 200px"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="首次护理时间">
                  <el-date-picker
                      v-model="form.firstCareTime"
                      type="date"
                      style="width: 200px"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="评估等级">
                  <el-input v-model="form.evaluationLervl" type="number" placeholder="请输入客户评估等级"
                            class="line" style="width: 200px"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="医保卡类型">
                  <el-select v-model="form.medicareCardType" placeholder="请选择医保卡类型" class="line">
                    <el-option
                        v-for="item in medicareTypes"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="推荐渠道">
                  <el-input v-model="form.recommend" placeholder="请输入客户推荐渠道"
                            class="line" style="width: 200px;"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="护理备注">
                  <el-input v-model="form.nursingRemarks" class="line" style="width: 800px;"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="des  assess">

            <el-row :gutter="0">
              <el-col :span="8" style="width: 35%">
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="指定护理员一">
                      <el-input v-model="careWorker_one" placeholder="请选择指定护理员一" class="line"
                                @focus="careInput(1)"></el-input>
                      <el-button type="danger" @click="deleteCareWorker_one()" v-show="!isAdd" size="mini"
                                 icon="el-icon-delete"
                                 circle></el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="指定原因">
                      <el-input v-model="form.designationReason_one" placeholder="指定护理员一原因" class="line"
                                clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="指定护理员二">
                      <el-input v-model="careWorker_two" placeholder="请选择指定护理员二" class="line"
                                @focus="careInput(2)"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="指定原因">
                      <el-input v-model="form.designationReason_two" placeholder="指定护理员二原因"
                                class="line"
                                clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="指定护理员三">
                      <el-input v-model="careWorker_three" placeholder="请选择指定护理员三" class="line"
                                @focus="careInput(3)"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="指定原因">
                      <el-input v-model="form.designationReason_three" placeholder="指定护理员三原因"
                                class="line"
                                clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="备用时间备注">
                      <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" style="width: 55%">
                <div v-for="(item, index) in timePeriodList" v-bind:key="index">
                  <el-row :gutter="20">
                    <el-col :span="4">
                      <el-select v-model="item.week" placeholder="周期">
                        <el-option
                            v-for="item in serveWeek"
                            :key="item.week"
                            :label="item.name"
                            :value="item.week">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="护理时间">
                        <el-time-select
                            style="width: 120px"
                            placeholder="起始时间"
                            v-model="item.beginTime"
                            format="HH:mm"
                            :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                        </el-time-select>
                        <el-time-select
                            style="width: 120px"
                            placeholder="结束时间"
                            v-model="item.endTime" :disabled="true" class="line"
                            :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-select v-model="item.medicareStatusValue" placeholder="状态">
                        <el-option
                            v-for="item in medicareStatus"
                            :key="item.medicareStatusValue"
                            :label="item.label"
                            :value="item.medicareStatusValue">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="des  assess">
            <el-row :gutter="0">
              <el-col :span="8" style="width: 35%">
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="预约指定护理员" label-width="160px">
                      <el-input v-model="reservation_careWorker" placeholder="请选择预约指定护理员" class="line"
                                @focus="careInput(4)" clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="预约指定原因" label-width="160px">
                      <el-input v-model="form.reservation_designationReason" placeholder="预约指定原因" style="width:200px"
                                clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="状态预约时间" prop="appointmentTime" v-if="!isAdd" label-width="160px">
                      <el-input v-model="form.appointmentTime" :disabled="true" style="width:200px"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="0">
                  <el-col :span="24">
                    <el-form-item label="预约变更周期时间" prop="appointmentCycleChangeTime" v-if="!isAdd"
                                  label-width="160px">
                      <el-input v-model="form.appointmentCycleChangeTime" :disabled="true"
                                style="width:200px"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" style="width: 55%">
                <div v-for="(item, index) in appointmentTimePeriodList" v-bind:key="index">
                  <el-row :gutter="20">
                    <el-col :span="4">
                      <el-select v-model="item.week" :disabled="true" placeholder="周期">
                        <el-option
                            v-for="item in serveWeek"
                            :key="item.week"
                            :label="item.name"
                            :value="item.week">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="护理时间">
                        <el-time-select
                            style="width: 120px"
                            placeholder="起始时间"
                            v-model="item.beginTime" :disabled="true"
                            format="HH:mm"
                            :picker-options="{
                                              start: '06:00',
                                              step: '00:05',
                                              end: '20:00'
                                            }">
                        </el-time-select>
                        <el-time-select
                            style="width: 120px"
                            placeholder="结束时间"
                            v-model="item.endTime" :disabled="true" class="line"
                            :picker-options="{
                                              start: '07:00',
                                              step: '00:05',
                                              end: '21:00',
                                              minTime:item.beginTime
                                            }">
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-select v-model="item.medicareStatusValue" :disabled="true" placeholder="状态">
                        <el-option
                            v-for="item in medicareStatus"
                            :key="item.medicareStatusValue"
                            :label="item.label"
                            :value="item.medicareStatusValue">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="des map">
            <div class="flex">
              <el-form-item label="纬度" label-width="50px">
                <el-input v-model="form.latltude" placeholder="" class="line"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="经度" label-width="50px">
                <el-input v-model="form.longltude" placeholder="" class="line"
                          clearable></el-input>
              </el-form-item>
              <!--<span class="local">纬度：{{form.latltude}}</span><span class="local">经度：{{form.longltude}}</span>-->
            </div>
            <div id="map"></div>
          </div>
          <div class="des card">
            <el-tabs v-model="activeName">
              <el-tab-pane label="身份证" name="first">
                <div class="flex img-box">
                  <div>
                    <el-upload
                        class="avatar-uploader"
                        action="customize"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        accept='.jpg,.png'
                        ref="idBefUpload"
                        :on-change="idBefChange"
                    >
                      <img v-if="idBefUrl" :src="idBefUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p>（身份证正面）</p>
                  </div>
                  <div style="margin-left: 30px">
                    <el-upload
                        class="avatar-uploader"
                        action="customize"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        accept='.jpg,.png'
                        ref="idBacUpload"
                        :on-change="idBacChange"
                    >
                      <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p>（身份证反面）</p>
                  </div>
                </div>
              </el-tab-pane>

              <el-tab-pane label="医保卡" name="second">
                <div class="flex img-box">
                  <div>
                    <el-upload
                        class="avatar-uploader"
                        action="customize"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        accept='.jpg,.png'
                        ref="soBefUpload"
                        :on-change="soBefChange"
                    >
                      <img v-if="soBefUrl" :src="soBefUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p>（社保卡正面）</p>
                  </div>
                  <div style="margin-left: 30px">
                    <el-upload
                        class="avatar-uploader"
                        action="customize"
                        :show-file-list="false"
                        :http-request="uploadFile"
                        accept='.jpg,.png'
                        ref="soBacUpload"
                        :on-change="soBacChange"
                    >
                      <img v-if="soBacUrl" :src="soBacUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p>（社保卡反面）</p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-form>
      </div>

    </el-dialog>
    <el-dialog title="查看家属信息" :visible.sync="familyInformationDialog" width="1200px">
      <el-table :data="familyInformation"
                border
                stripe
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center','man-width':'100px'}"
                max-height="700"
      >
        <el-table-column prop="familyName" label="家属姓名"></el-table-column>
        <el-table-column prop="familyMoblle" label="紧急联络电话"></el-table-column>
        <el-table-column prop="familyNotes" label="家属备注"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import pageTemp from "@/components/pageTemp.vue";
import Clipboard from 'clipboard';
import {
  careLiSch,
  addSchTime,
  schTime,
  chanSchTime,
  deleteSchTime,
  orderPythonExport,
  getCus,
  getCare,
  viewOtherFamilyMembers,
  roster,
  weeklyCalendar,
  siteChange
} from "@/common/js/index.js";
import {setHours, isEmpty} from "@/common/js/util.js"
import BMap from 'BMap';
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import '@fullcalendar/core/locales/zh-cn'

export default {
  name: "order",
  components: {pageTemp, FullCalendar},
  data() {
    return {
      mondayOddNumber:0,//周一单数
      tuesdayOddNumber:0,
      wednesdayOddNumber:0,
      thursdayOddNumber:0,
      fridayOddNumber:0,
      saturdayNumber:0,
      sunday:0,
      rosterEvents: [],
      weeklyCalendarEvents: [],
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      timePeriodList: [], //时间周期
      appointmentTimePeriodList: [], //预约时间周期
      medicareStatus: [{
        medicareStatusValue: '0',
        label: '正常'
      }, {
        medicareStatusValue: '2',
        label: '非医保服务'
      }, {
        medicareStatusValue: '4',
        label: '医保虚拟服务'
      }],
      serveWeek: [
        {name: "周一", week: '1'},
        {name: "周二", week: '2'},
        {name: "周三", week: '3'},
        {name: "周四", week: '4'},
        {name: "周五", week: '5'},
        {name: "周六", week: '6'},
        {name: "周日", week: '7'},
      ],
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      siteList: localStorage.getItem("siteList").split("$"),
      selectEvaluationNursingId: null,// 合约信息id
      contractTypeId: '',
      customeContracttypesList: [],// 老人 已签约 合约类型
      clientId: null,//客户Id
      familyInformationDialog: false,//查看家属信息
      familyInformation: [],
      /*用户begin*/
      idBefUrl: "",//身份证正面
      idBacUrl: "",//身份证反面
      soBefUrl: "",//社保卡正面
      soBacUrl: "",//社保卡反面
      form: {
        status: "",
        id: "",//用户id
        name: "",//姓名
        moblle: "",//电话
        identityCard: "",//身份证号
        age: "",//年龄
        familyName: "",//家人姓名
        familyMoblle: "",//紧急电话
        gender: "",//性别
        numbering: "",//编号
        medicareCardType: "",//医保卡类型
        recommend: "",//推荐渠道
        firstCareTime: "",//首次护理时间
        addressDetall: "",//详细地址
        sreet: "",//街道
        committees: "",//居委会
        latltude: "",//纬度
        longltude: "",//经度
        ldentlyCarPlc: null,//身份证正面
        ldentlyCardBackPlc: null,//身份证反面
        medlcareCardPlc: null,//社保卡正面
        medlcareCardPlcBack: null,//社保卡反面
        evaluationLervl: null,//评估等级
        plannlngSheet: "",//护理计划表编码
        evaluationPeriod: "",//评估有效期
        enterPlan: "0",//长护险平台录入计划（是否录入)
        enterPlanFlag: "0",//长护险平台接受（是否接受）
        mdicalPatform: "0",//医护平台派单（是否派单）
        lbeaconMajor: "",//设备编号
        nursingRemarks: "",//护理备注
        careWorker_id_one: null,//指定护理员(id)1
        designationReason_one: "",//指定原因1
        careWorker_id_two: null,//指定护理员(id)2
        designationReason_two: "",//指定原因2
        careWorker_id_three: null,//指定护理员(id)3
        designationReason_three: "",//指定原因3
        reservation_careWorker_id: "",//预约指定护理员
        reservation_designationReason: "",//预约指定原因
        statusRemarks: "", //状态备注
        standbyTimeRemarks: "",//备用时间备注
        appointmentTime: "",//状态操作预约时间
      },
      medicareTypes: localStorage.getItem("medicareCardType").split("$"),
      checkList: [],
      reservation_nursingCycle: [],//意向服务时间（周）
      nursingCycle: [],//护理周期
      careWorker_one: "",//指定护理员1
      careWorker_two: "",//指定护理员2
      careWorker_three: "",//指定护理员3
      reservation_careWorker: "",//预约指定护理员
      nursingTime: "",//护理时间
      nursingAppoTime: "",//预约护理时间
      clientDetails: false,
      cRules: {
        name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
        moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
        identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
        age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
        status: [{required: true, message: '请选择状态', trigger: 'change'}],
      },
      isAdd: false,
      /*用户end*/
      siteValue: sessionStorage.getItem("siteValue"),
      loading: true,
      load: false,
      addTimeDialog: false,
      title: "新增登记时间",
      careStatus: "合约中",
      careText: "",
      schArr: [],
      pages: 0,
      currPage: 1,
      activeName: 'first',
      careId: "",
      addTimeForm: {
        regionTime: "",
        timeType: "",
        serviceCycle: [],
        theFirstFew: 0,
        remarks: ""
      },
      weekTime: [
        {name: "周一", id: '1'},
        {name: "周二", id: '2'},
        {name: "周三", id: '3'},
        {name: "周四", id: '4'},
        {name: "周五", id: '5'},
        {name: "周六", id: '6'},
        {name: "周日", id: '7'},
      ],
      orderTable: [],
      weekTable: [],
      weektimeTable: [],
      regiTable: [],
      addTimeRules: {
        regionTime: [
          {required: true, message: '请选择服务时间范围', trigger: 'blur'},
        ],
        timeType: [
          {required: true, message: '请选择时间类型', trigger: 'blur'},
        ],
        serviceCycle: [
          {required: true, message: '请选择护理周期', trigger: 'blur'},
        ],
      },
      showDetailsWhetherToOpen:false, //显示详细信息是否开启
      showDetails:{//显示详细信息
        name:"",
        address: "",
        moblle:""
      },
      orderModule:{
        caregiverList:false,
        selectRegistrationTime:false,
        insertRegistrationTime:false,
        updateRegistrationTime:false,
        roster:false,
        weeklyCalendar:false,
        deleteRegistrationTime:false,
        schedulingExport:false
      },
    }
  },
  methods: {
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.orderModule.caregiverList = this.isExist(permissionList,"scheduling:caregiverList");
          this.orderModule.selectRegistrationTime = this.isExist(permissionList,"scheduling:selectRegistrationTime");
          this.orderModule.insertRegistrationTime = this.isExist(permissionList,"scheduling:insertRegistrationTime");
          this.orderModule.updateRegistrationTime = this.isExist(permissionList,"scheduling:updateRegistrationTime");
          this.orderModule.roster = this.isExist(permissionList,"scheduling:roster");
          this.orderModule.weeklyCalendar = this.isExist(permissionList,"scheduling:weeklyCalendar");
          this.orderModule.deleteRegistrationTime = this.isExist(permissionList,"scheduling:deleteRegistrationTime");
          this.orderModule.schedulingExport = this.isExist(permissionList,"scheduling:schedulingExport");

          this.getCareList()
        }
      })
    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    // 复制链接
    copyUrl() {
      let _this = this;
      let clipboard = new Clipboard(".copy-btn"); // 这里括号里填写上面点击事件绑定的class名
      clipboard.on("success", e => {
        // 复制成功，提示根据自己项目实际使用的UI来写
        _this.$message.success("复制成功")
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制，提示根据自己项目实际使用的UI来写
        _this.$message.error("该浏览器不支持自动复制")
        // 释放内存
        clipboard.destroy();
      });
    },
    eventClick(event){
      console.log("eventClick",event)
      this.showDetails.name = event.event.title
      this.showDetails.moblle = event.event._def.extendedProps.moblle
      this.showDetails.address = event.event._def.extendedProps.sreet + event.event._def.extendedProps.committees + event.event._def.extendedProps.addressDetall
      if (this.showDetails.address!==''){
        this.showDetailsWhetherToOpen =true
      }else {
        this.showDetailsWhetherToOpen =false
      }
    },
    viewOtherFamilyMembers() {
      let subData = 'clientId=' + this.clientId
      viewOtherFamilyMembers(subData).then(res => {
        if (res) {
          this.familyInformation = res.data
        }
      })
      this.familyInformationDialog = true;
    },
    /*点击begin*/
    jumpToCustomer(row) {
      this.customeContracttypesList = null
      this.load = true;
      this.fatForm();
      if (row !== undefined) {
        this.clientId = row.id;
      }
      if (row != null) {
        this.selectEvaluationNursingId = row.evaluationNursingId
      }

      let subData = "id=" + this.clientId + '&evaluationNursingId=' + this.selectEvaluationNursingId;

      this.isAdd = false;
      var s = this
      getCus(subData).then(res => {
        this.allLoading = false;
        this.load = false;

        if (res) {
          this.customeContracttypesList = res.data['customeContracttypesList']
          for (let i = 0; i < this.customeContracttypesList.length; i++) {
            if (this.customeContracttypesList[i].evaluationNursingId === this.selectEvaluationNursingId) {
              this.contractTypeId = this.customeContracttypesList[i].contractTypeId
            }
          }
          let timePeriod = res.data['basicInformation'][0].timePeriod;
          if (timePeriod != null) {
            this.timePeriodList = JSON.parse(timePeriod);
            this.timePeriodList.sort(function (a, b) {
              return b.week > a.week ? -1 : 1;
            });

          } else {
            this.timePeriodList = []
          }
          let appointmentTimePeriod = res.data['basicInformation'][0].appointmentTimePeriod;
          if (appointmentTimePeriod != null) {
            this.appointmentTimePeriodList = JSON.parse(appointmentTimePeriod);
            this.appointmentTimePeriodList.sort(function (a, b) {
              return b.week > a.week ? -1 : 1;
            });

          } else {
            this.appointmentTimePeriodList = []
          }
          var map = new BMap.Map("map");
          var point = new BMap.Point(res.data['basicInformation'][0].longltude, res.data['basicInformation'][0].latltude);
          map.centerAndZoom(point, 15);
          var marker = new BMap.Marker(point); // 创建点
          map.addOverlay(marker);

          function showInfo(e) {
            map.clearOverlays();
            //改经纬度
            var marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)); // 创建点
            map.addOverlay(marker);//增加点
            s.form.longltude = e.point.lng;
            s.form.latltude = e.point.lat;
          }

          map.addEventListener("click", showInfo);

          this.form = res.data['basicInformation'][0];
          this.idBefUrl = res.data['basicInformation'][0].ldentlyCarPlc;
          this.idBacUrl = res.data['basicInformation'][0].ldentlyCardBackPlc;
          this.soBefUrl = res.data['basicInformation'][0].medlcareCardPlc;
          this.soBacUrl = res.data['basicInformation'][0].medlcareCardPlcBack;
          this.operaList = [];
          for (let i = 0; i < res.data['list'].length; i++) {
            this.operaList.push({
              value: res.data['list'][i].split(',')[0],
              state: res.data['list'][i].split(',')[1],
            })
          }
          this.dynamicTags = res.data['label'];
          if (res.data['basicInformation'][0].nursingCycle != null) {
            this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
          } else {
            this.nursingCycle = [];
          }
          if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
            this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
          } else {
            this.reservation_nursingCycle = [];
          }
          if (isEmpty(this.form.careWorker_id_one)) {
            this.nurseDes(this.form.careWorker_id_one, this.form.contractInformationId, 1)
          }
          if (isEmpty(this.form.careWorker_id_two)) {
            this.nurseDes(this.form.careWorker_id_two, this.form.contractInformationId, 2)
          }
          if (isEmpty(this.form.careWorker_id_three)) {
            this.nurseDes(this.form.careWorker_id_three, this.form.contractInformationId, 3)
          }
          if (isEmpty(this.form.reservation_careWorker_id)) {
            this.nurseDes(this.form.reservation_careWorker_id, this.form.reservationContractInformationId, 4)
          }
          if (isEmpty(this.form.nursingStartTime) && isEmpty(this.form.nursingEndTime)) {
            this.nursingTime = [];
            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingStartTime.split(':')[0], this.form.nursingStartTime.split(':')[1], this.form.nursingStartTime.split(':')[2]));
            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingEndTime.split(':')[0], this.form.nursingEndTime.split(':')[1], this.form.nursingEndTime.split(':')[2]));
          }
          if (isEmpty(this.form.reservation_nursingStartTime) && isEmpty(this.form.reservation_nursingEndtime)) {
            this.nursingAppoTime = [];
            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingStartTime.split(':')[0], this.form.reservation_nursingStartTime.split(':')[1], this.form.reservation_nursingStartTime.split(':')[2]));
            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingEndtime.split(':')[0], this.form.reservation_nursingEndtime.split(':')[1], this.form.reservation_nursingEndtime.split(':')[2]));
          }
        }
      })
      this.clientDetails = true
    },
    // 文件上传
    uploadFile() {
    },
    idBefChange(file) {
      this.idBefUrl = URL.createObjectURL(file.raw);
    },
    idBacChange(file) {
      this.idBacUrl = URL.createObjectURL(file.raw);
    },
    soBefChange(file) {
      this.soBefUrl = URL.createObjectURL(file.raw);
    },
    soBacChange(file) {
      this.soBacUrl = URL.createObjectURL(file.raw);
    },
    //获取护理员名称
    nurseDes(id, contractInformationId, num) {
      let subData = "id=" + id + '&contractInformationId=' + contractInformationId;
      getCare(subData).then(res => {
        if (res) {
          let name = res.data['basicCaregiver'][0].name;
          switch (num) {
            case 1:
              this.careWorker_one = name;
              break;
            case 2:
              this.careWorker_two = name;
              break;
            case 3:
              this.careWorker_three = name;
              break;
            case 4:
              this.reservation_careWorker = name;
              break;
            default:
              break;
          }
        }
      })
    },
    /**
     *选择护理员
     * */
    careInput(num) {
      this.careFlag = num;
      this.careDialog = true;
      this.carePage = 1;
      this.careForm.status = "";
      this.careForm.searchCondition = "";
      this.getCares();
    },
    fatForm() {
      this.nursingTime = "";
      this.nursingAppoTime = '';
      this.reservation_nursingCycle = [];//预约服务时间（周）
      this.nursingCycle = [];//护理周期
      this.careWorker_one = "";//指定护理员1
      this.careWorker_two = "";//指定护理员2
      this.careWorker_three = "";//指定护理员3
      this.reservation_careWorker = "";//预约指定护理员
      this.form = {
        status: "",
        id: "",//用户id
        name: "",//姓名
        moblle: "",//电话
        identityCard: "",//身份证号
        age: "",//年龄
        familyName: "",//家人姓名
        familyMoblle: "",//紧急电话
        gender: "",//性别
        numbering: "",//编号
        medicareCardType: "",//医保卡类型
        recommend: "",//推荐渠道
        firstCareTime: "",//首次护理时间
        addressDetall: "",//详细地址
        sreet: "",//街道
        committees: "",//居委会
        latltude: "",//纬度
        longltude: "",//经度
        ldentlyCarPlc: null,//身份证正面
        ldentlyCardBackPlc: null,//身份证反面
        medlcareCardPlc: null,//社保卡正面
        medlcareCardPlcBack: null,//社保卡反面
        evaluationLervl: null,//评估等级
        plannlngSheet: "",//护理计划表编码
        evaluationPeriod: "",//评估有效期
        enterPlan: "0",//长护险平台录入计划（是否录入)
        enterPlanFlag: "0",//长护险平台接受（是否接受）
        mdicalPatform: "0",//医护平台派单（是否派单）
        lbeaconMajor: "",//设备编号
        nursingRemarks: "",//护理备注
        careWorker_id_one: null,//指定护理员(id)
        designationReason_one: "",//指定原因1
        careWorker_id_two: null,//指定护理员(id)2
        designationReason_two: "",//指定原因2
        careWorker_id_three: null,//指定护理员(id)3
        designationReason_three: "",//指定原因3
        reservation_careWorker_id: "",//预约指定护理员
        reservation_designationReason: "",//预约指定原因
        standbyTimeRemarks: "",//备用时间备注
        appointmentTime: "",//状态操作预约时间
      }
    },
    /*点击护理员end*/
    /*排班报表导出*/
    orderExport() {
      this.$confirm('生成排班报表中,请等待,稍后去报表中查看下载', '确定导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let subData = "caregiverId=" + this.careId + "&status=" + this.cliStatus
            + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
            + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
        orderPythonExport(subData).then(res => {
          if (res) {
            this.tableData = res.data
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].date != null && this.tableData[i].date !== '') {
                this.tableData[i].date = this.changeTime(this.tableData[i].date)
              }
            }
            this.change()//改变0/1为已完成/未完成模式
            this.$message.success('护理员排班信息导出成功,请到报表中进行查看下载');
            // console.log('export',this.tableData)
          }
        })

      }).catch(() => {
      });
    },

    /*排班报表导出*/
    orderAllExport() {
      this.$confirm('生成所有排班报表中,请等待,稍后去报表中查看下载', '确定导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let subData = "siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
            + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
        orderPythonExport(subData).then(res => {
          if (res) {
            this.$message.success('护理员排班信息导出成功,请到报表中进行查看下载');
          }
        })

      }).catch(() => {
      });
    },
    filterTag(value, row) {
      return row.status === value;
    },
    contractTypeNamefilterTag(value, row) {
      return row.contractTypeName === value;
    },
    // toClient(row) {
    //     if (isEmpty(row.id)) {
    //         this.$router.push({path: '/assignClient', query: {id: row.id}})
    //     }
    // },
    /**
     * 地址栏输入排班护理员id 提取
     * */
    submit() {
      var newurl = this.updateParam(window.location.href, 'orderId', this.input);
      //向当前url添加参数，没有历史记录
      window.history.replaceState({
        path: newurl
      }, '', newurl);
    },
    updateParam(uri, key, value) {
      if (!value) {
        return uri;
      }
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
      } else {
        return uri + separator + key + "=" + value;
      }
    },
    /**
     * 获取护理员列表
     * */
    getCareList() {
      let subData = "currPage=" + this.currPage + "&searchCondition=" + this.careText + "&status=" + this.careStatus
          + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
      careLiSch(subData).then(res => {
        if (res) {
          this.schArr = res.data;
          if (res.data.length!==0){
            this.pages = res.data[0].totalCount;
          }

        }
      })
    },
    /**
     * 获取护理员登记时间
     * */
    getSchTime(id) {
      let subData = "id=" + id;
      this.regiTable = [];
      schTime(subData).then(res => {
        if (res) {
          this.regiTable = res.data;
          this.load = false;
        }
      })
    },
    /**
     * 新增登记时间
     * */
    //新增按钮
    addTimeBu() {
      this.title = "新增登记时间";
      this.addTimeDialog = true;
      this.addTimeForm.timeType = "";
      this.addTimeForm.serviceCycle = [];
      this.addTimeForm.regionTime = "";
      this.addTimeForm.remarks = ""
    },
    //修改按钮
    regiTimeBu(row) {
      this.title = "修改登记时间";
      this.addTimeDialog = true;
      this.addTimeForm.timeType = row.timeType;
      this.addTimeForm.theFirstFew = row.theFirstFew;
      this.addTimeForm.serviceCycle = row.serviceCycle.split(",");
      this.addTimeForm.regionTime = [];
      this.addTimeForm.regionTime.push(new Date(2016, 9, 10, row.beginTime.split(':')[0], row.beginTime.split(':')[1], row.beginTime.split(':')[2]));
      this.addTimeForm.regionTime.push(new Date(2016, 9, 10, row.endTime.split(':')[0], row.endTime.split(':')[1], row.endTime.split(':')[2]));
      this.addTimeForm.remarks = row.remarks;
    },
    // 删除按钮
    deleteTimeBu(row) {
      let subData = "id=" + this.careId + "&theFirstFew=" + row.theFirstFew;
      this.$confirm('此操作将永久删除该护理员登记时间，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSchTime(subData).then(res => {
          if (res) {
            this.$message.success('删除成功')
            this.getSchTime(this.careId);
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //提交请求
    operaTimeForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let subData;
          if (this.title === "新增登记时间") {
            subData = {
              caregiverId: this.careId,
              beginTime: setHours(this.addTimeForm.regionTime[0]),
              endTime: setHours(this.addTimeForm.regionTime[1]),
              timeType: this.addTimeForm.timeType,
              serviceCycle: this.addTimeForm.serviceCycle.join(","),
              remarks: this.addTimeForm.remarks,
            };
            addSchTime(subData).then(res => {
              if (res) {
                this.$message.success('添加服务时间成功');
                this.getSchTime(this.careId);
                this.addTimeDialog = false;
                this.$refs[formName].resetFields();
              }
            })
          } else if (this.title === "修改登记时间") {
            subData = {
              caregiverId: this.careId,
              theFirstFew: this.addTimeForm.theFirstFew,
              beginTime: setHours(this.addTimeForm.regionTime[0]),
              endTime: setHours(this.addTimeForm.regionTime[1]),
              timeType: this.addTimeForm.timeType,
              serviceCycle: this.addTimeForm.serviceCycle.join(","),
              remarks: this.addTimeForm.remarks,
            };
            chanSchTime(subData).then(res => {
              if (res) {
                this.$message.success('修改服务时间成功');
                this.getSchTime(this.careId);
                this.addTimeDialog = false;
                this.$refs[formName].resetFields();
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    operaTimeCancel(formName) {
      this.addTimeDialog = false;
      this.$refs[formName].resetFields();
    },
    /**
     * 点击护理员列表
     * */
    getDes(id, contractInformationId) {
      this.showDetailsWhetherToOpen =false
      this.load = true;
      this.careId = id;
      this.getSchTime(id);
      this.getRoster(id, contractInformationId)
      this.getWeeklyCalendar(id,contractInformationId)
    },
    /**
     * 获取排班
     * */
    getRoster(id, contractInformationId) {
      this.orderTable = [];
      let subData = "id=" + id + '&contractInformationId=' + contractInformationId;
      roster(subData).then(res => {
        if (res) {
          this.rosterEvents = res.data;
          const length = this.rosterEvents.length;
          this.mondayOddNumber = this.rosterEvents[length-1].mondayOddNumber;
          this.tuesdayOddNumber = this.rosterEvents[length-1].tuesdayOddNumber;
          this.wednesdayOddNumber = this.rosterEvents[length-1].wednesdayOddNumber;
          this.thursdayOddNumber = this.rosterEvents[length-1].thursdayOddNumber;
          this.fridayOddNumber = this.rosterEvents[length-1].fridayOddNumber;
          this.saturdayNumber = this.rosterEvents[length-1].saturdayNumber;
          this.sunday = this.rosterEvents[length-1].sunday;
        }
      })
    },
    /**
     * 获取合并周历
     * */
    getWeeklyCalendar(id, contractInformationId) {
      this.orderTable = [];
      let subData = "id=" + id + '&contractInformationId=' + contractInformationId;
      weeklyCalendar(subData).then(res => {
        if (res) {
          this.weeklyCalendarEvents = res.data;
          const length = this.rosterEvents.length;
          this.mondayOddNumber = this.rosterEvents[length-1].mondayOddNumber;
          this.tuesdayOddNumber = this.rosterEvents[length-1].tuesdayOddNumber;
          this.wednesdayOddNumber = this.rosterEvents[length-1].wednesdayOddNumber;
          this.thursdayOddNumber = this.rosterEvents[length-1].thursdayOddNumber;
          this.fridayOddNumber = this.rosterEvents[length-1].fridayOddNumber;
          this.saturdayNumber = this.rosterEvents[length-1].saturdayNumber;
          this.sunday = this.rosterEvents[length-1].sunday;
        }
      })
    },
    goSpecifiedPage(val) {
      this.currPage = val;
      this.getCareList();
    },
    refresh() {
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.orderModule.caregiverList = this.isExist(permissionList,"scheduling:caregiverList");
          this.orderModule.selectRegistrationTime = this.isExist(permissionList,"scheduling:selectRegistrationTime");
          this.orderModule.insertRegistrationTime = this.isExist(permissionList,"scheduling:insertRegistrationTime");
          this.orderModule.updateRegistrationTime = this.isExist(permissionList,"scheduling:updateRegistrationTime");
          this.orderModule.roster = this.isExist(permissionList,"scheduling:roster");
          this.orderModule.weeklyCalendar = this.isExist(permissionList,"scheduling:weeklyCalendar");
          this.orderModule.deleteRegistrationTime = this.isExist(permissionList,"scheduling:deleteRegistrationTime");
          this.orderModule.schedulingExport = this.isExist(permissionList,"scheduling:schedulingExport");

          var str = "order"
          var orderId = "";
          if (this.orderId !== '' && this.orderId != null && this.orderId.search(str) === -1) {
            orderId = this.orderId
          }
          let subData = "currPage=" + this.currPage + "&searchCondition=" + this.careText + "&status=" + this.careStatus + "&staffId=" + orderId
              + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
              + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
          careLiSch(subData).then(res => {
            if (res) {
              this.schArr = res.data;
              if (res.data.length!==0){
                this.pages = res.data[0].totalCount;
                this.careId = res.data[0].id;
                this.getDes(this.careId,res.data[0].contractInformationId);
              }
              this.loading = false;

            }
          })
        }
      })

    }
  },
  created() {
    var loc = location.href;
    var n1 = loc.length;//地址的总长度
    var n2 = loc.indexOf("=");//取得=号的位置
    var id = decodeURI(loc.substr(n2 + 1, n1 - n2));//从=号后面的内容
    var str = "order"
    this.orderId = id;
    if (this.orderId !== '' && this.orderId != null && this.orderId.search(str) === -1) {
      this.getDes(this.orderId);
    }

    this.refresh();
    window.addEventListener('setItem', () => {
      this.loading = true;
      this.siteValue = sessionStorage.getItem('siteValue');
      this.refresh();
    }, false)
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.cycle {
  width: 202px;height: 50px;text-align: center;font-size:18px;
}
.client-ul {
  height: calc(100% - 120px);

}

.filter {
  width: 100%;
  padding: 5px 0 0 10px;
  height: 100px;
  box-sizing: border-box;
  border-bottom: 1px solid #DDD;

  .inp {
    width: 100%;
    margin-left: -10px;
    /*margin:0 20px;*/
  }

  .sel {
    width: 35%;
  }
}

.add-time {
  /* border: 1px solid red;*/
  width: 600px;
  margin: 80px 0 0 200px;
}

.client-left {
  width: 298px;
  height: 100%;
  border-right: 1px solid #DDD;
}

.client-right {
  box-sizing: border-box;
  width: calc(100% - 270px);
  margin-top: -18px;
  padding: 10px;

  .tab-box {
    overflow: auto;
  }
}

.state {
  background: #40CA98;
  color: #FFFFFF;
  height: 50px;
  width: 100px;
  position: absolute;
  right: -32px;
  top: -10px;
  transform: rotate(7deg);
  -ms-transform: rotate(7deg); /* IE 9 */
  -moz-transform: rotate(7deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
  -o-transform: rotate(7deg); /* Opera */
  text-align: center;
}

.state span {
  line-height: 80px;
  /*position: absolute;*/
  /*bottom: 0;*/
}

.client-list p {
  margin: 0 0 5px 0;
  font-weight: normal;
  font-size: 14px;
}

.client-list .num {
  color: #409EFF;
}

.add-regitime {
  text-align: right;
  padding-bottom: 10px;
}

.input {
  width: 200px;
}
</style>
<!--用户弹出页面css-->
<style scoped lang="scss">
.over-due {
  /* position: absolute;*/
  /* top:4px;*/
  font-size: 12px;
  color: #FFF;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: red;
  text-align: center;
  line-height: 20px;
}

.right-opare {
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #DDD;
  padding: 10px 20px;
  box-sizing: border-box;
}

.des-box {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0 20px 70px 20px;
  /*  border: 1px solid red;*/
  box-sizing: border-box;
}

.des {
  margin-top: 20px;
  border: 1px dashed #999;
  border-radius: 10px;
  overflow: hidden;

  .map {
    overflow: hidden;

  }

  .local {
    display: inline-block;
    margin-left: 20px;
  }
}

.serve {
  padding: 20px 0 0 20px;
}

.des-flex {
  min-width: 600px;
  display: -webkit-flex; /* Safari */
  display: flex;
  height: auto;
  padding-top: 20px;

  .input-box {
    margin-left: 5%;
  }

  .line {
    width: 300px;
  }
}

.assess {
  padding: 20px 0 0 30px;

  .line {
    width: 260px;
  }
}

#map {
  width: 100%;
  height: 300px;
}

.adress {
  padding-left: 5%;

  .line {
    width: 260px;
  }
}

.card {
  padding: 0 40px 40px 40px;

  .img-box {
    width: 500px;
    justify-content: space-between;

    p {
      text-align: center;
    }
  }
}

/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}

.avatar {
  width: 364px;
  height: 229px;
  display: block;
}

/*标签*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.form .form-left {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.form .form-right {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
.main {
  margin: 10px;
  text-align: center;
  background: #fff;
  padding: 10px;
}

.info {
  min-height: 50px;
  min-width: 300px;
  line-height: 30px;
  position: fixed;
  top: 0px;
  left: 43%;
  color: forestgreen;
  background: rgba(173, 255, 47, .2);
}
</style>
