<template>
    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                  view-class="p20-scrollbar__view">
        <el-container>
            <el-main>
                <el-radio v-model="clientProFileInterfaceSiteId" label="1">巾帼站</el-radio>
                <el-radio v-model="clientProFileInterfaceSiteId" label="2">建浦站</el-radio>
                <el-radio v-model="clientProFileInterfaceSiteId" label="3">安康通</el-radio>
                <el-radio v-model="clientProFileInterfaceSiteId" label="5">震瀛站</el-radio>
                <el-radio v-model="clientProFileInterfaceSiteId" label="4">震瀛二站</el-radio>
                <el-radio v-model="clientProFileInterfaceSiteId" label="6">优蓓杰</el-radio>
                <div class="chart" ref="clientProFileInterface"></div>
            </el-main>
            <el-main>
                <el-radio v-model="caregiverProFileInterfaceSiteId" label="1">巾帼站</el-radio>
                <el-radio v-model="caregiverProFileInterfaceSiteId" label="2">建浦站</el-radio>
                <el-radio v-model="caregiverProFileInterfaceSiteId" label="3">安康通</el-radio>
                <el-radio v-model="caregiverProFileInterfaceSiteId" label="5">震瀛站</el-radio>
                <el-radio v-model="caregiverProFileInterfaceSiteId" label="4">震瀛二站</el-radio>
                <el-radio v-model="caregiverProFileInterfaceSiteId" label="6">优蓓杰</el-radio>
                <div class="chart" ref="caregiverProFileInterface"></div>
            </el-main>
        </el-container>
        <el-container>
            <el-main>
                <el-radio v-model="evaluationLervlSiteId" label="1">巾帼站</el-radio>
                <el-radio v-model="evaluationLervlSiteId" label="2">建浦站</el-radio>
                <el-radio v-model="evaluationLervlSiteId" label="3">安康通</el-radio>
                <el-radio v-model="evaluationLervlSiteId" label="5">震瀛站</el-radio>
                <el-radio v-model="evaluationLervlSiteId" label="4">震瀛二站</el-radio>
                <el-radio v-model="evaluationLervlSiteId" label="6">优蓓杰</el-radio>
                <div class="chart" ref="evaluationLervl"></div>
            </el-main>
        </el-container>
        <el-container>
            <el-main class="el-main-two">
                <el-radio v-model="workOrderReportSiteId" label="1">巾帼站</el-radio>
                <el-radio v-model="workOrderReportSiteId" label="2">建浦站</el-radio>
                <el-radio v-model="workOrderReportSiteId" label="3">安康通</el-radio>
                <br/>
                <el-radio v-model="workOrderReportSiteId" label="5">震瀛站</el-radio>
                <el-radio v-model="workOrderReportSiteId" label="4">震瀛二站</el-radio>
                <el-radio v-model="workOrderReportSiteId" label="6">优蓓杰</el-radio>
                <div class="chart" ref="workOrderReport"></div>
            </el-main>
            <el-main class="el-main-three">
                <p><strong style="font-size: 20px">昨日实时数据</strong></p>
                <el-table
                        :data="tables"
                        border
                        stripe
                        style="width: 100%;margin-bottom:30px;font-size: 22px"
                        :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="500"
                >
                    <el-table-column prop="siteName" label="护理站"></el-table-column>
                    <el-table-column prop="addCustomer" label="新增用户"></el-table-column>
                    <el-table-column prop="suspendCustomer" label="中止用户"></el-table-column>
                    <el-table-column prop="restoreCustomer" label="恢复用户"></el-table-column>
                    <el-table-column prop="terminationCustomer" label="终止用户"></el-table-column>
                    <el-table-column prop="toSendASingleCustomer" label="待派单用户"></el-table-column>
                    <el-table-column prop="addCaregiver" label="新增护理员"></el-table-column>
                    <el-table-column prop="suspendCaregiver" label="中止护理员"></el-table-column>
                    <el-table-column prop="restoreCaregiver" label="恢复护理员"></el-table-column>
                    <el-table-column prop="terminationCaregiver" label="终止护理员"></el-table-column>
                </el-table>
            </el-main>
        </el-container>
        <el-container>
            <el-main class="el-main-four">
                <p><strong style="font-size: 18px">操作报表(上个自然月数据)</strong></p>
                <el-table
                        :data="operationReports"
                        border
                        stripe
                        style="width: 100%;margin-bottom:30px;font-size: 22px"
                        :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="1000"
                >
                    <el-table-column prop="userName" label="人员"></el-table-column>
                    <el-table-column prop="addCustomer" label="新增用户"></el-table-column>
                    <el-table-column prop="customerModifyInformation" label="修改信息"></el-table-column>
                    <el-table-column prop="suspend" label="暂停数"></el-table-column>
                    <el-table-column prop="restoreCustomer" label="恢复数"></el-table-column>
                    <el-table-column prop="customerReservationChangeStatus" label="预约数"></el-table-column>
                    <el-table-column prop="terminationCustomer" label="终止数"></el-table-column>
                    <el-table-column prop="customerRemark" label="备注"></el-table-column>
                    <el-table-column prop="addCaregiver" label="新增护理员"></el-table-column>
                    <el-table-column prop="addWorkOrder" label="新增工单"></el-table-column>
                    <el-table-column prop="deleteWorkOrder" label="删除工单"></el-table-column>
                    <el-table-column prop="addWorkflow" label="创建工作流"></el-table-column>
                    <el-table-column prop="workflowProcessing" label="处理工作流"></el-table-column>
                </el-table>
            </el-main>
        </el-container>
        <el-container>
            <el-main></el-main>
        </el-container>
    </el-scrollbar>
</template>
<script>
    import {
        clientProFileDateSelect, caregiverProFileSelect, evaluationLervlDistributionSelect,
        workOrderReportSelect, yesterdayIsLiveData, operationReportSelect
    } from '@/common/js/index.js'

    export default {
        name: "serviceCenterInterface",
        data() {
            return {
                clientProFileInterfaceSiteId: '1', // 用户概况选择护理站id
                caregiverProFileInterfaceSiteId: '1',// 护理员概况选择护理站id
                evaluationLervlSiteId: '1',// 评估等级分布情况选择护理站id
                workOrderReportSiteId: '1',//工单报表详情
                clientProFileData: [], //用户概况数据
                caregiverProFileData: [], //护理员概况数据
                evaluationLervlDistributionData: [], // 评估等级分布情况数据
                evaluationLervlDistributionXAxisData: [],
                workOrderReportData: [],
                tables: [],//昨天实时数据
                operationReports: [],
            }
        },
        mounted() {
        },
        methods: {
            /**
             * 操作报表
             */
            operationReportSelect() {
                operationReportSelect().then(res => {
                    if (res) {
                        this.operationReports = res.data;
                    }
                })
            },
            /**
             * 昨天实时数据
             */
            yesterdayIsLiveDataSelect() {
                yesterdayIsLiveData().then(res => {
                    if (res) {
                        this.tables = res.data;
                    }
                })
            },
            /**
             * 老人概况数据查询
             */
            clientProFileDateSelect() {
                let subData = "siteId=" + this.clientProFileInterfaceSiteId;
                clientProFileDateSelect(subData).then(res => {
                    if (res) {
                        this.clientProFileData = res.data;
                        this.clientProFile();
                    }
                })
            },
            /**
             * 护理员概况数据查询
             */
            caregiverProFileSelect() {
                let subData = "siteId=" + this.caregiverProFileInterfaceSiteId;
                caregiverProFileSelect(subData).then(res => {
                    if (res) {
                        this.caregiverProFileData = res.data;
                        this.caregiverProFile();
                    }
                })
            },

            /**
             * 评估等级分布情况
             */
            evaluationLervlDistributionSelect() {
                let subData = "siteId=" + this.evaluationLervlSiteId;
                evaluationLervlDistributionSelect(subData).then(res => {
                    if (res) {
                        this.evaluationLervlDistributionData = res.data.evaluationLervlDistribution;
                        this.evaluationLervlDistributionXAxisData = res.data.evaluationLervlDistributionXAxisData;
                        this.evaluationLervlDistribution();

                    }
                })
            },
            /**
             * 工单报表查询
             */
            workOrderReportSelect() {
                let subData = "siteId=" + this.workOrderReportSiteId;
                workOrderReportSelect(subData).then(res => {
                    if (res) {
                        this.workOrderReportData = res.data;
                        this.workOrderReport();

                    }
                })
            },
            /**
             * 老人概况
             */
            clientProFile() {
                // 基于准备好的dom，初始化echarts实例
                let clientProFileInterface = this.$echarts.init(this.$refs.clientProFileInterface);
                clientProFileInterface.setOption({ // 绘制图表

                    color: ['#3398DB'],
                    title: {text: '用户概况'},
                    tooltip: {},
                    xAxis: {
                        data: ["所有用户", "合约中用户", "中止中用户", "待派单用户", "待评估用户", "待确认用户", "长期中止用户", "已终止用户"],
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.clientProFileData,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    formatter: '{c}',
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        },
                    }]

                });
            },
            /**
             * 护理员概况
             */
            caregiverProFile() {
                // 基于准备好的dom，初始化echarts实例
                let caregiverProFileInterface = this.$echarts.init(this.$refs.caregiverProFileInterface);
                caregiverProFileInterface.setOption({ // 绘制图表
                    color: ['#3398DB'],
                    title: {text: '护理员概况'},
                    tooltip: {},
                    xAxis: {
                        data: ["所有护理员", "合约中护理员", "中止中护理员", "待审核护理员", "已终止护理员"],
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.caregiverProFileData,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    },
                                    formatter: '{c}人'
                                }
                            }
                        },
                    }]
                });
            },
            /**
             * 评估等级分布情况
             */
            evaluationLervlDistribution() {
                // 基于准备好的dom，初始化echarts实例
                let evaluationLervl = this.$echarts.init(this.$refs.evaluationLervl);
                evaluationLervl.setOption({ // 绘制图表
                    color: ['#3398DB'],
                    title: {text: '评估等级分布情况'},
                    tooltip: {},
                    xAxis: {
                        data: this.evaluationLervlDistributionXAxisData,
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.evaluationLervlDistributionData,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    },
                                    formatter: '{c}人'
                                }
                            }
                        },
                    }]
                });
            },
            /**
             * 工单报表
             */
            workOrderReport() {
                // 基于准备好的dom，初始化echarts实例
                let workOrderReportInterface = this.$echarts.init(this.$refs.workOrderReport);
                workOrderReportInterface.setOption({ // 绘制图表

                    color: ['#3398DB'],
                    title: {text: '工单报表'},
                    tooltip: {},
                    xAxis: {
                        data: ["今日工单数", "今日合约中用户"],
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {},
                    series: [{
                        name: '',
                        type: 'bar',
                        data: this.workOrderReportData,
                        itemStyle: {        //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    formatter: '{c}',
                                    textStyle: { //数值样式
                                        color: 'black',
                                        fontSize: 16
                                    }
                                }
                            }
                        },
                    }]

                });
            },
        },
        created() {
            this.clientProFileDateSelect();
            this.caregiverProFileSelect();
            this.evaluationLervlDistributionSelect();
            this.workOrderReportSelect();
            this.yesterdayIsLiveDataSelect();
            this.operationReportSelect();
        },
        watch: {
            clientProFileInterfaceSiteId: function () {
                this.clientProFileDateSelect()
            },
            caregiverProFileInterfaceSiteId: function () {
                this.caregiverProFileSelect()
            },
            evaluationLervlSiteId: function () {
                this.evaluationLervlDistributionSelect();
            },
            workOrderReportSiteId: function () {
                this.workOrderReportSelect();
            }
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 90%;
    }

    .el-main {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        width: 100%;
        height: 500px;
    }

    .el-main-two {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        width: 30%;
        height: 500px;
    }

    .el-main-three {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        width: 100%;
        height: 500px;
    }

    .el-main-four {
        background-color: #E9EEF3;
        color: #333;
        text-align: center;
        width: 100%;
        max-height: 1800px;
    }
</style>
