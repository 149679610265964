<template>
  <div class="client">
    <div class="left" v-loading="allLoad">
      <div class="filter">
        <el-select v-model="nurStatus" class="sel line" @change="getNurses()">
          <el-option label="全部状态" value=""></el-option>
          <el-option label="合约中" value="合约中"></el-option>
          <el-option label="中止中" value="中止中"></el-option>
          <el-option label="已终止" value="已终止"></el-option>
          <el-option label="待审核" value="待审核"></el-option>
          <el-option label="已删除" value="已删除"></el-option>
        </el-select>
        <el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="siteChangeOperating()"
            style="float: right;width: 200px"></el-cascader>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <br>
        <el-input v-model="nurText" class="inp line2" placeholder="姓名、联系号码、地址" clearable
                  @keyup.enter.native="getNurses"></el-input>
        <el-button size="small" type="primary" @click="getNurses()">搜索</el-button>

      </div>
      <br>
      <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1 && this.singleModule.serviceSituationList"></pageTemp>
      <div class="client-ul" v-if="this.singleModule.serviceSituationList">
        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                      view-class="p20-scrollbar__view">
          <div class="client-list" v-for="item in nurseArr" :key="item.index"
               @click="serviceSituationList(item.id,item.contractInformationId,item.name,'staForm')"
               :class="{'active-class':item.id==staffId}">
            <div class="flex number">
              <p class="num">{{ item.numbering }}</p>
            </div>
            <p>{{ item.name }} ({{ item.gender }}) | {{ item.moblle }}</p>
            <p>{{ item.statusChangetime }}</p>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="right" v-if="this.singleModule.serviceSituationList">
      <div style="height: 10%;">
        <div class="gd-top" style="margin:10px 0 20px 0">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">
            <el-form-item label="状态">
              <el-select v-model="searchForm.status" placeholder="请选择" class="sel2" @change="serviceSituationListButton()">
                <el-option
                    v-for="item in options"
                    :key="item.status"
                    :label="item.label"
                    :value="item.status">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务状态">
              <el-select v-model="searchForm.serviceStatus" placeholder="请选择" class="sel2" @change="serviceSituationListButton()">
                <el-option
                    v-for="item in options2"
                    :key="item.status"
                    :label="item.label"
                    :value="item.status">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="沟通状态">
              <el-select v-model="searchForm.communication" placeholder="请选择" class="sel2" @change="serviceSituationListButton()">
                <el-option
                    v-for="item in options3"
                    :key="item.status"
                    :label="item.label"
                    :value="item.status">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="日期默认今天">
              <el-date-picker
                  v-model="searchForm.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="serviceSituationListButton()">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="serviceSituationListButton()">查询</el-button>
              <el-button type="success" @click="turnOn()" v-if="this.singleModule.communicationConfirmation">沟通确认</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="height: 90%;border: 1px #b3d8ff dashed;border-radius: 5px;">
        <el-table
            :data="tableData"
            @selection-change="handleSelectionChange"
            style="width: 100% ;margin-bottom:30px;"
            :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
            :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
            border
            stripe
            max-height="730"
            id="out-table"
            v-if="boxFlag"
            v-loading="loading"
        >
          <el-table-column type='selection' style="height: 10px"
                           min-height="10px"></el-table-column>
          <el-table-column class-name="aa" label="老人" header-align="10px"  width="90px">
            <template slot-scope="scope">
              {{ scope.row.customerName }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="匹配状态" header-align="10px"  width="90px">
            <template slot-scope="scope">
              {{ scope.row.status }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="服务状态" header-align="10px"  width="90px">
            <template slot-scope="scope">
              {{ scope.row.serviceStatus }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="服务时长(分)" header-align="10px"  width="100px">
            <template slot-scope="scope">
              {{ scope.row.serviceHours }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="服务内容"  header-align="10px" width="90px">
            <template slot-scope="scope">
              {{scope.row.serviceContentId}}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="沟通状态" header-align="10px"  width="90px">
            <template slot-scope="scope">
              {{ scope.row.communication }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="开始时间" header-align="10px">
            <template slot-scope="scope">
              {{ scope.row.beginTime }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="结束时间" header-align="10px">
            <template slot-scope="scope">
              {{ scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column class-name="aa" label="沟通备注" header-align="10px">
            <template slot-scope="scope">
              {{ scope.row.remark }}
            </template>
          </el-table-column>
        </el-table>
        <div class="block" style="text-align:right">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="pagesizes"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="all">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="沟通确认" :visible.sync="openCommunicationConfirmation" width="400px">
      <el-form :model="searchForm" label-width="100px">
        <el-form-item label="沟通备注">
          <el-input v-model="searchForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="communicationConfirmation">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import pageTemp from "@/components/pageTemp.vue";
import {
  careList,
  serviceSituationList,
  communicationConfirmation,
  siteChange
} from "@/common/js";
import {setDate} from "@/common/js/util";

export default {
  name: "userDocument",
  components: {pageTemp},
  data() {
    return {
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStationTwo')),//用户可访问合约类型及护理站权限
      nurStatus: "合约中",
      nurText: "",
      pages: 0,
      nurseArr: [],
      currPage: 1,
      staffId: null,
      allLoad: true,
      options: [{
        status: '',
        label: '全部'
      }, {
        status: '待同步',
        label: '待同步'
      }, {
        status: '已匹配',
        label: '已匹配'
      }, {
        status: '未打卡',
        label: '未打卡'
      }, {
        status: '无工单',
        label: '无工单'
      }, {
        status: '已删除',
        label: '已删除'
      }],
      options2: [{
        status: '',
        label: '全部'
      }, {
        status: '正常',
        label: '正常'
      }, {
        status: '缺时',
        label: '缺时'
      }],
      options3: [{
        status: '',
        label: '全部'
      }, {
        status: '已沟通',
        label: '已沟通'
      }, {
        status: '未沟通',
        label: '未沟通'
      }],
      searchForm: {
        customerName: '',
        date: null,
        status: '',
        serviceStatus:'',
        communication:'',
        remark:''
      },
      tableData: [],
      currentPage: 1,
      boxFlag: true,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      all: 0,
      loading: false,
      caregiverName: null,
      result: [],//保存条件查询时箭名用的
      multipleSelection: [],
      ids: '',   //批量修改的id
      openCommunicationConfirmation:false,
      singleModule:{
        serviceSituationList:false,
        communicationConfirmation:false
      }
    }
  },
  methods: {
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.singleModule.serviceSituationList = this.isExist(permissionList,"serviceSituation:serviceSituationList");
          this.singleModule.communicationConfirmation = this.isExist(permissionList,"serviceSituation:communicationConfirmation");
          this.getNurses();

        }
      })

    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    turnOn(){
      this.openCommunicationConfirmation = true
    },
    // 沟通确认按钮
    communicationConfirmation(){
      this.ids = '';
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.ids += this.multipleSelection[i].id + ','
      }
      if (this.ids === '') {
        this.$message({
          message: "请选择需要沟通的选项",
          type: 'warning'
        })
      } else {
        let subData = 'ids=' + this.ids + '&remark=' + this.searchForm.remark
        communicationConfirmation(subData).then(res => {
          if (res) {
            this.$message.success('沟通确认成功');
            this.serviceSituationListButton()
            this.openCommunicationConfirmation = false
            this.searchForm.remark = null
          }
        })
      }
    },
    // 查询按钮
    serviceSituationListButton() {
      this.loading = true  //开启加载动画
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&customerName=' + this.searchForm.customerName
          + '&caregiverName=' + this.caregiverName
          + '&status=' + this.searchForm.status
          + '&serviceStatus=' + this.searchForm.serviceStatus
          + '&communication=' + this.searchForm.communication

      if (this.searchForm.date != null) {
        let searchStartTime = setDate(this.searchForm.date[0])
        let searchEndTime = setDate(this.searchForm.date[1])
        subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime + ' 23:59:59'
      }else {
        var nowDate = new Date();
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
            : nowDate.getMonth() + 1;
        var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
            .getDate();
        var date = year + "-" + month + "-" + day;
        subData += '&beginTime=' + date + '&endTime=' + date + ' 23:59:59'
      }
      serviceSituationList(subData).then(res => {
        if (res) {
          this.tableData = res.data
          for (let i = 0; i < this.tableData.length; i++) {
            var beginTime = this.tableData[i].beginTime;
            var endTime = this.tableData[i].endTime;
            var ms = Math.abs(new Date(endTime) - new Date(beginTime));
            var minute = Math.floor(ms / (60 * 1000));  //分
            this.tableData[i].serviceHours = minute
          }

          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
        }
        this.loading = false
      })

    },
    // 服务情况查询
    serviceSituationList(id, contractInformationId, name) {
      this.caregiverName = name
      this.staffId = id
      this.loading = true  //开启加载动画
      let status = '';
      let serviceStatus = '';
      let communication = '';
      if (this.searchForm.status.length > 0) {
        for (let i = 0; i < this.searchForm.status.length; i++) {

          const jsObject = JSON.parse(this.searchForm.status[i]);

          if (jsObject.status === "已匹配" || jsObject.status === "未匹配") {
            status = jsObject.status;
          } else if (jsObject.serviceStatus === "正常" || jsObject.serviceStatus === "缺时") {
            serviceStatus = jsObject.serviceStatus
          } else if (jsObject.communication === "已沟通" || jsObject.communication === "未沟通") {
            communication = jsObject.communication
          }

        }
      }
      let subData = 'currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
          + '&customerName=' + this.searchForm.customerName
          + '&caregiverName=' + this.caregiverName
          + '&status=' + status
          + '&serviceStatus=' + serviceStatus
          + '&communication=' + communication
      if (this.searchForm.date != null) {
        let searchStartTime = setDate(this.searchForm.date[0])
        let searchEndTime = setDate(this.searchForm.date[1])
        subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime + ' 24:00:00'
      }else {
        var nowDate = new Date();
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
            : nowDate.getMonth() + 1;
        var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
            .getDate();
        var date = year + "-" + month + "-" + day;
        subData += '&beginTime=' + date + '&endTime=' + date + ' 23:59:59'
      }
      serviceSituationList(subData).then(res => {
        if (res) {
          this.tableData = res.data
          for (let i = 0; i < this.tableData.length; i++) {
            var beginTime = this.tableData[i].beginTime;
            var endTime = this.tableData[i].endTime;
            var ms = Math.abs(new Date(endTime) - new Date(beginTime));
            var minute = Math.floor(ms / (60 * 1000));  //分
            this.tableData[i].serviceHours = minute
          }

          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
        }
        this.loading = false
      })


    },
    /**
     * 护理员列表
     * */
    getNurses() {
      console.log(this.contractTypeAndSiteInquireValue)
      let subData = "currPage=" + this.currPage + "&searchCondition=" + this.nurText + "&status=" + this.nurStatus
          + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
      careList(subData).then(res => {
        if (res) {
          this.nurseArr = res.data;
          if (res.data.length!==0){
            this.pages = res.data[0].totalCount;
          }
          this.allLoad = false;
        }
      })
    },
    /**
     * 分页
     * */
    goSpecifiedPage(val) {
      this.currPage = val;
      this.getNurses();
    },
    refresh() {
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.singleModule.serviceSituationList = this.isExist(permissionList,"serviceSituation:serviceSituationList");
          this.singleModule.communicationConfirmation = this.isExist(permissionList,"serviceSituation:communicationConfirmation");
          var str = "staff"
          var staffId = "";
          if (this.staffId !== '' && this.staffId != null && this.staffId.search(str) === -1) {
            staffId = this.staffId
          }
          console.log(this.contractTypeAndSiteInquireValue)
          let subData = "currPage=" + this.currPage + "&searchCondition=" + this.nurText + "&status=" + this.nurStatus
              + "&staffId=" + staffId + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
              + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);


          careList(subData).then(res => {
            if (res) {
              this.nurseArr = res.data;
              if (res.data.length!==0){
                this.staffId = res.data[0].id;
                this.contractInformationId = res.data[0].contractInformationId
                this.pages = res.data[0].totalCount;
                this.caregiverName = res.data[0].name;
                this.serviceSituationList(this.staffId, this.contractInformationId, this.caregiverName);
              }
              this.allLoad = false;
            }
          });
        }
      })

    },
    handleSelectionChange(val) {//改变选择时触发
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
  },
  created() {
    // this.getNurses()
    this.refresh();
  },
  watch: {
    currentPage: function () {
      this.serviceSituationListButton()
    },
    pagesize: function () {
      this.serviceSituationListButton()
    },
  }
}
</script>

<style scoped>
.left {
  width: 350px;
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 360px);
  border: 1px #b4bccc solid;
  border-radius: 5px;
}

.div-float {
  float: left;
  height: 99%;
}

.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}
.sel2 {
  width: 110px;
}
.inp {
  width: 100%;
  margin-left: -10px;
  /*margin:0 20px;*/
}
.line {
  width: 120px;
}
.line2 {
  width: 250px;
}
</style>
