<template>
    <div class="personal">
        <div class="pe-center">
            <div class="pe-left">
                <div class="left-img">
                    <!-- <img src="@/assets/head.png" class="avatar"> -->
                    <el-upload
                            class="avatar-uploader"
                            action="customize"
                            :show-file-list="false"
                            :http-request="uploadFile"
                            accept='.jpg,.png'
                            ref="headUpload"
                            :on-change="imgChange"
                    >
                        <img v-if="showData.headPortrait" :src="showData.headPortrait" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </div>
            <div class="pe-right">
                <el-form :model="showData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="账号 : " prop="name">
                        <el-input class="line" disabled v-model="showData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="昵称 : " prop="nickname">
                        <el-input class="line" v-model="showData.nickname"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="性别 : " prop="gender">
                        <el-input class="line" v-model="showData.gender"></el-input>
                    </el-form-item>  -->
                    <el-form-item label="性别 : " prop="gender">
                        <el-radio-group v-model="showData.gender" class="line1">
                            <el-radio label="男"></el-radio>
                            <el-radio label="女"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="手机 : " prop="moblle">
                        <el-input class="line" v-model="showData.moblle"></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align:right">
                    <el-button @click="updatePersonalInfo">提交修改</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {personalCenter, updatepersonalCenter} from '@/common/js/index.js'

    export default {
        name: "personal",
        data() {
            return {
                imageUrl: '',
                showData: {},
                rules: {
                    nickname: [
                        {required: true, message: '请输入昵称', trigger: 'blur'},
                        // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                    ],
                    name: [
                        {required: true, message: '请输入账号名字', trigger: 'blur'}
                    ],
                    moblle: [
                        {required: true, message: '号码不能为空', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            getPersonalInfo() {
                personalCenter().then(res => {
                    if (res) {
                        this.showData = res.data[0]
                        this.imageUrl = res.data[0].headPortrait;
                        this.$store.commit("increment", {imgUrl: res.data[0].headPortrait})
                    }
                })
            },
            uploadFile(params) {
            },
            updatePersonalInfo() {
                let headFiles = this.$refs.headUpload.uploadFiles;
                let formData = new FormData();
                formData.append("nickname", this.showData.nickname);
                formData.append("name", this.showData.name);
                formData.append("moblle", this.showData.moblle);
                formData.append("gender", this.showData.gender);
                // formData.append("headPortraitFile",headFiles[0].raw);
                if (headFiles.length !== 0) {
                    this.showData.headPortrait = headFiles[headFiles.length - 1].raw;
                    formData.append("headPortraitFile", this.showData.headPortrait);
                }
                updatepersonalCenter(formData).then(res => {
                    if (res.retCode === 0) {
                        this.$message.success('修改成功')
                        this.getPersonalInfo()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            imgChange(file) {
                this.showData.headPortrait = URL.createObjectURL(file.raw);
                // console.log(this.imageUrl)
            },
            checkPhoneNum(phone) {
                if (phone.length > 0) {
                    let str1 = ''
                    let str2 = ''
                    if (phone.length > 11) {
                        this.showData.moblle = phone.substring(0, phone.length - 1)
                        this.$message.warning('号码不能超过11位')
                    } else {
                        for (let i = 0; i < phone.length; i++) {
                            // arr.push(phone[i])
                            if (isNaN(phone[i])) {
                                if (i === phone.length - 1) {
                                    console.log('i1', i, phone.length, phone)
                                    this.showData.moblle = phone.substring(0, phone.length - 1)
                                } else {
                                    console.log('i2', i, phone.length, phone)
                                    str1 = phone.substring(0, i)
                                    str2 = phone.substring(i + 1, phone.length)
                                    this.showData.moblle = str1 + str2
                                }
                                this.$message.warning('号码应为数值')
                            }
                        }
                    }
                }
            }
        },
        created() {
            this.getPersonalInfo()
        },
        watch: {
            'showData.moblle': function () {
                this.checkPhoneNum(this.showData.moblle)
            }
        }
    }
</script>

<style scoped>
    .personal {
        height: 100%;
        width: 100%;
        /*  background:yellowgreen;*/
        padding-top: 10px;
        /* overflow: hidden;*/
    }

    .personal .pe-center {
        margin: 100px auto;
        width: 600px;
        border: 1px solid #999999;
        display: -webkit-flex; /* Safari */
        padding: 30px;
        border-radius: 8px;
        display: flex;
    }

    .pe-left {
        width: 220px;
    }

    .pe-left .left-img {
        border-radius: 100px;
        overflow: hidden;
    }

    .pe-left p {
        text-align: center;
    }

    .pe-right {
        margin-left: 40px;
    }

    .pe-right p {
        margin-top: 30px;
    }

    .line1 {
        border-bottom: 1px solid #ccc;
        padding-right: 120px;
        padding-bottom: 10px
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 220px;
        height: 220px;
        border-radius: 50%;
        line-height: 220px;
        text-align: center;
    }

    .avatar {
        width: 220px;
        height: 220px;
        display: block;
        border-radius: 50%;
    }

</style>
