<template>
  <div class="client">
    <div class="left">
      <div class="filter">
        <el-select v-model="cliStatus" class="sel line" @change="getClients()">
          <el-option label="全部状态" value=""></el-option>
          <el-option label="有意向" value="有意向"></el-option>
          <el-option label="待派单" value="待派单"></el-option>
          <el-option label="待申请" value="待申请"></el-option>
          <el-option label="待评估" value="待评估"></el-option>
          <el-option label="中止中" value="中止中"></el-option>
          <el-option label="长期中止" value="长期中止"></el-option>
          <el-option label="到期合约" value="到期合约"></el-option>
          <el-option label="待确认" value="待确认"></el-option>
          <el-option label="合约中" value="合约中"></el-option>
          <el-option label="已终止" value="已终止"></el-option>
          <el-option label="已删除" value="已删除"></el-option>
        </el-select>
        <el-cascader
            v-model="contractTypeAndSiteInquireValue"
            :options="contractTypeAndSiteInquireValueOptions"
            :props="{ expandTrigger: 'hover' }"
            @change="getClients()"
            style="float: right"></el-cascader>
        <br>
        <el-input v-model="cliText" class="inp line" placeholder="姓名、联系号码、地址" clearable
                  @keyup.enter.native="getClients"></el-input>
        <el-button size="small" type="primary" @click="getClients()">搜索</el-button>
      </div>
      <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1"></pageTemp>
      <div class="client-ul">
        <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                      view-class="p20-scrollbar__view">
          <div class="client-list" v-for="item in clientArr" :key="item.id"
               @click="clientDes(item.id,item.evaluationNursingId,item.contractTypeId)"
               :class="{'active-class':item.id===customerId}">
            <div class="flex number">
              <span class="over-due" v-if="item.overdue===1">逾</span>
              <p class="num">{{ item.numbering }}</p>
            </div>
            <p>{{ item.name }} ({{ item.gender }}) | {{ item.moblle }}</p>
            <p>{{ item.statusChangetime }}</p>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="right">
      <div style="height: 10%;border: 1px #B4BCCC solid">
        <div class="gd-top" style="margin:10px 0 20px 20px">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">
            <el-form-item label="状态">
              <el-select v-model="searchForm.type" placeholder="请选择" style="width: 350px" multiple
                         @change="userDocument()">
                <el-option
                    v-for="item in options"
                    :key="item.type"
                    :label="item.label"
                    :value="item.type">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                  v-model="searchForm.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 230px">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="userDocument()">查询</el-button>
              <el-button type="primary" @click="userDocumentAll()">全部老人查询</el-button>
              <el-button type="success" @click="generateDocumentation()">生成文档</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="height: 90%;">
        <div style="height: 90%;border: 1px #b3d8ff dashed;border-radius: 5px;">
          <el-table
              :data="documentList"
              @selection-change="handleSelectionChange"
              style="width: 100% ;margin-bottom:30px;"
              :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
              :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
              border
              stripe
              max-height="730"
              id="out-table"
              v-loading="loading"
          >
            <el-table-column type='selection' style="height: 10px"
                             min-height="10px"></el-table-column>
            <el-table-column class-name="aa" label="老人" header-align="10px">
              <template slot-scope="scope">
                {{ scope.row.customerName }}
              </template>
            </el-table-column>
            <el-table-column class-name="aa" label="时间" header-align="10px">
              <template slot-scope="scope">
                {{ scope.row.operatingTime }}
              </template>
            </el-table-column>
            <el-table-column class-name="aa" label="类型" header-align="10px">
              <template slot-scope="scope">
                {{ scope.row.type }}
              </template>
            </el-table-column>
            <el-table-column class-name="aa" label="原始状态" header-align="10px">
              <template slot-scope="scope">
                {{ scope.row.targetStatus }}
              </template>
            </el-table-column>
            <el-table-column class-name="aa" label="目前状态" header-align="10px">
              <template slot-scope="scope">
                {{ scope.row.originalStatus }}
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="text-align:right">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="all">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>
<script>

import pageTemp from "@/components/pageTemp.vue";
import {cusList, inquireUserDocumentTableList,generateDocumentation} from "@/common/js";
import {setDate} from "@/common/js/util";

const cityOptions = ['工单', '台账', '导出', '台历', '已结算', '未结算', '工资'];
export default {
  name: "userDocument",
  components: {pageTemp},
  data() {
    return {
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(), localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStationTwo')),//用户可访问合约类型及护理站权限
      cliStatus: "",
      cliText: "",
      pages: 0,
      currPage: 1,
      clientArr: [],
      customerId: '',//客户Id
      evaluationNursingId: null,
      contractTypeId: null,
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      searchForm: {
        customerName: '',
        date: null,
        type: '',
        serviceStatus:'',
        communication:'',
        remark:''
      },
      options: [{
        type: '恢复单',
        label: '恢复单'
      }, {
        type: '中止单',
        label: '中止单'
      }, {
        type: '计划表',
        label: '计划表'
      }, {
        type: '终止单',
        label: '终止单'
      }],
      documentList:[],
      currentPage: 1,
      pagesize: 20,
      all: 0,
      loading:true,
      multipleSelection:[],
      documentationIds:'',
    }
  },
  methods: {
    generateDocumentation(){
      this.documentationIds = '';
      for (let i = 0; i < this.multipleSelection.length; i++) {
        console.log(this.multipleSelection[i])
        this.documentationIds += this.multipleSelection[i].id + ','
      }

      if (this.documentationIds === '') {
        this.$message({
          message: "请选择需要生产文档的选项",
          type: 'warning'
        })
      }else {
        let subData = "documentationIds=" + this.documentationIds
            + "&siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
            + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2)
            + '&customerId=' + this.customerId;
        generateDocumentation(subData).then(res => {
          if (res) {
            this.$message.success('文档生成成功');
          }
        })
      }
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },

    handleSelectionChange(val) {//改变选择时触发
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },

    /**
     * 获取客户列表
     * */
    getClients() {
      let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus
          + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
      cusList(subData).then(res => {
        if (res) {
          this.clientArr = res.data;
          if (res.data.length !== 0) {
            this.pages = res.data[0].totalCount;
          }
        }
      })

    },
    userDocumentAll(){
      this.customerId = '';
      this.userDocument();
    },
    /**
     * 获取文档列表
     * */
    userDocument() {
      let subData = "currPage=" + this.currPage
          + "&pageSizePara=" + this.pagesize
          + "&type=" + this.searchForm.type
          + "&siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2)
          + '&customerId=' + this.customerId;
      if (this.searchForm.date != null) {
        let searchStartTime = setDate(this.searchForm.date[0])
        let searchEndTime = setDate(this.searchForm.date[1])
        subData += '&beginCreateTime=' + searchStartTime + '&endCreateTime=' + searchEndTime + ' 23:59:59'
      }
      inquireUserDocumentTableList(subData).then(res => {
        if (res) {
          this.documentList = res.data.list;
          if (res.data.peoplePageBean.length !== 0) {
            this.all = res.data.peoplePageBean.totalCount
          } else {
            this.all = 0
          }
          this.loading = false;
        }
      })

    },
    /**
     * 分页
     * */
    goSpecifiedPage(val) {
      this.currPage = val;
      this.getClients();
    },
    /**
     * 获取客户详情
     * */
    clientDes(id, evaluationNursingId, contractTypeId) {
      this.customerId = id;
      this.evaluationNursingId = evaluationNursingId
      this.contractTypeId = contractTypeId
      this.userDocument()
    }
  },
  created() {
    this.getClients();
    this.userDocument();
  },
  watch: {}
}
</script>

<style scoped>
.left {
  width: 400px;
  border: 1px #b4bccc solid;
}

.right {
  box-sizing: border-box;
  width: calc(100% - 400px);
}

.div-float {
  float: left;
  height: 99%;
}

</style>
