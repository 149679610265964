<template>
  <div v-loading="loading">
    <div class="gd-top" style="margin:10px 0 20px 0">
      <div class="gd-left">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="z-index:2">
          <el-cascader
              v-model="contractTypeAndSiteInquireValue"
              :options="contractTypeAndSiteInquireValueOptions"
              :props="{ expandTrigger: 'hover' }"
              @change="searchEpidemicList()"
          ></el-cascader>&nbsp;&nbsp;
          <el-form-item label="人员类型">
            <el-select v-model="searchForm.type" placeholder="请选择" clearable  @change="searchEpidemicList()">
              <el-option
                  v-for="item in types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="照片类型">
            <el-select v-model="searchForm.upType" placeholder="请选择" clearable  @change="searchEpidemicList()">
              <el-option
                  v-for="item in photoTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传人">
            <el-input v-model="searchForm.cuName" placeholder="上传人" style="width:150px"
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="searchForm.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 230px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchEpidemicList()">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="exportEpidemicList()">导出</el-button>
          </el-form-item>
        </el-form>

      </div>

    </div>
    <el-table
        :data="epidemicLists"
        @selection-change="handleSelectionChange"
        style="width: 100% ;margin-bottom:30px;"
        :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee',}"
        border
        stripe
        max-height="730"
        id="out-table"
        v-loading="loading"
    >
      <el-table-column class-name="aa" label="上传人"  header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.cuName }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="上传时间" header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.uploadTime }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="服务日期" header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.serviceDate }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="人员类型"  header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.typeName }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="照片类型"  header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.upType }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="抗原核酸姓名" header-align="10px">
        <template slot-scope="scope">
          {{ scope.row.correspondenceName }}
        </template>
      </el-table-column>
      <el-table-column prop="photo" label="照片" >
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px" :src="scope.row.photo" fit="cover"
                    @click="clickOnTheAttachment(scope.row.photo)"></el-image>
        </template>
      </el-table-column>

    </el-table>
    <div class="block" style="text-align:center">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pagesizes"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="all">
      </el-pagination>
    </div>
    <el-dialog title="照片" :visible.sync="viewAttachmentsPage" width="400px">
      <div>
        <img v-if="viewAttachments" :src="viewAttachments" >
      </div>
    </el-dialog>
  </div>


</template>

<script>
import { epidemicList,exportEpidemicList} from "@/common/js";
import {setDate, setHours, setTime, isEmpty} from '@/common/js/util.js'

export default {
  name: 'Video2',
  data() {
    return {

      all: 0,
      currentPage: 1,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      loading: false,
      epidemicLists: [],
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(), localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      searchForm: {
        sourcePlatform: '',
        cuName: '',
        mainTypesOfServices: '',
        type:'',
        upType:'',
        date:'',
      },
      viewAttachmentsPage:false,
      viewAttachments: '',
      types: [{
        value: '',
        label: '全部'
      }, {
        value: '本人',
        label: '护理员'
      }, {
        value: '老人',
        label: '老人'
      }],
      photoTypes: [{
        value: '',
        label: '全部'
      }, {
        value: '抗原',
        label: '抗原'
      }, {
        value: '核酸',
        label: '核酸'
      }, {
        value: '其他',
        label: '其他'
      }],
    }
  },
  methods: {
    clickOnTheAttachment(viewAttachments) {
      this.viewAttachments = viewAttachments
      this.viewAttachmentsPage = true
    },
    handleSelectionChange(val) {//改变选择时触发
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },
    searchEpidemicList() {    //查询按钮对应的方法
      this.loading = true  //开启加载动画
      let subData = 'currPage=' + this.currentPage + '&pageSize=' + this.pagesize
          + "&siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2)
          + '&type=' + this.searchForm.type
          + '&cuName=' + this.searchForm.cuName
          + '&upType=' + this.searchForm.upType

      if (this.searchForm.date != null) {
        let searchStartTime = setDate(this.searchForm.date[0])
        let searchEndTime = setDate(this.searchForm.date[1])
        subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime
      }

      epidemicList(subData).then(res => {
        if (res) {
          this.epidemicLists = res.data.epidemicList
          if (res.data.length !== 0) {
            this.all = res.data.totalCount
          } else {
            this.all = 0
          }
        }
        this.loading = false
      })

    },
    exportEpidemicList(){
      this.$message.success('正在导出中，请稍等。。。')
      let subData = 'currPage=' + this.currentPage + '&pageSize=' + this.pagesize
          + "&siteId=" + this.contractTypeAndSiteInquireValue.toString().charAt(0)
          + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2)
          + '&type=' + this.searchForm.type
          + '&cuName=' + this.searchForm.cuName
          + '&upType=' + this.searchForm.upType

      if (this.searchForm.date != null) {
        let searchStartTime = setDate(this.searchForm.date[0])
        let searchEndTime = setDate(this.searchForm.date[1])
        subData += '&beginTime=' + searchStartTime + '&endTime=' + searchEndTime
      }


      exportEpidemicList(subData).then(res => {
        if (res) {
          if (res.retCode === 0){
            this.$message.success('抗原核酸导出成功')
          }
        }
      })
    },
  },

  created() {    //护理站
    this.searchEpidemicList();
    window.addEventListener('setItem', () => {
      this.siteValue = sessionStorage.getItem('siteValue');
    })
  },
  watch: {
    currentPage: function () {
      this.searchEpidemicList()
    },
    pagesize: function () {
      this.searchEpidemicList()
    },
  }
}
</script>

<style scoped>
.video-js .vjs-icon-placeholder {
  width: 80%;
  height: 80%;
  display: block;
}

/* .video-player {
  width: 50%;
} */
.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
}

.gd-left {
  display: -webkit-flex; /* Safari */
  display: flex;
}

.red .el-form-item__label {
  color: #f31616;
}

</style>

<!--用户弹出页面css-->
<style scoped lang="scss">
.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.over-due {
  /* position: absolute;*/
  /* top:4px;*/
  font-size: 12px;
  color: #FFF;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: red;
  text-align: center;
  line-height: 20px;
}

.right-opare {
  display: flex;
  display: -webkit-flex; /* Safari */
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #DDD;
  padding: 10px 20px;
  box-sizing: border-box;
}

.des-box {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0 20px 70px 20px;
  /*  border: 1px solid red;*/
  box-sizing: border-box;
}

.des {
  margin-top: 20px;
  border: 1px dashed #999;
  border-radius: 10px;
  overflow: hidden;

  .map {
    overflow: hidden;

  }

  .local {
    display: inline-block;
    margin-left: 20px;
  }
}

.serve {
  padding: 20px 0 0 20px;
}

.des-flex {
  min-width: 600px;
  display: -webkit-flex; /* Safari */
  display: flex;
  height: auto;
  padding-top: 20px;

  .input-box {
    margin-left: 5%;
  }

  .line {
    width: 300px;
  }
}

.assess {
  padding: 20px 0 0 30px;

  .line {
    width: 260px;
  }
}

#map {
  width: 100%;
  height: 300px;
}

.adress {
  padding-left: 5%;

  .line {
    width: 260px;
  }
}

.card {
  padding: 0 40px 40px 40px;

  .img-box {
    width: 500px;
    justify-content: space-between;

    p {
      text-align: center;
    }
  }
}

/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 364px;
  height: 229px;
  line-height: 229px;
  text-align: center;
}

.avatar {
  width: 364px;
  height: 229px;
  display: block;
}

/*标签*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.form .form-left {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.form .form-right {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

。
.sel2 {
  width: 90px;
}
</style>
