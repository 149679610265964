<template>
    <div class="client">
        <div class="client-left">
            <div class="filter">
                <el-cascader
                        v-model="contractTypeAndSiteInquireValue"
                        :options="contractTypeAndSiteInquireValueOptions"
                        :props="{ expandTrigger: 'hover' }"
                        @change="siteChangeOperating()"
                        style="float: left;"></el-cascader>
                <el-button style="float: right;" size="medium" type="primary" @click="getClients()" v-if="this.mapReportModule.serviceCustomerList">搜索</el-button>
                <br>
                <el-input v-model="cliText" class="inp line" placeholder="姓名、电话、地址" clearable
                          @keyup.enter.native="getClients"></el-input>
            </div>
            <pageTemp small :pages="pages" @goSpecifiedPage="goSpecifiedPage" v-if="pages > 1 && this.mapReportModule.serviceCustomerList"></pageTemp>
            <div class="client-ul" v-if="this.mapReportModule.serviceCustomerList">
                <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                              view-class="p20-scrollbar__view">
                    <div class="client-list" v-for="item in clientArr" :key="item.id"
                         @click="getNurses(item.id,item.name,item.nursingCycle,item.nursingStartTime,item.nursingEndTime,item.nursingRemarks,item.evaluationLervl,item.longltude,item.latltude,item.siteId,item.contractTypeId,item.evaluationNursingId)"
                         :class="{'active-class':item.id===staffId}">
                        <div class="flex number">
                            <span class="over-due" v-if="item.overdue===1">逾</span>
                            <p class="num">{{item.numbering}} </p>
                            <div v-if="item.status === '待派单'"><p class="num">&nbsp;&nbsp;&nbsp;进入待派单时间:{{item.stateUnchangedTime}}</p>
                            </div>
                        </div>
                        <p>{{item.name}} ({{item.gender}}、{{item.evaluationLervl}}级) | {{item.moblle}}</p>
                        <p>{{item.statusChangetime}} | {{item.addressDetall}}</p>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="client-right" v-if="this.mapReportModule.recommendCaregiverList">
            <div class="client-up">
                <el-form ref="form" :model="form" label-width="100px">
                    <div class="des  assess">
                        <div class="flex">
                            <el-form-item label="护理时间" label-width="160px">
                                <el-time-picker
                                        class="line"
                                        is-range
                                        v-model="nursingTime"
                                        format="HH:mm"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        placeholder="选择时间范围">
                                </el-time-picker>
                            </el-form-item>
                            <el-form-item label="护理周期" label-width="160px">
                                <el-checkbox-group v-model="nursingCycle">
                                    <el-checkbox v-for="item in serveWeek" :label="item.id" :key="item.index">
                                        {{item.name}}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <el-form-item label="护理备注" label-width="160px">
                                <el-input v-model="form.nursingRemarks" placeholder="请输入护理备注" class="line inp"
                                          clearable
                                          style="width:300px"></el-input>
                            </el-form-item>
                            <br>
                            <el-form-item label="备用时间备注" label-width="160px">
                                <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item label="评估等级">
                                <el-input v-model="form.evaluationLervl"
                                          class="line2"></el-input>
                            </el-form-item>
                            <div style="margin-left: 10%">
                                <el-button size="small" type="primary" @click="sendOrder()">派单</el-button>
                            </div>
                        </div>

                    </div>
                </el-form>
            </div>
            <div class="client-down">
                <div class="client-BottomLeft">
                    <el-scrollbar class="default-scrollbar" wrap-class="default-scrollbar__wrap"
                                  view-class="p20-scrollbar__view" v-loading="loading">
                        <div class="client-list" style="position: relative" v-for="item in recommendedCaregiverList" :key="item.id"
                             @click="getDes(item.id,item.contractInformationId)" :class="{'active-class':item.id===orderId}">
                            <div class="left-border"></div>
                            <p>{{item.name}} ({{item.gender}}) | {{item.numberService}} 单</p>
<!--                            <p>推荐时间： {{item.date}} </p>-->
                            <p>距离约： {{item.averageDistance}} （米） </p>
                            <div :class="item.recommend=='1'?'green':'red'" >
                              <p>{{ item.cycleFree }}</p>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="client-BottomRight">
                    <div class="client-BottomRight-up">
                        <el-form ref="form" :model="form" label-width="100px">
                            <div class="des  assess">
                                <div class="flex">
                                    <el-form-item label="周期筛选" label-width="160px">
                                        <el-radio-group v-model="screeningCycle">
                                            <el-radio v-for="item in mapServeWeek" :label="item.mapServeWeekId"
                                                      :key="item.index" @change="cycleChange(item.mapServeWeekId)">
                                                {{item.name}}
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <div id="allmap" class="client-BottomRight-down"></div>
                </div>

            </div>
        </div>

    </div>


</template>

<script>
    import BMap from 'BMap'
    import pageTemp from "@/components/pageTemp.vue";
    // eslint-disable-next-line no-unused-vars
    import {
        cusList,
        orderList,
        beginSendOrder,
        customerMapInformation,
        getCus,
        serviceCustomerList,
        siteChange
    } from "@/common/js/index.js";

    export default {
        inject: ['reload'],
        name: "mapReport",
        components: {pageTemp},
        provide() {
            return {
                reload: this.reload,
            }
        },
        data() {
            return {
                evaluationNursingId:'',// 老人合约信息id
                contractInformationId :"", // 护理员合约信息ID
                contracttypes: '1',// 搜索合约类型id
                contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
                contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
                staffId: "",
                loading: false,
                orderCustomerId: "",//待派单老人id
                type: 'tab',
                address_detail: null,
                longltude: "",
                latltude: "",
                orderId: "",
                medicareTypes: localStorage.getItem("medicareCardType").split("$"),
                recommendedCaregiverList: [],//推荐护理员列表
                schArr: [],//排班
                caregiverName: "",
                numberService: "",
                gender: "",
                distance: "",
                canAppoin: false,
                clientRight: {
                    width: ""
                },
                isAssign: false,
                careFlag: 0,//第几个护理员
                careDialog: false,
                careName: "",
                careData: [],
                careForm: {
                    searchCondition: "",
                    status: "",
                },
                carePage: 0,
                careTotal: 0,
                operaDialog: false,//操作弹窗
                operaForm: {
                    targetStatus: "",
                    appointmentTime: "",
                    statusRemarks: ""
                },
                careList: [],//护理员列表
                formInline: {
                    logCTime: ""//日志时间筛选
                },
                siteValue: sessionStorage.getItem("siteValue"),
                customerName: "",
                //siteValue:"",
                allLoading: true,
                load: false,
                cliStatus: "待派单",
                cliText: "",
                operaList: [],
                pages: 0,
                currPage: 1,
                clientArr: [],
                checkList: [],
                orderTable: [],
                activeName: '',
                isRouterAlive: true,
                // 用户基本信息
                form: {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)1
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    statusRemarks: "", //状态备注
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                },
                nursingTime: "",//护理时间
                nursingAppoTime: "",//预约护理时间
                nursingCycle: [],//护理周期
                screeningCycle: [],//筛选周期
                serveWeek: [
                    {name: "周一", id: '1'},
                    {name: "周二", id: '2'},
                    {name: "周三", id: '3'},
                    {name: "周四", id: '4'},
                    {name: "周五", id: '5'},
                    {name: "周六", id: '6'},
                    {name: "周日", id: '7'},
                ],
                mapServeWeek: [
                    {name: "全部", mapServeWeekId: ''},
                    {name: "周一", mapServeWeekId: '1'},
                    {name: "周二", mapServeWeekId: '2'},
                    {name: "周三", mapServeWeekId: '3'},
                    {name: "周四", mapServeWeekId: '4'},
                    {name: "周五", mapServeWeekId: '5'},
                    {name: "周六", mapServeWeekId: '6'},
                    {name: "周日", mapServeWeekId: '7'},
                ],
                mapReportModule:{
                  recommendCaregiverList:false,
                  serviceCustomerList:false
                }
            }
        },
        mounted() {
            //this.ready()
        },
        methods: {
          siteChangeOperating(){
            let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
            siteChange(subData).then(res => {
              if (res) {
                const permissionList  = res.data.userPermission.permissionList;
                this.mapReportModule.recommendCaregiverList = this.isExist(permissionList,"mapReport:recommendCaregiverList");
                this.mapReportModule.serviceCustomerList = this.isExist(permissionList,"sendOrders:customerList");
                this.getClients()
              }
            })
          },
          isExist(permissionList,str) {
            if (permissionList.indexOf(str) !== -1) {
              return true;
            } else {
              return false;
            }
          },
            ready() {

                // 百度地图API功能
                var map = new BMap.Map("allmap");
                map.centerAndZoom(new BMap.Point(118.454, 32.955), 6);

            },


            filterTag(value, row) {
                return row.status === value;
            },
            jumpToCaregiver() {
                var caregiverId = sessionStorage.getItem("caregiverId");
                this.$router.push({path: '/order', query: {id: caregiverId, careText: '万立美'}})
            },
            /**
             * 地址栏输入用户id 提取
             * */
            submit() {
                var newurl = this.updateParam(window.location.href, 'sendOrdersId', this.input);
                //向当前url添加参数，没有历史记录
                window.history.replaceState({
                    path: newurl
                }, '', newurl);
            },
            updateParam(uri, key, value) {
                if (!value) {
                    return uri;
                }
                var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
                var separator = uri.indexOf('?') !== -1 ? "&" : "?";
                if (uri.match(re)) {
                    return uri.replace(re, '$1' + key + "=" + value + '$2');
                } else {
                    return uri + separator + key + "=" + value;
                }
            },

            /**
             * 派单
             * */
            sendOrder() {
                this.$confirm('', '确定派单', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',

                    type: 'warning'
                }).then(() => {
                    let subData = new FormData();
                    subData.append("id", this.staffId);
                    subData.append("careWorker_id_one", this.orderId);
                    subData.append("siteId", this.siteId);
                    subData.append("contractTypeId", this.contractTypeId);
                    subData.append("evaluationNursingId", this.evaluationNursingId);
                    subData.append("contractInformationId", this.contractInformationId);
                    beginSendOrder(subData).then(res => {
                        this.reload();
                        this.$message.success('派单成功');
                        this.getClients();
                    })
                }).catch(() => {
                });
            },

            /**
             * 分页
             * */
            goSpecifiedPage(val) {
                this.currPage = val;
                this.getClients();
            },
            /**
             * 获取客户列表
             * */
            getClients() {
              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.mapReportModule.recommendCaregiverList = this.isExist(permissionList,"mapReport:recommendCaregiverList");
                  this.mapReportModule.serviceCustomerList = this.isExist(permissionList,"sendOrders:customerList");

                  var str = this.contractTypeAndSiteInquireValue.toString();
                  if (this.cliText !== "" ){
                    this.cliStatus = "";
                  }else {
                    this.cliStatus = "待派单";
                  }
                  //let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus + "&siteList=" + this.siteValue + '&contractTypeId=' + this.contracttypes;
                  let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus + "&siteList=" + str.charAt(0) + '&contractTypeId=' + str.charAt(2);

                  orderList(subData).then(res => {
                    if (res) {
                      this.clientArr = res.data;
                      if (res.data.length !== 0) {
                        this.pages = res.data[0].totalCount;
                      }
                    }
                  })
                }
              })

            },
            /**
             * 地图上方改变周期
             * */
            cycleChange(mapServeWeekId) {
                // 百度地图API功能
                var map = new BMap.Map("allmap");

                /*缩放地图begin*/
                // setTimeout(function () {
                //     map.setZoom(14);
                // }, 2000);  //2秒后放大到14级
                map.enableScrollWheelZoom(true);
                /*缩放地图end*/
                let src = require('@/assets/mapIcon.png');
                let src2 = require('@/assets/mapIcon3.png');
                var mapPoints = [];
                let subData = "id=" + this.orderId + "&mapServeWeekId=" + mapServeWeekId + "&orderCustomerId=" + this.orderCustomerId + "&contractTypeId=" + this.contractTypeId;
                serviceCustomerList(subData).then(res => {
                    if (res) {
                        for (let i = 0; i < res.data['customerMapInformation'].length; i++) {

                            var name = res.data['customerMapInformation'][i].name;
                            var longltude = res.data['customerMapInformation'][i].longltude;
                            var latltude = res.data['customerMapInformation'][i].latltude;
                            var status = res.data['customerMapInformation'][i].status;
                            var caregiverName = res.data['customerMapInformation'][i].caregiverName;
                            var nursingStartTime = res.data['customerMapInformation'][i].nursingStartTime;
                            var nursingEndTime = res.data['customerMapInformation'][i].nursingEndTime;
                            var nursingCycle = res.data['customerMapInformation'][i].nursingCycle;
                            var freeTimeCount = res.data['customerMapInformation'][i].freeTimeCount;
                            var freeTimeNursingCycle = res.data['customerMapInformation'][i].freeTimeNursingCycle;
                            var nextNursingStartTime = res.data['customerMapInformation'][i].nextNursingStartTime;
                            var nextNursingEndTime = res.data['customerMapInformation'][i].nextNursingEndTime;
                            var freeTimeNursingCycleTime = res.data['customerMapInformation'][i].freeTimeNursingCycleTime;
                            var s = {
                                longltude: longltude,
                                latltude: latltude,
                                name: name,
                                status: "状态 : " + status,
                                caregiverName: "护理员姓名 : " + caregiverName,
                                nursingStartTime: nursingStartTime,
                                nursingEndTime: nursingEndTime,
                                nursingCycle: "护理周期 : " + nursingCycle,
                                freeTimeCount: "可服务天数 : " + freeTimeCount + "天",
                                freeTimeNursingCycle: "可服务周期 : " + freeTimeNursingCycle,
                                nextNursingStartTime: nextNursingStartTime,
                                nextNursingEndTime: nextNursingEndTime,
                                freeTimeNursingCycleTime: "下位服务老人 : <br>" + freeTimeNursingCycleTime
                            }
                            mapPoints.push(s)
                        }
                        for (var i = 0; i < mapPoints.length; i++) {
                            var pt = new BMap.Point(mapPoints[i].longltude, mapPoints[i].latltude);
                            // 显示待派单老人地址标记
                            if (mapPoints[i].name === this.customerName) {
                                map.centerAndZoom(pt, 15);
                                var myIcon = new BMap.Icon(src, new BMap.Size(27, 36));
                                var marker = new BMap.Marker(pt, {icon: myIcon});
                                var label = new BMap.Label(mapPoints[i].name, {offset: new BMap.Size(28, -10)});
                            } else {
                                //显示护理员下合约中老人标记
                                var myIcon2 = new BMap.Icon(src2, new BMap.Size(27, 36));
                                // eslint-disable-next-line no-redeclare
                                var marker = new BMap.Marker(pt, {icon: myIcon2});

                                if (mapServeWeekId == null || mapServeWeekId === "") {
                                    // eslint-disable-next-line no-redeclare
                                    var label = new BMap.Label(mapPoints[i].nursingStartTime + "-" + mapPoints[i].nursingEndTime + "<br>" + mapPoints[i].freeTimeCount, {offset: new BMap.Size(20, -15)});
                                } else {

                                    // eslint-disable-next-line no-redeclare
                                    var label = new BMap.Label(mapPoints[i].nursingStartTime + "-" + mapPoints[i].nursingEndTime + "<br>" + "下位老人护理时间:" + "<br>" +
                                        mapPoints[i].nextNursingStartTime + "-" + mapPoints[i].nextNursingEndTime, {offset: new BMap.Size(20, -15)});

                                    // 路线
                                    var walking = new BMap.WalkingRoute(map, {
                                        renderOptions: {
                                            map: map,
                                            autoViewport: true,
                                        }
                                    });
                                    if (i < mapPoints.length - 2) {
                                        var start = new BMap.Point(mapPoints[i].longltude, mapPoints[i].latltude);
                                        var end = new BMap.Point(mapPoints[i + 1].longltude, mapPoints[i + 1].latltude);
                                        walking.search(start, end);
                                    }

                                }

                            }

                            marker.setLabel(label);
                            var content = mapPoints[i].name + "<br>"
                                + mapPoints[i].status + "<br>"
                                + mapPoints[i].nursingStartTime + "-" + mapPoints[i].nursingEndTime + "<br>"
                                + mapPoints[i].nursingCycle + "<br>"
                                + mapPoints[i].freeTimeCount + "<br>"
                                + mapPoints[i].freeTimeNursingCycleTime + "<br>";
                            map.addOverlay(marker);               // 将标注添加到地图中
                            // marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
                            if (mapPoints[i].name != null) {
                                addClickHandler(content, marker);
                            }
                        }
                      var circle = res.data['circle'];
                      // 绘制圆
                      var circle = new BMap.Circle(new BMap.Point(circle.lng, circle.lat), circle.averageDistance, {
                        strokeColor: 'blue',
                        strokeWeight: 2,
                        strokeOpacity: 0.5,
                        fillColor: "",
                        fillOpacity: 0.6
                      });
                      map.addOverlay(circle);
                    }

                })

                function addClickHandler(content, marker) {
                    marker.addEventListener("click", function (e) {
                            openInfo(content, e)
                        }
                    );
                }

                var opts = {
                    width: 100,     // 信息窗口宽度
                    height: 270,     // 信息窗口高度
                    enableMessage: true,//设置允许信息窗发送短息
                };

                function openInfo(content, e) {
                    var p = e.target;
                    var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
                    var infoWindow = new BMap.InfoWindow(content, opts);  // 创建信息窗口对象
                    map.openInfoWindow(infoWindow, point); //开启信息窗口
                }
            },
            /**
             * 点击推荐护理员列表
             * */
            getDes(id,contractInformationId) {
                this.screeningCycle = '';
                this.contractInformationId = contractInformationId;
                this.orderId = id;
                // 百度地图API功能
                var map = new BMap.Map("allmap");

                /*缩放地图begin*/
                // setTimeout(function () {
                //     map.setZoom(30);
                // }, 100);  //2秒后放大到14级
                map.enableScrollWheelZoom(true);
                /*缩放地图end*/
                let src = require('@/assets/mapIcon.png');
                let src2 = require('@/assets/mapIcon3.png');
                var mapPoints = [];
                let subData = "id=" + id + "&orderCustomerId=" + this.orderCustomerId+ "&contractTypeId=" + this.contractTypeId;
                serviceCustomerList(subData).then(res => {

                    if (res) {
                        for (let i = 0; i < res.data['customerMapInformation'].length; i++) {

                            var name = res.data['customerMapInformation'][i].name;
                            var longltude = res.data['customerMapInformation'][i].longltude;
                            var latltude = res.data['customerMapInformation'][i].latltude;
                            var status = res.data['customerMapInformation'][i].status;
                            var caregiverName = res.data['customerMapInformation'][i].caregiverName;
                            var nursingStartTime = res.data['customerMapInformation'][i].nursingStartTime;
                            var nursingEndTime = res.data['customerMapInformation'][i].nursingEndTime;
                            var nursingCycle = res.data['customerMapInformation'][i].nursingCycle;
                            var freeTimeCount = res.data['customerMapInformation'][i].freeTimeCount;
                            var freeTimeNursingCycle = res.data['customerMapInformation'][i].freeTimeNursingCycle;
                            var freeTimeNursingCycleTime = res.data['customerMapInformation'][i].freeTimeNursingCycleTime;
                            var s = {
                                longltude: longltude,
                                latltude: latltude,
                                name: name,
                                status: "状态 : " + status,
                                caregiverName: "护理员姓名 : " + caregiverName,
                                nursingStartTime: nursingStartTime,
                                nursingEndTime: nursingEndTime,
                                nursingCycle: "护理周期 : " + nursingCycle,
                                freeTimeCount: "可服务天数 : " + freeTimeCount + "天",
                                freeTimeNursingCycle: "可服务周期 : " + freeTimeNursingCycle,
                                freeTimeNursingCycleTime: "下位服务老人 : <br>" + freeTimeNursingCycleTime
                            }
                            mapPoints.push(s)
                        }
                        for (var i = 0; i < mapPoints.length; i++) {

                            if (mapPoints[i].name === this.customerName) {
                                var pt = new BMap.Point(mapPoints[i].longltude, mapPoints[i].latltude);
                                map.centerAndZoom(pt, 15);
                                var myIcon = new BMap.Icon(src, new BMap.Size(27, 36));
                                var marker = new BMap.Marker(pt, {icon: myIcon});
                                var label = new BMap.Label(mapPoints[i].name, {offset: new BMap.Size(28, -10)});
                            } else {
                                // eslint-disable-next-line no-redeclare
                                var pt = new BMap.Point(mapPoints[i].longltude, mapPoints[i].latltude);
                                var myIcon2 = new BMap.Icon(src2, new BMap.Size(27, 36));
                                // eslint-disable-next-line no-redeclare
                                var marker = new BMap.Marker(pt, {icon: myIcon2});
                                // eslint-disable-next-line no-redeclare
                                var label = new BMap.Label(mapPoints[i].nursingStartTime + "-" + mapPoints[i].nursingEndTime + "<br>" + mapPoints[i].freeTimeCount, {offset: new BMap.Size(20, -15)});

                            }

                            marker.setLabel(label);
                            var content = mapPoints[i].name + "<br>"
                                + mapPoints[i].status + "<br>"
                                + mapPoints[i].nursingStartTime + "-" + mapPoints[i].nursingEndTime + "<br>"
                                + mapPoints[i].nursingCycle + "<br>"
                                + mapPoints[i].freeTimeCount + "<br>"
                                + mapPoints[i].freeTimeNursingCycleTime + "<br>";
                            map.addOverlay(marker);               // 将标注添加到地图中
                            // marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
                            if (mapPoints[i].name != null) {
                                addClickHandler(content, marker);
                            }

                        }

                    }
                  var circle = res.data['circle'];
                  // 绘制圆
                  var circle = new BMap.Circle(new BMap.Point(circle.lng, circle.lat), circle.averageDistance, {
                    strokeColor: 'blue',
                    strokeWeight: 2,
                    strokeOpacity: 0.5,
                    fillColor: "",
                    fillOpacity: 0.6
                  });
                  map.addOverlay(circle);
                })

                function addClickHandler(content, marker) {
                    marker.addEventListener("click", function (e) {
                            openInfo(content, e)
                        }
                    );
                }

                var opts = {
                    width: 100,     // 信息窗口宽度
                    height: 270,     // 信息窗口高度
                    //title : "老人护理信息" , // 信息窗口标题
                    enableMessage: true,//设置允许信息窗发送短息
                };

                function openInfo(content, e) {
                    var p = e.target;
                    var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
                    var infoWindow = new BMap.InfoWindow(content, opts);  // 创建信息窗口对象
                    map.openInfoWindow(infoWindow, point); //开启信息窗口

                }

            },
            /**
             * 待派单推荐护理员列表信息
             * */
            getNurses(id, customerName, nursingCycle, nursingStartTime, nursingEndTime, nursingRemarks, evaluationLervl, longltude, latltude ,siteId,contractTypeId,evaluationNursingId) {


                this.$confirm('查询速度较慢，请稍等...', '确定查询护理员优先级列表', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.evaluationNursingId = evaluationNursingId
                    this.loading = true  //开启加载动画
                    this.customerName = customerName
                    this.orderCustomerId = id
                    this.longltude = longltude
                    this.latltude = latltude
                    this.siteId = siteId
                    this.contractTypeId = contractTypeId
                    this.fatForm();
                    this.staffId = id;
                    this.resetSetItem('clientId', this.staffId);
                    let subData1 = "evaluationLervl=" + evaluationLervl + "&longltude=" + longltude + "&latltude=" + latltude + "&siteList=" + this.siteId + '&contractTypeId=' + this.contractTypeId;
                    customerMapInformation(subData1).then(res => {
                        if (res) {
                            this.recommendedCaregiverList = res.data;
                            if (this.recommendedCaregiverList!==null){
                                this.recommendedCaregiverList.sort(
                                    (pre, cur) =>
                                        //pre.numberService - cur.numberService||
                                        pre.averageDistance - cur.averageDistance
                                )
                            }

                        }
                        this.loading = false
                    });
                    this.nursingTime = [];
                    this.nursingTime.push(new Date(2016, 9, 10, nursingStartTime.split(':')[0], nursingStartTime.split(':')[1], nursingStartTime.split(':')[2]));
                    this.nursingTime.push(new Date(2016, 9, 10, nursingEndTime.split(':')[0], nursingEndTime.split(':')[1], nursingEndTime.split(':')[2]));
                    getCus("id=" + this.staffId + "&evaluationNursingId=" + this.evaluationNursingId).then(res => {
                        this.form = res.data['basicInformation'][0];
                        // eslint-disable-next-line no-undef
                        if (res.data['basicInformation'][0].nursingCycle != null) {
                            this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
                        } else {
                            this.nursingCycle = [];
                        }
                        if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
                            this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
                        } else {
                            this.reservation_nursingCycle = [];
                        }
                    });

                }).catch(() => {
                });
            },
            fatForm() {
                this.nursingTime = "";
                this.nursingAppoTime = '';
                this.reservation_nursingCycle = [];//预约服务时间（周）
                this.nursingCycle = [];//护理周期
                this.careWorker_one = "";//指定护理员1
                this.careWorker_two = "";//指定护理员2
                this.careWorker_three = "";//指定护理员3
                this.reservation_careWorker = "";//预约指定护理员
                this.form = {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                }
            },


            refresh() {
              let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
              siteChange(subData).then(res => {
                if (res) {
                  const permissionList  = res.data.userPermission.permissionList;
                  this.mapReportModule.recommendCaregiverList = this.isExist(permissionList,"mapReport:recommendCaregiverList");
                  this.mapReportModule.serviceCustomerList = this.isExist(permissionList,"sendOrders:customerList");
                  var str = "mapReport"
                  var sendOrdersId = "";
                  if (this.sendOrdersId !== '' && this.sendOrdersId != null && this.sendOrdersId.search(str) === -1) {
                    sendOrdersId = this.sendOrdersId
                  }
                  let subData = "currPage=" + this.currPage + "&searchCondition=" + this.cliText + "&status=" + this.cliStatus +  "&clientId=" + sendOrdersId+ "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(0) + '&contractTypeId=' + this.contractTypeAndSiteInquireValue.toString().charAt(2);
                  cusList(subData).then(res => {
                    if (res) {
                      this.clientArr = res.data;
                      this.schArr = res.data;
                      this.getDes(this.careId);
                      if (res.data.length !== 0) {
                        this.pages = res.data[0].totalCount;
                        this.allLoading = false;
                      }
                      this.ready();
                    }
                  });
                }
              })

            },
            siteFresh() {
                this.allLoading = true;
                this.siteValue = sessionStorage.getItem('siteValue');
                this.refresh();
            }
        },
        created() {
            var loc = location.href;
            var n1 = loc.length;//地址的总长度
            var n2 = loc.indexOf("=");//取得=号的位置
            var id = decodeURI(loc.substr(n2 + 1, n1 - n2));//从=号后面的内容
            this.sendOrdersId = id;

            window.addEventListener('setItem', this.siteFresh, false);

            window.addEventListener('setItem', () => {
                this.loading = true;
                this.siteValue = sessionStorage.getItem('siteValue');
                this.refresh();
            }, false)

            // 由工单点击客户名字跳转触发函数
            if (this.$route.query.id !== null && this.$route.query.id !== undefined) {
                this.clientId = this.$route.query.id;
                this.clientDes(this.clientId);
                this.clientRight.width = '100%'
            } else {
                this.isAssign = true;
                this.refresh();
            }
        },
        watch: {
            '$route'(to, from) {
                if (this.$route.query.id !== null && this.$route.query.id !== undefined) {
                    this.isAssign = false;
                    this.clientId = this.$route.query.id;
                    this.clientDes(this.clientId);
                    this.clientRight.width = '100%'
                } else {
                    this.clientRight.width = '';
                    this.isAssign = true;
                    this.refresh();
                }
            },
        },

    }
</script>

<style scoped lang="scss">
    .red{
      color: orangered;
    }
    .green{
      color: darkgreen;
    }
    .left-border {
        position: absolute;
        left: 0;
        top: 16px;
        background: green;
        width: 2px;
        height: 60px;
    }

    .right-center {
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex; /* Safari */
        /*   border: 1px solid red;*/
        .center-one {
            width: 15%;
            border-left: 1px solid #DDD;
        }

        .center-two {
            margin-top: -70px;
            width: 90%;
            border-left: 1px solid #DDD;
        }
    }

    .over-due {
        /* position: absolute;*/
        /* top:4px;*/
        font-size: 12px;
        color: #FFF;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: red;
        text-align: center;
        line-height: 20px;
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 70px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 100px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .local {
            display: inline-block;
            margin-left: 20px;
        }
    }

    .serve {
        padding: 20px 0 0 20px;
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 5%;
        }

        .line {
            width: 300px;
        }
    }

    .assess {
        width: 99.5%;
        margin: 0px auto;
        padding: 10px 50px -10px 20px;

        .line {
            width: 200px;
        }
    }

    .flex {
        display: -webkit-flex; /* Safari */
        display: flex;
    }

    #map {
        width: 100%;
        height: 300px;
    }

    .adress {
        padding-left: 5%;

        .line {
            width: 260px;
        }

        .line2 {
            width: 10px;
            background: #409EFF;
            color: #FFFFFF;
        }
    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }
    }

    /*上传图片*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }


    .filter {
        width: 100%;
        padding: 5px 0 0 10px;
        height: 90px;
        box-sizing: border-box;
        border-bottom: 1px solid #DDD;

        .inp {
            width: 100%;
            margin-left: -10px;
            /*margin:0 20px;*/
        }

        .sel {
            width: 30%;
        }
    }


    /*#allmap{*/
    /*    width: 70%;*/
    /*    height: 100%;*/
    /*}*/
    .client {
        display: -webkit-flex; /* Safari */
        display: flex;
        width: 100%;
        height: 100%;

        .client-left {
            width: 300px;
            height: 100%;
            border-radius: 10px;
            border: 1px solid #999;
        }

        .client-right {
            width: calc(100% - 300px);
            height: 100%;
            //border-right: 1px solid yellow;
            /* border: 1px solid green;*/
            /*overflow: hidden;*/

            /*.tag {*/
            /*    !*border: 1px solid red;*!*/
            /*    padding: 8px 20px 0 20px;*/
            /*}*/
        }

        .client-up {
            width: 100%;
            height: 15%;
            //border: 1px solid red;
        }

        .client-down {
            width: 100%;
            height: 83%;
            // border: 1px solid green;
        }

        .client-BottomLeft {
            width: 198px;
            height: 100%;
            float: left;
            border-radius: 10px;
            border: 1px solid #999;
        }

        .client-BottomRight {
            width: calc(100% - 205px);
            height: 100%;
            float: right;
            border-radius: 10px;
            /*margin: 0 auto;*/
        }

        .client-BottomRight-up {
            width: 100%;
            height: 40px;
        }

        .client-BottomRight-down {
            width: 100%;
            height: calc(100% - 40px);
            float: right;
            border-radius: 10px;
            border: 1px dashed #999;
            /*margin: 0 auto;*/
        }

        .client-BottomRight {
            width: calc(100% - 205px);
            height: 100%;
            float: right;
            border-radius: 10px;
            border: 1px dashed #999;
            /*margin: 0 auto;*/
        }

        .client-list {
            cursor: pointer;
            border-bottom: 1px solid #DDD;
            padding: 10px 0 0 10px;
            position: relative;
            overflow: hidden;
            height: 86px;
            box-sizing: border-box;

            .number {
                height: 20px;
            }

            p {
                margin: 0 0 8px 0;
                font-weight: normal;
                font-size: 14px;
            }

            .state {
                background: #409EFF;
                color: #FFFFFF;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }

            }

            .stateGreen {
                background: #7FFFAA;
                color: #696969;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }
            }

            .stateRed {
                background: #FF0000;
                color: #ffffff;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }
            }

            .stateDarkRed {
                background: #DC143C;
                color: #696969;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }
            }

            .stateDarkgray {
                background: #C0C0C0;
                color: #696969;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }
            }

            .stateYellow {
                background: #F0E68C;
                color: #696969;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }
            }

            .lightSkyBlue {
                background: #87CEFA;
                color: #696969;
                height: 50px;
                width: 100px;
                position: absolute;
                right: -32px;
                top: -10px;
                transform: rotate(7deg);
                -ms-transform: rotate(7deg); /* IE 9 */
                -moz-transform: rotate(7deg); /* Firefox */
                -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
                -o-transform: rotate(7deg); /* Opera */
                text-align: center;

                span {
                    line-height: 80px;
                    font-size: 14px;
                    /*position: absolute;*/
                    /*bottom: 0;*/
                }
            }

            .num {
                color: #409EFF;
            }
        }
    }

    .inp2 {
        width: 60%;
        /*margin-left: -10px;*/
        float: right;
        /*margin:0 20px;*/
    }

    .sel2 {
        width: 32%;
    }

</style>
