<template>
  <div class="billingDetails" v-loading="loading">
    <div class="gd-top" style="margin:10px 0 20px 0">
      <div class="gd-left">
        <el-form :inline="true" class="demo-form-inline" style="z-index:2">
          <el-cascader
              v-model="contractTypeAndSiteInquireValue"
              :options="contractTypeAndSiteInquireValueOptions"
              :props="{ expandTrigger: 'hover' }"
              @change="siteChangeOperating()"
              style="width:200px"></el-cascader>
          <el-form-item label="状态">
            <el-select v-model="status" @change="inquiryWageList()" style="width: 150px">
              <el-option label="全部状态" value=""></el-option>
              <el-option label="合约中" value="合约中"></el-option>
              <el-option label="中止中" value="中止中"></el-option>
              <el-option label="已终止" value="已终止"></el-option>
              <el-option label="待审核" value="待审核"></el-option>
              <el-option label="已删除" value="已删除"></el-option>
            </el-select>
          </el-form-item>
          &nbsp;
          <el-form-item label="护理员姓名">
            <el-input v-model="caregiverName" placeholder="请输入老人姓名" style="width:150px"
                      @keyup.enter.native="inquiryWageList"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="查询月份">
            <el-date-picker
                v-model="wageMonth"
                type="month" :rules="{required: true, message: '请选择月份', trigger: 'blur'}"
                placeholder="选择月"
                style="width: 150px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="inquiryWageList()">查询</el-button>
            <el-button  type="success" @click="wageExportButton()" v-if="this.wageModule.wageExport">导出工资
            </el-button>
          </el-form-item>
        </el-form>

      </div>
      <div class="right">
        <el-form :inline="true" class="demo-form-inline" style="z-index:2" v-if="this.wageModule.wageGeneration">
          <el-form-item label="月份筛选">
            <el-date-picker
                v-model="month"
                type="month" :rules="{required: true, message: '请选择月份', trigger: 'blur'}"
                placeholder="选择月"
                style="width: 150px">
            </el-date-picker>&nbsp;&nbsp;
            <el-button type="primary" @click="wageGenerationButton()" >计算工资
            </el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <el-table
        :data="wageList"
        @selection-change="handleSelectionChange"
        style="width: 100% ;margin-bottom:30px;"
        :header-cell-style="{background:'#dee2ec', color: '#666666','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee'}"
        border
        stripe
        max-height="730"
        id="out-table"
        v-if="boxFlag && this.wageModule.wageList"
        v-loading="loading"
    >
      <el-table-column class-name="aa" label="护理员" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.caregiverName }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="状态" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.status }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="工资月份" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.wageMonth }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="工资生成时间" header-align="10px" width="170px">
        <template slot-scope="scope">
          {{ scope.row.buildTime }}
        </template>
      </el-table-column>
<!--      <el-table-column class-name="aa" label="工单日期" header-align="10px" width="190px">-->
<!--        <template slot-scope="scope">-->
<!--          {{ scope.row.settlementDate }}-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column class-name="aa" label="结算金额" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.normalSettlementAmount }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="结算明细" header-align="10px">
        <template slot-scope="scope">
          <p @click="normalSettlementDetailsOpen(scope.row.normalSettlementDetailsJson,scope.row.caregiverName)">
            {{ scope.row.normalSettlementDetails }}</p>
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="提成金额" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.workOrderCommissionAmount }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="提成明细" header-align="10px" width="220px">
        <template slot-scope="scope">
          <p @click="workOrderCommissionDetailsOpen(scope.row.workOrderCommissionDetailsJson,scope.row.caregiverName)">
            {{ scope.row.workOrderCommissionDetails }}</p>
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="护理员提成金额" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.caregiverWorkOrderCommissionAmount }}
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="护理员提成明细" header-align="10px" width="220px">
        <template slot-scope="scope">
          <p @click="caregiverWorkOrderCommissionDetailsOpen(scope.row.caregiverWorkOrderCommissionDetailsJson,scope.row.caregiverName)">
            {{ scope.row.caregiverWorkOrderCommissionDetails }}</p>
        </template>
      </el-table-column>
      <el-table-column class-name="aa" label="总金额(元)" header-align="10px" width="80px">
        <template slot-scope="scope">
          {{ scope.row.totalAmount }}
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align:right" v-if="this.wageModule.wageList">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pagesizes"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="all">
      </el-pagination>
    </div>
    <el-dialog
        title="结算明细详情"
        :visible.sync="normalSettlementDetailsWindow"
        width="80%">
      <el-row>
      </el-row>
      <div class="client-list" v-for="item in normalSettlementDetailsJson" :key="item.index"
           style="font-size: 18px;font-weight: bold">
        <el-row>
          <el-col :span="4" style="color: #00a7b8">护理员：{{ name }}</el-col>
          <el-col :span="4">修正单价：{{ item.unitPrice }} (元)</el-col>
          <el-col :span="4">原始单价：{{ item.baseWage }} (元)</el-col>
          <el-col :span="4">服务月数：{{ item.numberOfMonths }}</el-col>
        </el-row>
        <el-row>

          <el-col :span="4">正常结算单数：{{ item.totalSettlement }}</el-col>
          <el-col :span="4">正常结算单数(缺时)：{{ item.totalLackOfTimeSettlement }}</el-col>
          <el-col :span="4">自费单数：{{ item.totalOwnExpense }}</el-col>
          <el-col :span="4">自费单数（缺时）：{{ item.totalLackOfTimeOwnExpense }}</el-col>

        </el-row>
        <br>
        <el-row>
          <el-col :span="24">工资详情：{{ item.wageDetailsList }}</el-col>
        </el-row>
        <br>
        <el-row>
          <el-col :span="24">服务月：{{ item.monthsOfServices }}</el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
    <el-dialog
        title="提成明细详情"
        :visible.sync="workOrderCommissionDetailsWindow"
        width="60%">
      <el-row>
      </el-row>
      <div class="client-list" v-for="item in workOrderCommissionDetailsJson" :key="item.index"
           style="font-size: 18px;font-weight: bold">
        <el-row>
          <el-col :span="4" style="color: #00a7b8">护理员：{{ name }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="12">推荐老人单价：{{ item.recommendedPrice }} (元)</el-col>
          <el-col :span="12">推荐老人总单数：{{ item.workOrderCommissionSingular }}</el-col>
        </el-row>
        <br>
        <el-row>
          <el-col :span="24">老人单数：{{ item.cutomerList }}</el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
    <el-dialog
        title="护理员提成明细"
        :visible.sync="caregiverWorkOrderCommissionDetailsWindow"
        width="60%">
      <el-row>
      </el-row>
      <div class="client-list" v-for="item in caregiverWorkOrderCommissionDetailsJson" :key="item.index"
           style="font-size: 18px;font-weight: bold">
        <el-row>
          <el-col :span="4" style="color: #00a7b8">护理员：{{ name }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="12">推荐护理员单价：{{ item.recommendedPrice }} (元)</el-col>
          <el-col :span="12">推荐护理员总单数：{{ item.caregiverWorkOrderCommission }}</el-col>
        </el-row>
        <br>
        <el-row>
          <el-col :span="24">护理员单数：{{ item.caregiverList }}</el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  wageGenerationButton,
  inquiryWageList, wageExport,siteChange
} from '@/common/js/index.js'
import {setDate} from '@/common/js/util.js'

export default {
  //el: 'form',
  name: "billingDetails",
  data() {
    return {
      status: '',
      all: 0,
      wageList: [],
      loading: false,
      currentPage: 1,
      boxFlag: true,
      pagesize: 20,
      pagesizes: [10, 20, 30, 50, 100, 200, 300, 400, 500, 1000],
      multipleSelection: [],
      searchFlag: true,
      contractTypeAndSiteInquireValue: [localStorage.getItem('siteId_1').toString(),localStorage.getItem('contractType_1').toString()],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      month: '',
      wageMonth: '',
      caregiverName: '',
      normalSettlementDetailsWindow: false,
      workOrderCommissionDetailsWindow: false,
      caregiverWorkOrderCommissionDetailsWindow: false,
      name: '',
      normalSettlementDetailsJson: [],
      workOrderCommissionDetailsJson: [],
      caregiverWorkOrderCommissionDetailsJson: [],
      wageModule:{
        wageList:false,
        wageGeneration:false,
        modifyBaseMage:false,
        wageExport:false
      }
    }
  },
  methods: {
    siteChangeOperating(){
      let subData = "siteId=" +this.contractTypeAndSiteInquireValue.toString().charAt(0)
      siteChange(subData).then(res => {
        if (res) {
          const permissionList  = res.data.userPermission.permissionList;
          this.wageModule.wageList = this.isExist(permissionList,"wage:wageList");
          this.wageModule.wageGeneration = this.isExist(permissionList,"wage:wageGeneration");
          this.wageModule.modifyBaseMage = this.isExist(permissionList,"wage:modifyBaseMage");
          this.wageModule.wageExport = this.isExist(permissionList,"wage:wageExport");
          this.inquiryWageList();

        }
      })

    },
    isExist(permissionList,str) {
      if (permissionList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    wageExportButton(){
      this.$confirm('导出工资中,请等待,稍后去报表中查看下载', '确定导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var strs = this.contractTypeAndSiteInquireValue.toString();
        var d = this.wageMonth;
        let subData = 'siteId=' + strs.charAt(0)
            + '&contractTypeId=' + strs.charAt(2)
            + '&status=' + this.status
            + '&caregiverName=' + this.caregiverName
        if (this.wageMonth !== '' && this.wageMonth !== null) {
          // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 201903
          const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1))
          subData = subData + '&wageMonth=' + resDate
        }
        wageExport(subData).then(res => {
          if (res) {
            this.$message.success('工资导出成功,请到报表中进行查看下载');
          }
        })
      }).catch(() => {
      });
    },
    p(s) {
      return s < 10 ? '0' + s : s
    },
    normalSettlementDetailsOpen(normalSettlementDetailsJson, name) {
      this.name = name
      this.normalSettlementDetailsJson = JSON.parse(normalSettlementDetailsJson);
      this.normalSettlementDetailsWindow = true;
    },
    workOrderCommissionDetailsOpen(workOrderCommissionDetailsJson, name) {
      this.name = name
      this.workOrderCommissionDetailsJson = JSON.parse(workOrderCommissionDetailsJson);
      this.workOrderCommissionDetailsWindow = true;
    },
    caregiverWorkOrderCommissionDetailsOpen(caregiverWorkOrderCommissionDetailsJson, name) {
      this.name = name
      this.caregiverWorkOrderCommissionDetailsJson = JSON.parse(caregiverWorkOrderCommissionDetailsJson);
      this.caregiverWorkOrderCommissionDetailsWindow = true;
    },
    // 查询工资列表
    inquiryWageList() {
      var strs = this.contractTypeAndSiteInquireValue.toString();
      var d = this.wageMonth;
      console.log("this.wageMonth=" + this.wageMonth)
      let subData = 'siteId=' + strs.charAt(0)
          + '&contractTypeId=' + strs.charAt(2)
          + '&status=' + this.status
          + '&caregiverName=' + this.caregiverName
          + '&currPage=' + this.currentPage
          + '&pageSizePara=' + this.pagesize
      if (this.wageMonth !== '' && this.wageMonth !== null) {
        // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 201903
        const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1))
        subData = subData + '&wageMonth=' + resDate
      }
      this.loading = true
      inquiryWageList(subData).then(res => {
        if (res) {
          this.wageList = res.data
          if (res.data.length !== 0) {
            this.all = res.data[0].totalCount
          } else {
            this.all = 0
          }
        }
        this.loading = false
      })
    },
    // 计算工资按钮
    wageGenerationButton() {
      if (this.month === '') {
        this.$message({
          message: '计算工资日期选择不能为空',
          type: 'warning'
        });
      } else {
        var d = this.month;
        // 1 Thu Mar 07 2019 12:00:00 GMT+0800 (中国标准时间) 转换为 201903
        const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1))
        var strs = this.contractTypeAndSiteInquireValue.toString();
        let subData = 'generateWageMonth=' + resDate
            + '&siteId=' + strs.charAt(0)
            + '&contractTypeId=' + strs.charAt(2);
        this.$message(resDate + '工资计算中，请稍后查看');
        wageGenerationButton(subData).then(res => {
          if (res) {
            this.$message({
              message: resDate + '工资生成成功',
              type: 'success'
            });
          }
          this.loading = false
        })
      }

    },
    handleSelectionChange(val) {//改变选择时触发
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange(val) {
      this.pagesize = val
    },

  },
  created() {
    this.siteChangeOperating();
  },
  watch: {
    currentPage: function () {
      this.inquiryWageList();
    },
    pagesize: function () {
      this.inquiryWageList();
    },
  }
}
</script>

<style scoped>
.gd-top {
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding: 5px 20px;
  margin: 10px 0 20px 0;
  display: -webkit-flex; /* Safari */
  display: flex;
  text-align: center;

}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.sel2 {
  width: 100px;
}
</style>
