<template>
    <div>

        <div style="float:left;width:100%;margin-left:20px;padding:20px 0 ">
            <div style="float:left;width:25%;  ">
                <el-select v-model="selectRole"  style="width: 120px" @change="getDataList()">
                    <el-option label="个人" value="0"></el-option>
                    <el-option label="主管-指派" value="1"></el-option>
                    <el-option label="主管-认领" value="2"></el-option>
                </el-select>&nbsp;&nbsp;
                <el-cascader
                        v-model="noticeContractTypeAndSiteInquireValue"
                        :options="contractTypeAndSiteInquireValueOptions"
                        :props="{ expandTrigger: 'hover' }"
                        @change="getDataList()" clearable
                        style="width: 200px"></el-cascader>
            </div>

            <div style="float:left;width:40%;  ">
                <el-checkbox-group v-model="stateSelection" v-if="whetherToDisplayActivation">
                    <el-checkbox label="待完成"></el-checkbox>
                    <el-checkbox label="待认领"></el-checkbox>
                    <el-checkbox label="待激活"></el-checkbox>
                    <el-checkbox label="待后续"></el-checkbox>
                    <el-checkbox label="已完成"></el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-model="stateSelection" v-show="!whetherToDisplayActivation">
                    <el-checkbox label="待完成"></el-checkbox>
                    <el-checkbox label="待认领"></el-checkbox>
                    <el-checkbox label="待后续"></el-checkbox>
                    <el-checkbox label="已完成"></el-checkbox>
                </el-checkbox-group>
            </div>
            <div style="float:right;width:10%;  ">
                <el-button type="success" plain style="margin: 0 0 0 10px" @click="externalCreateWorkflow()">创建
                </el-button>
            </div>

        </div>
        <el-table
                border
                :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                :cell-style="{'text-align':'center','border-right':'1px solid #eee','border-bottom':'1px solid #eee','padding':'0'}"
                :data="tableData"
                stripe
                max-height="750"
                style="width: 100%;margin-bottom:20px">
            <el-table-column
                    prop="state"
                    label="状态"
            >
            </el-table-column>
            <el-table-column
                    prop="creationTime"
                    label="创建时间"
            >
            </el-table-column>
            <el-table-column
                    prop="activationTime"
                    label="激活时间"
            >
            </el-table-column>
            <el-table-column
                    prop="siteName"
                    label="护理站"
            >
            </el-table-column>
            <el-table-column
                    prop="contractTypeName"
                    label="合约类型"
            >
            </el-table-column>
            <el-table-column

                    label="用户名"
            >
                <template slot-scope="scope">
                    <p @click="jumpToCustomer(scope.row)">{{scope.row.customerName}}</p>
                </template>
            </el-table-column>
            <el-table-column
                    prop="jobTitle"
                    label="工作内容"
            >
            </el-table-column>
            <el-table-column
                    prop="assignRole"
                    label="指派角色"
            >
            </el-table-column>
            <el-table-column
                    prop="claimRole"
                    label="认领角色"
            >
            </el-table-column>
            <el-table-column
                    prop="claimUser"
                    label="认领账号"
            >
            </el-table-column>
            <el-table-column prop="operation" label="回报详情" width="160">
                <template slot-scope="scope">
                    <el-button size="small" @click="distribution(scope.row.id,scope.row.contractTypeId)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div style="text-align:right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pagesizes"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
        <el-dialog title="工作流模块(详情页)" :visible.sync="dialogFormVisible">
            <el-form :ref="customerWorkflowDetails" :model="customerWorkflowDetails" label-width="160px"
                     :inline="true" v-loading="loading">
                <el-container>
                    <el-main width="200px">
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;状态 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.state}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;指派角色 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.assignRole}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;激活时间 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.activationTime}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;认领角色 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.claimRole}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;用户姓名 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.customerName}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;认领账号 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.claimUser}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;工作内容 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.jobTitle}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;认领时间 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.claimTime}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;工作备注 :</div>
                            </el-col>
                            <el-col :span="18">
                                <el-input v-model="customerWorkflowDetails.workNote" placeholder="请输入备注"
                                          clearable></el-input>
                            </el-col>
                        </el-row>
                        <el-row></el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;回复账号 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.replyUser}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;回复时间 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{customerWorkflowDetails.recoveryTime}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;回复地点 :</div>
                            </el-col>
                            <el-col :span="18">
                                <el-input v-model="customerWorkflowDetails.replyLocationByHand" placeholder="请输入回复地点"
                                          clearable></el-input>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="24">
                                <el-input
                                        type="textarea"
                                        :rows="4"
                                        placeholder="请输入回复详情"
                                        v-model="customerWorkflowDetails.replyForDetails">
                                </el-input>
                            </el-col>
                        </el-row>
                        <!--附图-->
                        <el-row :gutter="5">
                            <el-col :span="24">
                                <div style="height: 100%;width: 100%;border: 1px solid #D3D3D3; border-radius: 4px;">
                                    <el-upload
                                            action="/api/customerWorkflow/uploadTheAppendedDrawings"
                                            list-type="picture-card"
                                            name="file"
                                            :data={customerWorkId:this.customerWorkId}
                                            :on-preview="handlePictureCardPreview"

                                            :file-list="fileList">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </el-dialog>
                                </div>
                            </el-col>
                        </el-row>
                    </el-main>
                    <el-aside width="80px">
                        <el-row>
                            <el-button type="primary" plain style="margin: 0 0 0 10px"
                                       @click="createWorkflow(customerWorkflowDetails.id)">创建
                            </el-button>
                            <el-button type="primary" plain @click="claim(customerWorkflowDetails.id)">认领</el-button>
                            <el-button type="primary" plain @click="assigned(customerWorkflowDetails.id)">指派</el-button>
                            <el-button type="primary" plain @click="activation(customerWorkflowDetails.id)">激活
                            </el-button>
                            <el-button type="primary" plain @click="save(customerWorkflowDetails.id)">保存</el-button>
                            <el-button type="primary" plain @click="complete(customerWorkflowDetails.id)">完成</el-button>
                        </el-row>
                    </el-aside>
                </el-container>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="assignWorkflow(customerWorkflowDetails.assignWorkflowId)"
                           :style="{ display:customerWorkflowDetails.assignWorkflowButton}">
                    查看指派工作流信息
                </el-button>
                <el-button type="success" @click="customerHistoryWorkflowDetails(customerWorkflowDetails.customerId)">
                    查看历史工作流信息
                </el-button>
                <el-button @click="dialogFormVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看指派工作流信息" :visible.sync="dialogFormVisible2">
            <el-form :ref="selectassignWorkflow" :model="selectassignWorkflow" label-width="160px"
                     :inline="true" v-loading="loading">
                <el-container>
                    <el-main width="200px">
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;状态 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.state}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;指派角色 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.assignRole}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;激活时间 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.activationTime}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;认领角色 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.claimRole}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;用户姓名 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.customerName}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;认领账号 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.claimUser}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;工作内容 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.jobTitle}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;认领时间 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.claimTime}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;工作备注 :</div>
                            </el-col>
                            <el-col :span="18">
                                <el-input v-model="selectassignWorkflow.workNote" :disabled="true"></el-input>
                            </el-col>
                        </el-row>
                        <el-row></el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;回复账号 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.replyUser}}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;回复时间 :</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{selectassignWorkflow.recoveryTime}}</div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">&nbsp;&nbsp;回复地点 :</div>
                            </el-col>
                            <el-col :span="18">
                                <el-input v-model="selectassignWorkflow.replyLocationByHand"
                                          :disabled="true"></el-input>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="24">
                                <el-input
                                        type="textarea"
                                        :rows="4"
                                        :disabled="true"
                                        v-model="selectassignWorkflow.replyForDetails">
                                </el-input>
                            </el-col>
                        </el-row>
                        <!--附图-->
                        <el-row :gutter="5">
                            <el-col :span="24">
                                <div style="height: 100%;width: 100%;border: 1px solid #D3D3D3; border-radius: 4px;">
                                    <el-upload
                                            list-type="picture-card"
                                            :on-preview="handlePictureCardPreview"
                                            :file-list="assignWorkflowFileList" :disabled="true">

                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </el-dialog>
                                </div>
                            </el-col>
                        </el-row>
                    </el-main>

                </el-container>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">关 闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看历史工作流信息" :visible.sync="dialogFormVisible3">
            <el-form :ref="customerHistoryWorkflowDetailsList" :model="customerHistoryWorkflowDetailsList"
                     label-width="160px"
                     :inline="true" v-loading="loading">
                <div v-for="(item,index) in customerHistoryWorkflowDetailsList" :key="item.id">
                    <el-row>
                        <el-col :span="24">
                            <div style="text-align: left;font-size: 18px"><b>历史工作流{{index+1}} :</b></div>
                        </el-col>
                    </el-row>
                    <el-container>
                        <el-main width="200px">
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;状态 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">
                                        &nbsp;&nbsp;{{item.state}}
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;指派角色 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.assignRole}}</div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;激活时间 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.activationTime}}</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;认领角色 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.claimRole}}</div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;用户姓名 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.customerName}}</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;认领账号 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.claimUser}}</div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;工作内容 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.jobTitle}}</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;认领时间 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.claimTime}}</div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;工作备注 :</div>
                                </el-col>
                                <el-col :span="18">
                                    <el-input v-model="item.workNote" :disabled="true"></el-input>
                                </el-col>
                            </el-row>
                            <el-row></el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;回复账号 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.replyUser}}</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;回复时间 :</div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="grid-content bg-purple" style="color:#1E90FF">&nbsp;&nbsp;{{item.recoveryTime}}</div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">&nbsp;&nbsp;回复地点 :</div>
                                </el-col>
                                <el-col :span="18">
                                    <el-input v-model="item.replyLocationByHand"
                                              :disabled="true"></el-input>
                                </el-col>
                            </el-row>
                            <el-row :gutter="5">
                                <el-col :span="24">
                                    <el-input
                                            type="textarea"
                                            :rows="4"
                                            :disabled="true"
                                            v-model="item.replyForDetails">
                                    </el-input>
                                </el-col>
                            </el-row>
                            <!--附图-->
                            <el-row :gutter="5">
                                <el-col :span="24">
                                    <div style="height: 100%;width: 100%;border: 1px solid #D3D3D3; border-radius: 4px;">
                                        <el-upload
                                                list-type="picture-card"
                                                :on-preview="handlePictureCardPreview"
                                                :file-list="item.fileList" :disabled="true">

                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <el-dialog :visible.sync="dialogVisible">
                                            <img width="100%" :src="dialogImageUrl" alt="">
                                        </el-dialog>
                                    </div>
                                </el-col>
                            </el-row>

                        </el-main>

                    </el-container>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible3 = false">关 闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="选择指派人员" :visible.sync="assignedUser" width="300px">
            <el-table :data="roleList"
                      @selection-change="handleSelectionChange"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center','man-width':'100px'}"
                      max-height="600"
            >
                <el-table-column type='selection' v-if="true" style="height: 10px"
                                 min-height="10px"></el-table-column>
                <el-table-column property="nickname" label="姓名"></el-table-column>

            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="assignedUser = false">取 消</el-button>
                <el-button type="primary" @click="assignedUserButton()">指 派</el-button>
            </div>
        </el-dialog>
        <el-dialog title="指派信息填写" :visible.sync="createWorkflowDialog" width="500px">
            <el-form>
                <el-form-item label="选择认领角色 :" :label-width="formLabelWidth">
                    <el-select v-model="claimRoleId" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工作内容 : " :label-width="formLabelWidth">
                    <el-input v-model="jobTitle"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="createWorkflowDialog = false">取 消</el-button>
                <el-button type="primary" @click="createWorkflowButton()">创 建</el-button>
            </div>
        </el-dialog>
        <el-dialog title="新增工作流信息填写" :visible.sync="externalCreateWorkflowDialog" width="500px">
            <el-form>
                <el-form-item label="选择老人 : " :label-width="formLabelWidth">
                    <el-input v-model="clientName" @focus="clientInput"></el-input>
                </el-form-item>
                <el-form-item label="选择已签约类型:" :label-width="formLabelWidth">
                    <el-select v-model="createWorkflowEvaluationNursingId" >
                        <el-option
                                v-for="item in customeContracttypesList"
                                :key="item.index"
                                :label="item.customeContractType"
                                :value="item.evaluationNursingId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择认领角色 :" :label-width="formLabelWidth">
                    <el-select v-model="claimRoleId" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="工作内容 : " :label-width="formLabelWidth">
                    <el-input v-model="jobTitle"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="externalCreateWorkflowDialog = false">取 消</el-button>
                <el-button type="primary" @click="externalCreateWorkflowButton()">创 建</el-button>
            </div>
        </el-dialog>
        <el-dialog title="老人列表" :visible.sync="clientDialog" width="1200px">
            <el-form :inline="true" :model="careForm" class="demo-form-inline" label-width="120px">
                <el-form-item>
                    <el-select v-model="careForm.status" class="sel line" style="width: 100px" @change="getClients()">
                        <el-option label="全部状态" value=""></el-option>
                        <el-option label="有意向" value="有意向"></el-option>
                        <el-option label="待派单" value="待派单"></el-option>
                        <el-option label="待申请" value="待申请"></el-option>
                        <el-option label="待评估" value="待评估"></el-option>
                        <el-option label="中止中" value="中止中"></el-option>
                        <el-option label="长期中止" value="长期中止"></el-option>
                        <el-option label="到期合约" value="到期合约"></el-option>
                        <el-option label="待确认" value="待确认"></el-option>
                        <el-option label="合约中" value="合约中"></el-option>
                        <el-option label="已终止" value="已终止"></el-option>
                        <el-option label="已删除" value="已删除"></el-option>
                    </el-select>&nbsp;
                    <el-cascader
                            v-model="contractTypeAndSiteInquireValue"
                            :options="contractTypeAndSiteInquireValueOptions"
                            :props="{ expandTrigger: 'hover' }"
                            @change="getClients()"
                            style="width: 200px"></el-cascader>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="careForm.searchCondition" class="inp line" placeholder="关键字"
                              @keyup.enter.native="getClients()"
                              clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" @click="getClients()">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="clientArr"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center','man-width':'100px'}"
                      max-height="700"
            >
                <el-table-column prop="name" label="姓名"></el-table-column>
<!--                <el-table-column prop="status" label="状态"></el-table-column>-->
                <el-table-column prop="numbering" label="编号"></el-table-column>
                <el-table-column prop="gender" label="性别"></el-table-column>
                <el-table-column prop="moblle" label="电话"></el-table-column>
                <el-table-column prop="statusChangetime" label="时间"></el-table-column>

                <el-table-column prop="operation" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="seleCare(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="carePageChange"
                    :current-page="clientPage"
                    style="text-align:right;margin-top: 20px"
                    layout="total,prev, pager, next, jumper"
                    :page-size="10"
                    :total="clientTotal">
            </el-pagination>
        </el-dialog>
        <!--用户详情-->
        <el-dialog title="用户详情" :visible.sync="clientDetails" width="80%">
            <div class="des-box">
                <el-form ref="form" :model="form" :rules="cRules" label-width="100px">
                    <div class="des  assess">
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="姓名" prop="name">
                                    <el-input v-model="form.name" placeholder="请输入客户姓名" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="手机号" prop="moblle">
                                    <el-input v-model="form.moblle" placeholder="请输入客户手机号码" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="身份证号" prop="identityCard">
                                    <el-input v-model="form.identityCard" placeholder="请输入客户身份证号"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="年龄" prop="age">
                                    <el-input v-model="form.age" placeholder="请输入客户年龄" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="性别">
                                    <el-radio-group v-model="form.gender">
                                        <el-radio label="男">男</el-radio>
                                        <el-radio label="女">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="家属姓名">
                                    <el-input v-model="form.familyName" placeholder="请输入客户家属姓名"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="紧急联络电话">
                                    <el-input v-model="form.familyMoblle" placeholder="请输入客户紧急联系方式"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="primary" @click="viewOtherFamilyMembers()" size="small"
                                           v-show="!isAdd">查看其它家属
                                </el-button>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="状态备注" prop="statusRemarks">
                                    <el-input v-model="form.statusRemarks" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <br/>
                    <el-row :gutter="0">
                        <div style="color: #1E90FF">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注 : 默认显示为当前工作流老人 签约信息</div>
                        <el-col :span="6">
                            <el-form-item label="已签约:">
                                <el-select v-model="selectEvaluationNursingId" @change="jumpToCustomer()" v-show="!isAdd"
                                           style="width: 150px">
                                    <el-option
                                            v-for="item in customeContracttypesList"
                                            :key="item.index"
                                            :label="item.customeContractType"
                                            :value="item.evaluationNursingId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <div class="client-list-two">
                                <div v-if="form.status == '有意向'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '待派单'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '待申请'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '待评估'" class="lightSkyBlue">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '中止中'" class="stateRed">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '长期中止'" class="stateMaroon">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '待确认'" class="stateYellow">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '合约中'" class="stateGreen">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '已终止'" class="stateRed">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '到期合约'" class="stateDarkgray">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                                <div v-else-if="form.status == '已删除'" class="stateDarkgray">
                                    <span>该合约状态：{{form.status}}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <!--老人合约架构修改 begin-->
                    <div class="des  assess">
                        <el-row :gutter="0">
                            <el-col :span="6">
                                <el-form-item label="长护险平台是否录入" label-width="160px">
                                    <el-switch v-model="form.enterPlan" active-value="1"
                                               inactive-value="0"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="长护险平台是否接收" label-width="160px">
                                    <el-switch v-model="form.enterPlanFlag" active-value="1"
                                               inactive-value="0"></el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="医护平台是否派单" label-width="160px">
                                    <el-switch v-model="form.mdicalPatform" active-value="1"
                                               inactive-value="0"></el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="护理计划编码">
                                    <el-input v-model="form.plannlngSheet" placeholder="请输入客户护理计划编码"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="设备编号">
                                    <el-input v-model="form.lbeaconMajor" placeholder="请输入设备编号" class="line"
                                              learable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="评估等级">
                                    <el-input v-model="form.evaluationLervl" type="number" placeholder="请输入客户评估等级"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="评估有效期">
                                    <el-date-picker
                                            placeholder="请选择客户的评估有效期"
                                            v-model="form.evaluationPeriod"
                                            type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="护理备注">
                                    <el-input v-model="form.nursingRemarks" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="首次护理时间">
                                    <el-date-picker
                                            placeholder="选择首次护理时间"
                                            v-model="form.firstCareTime"
                                            type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="推荐渠道">
                                    <el-input v-model="form.recommend" placeholder="请输入客户推荐渠道"
                                              class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="医保卡类型">
                                    <el-select v-model="form.medicareCardType" placeholder="请选择医保卡类型" class="line">
                                        <el-option
                                                v-for="item in medicareTypes"
                                                :key="item"
                                                :label="item"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="街道">
                                    <el-input v-model="form.sreet" class="line" @focus="toAddress()"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="居委会" prop="committees">
                                    <el-input v-model="form.committees" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="详细地址">
                                    <el-input v-model="form.addressDetall" class="line"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <!--老人合约架构修改 end-->
                    <div class="des  assess">
                        <div class="flex">
                            <el-form-item label="指定护理员一">
                                <el-input v-model="careWorker_one" placeholder="请选择指定护理员一" class="line"
                                          @focus="careInput(1)"></el-input>
                                <el-button type="danger" @click="deleteCareWorker_one()" v-show="!isAdd" size="mini"
                                           icon="el-icon-delete"
                                           circle></el-button>
                            </el-form-item>
                            <el-form-item label="指定原因">
                                <el-input v-model="form.designationReason_one" placeholder="指定护理员一原因" class="line"
                                          clearable></el-input>
                            </el-form-item>
                        </div>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="指定护理员二">
                                    <el-input v-model="careWorker_two" placeholder="请选择指定护理员二" class="line"
                                              @focus="careInput(2)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="指定原因">
                                    <el-input v-model="form.designationReason_two" placeholder="指定护理员二原因"
                                              class="line"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="6">
                                <el-form-item label="指定护理员三">
                                    <el-input v-model="careWorker_three" placeholder="请选择指定护理员三" class="line"
                                              @focus="careInput(3)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="指定原因">
                                    <el-input v-model="form.designationReason_three" placeholder="指定护理员三原因"
                                              class="line"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="9">
                                <el-form-item label="护理时间" label-width="160px">
                                    <el-time-picker
                                            class="line"
                                            is-range
                                            v-model="nursingTime"
                                            format="HH:mm"
                                            range-separator="至"
                                            start-placeholder="开始时间"
                                            end-placeholder="结束时间"
                                            placeholder="选择时间范围">
                                    </el-time-picker>
                                </el-form-item>&nbsp;&nbsp;&nbsp;&nbsp;
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="备用时间备注">
                                    <el-input v-model="form.standbyTimeRemarks" placeholder="时间备注" class="line"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="150">
                            <el-col :span="24">
                                <el-form-item label="护理周期">
                                    <el-checkbox-group v-model="nursingCycle">
                                        <el-checkbox v-for="item in serveWeek" :label="item.id" :key="item.index">
                                            {{item.name}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="des serve">
                        <div class="flex">
                            <el-form-item label="预约指定护理员" label-width="160px">
                                <el-input v-model="reservation_careWorker" placeholder="请选择预约指定护理员" class="line"
                                          @focus="careInput(4)" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="预约指定原因" label-width="160px">
                                <el-input v-model="form.reservation_designationReason" placeholder="预约指定原因"
                                          class="line" clearable></el-input>
                            </el-form-item>
                        </div>

                        <div class="flex">
                            <el-form-item label="状态预约时间" prop="appointmentTime" v-if="!isAdd" label-width="160px">
                                <el-input v-model="form.appointmentTime" :disabled="true" class="line"></el-input>
                            </el-form-item>
                            <el-form-item label="预约变更周期时间" prop="appointmentCycleChangeTime" v-if="!isAdd"
                                          label-width="160px">
                                <el-input v-model="form.appointmentCycleChangeTime" :disabled="true"
                                          class="line"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="预约护理时间" label-width="160px">
                                <el-time-picker
                                        class="line"
                                        is-range
                                        v-model="nursingAppoTime"
                                        format="HH:mm"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        placeholder="选择时间范围">
                                </el-time-picker>
                            </el-form-item>
                        </div>
                        <!--<el-form-item label="护理备注" label-width="160px">
                            <el-input v-model="form.nursingRemarks" placeholder="请输入护理备注" class="line inp" clearable
                                      style="width:300px"></el-input>
                        </el-form-item>-->
                        <el-form-item label="预约护理周期" label-width="160px">
                            <el-checkbox-group v-model="reservation_nursingCycle">
                                <el-checkbox v-for="item in serveWeek" :label="item.id" :key="item.index">
                                    {{item.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <div class="flex">
                        </div>
                    </div>
                    <div class="des map">
                        <div class="flex">
                            <el-form-item label="纬度" label-width="50px">
                                <el-input v-model="form.latltude" placeholder="" class="line"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item label="经度" label-width="50px">
                                <el-input v-model="form.longltude" placeholder="" class="line"
                                          clearable></el-input>
                            </el-form-item>
                            <!--<span class="local">纬度：{{form.latltude}}</span><span class="local">经度：{{form.longltude}}</span>-->
                        </div>
                        <div id="map"></div>
                    </div>
                    <div class="des card">
                        <el-tabs v-model="activeName">
                            <el-tab-pane label="身份证" name="first">
                                <div class="flex img-box">
                                    <div>
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="idBefUpload"
                                                :on-change="idBefChange"
                                        >
                                            <img v-if="idBefUrl" :src="idBefUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（身份证正面）</p>
                                    </div>
                                    <div style="margin-left: 30px">
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="idBacUpload"
                                                :on-change="idBacChange"
                                        >
                                            <img v-if="idBacUrl" :src="idBacUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（身份证反面）</p>
                                    </div>
                                </div>
                            </el-tab-pane>

                            <el-tab-pane label="医保卡" name="second">
                                <div class="flex img-box">
                                    <div>
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="soBefUpload"
                                                :on-change="soBefChange"
                                        >
                                            <img v-if="soBefUrl" :src="soBefUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（社保卡正面）</p>
                                    </div>
                                    <div style="margin-left: 30px">
                                        <el-upload
                                                class="avatar-uploader"
                                                action="customize"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept='.jpg,.png'
                                                ref="soBacUpload"
                                                :on-change="soBacChange"
                                        >
                                            <img v-if="soBacUrl" :src="soBacUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                        <p>（社保卡反面）</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="查看家属信息" :visible.sync="familyInformationDialog" width="1200px">
            <el-table :data="familyInformation"
                      border
                      stripe
                      :header-cell-style="{background:'#dee2ec', color: '#666666'}"
                      :cell-style="{'text-align':'center','man-width':'100px'}"
                      max-height="700"
            >
                <el-table-column prop="familyName" label="家属姓名"></el-table-column>
                <el-table-column prop="familyMoblle" label="紧急联络电话"></el-table-column>
                <el-table-column prop="familyNotes" label="家属备注"></el-table-column>
            </el-table>
        </el-dialog>
    </div>

</template>

<script>
    import $ from 'jquery'
    import {
        customerWorkflowList,
        customerWorkflowDetails,
        customerWorkflowButtonToTrigger,
        querySupervisorPermissions,
        createWorkflowButton,
        customerHistoryWorkflowDetails,
        cusList,
        externalCreateWorkflowButton,
        getCus,
        getCare,
        viewOtherFamilyMembers
    } from '@/common/js/index.js'
    import {isEmpty} from "@/common/js/util.js"
    import BMap from 'BMap';

    export default {
        name: 'natice',
        data() {
            return {
                familyInformationDialog: false,//查看家属信息
                familyInformation: [],
                createWorkflowEvaluationNursingId:null,// 创建工作流选择合约信息id
                selectEvaluationNursingId:null,// 老人基本信息查询 合约信息id
                noticeContractTypeAndSiteInquireValue:[],
                contractTypeAndSiteInquireValue: ['1','1'],
                contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
                whetherToDisplayActivation:true,//是否显示待激活工作流,默认显示 ,服务中心不显示
                roleId:localStorage.getItem("roleId"),
                siteList: localStorage.getItem("siteList").split("$"),
                customeContracttypesList: [],// 老人 已签约 合约类型
                contractTypeId:'',
                workflowDetailsContractTypeId:'',//工作流详情
                selectRole: '0',
                careForm: {
                    searchCondition: "",
                    status: "",
                },
                currPage: 1,
                clientPage: 1,
                clientTotal: 0,
                clientArr: [],
                loading: false,
                clientDialog: false,
                clientId: '',
                clientName: '',
                fileList: [], // 附图list
                assignWorkflowFileList: [],//指派工作流附图list
                associatedworkflowList: [],// 关联工作流
                associatedworkflowFileList: [],// 关联工作流附图list
                customerHistoryWorkflowDetailsList: [], //历史工作流
                stateSelection: ['待完成', '待认领', '待激活'],
                total: 0,
                currentPage: 1,
                tableData: [],
                pagesize: 10,
                pagesizes: [10, 20, 30, 50, 100, 200, 500],
                value: false,
                dialogFormVisible: false,
                dialogFormVisible2: false,
                dialogFormVisible3: false,
                assignedUser: false,
                createWorkflowDialog: false,
                externalCreateWorkflowDialog: false,
                distributionStaff: '',
                id: '',
                customerWorkflowDetails: {
                    workNote: '', //工作备注
                    replyForDetails: '', //回复详情
                    replyLocationByHand: '',// 回复地点
                    assignWorkflowButton: '', //查看指派工作流信息按钮显示
                },
                selectassignWorkflow: {
                    id: '',
                    workNote: '', //工作备注
                    replyForDetails: '', //回复详情
                    replyLocationByHand: ''// 回复地点
                },
                siteValue: sessionStorage.getItem("siteValue"),
                roleList: [],
                multipleSelection: [],
                customerWorkId: '',

                dialogImageUrl: '',
                dialogVisible: false,

                options: [{
                    value: '2',
                    label: '站长'
                }, {
                    value: '3',
                    label: '服务中心'
                }, {
                    value: '4',
                    label: '管理层'
                }],
                claimRoleId: '',//角色id
                jobTitle: '',//指派工作标题
                formLabelWidth: '120px',
                /*用户begin*/
                idBefUrl: "",//身份证正面
                idBacUrl: "",//身份证反面
                soBefUrl: "",//社保卡正面
                soBacUrl: "",//社保卡反面
                activeName: 'first',
                form: {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)1
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    statusRemarks: "", //状态备注
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                },
                medicareTypes: localStorage.getItem("medicareCardType").split("$"),
                checkList: [],
                reservation_nursingCycle: [],//意向服务时间（周）
                nursingCycle: [],//护理周期
                careWorker_one: "",//指定护理员1
                careWorker_two: "",//指定护理员2
                careWorker_three: "",//指定护理员3
                reservation_careWorker: "",//预约指定护理员
                nursingTime: "",//护理时间
                nursingAppoTime: "",//预约护理时间
                serveWeek: [
                    {name: "周一", id: '1'},
                    {name: "周二", id: '2'},
                    {name: "周三", id: '3'},
                    {name: "周四", id: '4'},
                    {name: "周五", id: '5'},
                    {name: "周六", id: '6'},
                    {name: "周日", id: '7'},
                ],
                clientDetails: false,
                cRules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    moblle: [{required: true, message: '请输入客户手机号码', trigger: 'blur'}],
                    identityCard: [{required: true, message: '请输入客户身份证号', trigger: 'blur'}],
                    age: [{required: true, message: '请输入客户年龄', trigger: 'blur'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                },
                isAdd: false,
                /*用户end*/
            }
        },
        methods: {
            viewOtherFamilyMembers() {
                let subData = 'clientId=' + this.clientId
                viewOtherFamilyMembers(subData).then(res => {
                    if (res) {
                        this.familyInformation = res.data
                    }
                })
                this.familyInformationDialog = true;
            },
            /*点击begin*/
            jumpToCustomer(row) {
                this.load = true;
                this.fatForm();
                if (row !== undefined) {
                    this.clientId = row.customerId;
                }

                this.isAdd = false;
                if (row!=null){

                    this.selectEvaluationNursingId = row.evaluationNursingId
                }
                let subData = "id=" + this.clientId + '&evaluationNursingId=' + this.selectEvaluationNursingId;

                var s = this
                getCus(subData).then(res => {
                    this.allLoading = false;
                    this.load = false;

                    if (res) {
                        this.customeContracttypesList = res.data['customeContracttypesList']
                        for (let i = 0; i < this.customeContracttypesList.length; i++) {
                            if (this.customeContracttypesList[i].evaluationNursingId === this.selectEvaluationNursingId){
                                this.contractTypeId = this.customeContracttypesList[i].contractTypeId
                            }
                        }
                        var map = new BMap.Map("map");
                        var point = new BMap.Point(res.data['basicInformation'][0].longltude, res.data['basicInformation'][0].latltude);
                        map.centerAndZoom(point, 15);
                        var marker = new BMap.Marker(point); // 创建点
                        map.addOverlay(marker);

                        function showInfo(e) {
                            map.clearOverlays();
                            //改经纬度
                            var marker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)); // 创建点
                            map.addOverlay(marker);//增加点
                            s.form.longltude = e.point.lng;
                            s.form.latltude = e.point.lat;
                        }

                        map.addEventListener("click", showInfo);

                        this.form = res.data['basicInformation'][0];
                        this.idBefUrl = res.data['basicInformation'][0].ldentlyCarPlc;
                        this.idBacUrl = res.data['basicInformation'][0].ldentlyCardBackPlc;
                        this.soBefUrl = res.data['basicInformation'][0].medlcareCardPlc;
                        this.soBacUrl = res.data['basicInformation'][0].medlcareCardPlcBack;
                        this.operaList = [];
                        for (let i = 0; i < res.data['list'].length; i++) {
                            this.operaList.push({
                                value: res.data['list'][i].split(',')[0],
                                state: res.data['list'][i].split(',')[1],
                            })
                        }
                        this.dynamicTags = res.data['label'];
                        if (res.data['basicInformation'][0].nursingCycle != null) {
                            this.nursingCycle = res.data['basicInformation'][0].nursingCycle.split(',');
                        } else {
                            this.nursingCycle = [];
                        }
                        if (res.data['basicInformation'][0].reservation_nursingCycle != null) {
                            this.reservation_nursingCycle = res.data['basicInformation'][0].reservation_nursingCycle.split(',');
                        } else {
                            this.reservation_nursingCycle = [];
                        }
                        if (isEmpty(this.form.careWorker_id_one)) {
                            this.nurseDes(this.form.careWorker_id_one,this.form.contractInformationId, 1)
                        }
                        if (isEmpty(this.form.careWorker_id_two)) {
                            this.nurseDes(this.form.careWorker_id_two,this.form.contractInformationId,2)
                        }
                        if (isEmpty(this.form.careWorker_id_three)) {
                            this.nurseDes(this.form.careWorker_id_three,this.form.contractInformationId, 3)
                        }
                        if (isEmpty(this.form.reservation_careWorker_id)) {
                            this.nurseDes(this.form.reservation_careWorker_id,this.form.contractInformationId,4)
                        }
                        if (isEmpty(this.form.nursingStartTime) && isEmpty(this.form.nursingEndTime)) {
                            this.nursingTime = [];
                            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingStartTime.split(':')[0], this.form.nursingStartTime.split(':')[1], this.form.nursingStartTime.split(':')[2]));
                            this.nursingTime.push(new Date(2016, 9, 10, this.form.nursingEndTime.split(':')[0], this.form.nursingEndTime.split(':')[1], this.form.nursingEndTime.split(':')[2]));
                        }
                        if (isEmpty(this.form.reservation_nursingStartTime) && isEmpty(this.form.reservation_nursingEndtime)) {
                            this.nursingAppoTime = [];
                            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingStartTime.split(':')[0], this.form.reservation_nursingStartTime.split(':')[1], this.form.reservation_nursingStartTime.split(':')[2]));
                            this.nursingAppoTime.push(new Date(2016, 9, 10, this.form.reservation_nursingEndtime.split(':')[0], this.form.reservation_nursingEndtime.split(':')[1], this.form.reservation_nursingEndtime.split(':')[2]));
                        }
                    }
                })
                this.clientDetails = true
            },
            // 文件上传
            uploadFile() {
            },
            idBefChange(file) {
                this.idBefUrl = URL.createObjectURL(file.raw);
            },
            idBacChange(file) {
                this.idBacUrl = URL.createObjectURL(file.raw);
            },
            soBefChange(file) {
                this.soBefUrl = URL.createObjectURL(file.raw);
            },
            soBacChange(file) {
                this.soBacUrl = URL.createObjectURL(file.raw);
            },
            //获取护理员名称
            nurseDes(id,contractInformationId, num) {
                let subData = "id=" + id + '&contractInformationId=' + contractInformationId;
                getCare(subData).then(res => {
                    if (res) {
                        let name = res.data['basicCaregiver'][0].name;
                        switch (num) {
                            case 1:
                                this.careWorker_one = name;
                                break;
                            case 2:
                                this.careWorker_two = name;
                                break;
                            case 3:
                                this.careWorker_three = name;
                                break;
                            case 4:
                                this.reservation_careWorker = name;
                                break;
                            default:
                                break;
                        }
                    }
                })
            },
            /**
             *选择护理员
             * */
            careInput(num) {
                this.careFlag = num;
                this.careDialog = true;
                this.carePage = 1;
                this.careForm.status = "";
                this.careForm.searchCondition = "";
                this.getCares();
            },
            fatForm() {
                this.nursingTime = "";
                this.nursingAppoTime = '';
                this.reservation_nursingCycle = [];//预约服务时间（周）
                this.nursingCycle = [];//护理周期
                this.careWorker_one = "";//指定护理员1
                this.careWorker_two = "";//指定护理员2
                this.careWorker_three = "";//指定护理员3
                this.reservation_careWorker = "";//预约指定护理员
                this.form = {
                    status: "",
                    id: "",//用户id
                    name: "",//姓名
                    moblle: "",//电话
                    identityCard: "",//身份证号
                    age: "",//年龄
                    familyName: "",//家人姓名
                    familyMoblle: "",//紧急电话
                    gender: "",//性别
                    numbering: "",//编号
                    medicareCardType: "",//医保卡类型
                    recommend: "",//推荐渠道
                    firstCareTime: "",//首次护理时间
                    addressDetall: "",//详细地址
                    sreet: "",//街道
                    committees: "",//居委会
                    latltude: "",//纬度
                    longltude: "",//经度
                    ldentlyCarPlc: null,//身份证正面
                    ldentlyCardBackPlc: null,//身份证反面
                    medlcareCardPlc: null,//社保卡正面
                    medlcareCardPlcBack: null,//社保卡反面
                    evaluationLervl: null,//评估等级
                    plannlngSheet: "",//护理计划表编码
                    evaluationPeriod: "",//评估有效期
                    enterPlan: "0",//长护险平台录入计划（是否录入)
                    enterPlanFlag: "0",//长护险平台接受（是否接受）
                    mdicalPatform: "0",//医护平台派单（是否派单）
                    lbeaconMajor: "",//设备编号
                    nursingRemarks: "",//护理备注
                    careWorker_id_one: null,//指定护理员(id)
                    designationReason_one: "",//指定原因1
                    careWorker_id_two: null,//指定护理员(id)2
                    designationReason_two: "",//指定原因2
                    careWorker_id_three: null,//指定护理员(id)3
                    designationReason_three: "",//指定原因3
                    reservation_careWorker_id: "",//预约指定护理员
                    reservation_designationReason: "",//预约指定原因
                    standbyTimeRemarks: "",//备用时间备注
                    appointmentTime: "",//状态操作预约时间
                }
            },
            /*点击end*/
            seleCare(row) {

                this.clientName = row.name;
                this.clientId = row.id;
                let subData = "id=" + this.clientId + '&evaluationNursingId=' + row.evaluationNursingId;
                getCus(subData).then(res => {
                    if (res) {
                        this.customeContracttypesList = res.data['customeContracttypesList']
                    }
                })
                this.clientDialog = false;

            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            /**
             * 改变选择时触发
             */
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            /**
             * 外部工作流创建按钮触发
             */
            externalCreateWorkflow() {
                this.customeContracttypesList = []
                this.createWorkflowEvaluationNursingId = ''
                this.externalCreateWorkflowDialog = true
            },
            externalCreateWorkflowButton() {
                let siteList = '';
                for (let i = 0; i < this.customeContracttypesList.length; i++) {
                    if (this.contracttypes === this.customeContracttypesList[i].contractTypeId){
                        siteList = this.customeContracttypesList[i].siteId
                    }
                }
                let subData = 'clientId=' + this.clientId
                    + '&claimRoleId=' + this.claimRoleId
                    + '&jobTitle=' + this.jobTitle
                    + '&siteList=' + siteList
                    + '&evaluationNursingId=' + this.createWorkflowEvaluationNursingId;
                externalCreateWorkflowButton(subData).then(res => {
                    if (res) {
                        this.$message.success('创建完成');
                        this.externalCreateWorkflowDialog = false;
                        this.jobTitle = '';
                        this.clientId = '';
                        this.claimRoleId = '';
                        this.clientName = '';
                        this.getDataList();
                    }
                })
            },
            /**
             *选择老人
             * */
            clientInput() {
                this.clientDialog = true;
                this.clientPage = 1;
                this.careForm.status = "";
                this.careForm.searchCondition = "";
                this.getClients();
            },
            carePageChange(val) {
                this.clientPage = val;
                this.getClients();
            },
            /**
             * 获取客户列表
             * */
            getClients() {
                let subData = "currPage=" + this.clientPage + "&searchCondition=" + this.careForm.searchCondition + "&status=" + this.careForm.status
                    + "&siteList=" + this.contractTypeAndSiteInquireValue.toString().charAt(2)
                    + '&contractTypeId='+ this.contractTypeAndSiteInquireValue.toString().charAt(0);
                cusList(subData).then(res => {
                    if (res) {
                        this.clientArr = res.data;
                        if (res.data.length !== 0) {
                            this.clientTotal = res.data[0].totalCount;
                        }
                    }
                })

            },
            /**
             * 工作流创建按钮触发
             */
            createWorkflow() {
                this.createWorkflowDialog = true
            },
            /**
             * 工作流创建
             */
            createWorkflowButton() {
                let subData = 'customerWorkId=' + this.customerWorkId
                    + '&claimRoleId=' + this.claimRoleId
                    + '&jobTitle=' + this.jobTitle
                    + '&siteList=' + this.siteValue
                    + '&contractTypeId='+this.workflowDetailsContractTypeId
                createWorkflowButton(subData).then(res => {
                    if (res) {
                        this.$message.success('创建完成');
                        this.createWorkflowDialog = false;
                        this.getDataList();
                        this.distribution(this.customerWorkId)
                    }
                })

            },

            /**
             * 工作流激活按钮触发
             * 提示是否认领，同意则激活认领一起完成，否则仅手动激活到待认领
             */
            activation(id) {
                var state = '';
                let subData = '';
                this.$confirm('激活并认领该工单', '是否认领', {
                    confirmButtonText: '激活并认领',
                    cancelButtonText: '仅激活',
                    type: 'warning'
                }).then(() => {
                    // 认领该工单
                    state = "激活并认领"
                    subData = 'state=' + state + '&customerWorkId=' + id
                    customerWorkflowButtonToTrigger(subData).then(res => {
                        if (res) {
                            this.$message.success('认领完成');
                            this.getDataList();
                            this.distribution(id)
                        }
                    })
                }).catch(() => {
                    // 激活该工单
                    state = "仅激活"
                    subData = 'state=' + state + '&customerWorkId=' + id
                    customerWorkflowButtonToTrigger(subData).then(res => {
                        if (res) {
                            this.$message.success('激活完成');
                            this.getDataList();
                            this.distribution(id)
                        }
                    })
                });


            },
            /**
             * 工作流认领按钮触发
             */
            claim(id) {
                var state = '';
                let subData = '';
                this.$confirm('认领该工单', '是否认领', {
                    confirmButtonText: '认领',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 认领该工单
                    state = "认领"
                    subData = 'state=' + state + '&customerWorkId=' + id
                    customerWorkflowButtonToTrigger(subData).then(res => {
                        if (res) {
                            this.$message.success('认领完成');
                            this.getDataList();
                            this.distribution(id)
                        }
                    })
                }).catch(() => {

                });


            },
            /**
             * 工作流完成按钮触发
             */
            complete(id) {
                var state = '';
                let subData = '';
                this.$confirm('工单及关联工单统统置入已完成', '是否完成', {
                    confirmButtonText: '完成',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 完成该工单
                    state = "完成"
                    subData = 'state=' + state + '&customerWorkId=' + id
                    customerWorkflowButtonToTrigger(subData).then(res => {
                        if (res) {
                            this.$message.success('工单及关联工单统统置入已完成');
                            this.getDataList();
                            this.distribution(id)
                        }
                    })
                }).catch(() => {

                });


            },

            /**
             * 工作流指派按钮触发
             */
            assigned() {
                querySupervisorPermissions().then(res => {
                    if (res) {
                        this.roleList = res.data;
                        this.assignedUser = true
                    }
                })

            },
            /**
             * 指派确定按钮
             */
            assignedUserButton() {

                if (this.multipleSelection.length != null && this.multipleSelection.length < 1) {
                    this.$message({
                        message: "请选择需要指派的人员",
                        type: 'warning'
                    })
                } else if (this.multipleSelection.length > 1) {
                    this.$message({
                        message: "只能选择一个人员进行指派",
                        type: 'warning'
                    })
                } else {
                    var state = '指派';
                    let subData = 'state=' + state + '&customerWorkId=' + this.customerWorkId + '&assignedUserId=' + this.multipleSelection[0].id;
                    customerWorkflowButtonToTrigger(subData).then(res => {
                        if (res) {
                            this.$message.success('指派完成');
                            this.getDataList();
                            this.distribution(this.customerWorkId);
                            this.assignedUser = false
                        }
                    })
                }
            },
            /**
             * 工作流保存按钮触发
             */
            save(id) {
                console.log(this.customerWorkflowDetails.replyForDetails)
                if (this.customerWorkflowDetails.replyForDetails !== null && this.customerWorkflowDetails.replyForDetails !== '') {
                    console.log(123321)
                    // 换行
                    this.customerWorkflowDetails.replyForDetails = this.customerWorkflowDetails.replyForDetails.replace(/\n|\r\n/g, "<br/>")
                }

                // eslint-disable-next-line no-control-regex,no-undef
                let subData = 'state=' + '保存' + '&customerWorkId=' + id
                    + '&workNote=' + this.customerWorkflowDetails.workNote
                    + '&replyForDetails=' + this.customerWorkflowDetails.replyForDetails
                    + '&replyLocationByHand=' + this.customerWorkflowDetails.replyLocationByHand;
                customerWorkflowButtonToTrigger(subData).then(res => {
                    if (res) {
                        this.$message.success('保存完成');
                        this.getDataList();
                        this.distribution(id)
                    }
                })

            },
            handleSizeChange(val) {
                this.pagesize = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            /**
             * 获取工作流list
             */
            getDataList() {
                Array.prototype.indexOf = function(val) {
                    for (var i = 0; i < this.length; i++) {
                        if (this[i] === val) return i;
                    }
                    return -1;
                };
                Array.prototype.remove = function(val) {
                    var index = this.indexOf(val);
                    if (index > -1) {
                        this.splice(index, 1);
                    }
                };
               // 如果登陆角色为服务中心 ,则隐藏待激活工作流
                if (this.roleId === '3'){
                    this.whetherToDisplayActivation = false;
                    this.stateSelection.remove('待激活')
                }
                let subData = 'selectRole=' + this.selectRole + '&stateSelection=' + this.stateSelection
                    + '&siteList=' + this.noticeContractTypeAndSiteInquireValue.toString().charAt(2)
                    + '&contractTypeId='+ this.noticeContractTypeAndSiteInquireValue.toString().charAt(0)
                    + '&currPage=' + this.currentPage
                    + '&pageSizePara=' + this.pagesize
                customerWorkflowList(subData).then(res => {
                    if (res) {
                        this.tableData = res.data;
                        if (res.data.length !== 0) {
                            this.total = res.data[0].totalCount;
                        }

                    }
                })
            },
            // 获取工作流详情
            distribution: function (id,contractTypeId) {
                this.loading = true
                this.customerWorkId = id;

                this.workflowDetailsContractTypeId = contractTypeId;
                let subData = 'id=' + id
                customerWorkflowDetails(subData).then(res => {
                    if (res) {
                        this.customerWorkflowDetails = res.data.details[0];
                        if (this.customerWorkflowDetails.replyForDetails !== null && this.customerWorkflowDetails.replyForDetails !== '') {
                            // 换行
                            var reg = new RegExp("<br/>", "g");
                            this.customerWorkflowDetails.replyForDetails = this.customerWorkflowDetails.replyForDetails.replace(reg, "\r\n");
                        }


                        this.fileList = res.data.fileList;
                        // this.associatedworkflowList = res.data.associatedworkflowList;
                        this.associatedworkflowFileList = res.data.associatedworkflowFileList;
                        if (this.customerWorkflowDetails.assignWorkflowId === null) {
                            // 查看指派工作流信息按钮隐藏
                            this.customerWorkflowDetails.assignWorkflowButton = 'none'
                        }
                    }
                    this.loading = false
                })
                this.dialogFormVisible = true

            },
            // 查看指派工作流信息
            assignWorkflow(assignWorkflowId) {
                this.loading = true
                let subData = 'id=' + assignWorkflowId;
                customerWorkflowDetails(subData).then(res => {
                    if (res) {
                        this.selectassignWorkflow = res.data.details[0]
                        this.assignWorkflowFileList = res.data.fileList
                    }
                    this.loading = false
                })
                this.dialogFormVisible2 = true
            },
            //查看历史工作流
            customerHistoryWorkflowDetails(customerId) {
                this.loading = true
                let subData = 'id=' + customerId;
                customerHistoryWorkflowDetails(subData).then(res => {
                    if (res) {
                        this.customerHistoryWorkflowDetailsList = res.data.customerHistoryWorkflowDetailsList;
                    }
                    this.loading = false
                })
                this.dialogFormVisible3 = true
            },
        },
        created() {
            this.getDataList();
            window.addEventListener('setItem', () => {
                // this.allLoading=true;
                this.siteValue = sessionStorage.getItem('siteValue');
                this.getDataList();
            }, false);

        },
        watch: {
            value: function () {
                this.getDataList()
            },
            stateSelection: function () {
                this.getDataList()
            },
            currentPage: function () {
                this.getDataList()
            },
            pagesize: function () {
                this.getDataList()
            },
        }
    }
</script>


<style scoped lang="scss">
    .client-list-two {
        font-size: 25px;
        height: 40px;
        width: 280px;
        text-align: center;

        .state {
            background: #409EFF;
            color: #FFFFFF;
        }

        .stateGreen {
            background: #7FFFAA;
            color: #696969;
        }

        .stateRed {
            background: #FF0000;
            color: #ffffff;
        }

        .stateMaroon {
            background: #800000;
            color: #ffffff;
        }

        .stateDarkRed {
            background: #DC143C;
            color: #696969;
        }

        .stateDarkgray {
            background: #C0C0C0;
            color: #696969;
        }

        .stateYellow {
            background: #F0E68C;
            color: #696969;
        }

        .lightSkyBlue {
            background: #87CEFA;
            color: #696969;
        }

    }
    .pre-text {
        white-space: pre;
    }

    .el-aside {
        color: #333;
        text-align: left;
        line-height: 55px;
    }

    .el-main {
        /*background-color: #E9EEF3;*/
        color: #333;
        text-align: center;
        height: 620px;
    }

    .el-row {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #E9EEF3;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        height: 40px;
        color: #303133;
        font-size: 21px;
        text-align: left;
    }

    .grid-content-two {
        border-radius: 4px;
        height: 80px;
        color: #303133;
        font-size: 21px;
        text-align: left;
    }

    .grid-content-three {
        border-radius: 4px;
        height: 180px;
        color: #303133;
        font-size: 21px;
        text-align: left;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .over-due {
        /* position: absolute;*/
        /* top:4px;*/
        font-size: 12px;
        color: #FFF;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: red;
        text-align: center;
        line-height: 20px;
    }

    .right-opare {
        display: flex;
        display: -webkit-flex; /* Safari */
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #DDD;
        padding: 10px 20px;
        box-sizing: border-box;
    }

    .des-box {
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 20px 70px 20px;
        /*  border: 1px solid red;*/
        box-sizing: border-box;
    }

    .des {
        margin-top: 20px;
        border: 1px dashed #999;
        border-radius: 10px;
        overflow: hidden;

        .map {
            overflow: hidden;

        }

        .local {
            display: inline-block;
            margin-left: 20px;
        }
    }

    .serve {
        padding: 20px 0 0 20px;
    }

    .des-flex {
        min-width: 600px;
        display: -webkit-flex; /* Safari */
        display: flex;
        height: auto;
        padding-top: 20px;

        .input-box {
            margin-left: 5%;
        }

        .line {
            width: 300px;
        }
    }

    .assess {
        padding: 20px 0 0 30px;

        .line {
            width: 260px;
        }
    }

    #map {
        width: 100%;
        height: 300px;
    }

    .adress {
        padding-left: 5%;

        .line {
            width: 260px;
        }
    }

    .card {
        padding: 0 40px 40px 40px;

        .img-box {
            width: 500px;
            justify-content: space-between;

            p {
                text-align: center;
            }
        }
    }

    /*上传图片*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 364px;
        height: 229px;
        line-height: 229px;
        text-align: center;
    }

    .avatar {
        width: 364px;
        height: 229px;
        display: block;
    }

    /*标签*/
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .form .form-left {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }

    .form .form-right {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }


</style>
