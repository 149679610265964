<template>
	<div class="pagination">
		<div class="block">
			<el-pagination  @current-change="pageChange" :page-size="pageSize"   :current-page.sync="currentPage" :layout="layoutPag" :total="pages"  :pager-count="5">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 1
    };
  },
  props: {
    pages: {
      type: Number,
      default: 0
    },
    pageSize:{
      type: Number,
      default: 10
    },
    layoutPag:{
      type:String,
      default:'total, prev, pager, next'
    }
  },
  components: {},
  filters: {},
  computed: {},
  methods: {
    pageChange(val) {
      this.currentPage = val;
      this.$emit("goSpecifiedPage", val);//子組件的“current-change”事件发生后，触发父组件的“goSpecifiedPage”事件
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped="" type="text/css">
.block {
  text-align: center;
}
</style>
