<template>
  <div class="home">
    <div>
      <el-menu
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#6b011f"
          router v-bind:default-active="$route.path">
        <div class="head-img">
          <img src="@/assets/logo_a.png" alt="">
        </div>
<!--        <el-menu-item index="/overview" style="height: 45px">-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span slot="title">总览</span>-->
<!--        </el-menu-item>-->
        <el-menu-item index="/client" v-if="customer" style="height: 45px">
          <i class="el-icon-user-solid"></i>
          <span slot="title">用户</span>
        </el-menu-item>
        <el-submenu index="6" v-if="sendOrders||mapReport||scheduling">
          <template slot="title">
            <i class="el-icon-date"></i>
            <span>排班模块</span>
          </template>
          <el-menu-item index="/sendOrders" v-if="sendOrders">派单</el-menu-item>
          <el-menu-item index="/mapReport" v-if="mapReport">地图派单</el-menu-item>
          <el-menu-item index="/order" v-if="scheduling">排班</el-menu-item>
        </el-submenu>
        <el-menu-item index="/worksheet" v-if="dailyWorkOrder" style="height: 45px">
          <i class="el-icon-document"></i>
          <span slot="title">工单</span>
        </el-menu-item>
        <el-menu-item index="/staff" v-if="caregiver" style="height: 45px">
          <i class="el-icon-s-custom"></i>
          <span slot="title">护理员</span>
        </el-menu-item>
        <el-menu-item index="/windControl" v-if="windControl" style="height: 45px">
          <i class="el-icon-s-platform"></i>
          <span slot="title">风控</span>
        </el-menu-item>
        <el-submenu index="9">
          <template slot="title">
            <i class="el-icon-s-platform"></i>
            <span>疫情</span>
          </template>
          <el-menu-item index="/epidemic" style="height: 40px">抗原列表</el-menu-item>
        </el-submenu>
        <el-submenu index="5" v-if="single||serviceSituation">
          <template slot="title">
            <i class="el-icon-tickets"></i>
            <span>对单情况</span>
          </template>
           <el-menu-item index="/single" v-if="single">对单</el-menu-item>
          <el-menu-item index="/serviceSituation" v-if="serviceSituation">服务情况</el-menu-item>
        </el-submenu>
        <el-submenu index="7" v-if="report">
          <template slot="title">
            <i class="el-icon-download"></i>
            <span>下载</span>
          </template>
          <el-menu-item index="/statement">报表</el-menu-item>
          <el-menu-item index="/document">文档</el-menu-item>
        </el-submenu>

        <el-submenu index="1" v-if="recommend">
          <template slot="title">
            <i class="el-icon-share"></i>
            <span>推荐</span>
          </template>
          <el-menu-item index="/customerRecommend">推荐老人</el-menu-item>
          <el-menu-item index="/caregiverRecommend">推荐护理员</el-menu-item>
        </el-submenu>
        <el-submenu index="2" v-if="serviceCenterInterface||webmasterInterface">
          <template slot="title">
            <i class="el-icon-s-marketing"></i>
            <span>实时数据</span>
          </template>
          <el-menu-item index="/serviceCenterInterface" v-if="serviceCenterInterface">服务中心</el-menu-item>
          <el-menu-item index="/webmasterInterface" v-if="webmasterInterface">站长界面</el-menu-item>
        </el-submenu>
        <el-submenu index="3" v-if="billingDetails||wage">
          <template slot="title">
            <i class="el-icon-s-data"></i>
            <span>结算模块</span>
          </template>
          <el-menu-item index="/billingDetails" v-if="billingDetails">结算详情</el-menu-item>
          <el-menu-item index="/caregiverBilling" v-if="billingDetails">护理员结算</el-menu-item>
          <el-menu-item index="/wage" v-if="wage">工资</el-menu-item>
        </el-submenu>
        <el-submenu index="8" v-if="homeVisit">
          <template slot="title">
            <i class="el-icon-s-data"></i>
            <span>其他</span>
          </template>
          <el-menu-item index="/homeVisit" v-if="homeVisit">家访</el-menu-item>
        </el-submenu>
        <el-submenu index="4" v-if="manage" style="height: 48px">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span>管理</span>
          </template>
          <el-menu-item index="/neighborhood">居委会的信息</el-menu-item>
          <el-menu-item index="/company">企业信息</el-menu-item>
          <el-menu-item index="/role">角色管理</el-menu-item>
          <el-menu-item index="/crew">人员管理</el-menu-item>
          <el-menu-item index="/site">护理站权限</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="home-right">

      <div class="home-top">
        <div style="margin-left: 0px" @click="toMessage" class="notice"><span class="message-box"
                                                                              @click="toMessage">我的工作流 : {{
            messages
          }}</span>
        </div>
        <div>
<!--          <el-cascader-->
<!--              v-model="contractTypeAndSiteInquireValue"-->
<!--              :options="contractTypeAndSiteInquireValueOptionsTwo"-->
<!--              :props="{ expandTrigger: 'hover' }"-->
<!--              style="float: right"-->
<!--              @change="siteChange()"></el-cascader>-->
          <!--                    <el-select v-model="siteValue" placeholder="请选择站点" class="site line" @change="siteChange()">-->
          <!--                        <el-option-->
          <!--                                v-for="item in siteList"-->
          <!--                                :key="item"-->
          <!--                                :label="item.split(',')[1]"-->
          <!--                                :value="item">-->
          <!--                        </el-option>-->
          <!--                    </el-select>-->

          <el-dropdown @command="handleCommand">
            <div class="user-center" split-button>
              <div class="user-img">
                <img :src="imgHead" alt="">
              </div>
              <span>{{ userName }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-user" command="a">个人中心</el-dropdown-item>
              <el-dropdown-item icon="el-icon-lock" command="b">设置密码</el-dropdown-item>
              <el-dropdown-item icon="el-icon-back" command="c">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="home-main">
        <router-view/>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialog" width="400px">
      <el-form :model="form" label-width="100px" :rules="rules" ref="form">
        <el-form-item label="原密码" prop="oldPwd">
          <el-input v-model="form.oldPwd" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input v-model="form.newPwd" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input v-model="form.confirmPwd" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pwdSubmit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {logout, notify, setPwd, personalCenter} from "@/common/js/index.js"

export default {
  name: 'home',
  data() {
    return {
      contractTypeAndSiteInquireValue: ['1', '1'],
      contractTypeAndSiteInquireValueOptions: JSON.parse(localStorage.getItem('contractTypeAndNursingStation')),//用户可访问合约类型及护理站权限
      contractTypeAndSiteInquireValueOptionsTwo: JSON.parse(localStorage.getItem('contractTypeAndNursingStationTwo')),//用户可访问合约类型及护理站权限
      dialog: false,
      dailyWorkOrder: false,
      billingDetails: false,
      serviceCenterInterface: false,
      recommend: false,
      windControl: false,
      manage: false,
      mapReport: false,
      single: false,
      webmasterInterface: false,
      userDocument: false,
      serviceSituation: false,
      caregiver: false,
      scheduling: false,
      report: false,
      customer: false,
      sendOrders: false,
      wage: false,
      homeVisit:false,
      form: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: ""
      },
      rules: {
        oldPwd: [{required: true, message: '请输入原密码', trigger: 'blur'}],
        newPwd: [{required: true, message: '请输入新密码', trigger: 'blur'}],
        confirmPwd: [{required: true, message: '请再次输入新密码', trigger: 'blur'}],
      },
      userName: localStorage.getItem('chbName'),
      siteList: localStorage.getItem("siteList").split("$"),
      menuList: localStorage.getItem("menuList").split("$"),
      siteValue: sessionStorage.getItem("siteValue"),
      messages: ""
    }
  },
  computed: {
    imgHead() {
      return this.$store.state.img
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    pwdSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.newPwd === this.form.confirmPwd) {
            let subData = {
              password: this.form.newPwd,
              oldPassword: this.form.oldPwd
            };
            setPwd(subData).then(res => {
              if (res) {
                this.dialog = false;
                this.$message.success("修改密码成功")
              }
            })
          } else {
            this.$message.error("两次输入的新密码不一致")
          }

        } else {
          return false;
        }
      });
    },
    getMessage() {
      notify().then(res => {
        if (res) {
          if (res.data.length !== 0) {
            this.messages = res.data.join("，")
          }
        }
      })
    },
    toMessage() {
      this.$router.push({
        name: 'notice'
      })
    },
    loginOut() {
      logout().then(res => {
        this.$router.push({
          name: 'login'
        })
        if (res) {
          this.$message.success('退出成功');
          localStorage.setItem("token", "");
        } else {
          localStorage.setItem("token", "");
        }

      })
    },
    handleCommand(command) {
      switch (command) {
        case 'a':
          this.$router.push({
            name: 'personal'
          })
          break;
        case 'b':
          this.dialog = true;
          break;
        case 'c':
          this.loginOut();
          break;
        default:
          break;
      }
    },
    siteChange() {
      this.resetSetItem('contractTypeAndSiteInquireValue', this.contractTypeAndSiteInquireValue)
    },
    isExist(str) {
      if (this.menuList.indexOf(str) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.dailyWorkOrder = this.isExist("dailyWorkOrder");
    this.billingDetails = this.isExist("billingDetails");
    this.serviceCenterInterface = this.isExist("serviceCenterInterface");
    this.recommend = this.isExist("recommend");
    this.windControl = this.isExist("windControl");
    this.manage = this.isExist("manage");
    this.mapReport = this.isExist("mapReport");
    this.single = this.isExist("serviceSituation");
    this.webmasterInterface = this.isExist("webmasterInterface");
    this.userDocument = this.isExist("userDocument");
    this.serviceSituation = this.isExist("serviceSituation");
    this.caregiver = this.isExist("caregiver");
    this.scheduling = this.isExist("scheduling");
    this.report = this.isExist("report");
    this.timedTask = this.isExist("timedTask");
    this.customerWorkflow = this.isExist("customerWorkflow");
    this.customer = this.isExist("customer");
    this.sendOrders = this.isExist("sendOrders");
    this.wage = this.isExist("wage");
    this.homeVisit = this.isExist("homeVisit");
    this.getMessage();
    var that = this;
    this.timer = setInterval(function () {
      that.getMessage();
    }, 60000)
    personalCenter().then(res => {
      if (res) {
        this.$store.commit("increment", {imgUrl: res.data[0].headPortrait});
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.timer); //关闭
  }
}
</script>
<style scoped lang="scss">
.home {
  display: -webkit-flex; /* Safari */
  display: flex;
  height: 100%;
  /*border: 1px solid red;*/
}

.el-menu-vertical-demo {
  height: 100%;
  width: 138px;
  overflow: hidden;
}

.home-right {
  width: calc(100% - 140px);
  height: 100%;
}

.home-top {
  border-bottom: 1px solid #DDD;
  height: 50px;
  width: 100%;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 420px;
  box-sizing: border-box;

  .message-box {
    cursor: pointer;
    display: block;
    height: 30px;
    margin: 10px 0;
    line-height: 30px;
  }

  .site {
    position: relative;
    top: -10px;
    width: 100px;
  }
}

.home-main {
  width: 100%;
  height: calc(100% - 50px);
  /* border: 1px solid blue;*/
}

.el-menu-item.is-active {
  background-color: #409EFF !important;
}

.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  width: 120px;
}

.head-img {
  width: 55px;
  height: 55px;
  border-radius: 60px;
  overflow: hidden;
  margin: 5px auto;
}

.user-center {
  color: #409EFF;
  font-size: 28px;
  height: 40px;
  /*background: #4A7DFA;*/
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  /* border-radius: 6px;*/
  margin-top: 5px;
  cursor: pointer;

  span {
    margin-top: 7px;
  }
}

.user-center .user-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  margin: 5px 20px 0 0;
}

.el-dropdown-menu {
  width: 160px;
}

.notice {
  border-top-left-radius: 3px;
}
</style>
