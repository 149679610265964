import { render, staticRenderFns } from "./caregiverRecommend.vue?vue&type=template&id=6fe62090&scoped=true&"
import script from "./caregiverRecommend.vue?vue&type=script&lang=js&"
export * from "./caregiverRecommend.vue?vue&type=script&lang=js&"
import style0 from "./caregiverRecommend.vue?vue&type=style&index=0&id=6fe62090&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe62090",
  null
  
)

export default component.exports